import { Pipe, PipeTransform } from '@angular/core';

import { BreadcrumbTheme } from '@shared/enums/breadcrumb-theme.enum';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';

@Pipe({
  name: 'kanbanHeaderWorkflowStep',
})
export class KanbanHeaderWorkflowStepPipe implements PipeTransform {
  transform(
    step: WorkflowStepEnumType,
    currentStep: WorkflowStepEnumType,
    currentTheme: BreadcrumbTheme
  ): BreadcrumbTheme {
    if (!step) {
      return BreadcrumbTheme.KANBAN_GRAY2;
    }

    if (!currentStep) {
      return currentTheme;
    }

    if (step < currentStep) {
      return BreadcrumbTheme.KANBAN_GREEN8;
    }

    if (step === currentStep) {
      return BreadcrumbTheme.KANBAN_GREEN4;
    }

    return BreadcrumbTheme.KANBAN_GRAY2;
  }
}
