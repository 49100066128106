import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import {
  RestPortfolioWorkflowStepAssignmentModel,
  RestWorkflowPortfolioStepAssignmentModel,
  RestWorkflowStepAssignmentModel,
} from '@shared/interfaces/rest-workflow-step-assignment-model.interface';

@Injectable({
  providedIn: 'root',
})
export class TurnoverConfigurationStepAssigneesService {
  constructor(private restApiService: RestapiService) {}

  getDefaultAssigneesForPortfolio(portfolioId: number): Observable<RestPortfolioWorkflowStepAssignmentModel[]> {
    return this.restApiService.getData<RestPortfolioWorkflowStepAssignmentModel[]>(
      `PorfolioDefaultAssignments/${portfolioId}`
    );
  }

  updateDefaultAssigneesForPortfolio(
    portfolioId: number,
    items: RestWorkflowPortfolioStepAssignmentModel[]
  ): Observable<void> {
    return this.restApiService.create<void>(`PorfolioDefaultAssignments/${portfolioId}`, items);
  }

  deleteDefaultAssigneesForPortfolio(portfolioId: number, stepId: WorkflowStepEnumType): Observable<void> {
    return this.restApiService.delete<void>(`PorfolioDefaultAssignments/${portfolioId}/${stepId}`);
  }

  getResponsiblePartiesByPortfolioId(portfolioId: number): Observable<RestWorkflowStepAssignmentModel[]> {
    return this.restApiService.getData<RestWorkflowStepAssignmentModel[]>(
      `workflowassignments/portfolio/${portfolioId}`
    );
  }

  getResponsiblePartiesOverrideByPortfolioId(portfolioId: number): Observable<RestWorkflowStepAssignmentModel[]> {
    return this.restApiService.getData<RestWorkflowStepAssignmentModel[]>(
      `workflowassignments/portfolio/overrides/${portfolioId}`
    );
  }

  getResponsiblePartiesByPropertyId(propertyId: number): Observable<RestWorkflowStepAssignmentModel[]> {
    return this.restApiService.getData<RestWorkflowStepAssignmentModel[]>(`workflowassignments/property/${propertyId}`);
  }

  getWorkflowAssignmentsByStepId(
    propertyId: number,
    stepId: WorkflowStepEnumType
  ): Observable<RestWorkflowStepAssignmentModel> {
    return this.restApiService.getData<RestWorkflowStepAssignmentModel>(
      `workflowassignments/property/${propertyId}/${stepId}`
    );
  }

  createResponsibleParties(
    workflowList: RestWorkflowStepAssignmentModel[]
  ): Observable<RestWorkflowStepAssignmentModel[]> {
    return this.restApiService.create<RestWorkflowStepAssignmentModel[]>(`workflowassignments`, workflowList);
  }

  updateResponsibleParties(
    workflowList: RestWorkflowStepAssignmentModel[]
  ): Observable<RestWorkflowStepAssignmentModel[]> {
    return this.restApiService.update<RestWorkflowStepAssignmentModel[]>(`workflowassignments`, workflowList);
  }

  deleteResponsibleParties(
    propertyId: number,
    workflowStepType?: WorkflowStepEnumType
  ): Observable<RestWorkflowStepAssignmentModel[]> {
    let uri = `workflowassignments/property/${propertyId}`;
    if (workflowStepType) {
      uri += `?workflowStepType=${workflowStepType}`;
    }
    return this.restApiService.delete<RestWorkflowStepAssignmentModel[]>(uri);
  }
}
