import { ChangeDetectorRef } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter, map } from 'rxjs';

import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { DndExpandableService } from './dnd-expandable.service';

const sizeForCollapsedItem = 20;
const sizeForWarningMessage = 50;

export abstract class CommonExpandWorker {
  constructor(
    protected cdr: ChangeDetectorRef,
    protected dndExpandableService: DndExpandableService,
    protected snackbarService: SnackbarService
  ) {}

  protected _parentAreaId: number;
  protected _isExpanded = true;
  private _itemsAmount: number;
  singleExpandClick = true;
  isContentVisible = true;
  isExpandedState = true;

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    if (value && !this.isContentVisible) {
      this.isContentVisible = value;
      this.cdr.markForCheck();
      setTimeout(() => {
        this._isExpanded = value;
        this.cdr.markForCheck();
      }, 25);
    } else {
      this._isExpanded = value;
    }
    if (!value) {
      this.dndExpandableService.viewportZoneChanging.next(null);
    }
  }

  init(itemsAmount: number, parentAreaId: number, component: any): void {
    this._parentAreaId = parentAreaId;
    this._itemsAmount = itemsAmount;
    this._isExpanded = itemsAmount <= sizeForCollapsedItem;
    this.isContentVisible = this.isExpanded;
    this.getDndEvent()
      .pipe(untilDestroyed(component))
      .subscribe(e => {
        if (e == 'start') {
          this.isExpandedState = this.isExpanded;
          this.isExpanded = false;
        } else {
          this.isExpanded = this.isExpandedState;
        }
        this.cdr.markForCheck();
      });
    this.getExpandEvent()
      .pipe(untilDestroyed(component))
      .subscribe(e => {
        this.isExpanded = e;
        this.cdr.markForCheck();
      });
  }

  abstract getDndEvent(): Observable<'start' | 'end'>;

  abstract getExpandEvent(): Observable<boolean>;

  toggleExpand() {
    this.singleExpandClick = true;
    setTimeout(() => {
      if (this.singleExpandClick) {
        this.isExpanded = !this.isExpanded;
        this.cdr.markForCheck();
      }
    }, 200);
  }

  toggleExpandAll() {
    this.singleExpandClick = false;
    if (!this.isContentVisible && this._itemsAmount >= sizeForWarningMessage) {
      this.snackbarService.warning('Woah… big…  this may take a while');
      setTimeout(() => {
        this.dndExpandableService.expandAllEvent.next({ isExpanded: !this.isExpanded, areaId: this._parentAreaId });
        setTimeout(() => this.snackbarService.dismiss(), this.dndExpandableService.expandDuration + 100);
      }, 100);
    } else {
      this.dndExpandableService.expandAllEvent.next({ isExpanded: !this.isExpanded, areaId: this._parentAreaId });
    }
  }
}

export class AreaExpandWorker extends CommonExpandWorker {
  getDndEvent(): Observable<'start' | 'end'> {
    return this.dndExpandableService.dndAreaEvent$;
  }

  getExpandEvent(): Observable<boolean> {
    return this.dndExpandableService.expandAreaEvent$;
  }
}

export class ItemExpandWorker extends CommonExpandWorker {
  getDndEvent(): Observable<'start' | 'end'> {
    return this.dndExpandableService.dndItemEvent$;
  }

  getExpandEvent(): Observable<boolean> {
    return this.dndExpandableService.expandItemEvent$.pipe(
      filter(e => e.areaId == this._parentAreaId),
      map(e => e.isExpanded)
    );
  }
}

export class WrapperExpandWorker extends CommonExpandWorker {
  init() {
    //
  }

  getDndEvent(): Observable<'start' | 'end'> {
    return null;
  }

  getExpandEvent(): Observable<boolean> {
    return null;
  }
}
