import { GeneralRole } from '@shared/interfaces/general-role';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export function getGeneralRoleRadioList(generalRoles: GeneralRole[]): IRadioButtonOption<number>[] {
  return generalRoles.map(item => {
    return {
      value: item.id,
      label: item.roleName,
    };
  });
}
