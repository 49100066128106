import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(bytesNumber: number): string {
    if (bytesNumber < 1024) return `${bytesNumber} bytes`;

    const kBNumber = bytesNumber / 1024;
    if (kBNumber < 1024) return `${kBNumber.toFixed(0)} KB`;

    const mBNumber = kBNumber / 1024;
    if (mBNumber < 1024) return `${mBNumber.toFixed(0)} MB`;

    const gBNumber = mBNumber / 1024;
    if (gBNumber < 1024) return `${gBNumber.toFixed(0)} GB`;

    const tBNumber = gBNumber / 1024;
    if (tBNumber < 1024) return `${tBNumber.toFixed(0)} TB`;
  }
}
