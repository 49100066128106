import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GenerateThumbnailService {
  generateThumbnail(file: File): Observable<{ thumbnail: Blob; duration?: number }> {
    const isVideo = file.type.startsWith('video/');
    return isVideo ? this.generateVideoThumbnail(file) : this.compressImage(file);
  }

  private generateVideoThumbnail(file: File, captureTime = 1): Observable<{ thumbnail: Blob; duration: number }> {
    return new Observable(observer => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const objectURL = URL.createObjectURL(file);

      video.src = objectURL;
      video.load();

      video.onloadedmetadata = () => {
        video.currentTime = captureTime;
      };

      video.onseeked = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          blob => {
            URL.revokeObjectURL(objectURL);
            if (blob) {
              observer.next({ thumbnail: blob, duration: video.duration });
              observer.complete();
            } else {
              observer.error(new Error('Failed to generate thumbnail'));
            }
          },
          'image/jpeg',
          0.5
        );
      };

      video.onerror = () => {
        URL.revokeObjectURL(objectURL);
        observer.error(new Error('Error loading video'));
      };
    });
  }

  private compressImage(file: File): Observable<{ thumbnail: Blob }> {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    return new Observable<{ thumbnail: Blob }>(observer => {
      reader.onload = e => {
        img.src = e.target?.result as string;
      };

      reader.onerror = () => {
        observer.error('Error reading image file');
      };

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        this.compressCanvasToBlob(canvas, 0.5).subscribe({
          next: blob => observer.next({ thumbnail: blob }),
          error: err => observer.error(err),
          complete: () => observer.complete(),
        });
      };

      reader.readAsDataURL(file);
    });
  }

  private compressCanvasToBlob(canvas: HTMLCanvasElement, quality: number): Observable<Blob> {
    return new Observable<Blob>(observer => {
      canvas.toBlob(
        blob => {
          if (blob) {
            observer.next(blob);
            observer.complete();
          } else {
            observer.error('Failed to compress canvas');
          }
        },
        'image/jpeg',
        quality
      );
    });
  }
}
