import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';

import { AnimationComponent } from './animation.component';

@NgModule({
  declarations: [AnimationComponent],
  imports: [CommonModule, LottieModule],
  exports: [AnimationComponent],
})
export class AnimationModule {}
