import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';

@Pipe({
  name: 'labelAvailableDayInStep',
})
export class LabelAvailableDayInStepPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(dayInStep: number): string {
    const dt = moment(this.timezoneService.getCurrentDate()).add(dayInStep, 'days').format('MM/DD');
    const days = Math.abs(dayInStep);
    return `Available on ${dt}`;
    // Lyman commented this out to try a simpler approach
    // if (dayInStep === 0) {
    //   return 'Available today';
    // } else if (dayInStep > 0) {
    //   if (dayInStep > 1) {
    //     return `Available in ${days} days (${dt})`;
    //   } else {
    //     return `Available in ${days} day (${dt})`;
    //   }
    // } else {
    //   if (dayInStep < -1) {
    //     return `Available ${days} days ago (${dt})`;
    //   } else {
    //     return `Available ${days} day ago (${dt})`;
    //   }
    // }
  }
}
