import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNumber } from 'lodash';
import { BehaviorSubject, take, timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { procentCut } from '@app/utils/procent-cut';
import {
  InspectionSummaryAppliedFilters,
  InspectionSummaryExpandedTableData,
  InspectionSummaryStatsAssigneeDTO,
  InspectionSummaryTableDataTotal,
} from '@main-application/inspections/models/inspection-summary.model';
import { InspectionStatus } from '@main-application/inspections/models/rest-inspections-model.interface';
import { InspectionsFacade } from '@main-application/inspections/store/inspections.facade';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { scrollToElement } from '@shared/utils/dom/scroll-to-element.util';
import { SummaryExpandedRecord } from '@ui-components/modals/inspections-summary-report-modal/components/summary-expanded-table/summary-expanded-table.model';
import { InspectionSummaryClickFiltering } from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@UntilDestroy()
@Component({
  selector: 'app-summary-expanded-table',
  templateUrl: './summary-expanded-table.component.html',
  styleUrls: ['./summary-expanded-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryExpandedTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() summaryExpandedTableData: InspectionSummaryExpandedTableData[] = [];

  @Input() expandedRawsIds: number[] = [];

  @Input() templateScrollPointName: string | null = null;

  @Output() filterInspectionListValue = new EventEmitter<InspectionSummaryClickFiltering>();

  expandedTableData: InspectionSummaryExpandedTableData[] = [];
  expandedStatusRecord: SummaryExpandedRecord[] = [];

  appliedFilterObservable$ = this.inspectionsFacade.appliedFilter$.pipe(take(1));

  appliedFilters$ = new BehaviorSubject<InspectionSummaryAppliedFilters | null>(null);

  readonly eColorPalette = EColorPalette;
  readonly inspectionStatus = InspectionStatus;

  get expandedPropertyIds(): number[] {
    return this.expandedStatusRecord.filter(a => a.opened).map(a => a.propertyId);
  }

  constructor(private inspectionsFacade: InspectionsFacade, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.appliedFilterObservable$.subscribe(value => this.appliedFilters$.next(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.expandedTableData = changes.summaryExpandedTableData.currentValue;
    this.expandedStatusRecord = this.expandedTableData.map(a => ({ propertyId: a.propertyId, opened: false }));

    if (changes.expandedRawsIds.currentValue.length) {
      this.expandedStatusRecord.forEach(a => (a.opened = changes.expandedRawsIds.currentValue.includes(a.propertyId)));
    }
  }

  ngAfterViewInit() {
    this.scrollTo();
  }

  scrollTo(): void {
    timer(50)
      .pipe(
        tap(() => scrollToElement(`area-${this.templateScrollPointName}`)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getExpandedStatus(propertyId: number): boolean {
    return this.expandedStatusRecord.find(a => a.propertyId === propertyId)?.opened;
  }

  setExpandedStatus(propertyId: number, status: boolean): void {
    const record = this.expandedStatusRecord.find(a => a.propertyId === propertyId);
    if (record) {
      record.opened = status;
    }

    this.inspectionsFacade.setAppliedFilters({
      ...this.appliedFilters$.value,
      expandedPropertyIds: this.expandedPropertyIds,
    });
  }

  getContext(
    item: InspectionSummaryExpandedTableData,
    showTotal: boolean
  ): { showTotal: boolean } & InspectionSummaryTableDataTotal {
    return { showTotal, ...item.total };
  }

  getCompletedLate(value: number, total: number): number {
    return procentCut((value / total) * 100);
  }

  onFilterInspectionList(value: InspectionSummaryStatsAssigneeDTO, status?: number): void {
    const filter = {
      templateId: value.templateId,
      assigneeId: value.assigneeId,
      groupByPropertyId: value.propertyId,
      status: isNumber(status) ? status : null,
    } as InspectionSummaryClickFiltering;

    this.filterInspectionListValue.emit(filter);

    this.updateExpandedFilters(value.inspectionName);
  }

  private updateExpandedFilters(template: string): void {
    this.inspectionsFacade.setAppliedFilters({
      ...this.appliedFilters$.value,
      template,
      expandedPropertyIds: this.expandedPropertyIds,
    });
  }
}
