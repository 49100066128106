import { Pipe, PipeTransform } from '@angular/core';

import { getCurrentWorkflowStep } from '@shared/functions/get-current-workflow-step.function';
import { RestTurnoverDataModelNew, RestWorkflowInstanceModel } from '@shared/interfaces/turnover.interface';

@Pipe({
  name: 'getCurrentWorkflowStep',
})
export class GetCurrentWorkflowStepPipe implements PipeTransform {
  transform(turnoverData: RestTurnoverDataModelNew): RestWorkflowInstanceModel {
    return getCurrentWorkflowStep(turnoverData.activeWorkflowStepType, turnoverData.workflow);
  }
}
