import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputErrorService } from '@app/modules/ui-components/components/input-error/services/input-error.service';

import { InputErrorComponent } from './input-error.component';

@NgModule({
  declarations: [InputErrorComponent],
  providers: [InputErrorService],
  exports: [InputErrorComponent],
  imports: [CommonModule],
})
export class InputErrorModule {}
