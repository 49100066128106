import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'getFromGroup',
})
export class GetFromGroupPipe implements PipeTransform {
  transform(group: FormGroup, fieldName: string): FormGroup {
    if (!group || !fieldName) {
      return null;
    }

    return group.get(fieldName) as FormGroup;
  }
}
