import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, map, merge, of, take, tap } from 'rxjs';

import { EIcon } from '@shared/enums/icon.enum';
import { ImageOrientation } from '@shared/interfaces';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { ModalsService } from '@ui-components/modals/modals.service';

import { InspectionStepModel } from '../../models/inspection-step.model';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoViewerComponent {
  EIcon = EIcon;
  currentUrl: { cloudUri: string; thumbnailFileUploadCloudUri?: any; imageOrientation?: ImageOrientation };
  deletedImages: string[] = [];
  protected readonly ImageOrientation = ImageOrientation;
  private modalsService = inject(ModalsService);
  private cdr = inject(ChangeDetectorRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      step: InspectionStepModel;
      imageUrls: { cloudUri: string; thumbnailFileUploadCloudUri: any; imageOrientation?: ImageOrientation }[];
      initialIndex: number;
      isEditorMode?: boolean;
    },
    private dialogRef: MatDialogRef<PhotoViewerComponent>
  ) {
    this.currentUrl = this.data.imageUrls[this.data.initialIndex] || this.data.imageUrls[0];
  }

  onChange(mediaUrl: string): void {
    this.currentUrl = this.data.imageUrls.find(el => el.cloudUri === mediaUrl);
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close({
      deletedImages: this.deletedImages,
    });
  }

  private confirmDeletionDialog() {
    const dialogRef = this.modalsService.openConfirmationModal({
      title: `Are you sure you want to remove this?`,
      confirmColor: 'warn',
      cancelLabel: 'Cancel',
      confirmLabel: 'Confirm',
    });

    const useCloseParentDialog = this.dialogRef.afterClosed().pipe(
      take(1),
      tap(() => dialogRef.close()),
      map(() => of(DialogResult.Fail))
    );

    const useCloseTargetDialog = dialogRef.afterClosed().pipe(take(1));

    return merge(useCloseParentDialog, useCloseTargetDialog).pipe(filter(result => result === DialogResult.Success));
  }

  deleteImage(cloudUri: string): void {
    this.confirmDeletionDialog().subscribe(() => {
      const index = this.data.imageUrls.findIndex(el => el.cloudUri === cloudUri);
      if (index !== -1) {
        this.deletedImages.push(cloudUri);
        this.data.imageUrls.splice(index, 1);

        if (this.data.imageUrls.length > 0) {
          this.currentUrl = this.data.imageUrls[0];
        } else {
          this.close();
        }
      }
      this.cdr.detectChanges();
    });
  }

  isFile(cloudUri: string): boolean {
    return cloudUri.endsWith('.pdf') || cloudUri.endsWith('.docx');
  }

  public isImage(url: string): boolean {
    const extension = url.split('.').pop()?.toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);
  }

  public openInNewTab(cloudUri: string): void {
    window.open(cloudUri, '_blank');
  }
  downloadFile(cloudUri: string): void {
    const link = document.createElement('a');
    link.href = cloudUri;
    link.download = cloudUri.split('/').pop() || 'file';
    link.click();
  }
}
