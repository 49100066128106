import { Pipe, PipeTransform } from '@angular/core';

import { PriorityType } from '@shared/enums/priority-type';

const TaskCssClasses = {
  ERROR: 'error',
  WARNING: 'warning',
  ACCENT: 'accent',
};

@Pipe({
  name: 'priorityToCssClass',
})
export class PriorityToCssClassPipe implements PipeTransform {
  transform(value: PriorityType): string {
    switch (value) {
      case PriorityType.High:
        return TaskCssClasses.ERROR;
      case PriorityType.Medium:
        return TaskCssClasses.WARNING;
      default:
        return TaskCssClasses.ACCENT;
    }
  }
}
