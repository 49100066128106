import { Pipe, PipeTransform } from '@angular/core';

import { NoValue } from '@shared/constants/none-radio-option.const';

@Pipe({
  name: 'customNumber',
})
export class NumberPipe implements PipeTransform {
  transform(value: number, precision = 0, suffix = ''): string {
    if (isNaN(value) || value === null) {
      return NoValue;
    }
    return value.toFixed(precision) + suffix;
  }
}
