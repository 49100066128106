import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CountryCodes } from '@shared/constants/country-phone-codes.const';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { parsePhoneNumber } from '@ui-components/validators/phone.validator';

@UntilDestroy()
@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputModule, RadioButtonDropdownModule, MatIconModule, NgIf, NgClass, InputErrorModule],
})
export class PhoneNumberInputComponent extends CustomControlAbstract<string> implements OnInit {
  @Input() attrLabel: string;
  protected readonly countryCodes = CountryCodes;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  phoneCodeControl = new FormControl<number>(null);
  phoneNumberControl = new FormControl<string>(null);

  ngOnInit(): void {
    this.initControlBase();

    this.control.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(value => {
      this.parsePhoneNumber(value);
    });

    this.control.registerOnDisabledChange(isDisabled => {
      this.setDisabled(isDisabled);
    });

    this.phoneCodeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.validateAndEmitValue();
        })
      )
      .subscribe();

    this.phoneNumberControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.validateAndEmitValue();
        })
      )
      .subscribe();

    this.setDisabled(this.control.disabled);
    this.parsePhoneNumber(this.control.value);
  }

  writeValue(value: any): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  private setDisabled(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneNumberControl.disable();
      this.phoneCodeControl.disable();
    } else {
      this.phoneNumberControl.enable();
      this.phoneCodeControl.enable();
    }
  }

  private validateAndEmitValue() {
    const phoneCode = this.phoneCodeControl.value;
    const phoneNumberRaw = this.phoneNumberControl.value;
    if (phoneNumberRaw && phoneCode) {
      const phoneNumber = phoneNumberRaw.replace(/\D/g, '');
      if (phoneNumber.length === 10) {
        this.onChanged(`+${phoneCode}${phoneNumber}`);
      }
    }
  }

  private parsePhoneNumber(value: string) {
    const { phoneCode, phoneNumber } = parsePhoneNumber(value);
    if (phoneCode) {
      this.phoneCodeControl.setValue(parseInt(phoneCode));
    }
    this.phoneNumberControl.setValue(phoneNumber);
  }
}
