import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'valueIfPositive' })
export class ValueIfPositivePipe implements PipeTransform {
  transform(value: number): number {
    if (!value) return null;

    if (0 > value) return null;

    return value;
  }
}
