import { CommonModule } from '@angular/common';
import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matTooltip]',
})
export class TooltipDirective {
  @Input() showTooltip = true;

  constructor(private matTooltip: MatTooltip) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.showTooltip) {
      this.matTooltip.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.matTooltip.hide();
  }
}

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [TooltipDirective],
  exports: [TooltipDirective, MatTooltipModule],
})
export class TooltipDirectiveModule {}
