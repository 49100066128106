import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'lodash';

@Pipe({
  name: 'first',
})
export class FirstPipe implements PipeTransform {
  transform<T>(value: T[]): T {
    return first(value);
  }
}
