import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueLength',
})
export class ValueLengthPipe implements PipeTransform {
  transform(value: string | number): number {
    return (value || '').toString().length;
  }
}
