<div class="turnover-filter">
  <button type="button" class="turnover-filter-trigger" mat-button color="primary" [matMenuTriggerFor]="filterMenu">
    <app-icon [svg]="EIcon.FILTER" [iconWidth]="24" [iconHeight]="24"></app-icon>
    <span *ngIf="filter | notEmptyPropsAmount: false:excludedProps" class="turnover-filter-amount">
      {{ filter | notEmptyPropsAmount: false:excludedProps }}
    </span>
  </button>
  <mat-menu #filterMenu="matMenu" class="menu-with-backdrop filter-menu">
    <div class="filters-wrapper" [style.padding]="padding">
      <ng-container *ngIf="showClearFilters">
        <div class="filters-header" appStopPropagation>
          <a class="text-color red8 text-size s14" (click)="clearFilter()">Clear filters</a>
        </div>
      </ng-container>
      <div class="filters" appStopPropagation>
        <div class="filter-column">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
