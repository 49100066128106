import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pluralization' })
export class PluralizationPipe implements PipeTransform {
  transform(value: string, count: number): string {
    if (!value) return null;

    if (count == null) {
      return value;
    }

    if (count === 0 || count > 1) {
      return `${value}s`;
    }
    return value;
  }
}
