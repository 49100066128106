<div [ngClass]="containerCss" class="attachment-item-container display-flex flex-column">
  <ng-container
    *ngIf="attachmentItem?.fileUploadId || newAttachmentItem?.upload"
    [ngTemplateOutlet]="fileTemplate"
    [ngTemplateOutletContext]="{
      attachmentItem: attachmentItem?.fileUploadId ? attachmentItem : newAttachmentItem?.upload
    }"></ng-container>
  <ng-container
    *ngIf="attachmentItem?.externalUrl || newAttachmentItem?.link"
    [ngTemplateOutlet]="linkTemplate"
    [ngTemplateOutletContext]="{
      attachmentItem: attachmentItem?.externalUrl ? attachmentItem : newAttachmentItem?.link
    }"></ng-container>
</div>

<ng-template #fileTemplate let-attachmentItem="attachmentItem">
  <div class="display-flex align-items-center grid-gap-16">
    <div *ngIf="!showPreview" class="display-flex align-items-center flex-1 justify-content-center">
      <ng-container
        [ngTemplateOutlet]="downloadButton"
        [ngTemplateOutletContext]="{
          color: EColorPalette.cBlack
        }"></ng-container>
    </div>
    <div *ngIf="showPreview" class="display-flex align-items-center grid-gap-16 flex-1 fade-truncate attachment-item">
      <div [ngSwitch]="attachmentItem.originalFileName | fileMimeType" class="attachment-item-preview">
        <ng-container *ngSwitchCase="FileMimeType.Image">
          <img
            [attr.src]="attachmentItem.cloudUri || attachmentItem.url"
            [attr.alt]="attachmentItem.title"
            class="display-flex img-control" />
          <div class="download-button">
            <ng-container
              [ngTemplateOutlet]="downloadButton"
              [ngTemplateOutletContext]="{
                color: EColorPalette.cWhite
              }"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="attachment-item-preview-default"></div>
          <div class="download-button">
            <ng-container
              [ngTemplateOutlet]="downloadButton"
              [ngTemplateOutletContext]="{ color: EColorPalette.cGreen }"></ng-container>
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="showFileName"
        class="flex-1"
        [ngClass]="labelCss"
        [matTooltip]="
          attachmentItem?.originalFileName ||
          attachmentItem?.title ||
          newAttachmentItem.upload?.originalFileName ||
          newAttachmentItem.upload?.internalName ||
          ''
        ">
        {{
          attachmentItem?.originalFileName ||
            attachmentItem?.title ||
            newAttachmentItem.upload?.originalFileName ||
            newAttachmentItem.upload?.internalName ||
            ''
        }}
      </div>
    </div>
    <div *ngIf="showFileSize" class="display-flex align-items-center grid-gap-8 ml-auto">
      <div [ngClass]="labelCss">{{ attachmentItem?.size || newAttachmentItem.upload?.size | fileSize }}</div>
      <ng-container *ngIf="showDeleteBtn" [ngTemplateOutlet]="deleteButtonTemplate"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #linkTemplate let-attachmentItem="attachmentItem">
  <div class="display-flex justify-content-space-between grid-gap-8">
    <div class="display-flex align-items-center grid-gap-16 flex-1 fade-truncate attachment-item">
      <button
        type="button"
        mat-icon-button
        class="btn-attachment"
        appStopPropagation
        [matTooltip]="'Copy link'"
        [disabled]="lockFields"
        (click)="copyLink()">
        <app-icon [svg]="EIcon.HYPERLINK" [iconWidth]="24" [iconHeight]="24"></app-icon>
      </button>
      <ng-container [ngTemplateOutlet]="linkValueTemplate"></ng-container>
    </div>
    <ng-container *ngIf="showDeleteBtn" [ngTemplateOutlet]="deleteButtonTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #deleteButtonTemplate>
  <button
    type="button"
    class="button-icon-background btn-attachment"
    mat-icon-button
    (click)="deleteConfirmation()"
    [matTooltip]="'Remove attachment'"
    [disabled]="lockFields || lockDelete">
    <app-icon [svg]="EIcon.CLOSE" [iconWidth]="22" [iconHeight]="22" [iconColor]="EColorPalette.cRed2"></app-icon>
  </button>
</ng-template>

<ng-template #linkValueTemplate>
  <div
    [ngClass]="labelCss"
    *ngIf="trimLink"
    class="body-description flex-1"
    [matTooltip]="attachmentItem?.externalUrl || newAttachmentItem?.link || ''">
    <a href="{{ getLink() }}" target="_blank">{{ attachmentItem?.externalUrl || newAttachmentItem?.link || '' }}</a>
  </div>

  <div [ngClass]="labelCss" *ngIf="!trimLink" class="body-description">
    {{ attachmentItem?.externalUrl || newAttachmentItem?.link || '' }}
  </div>
</ng-template>

<ng-template #downloadButton let-color="color">
  <button
    type="button"
    mat-icon-button
    class="btn-attachment"
    appStopPropagation
    [matTooltip]="'Download file'"
    [disabled]="lockFields"
    (click)="download()">
    <app-icon [svg]="EIcon.ARROW_TO_BOTTOM" [iconWidth]="24" [iconHeight]="24" [iconColor]="color"></app-icon>
  </button>
</ng-template>
