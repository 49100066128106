import { v4 as uuidv4 } from 'uuid';

import { RestTemplateAreaModel, RestTemplateModel } from '@template/models/rest-template-model.interface';

import { AreaHelper } from './area.helper';
import { AreaModel, AreaSurveyModel } from './template-editor-model';

export class TemplateEditorContent {
  areas: AreaModel[] = [];

  constructor(template?: RestTemplateModel) {
    if (template) {
      this.init(template);
    }
  }

  init(template: RestTemplateModel) {
    this.areas = template.inspectionTemplateAreas
      .sort((a, b) => {
        if (a.position > b.position) return 1;
        if (a.position < b.position) return -1;
        return 0;
      })
      .map(area => ({ ...area, areaSurvey: this.jsonToAreaSurveyModel(area.areaSurveyJson) }));
  }

  getClonedArea(area: AreaModel) {
    const maxCloneIndex = this.areas
      .filter(e => new RegExp(`^Clone( #[0-9]*)* - ${area.title}$`).test(e.title))
      .map(c => {
        const matches = /Clone #([0-9]+)/.exec(c.title);
        if (!matches?.length) {
          return 1;
        } else {
          return +matches?.[1];
        }
      })
      .reduce((prev, max) => (prev > max ? prev : max), 0);

    const title = `Clone${maxCloneIndex + 1 === 1 ? '' : ` #${maxCloneIndex + 1}`} - ${area.title}`;
    const { id, ...rest }: AreaModel = {
      ...area,
      title,
      areaSurvey: {
        ...area.areaSurvey,
        title,
        pages: area.areaSurvey.pages?.map(page => AreaHelper.getClonedItem(page, title)),
      },
    };

    return rest;
  }

  addArea(area: RestTemplateAreaModel) {
    const index = this.areas.findIndex(e => e.position >= area.position);
    const newArea = { ...area, areaSurvey: this.jsonToAreaSurveyModel(area.areaSurveyJson) };
    if (index >= 0) {
      this.areas.splice(index, 0, newArea);
      for (let i = index + 1; i < this.areas.length; i++) {
        if (this.areas[i - 1] && this.areas[i - 1].position >= this.areas[i].position) {
          this.areas[i].position = this.areas[i - 1].position + 1;
        }
      }
    } else {
      this.areas.push(newArea);
    }
  }

  replaceTempAreaWithRestArea(id: number, restArea: RestTemplateAreaModel) {
    this.areas = this.areas.map(area => {
      if (area.id === id) {
        return { ...restArea, areaSurvey: this.jsonToAreaSurveyModel(area.areaSurveyJson) };
      } else {
        return area;
      }
    });
  }

  removeArea(id: number) {
    this.areas = [...this.areas.filter(item => item.id !== id)];
  }

  private jsonToAreaSurveyModel(areaSurveyJson: string): AreaSurveyModel {
    const areaSurvey = JSON.parse(areaSurveyJson) as AreaSurveyModel;
    areaSurvey.pages ??= [];
    areaSurvey.pages.forEach(page => {
      page.uuid = uuidv4();
      page.compulsoryItems ??= [];
      page.elements.forEach(e => {
        if (e.type === 'propertystate') {
          e.items.forEach(item => {
            item.item.onSelect ??= [];
            item.item.isDefault ??= false;
            item.item.showQtyWidget ??= false;
            item.item.timeMultiplier ??= 0;
            item.item.materialsMultiplier ??= 0;
          });
        }
      });
    });
    return areaSurvey;
  }

  updateArea(item: AreaModel) {
    this.areas = this.areas.map(innerItem => (innerItem.id !== item.id ? innerItem : { ...innerItem, ...item }));
  }
}
