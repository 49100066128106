import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

import { IDateSelectConfig } from '@app/modules/ui-components/components/date-range-dropdown/config/interfaces/date-select-config.interface';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import { ChartTypes } from '@dashboards/models/chart-types.enum';
import { TurnoverForm } from '@main-application/turnovers/config/enums/turnover-form';
import { NoValue } from '@shared/constants/none-radio-option.const';
import { PriorityTypeListConst } from '@shared/constants/priority-type-list.const';
import { WorkflowStepListConst } from '@shared/constants/workflow-step-list.const';
import { Animation } from '@shared/enums/animation';
import { AuditableEntityType } from '@shared/enums/auditable-entity-type';
import { BreadcrumbTheme } from '@shared/enums/breadcrumb-theme.enum';
import { ButtonType } from '@shared/enums/button-type';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { CompareOperator } from '@shared/enums/compare-operator.enum';
import { DashboardViewType } from '@shared/enums/dashboard-view-type';
import { DelinquencyResultType } from '@shared/enums/delinquency-result-type';
import { EDropdownTab } from '@shared/enums/dropdown-tab.enum';
import { FieldType } from '@shared/enums/field-type';
import { EIcon } from '@shared/enums/icon.enum';
import { LoaderType } from '@shared/enums/loader-type';
import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';
import { MainPageActions } from '@shared/enums/main-page-actions';
import { ManagementRoute } from '@shared/enums/management-route.enum';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { PillOptions } from '@shared/enums/pill-options.enum';
import { PriorityType } from '@shared/enums/priority-type';
import { RoutePath } from '@shared/enums/route-path.enum';
import { ESelectionDateMode } from '@shared/enums/selection-date-mode.enum';
import { ESelectionRangeMode } from '@shared/enums/selection-range-mode.enum';
import { TicketStatusType } from '@shared/enums/ticket-status-type';
import { TurnoverAuditMode } from '@shared/enums/turnover-audit-mode';
import { UpdateStatus } from '@shared/enums/update-status';
import { UserType } from '@shared/enums/user-type';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { TenantData } from '@shared/interfaces/tenant-data';
import { UserData } from '@shared/interfaces/user-data';

@Directive()
export abstract class ComponentAbstract extends PuSubscribable {
  /*
   * For now there's no way to subscribe to FormControl.touched changes
   * so it needs to be passed as param to trigger view update (due to ChangeDetectionStrategy.OnPush)
   * hopefully this field could be removed with v14 - https://github.com/angular/angular/issues/10887
   */
  @Input() showValidationError = false;
  @Input() displayErrorContent = true;

  EIcon = EIcon;
  EColorPalette = EColorPalette;
  EDropdownTab = EDropdownTab;
  ESelectionDateMode = ESelectionDateMode;
  ESelectionRangeMode = ESelectionRangeMode;

  readonly tabConfig: IDateSelectConfig[] = [
    {
      index: this.EDropdownTab.PREDEFINED,
      label: 'Predefined',
    },
    {
      index: this.EDropdownTab.OWN,
      label: 'Own settings',
    },
  ];
  readonly workflowStepList: IRadioButtonOption<WorkflowStepEnumType>[] = WorkflowStepListConst;
  readonly priorityTypeList: IRadioButtonOption<PriorityType>[] = PriorityTypeListConst;
  readonly NoValue = NoValue;

  @Input() isExpanded = false;
  @Input() isHideArrow = false;
  isActive = false;
  invalid = false;
  isDisabled = false;
  errors: ValidationErrors;
  currentTab: EDropdownTab = EDropdownTab.PREDEFINED;
  tenantData: TenantData;
  userData: UserData;
  userData$: Observable<UserData>;
  isAuthenticated = false;
  dashboardViewType$: Observable<EnumerationItem[]>;
  dashboardViewType: DashboardViewType;
  isLoading$: Observable<boolean>;
  isEmptyValue = false;

  protected constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  get RoutePath(): typeof RoutePath {
    return RoutePath;
  }

  get WorkflowPhaseType(): typeof WorkflowPhaseType {
    return WorkflowPhaseType;
  }

  get MainPageActions(): typeof MainPageActions {
    return MainPageActions;
  }

  get BreadcrumbTheme(): typeof BreadcrumbTheme {
    return BreadcrumbTheme;
  }

  get PermissionLevelType(): typeof PermissionLevelType {
    return PermissionLevelType;
  }

  get WorkflowStep(): typeof WorkflowStepEnumType {
    return WorkflowStepEnumType;
  }

  get PillOptions(): typeof PillOptions {
    return PillOptions;
  }

  get CompareOperator(): typeof CompareOperator {
    return CompareOperator;
  }

  get ChartTypes(): typeof ChartTypes {
    return ChartTypes;
  }

  get UserType(): typeof UserType {
    return UserType;
  }

  get AuditableEntityType(): typeof AuditableEntityType {
    return AuditableEntityType;
  }

  get FieldType(): typeof FieldType {
    return FieldType;
  }

  get UpdateStatus(): typeof UpdateStatus {
    return UpdateStatus;
  }

  get DelinquencyResultType(): typeof DelinquencyResultType {
    return DelinquencyResultType;
  }

  get TurnoverForm(): typeof TurnoverForm {
    return TurnoverForm;
  }

  get TicketStatusType(): typeof TicketStatusType {
    return TicketStatusType;
  }

  get LoaderType(): typeof LoaderType {
    return LoaderType;
  }

  get Animation(): typeof Animation {
    return Animation;
  }

  get TurnoverAuditMode(): typeof TurnoverAuditMode {
    return TurnoverAuditMode;
  }

  get ButtonType(): typeof ButtonType {
    return ButtonType;
  }

  get LocalStorageDataEnum(): typeof LocalStorageDataEnum {
    return LocalStorageDataEnum;
  }

  get ManagementRoute(): typeof ManagementRoute {
    return ManagementRoute;
  }

  get WorkflowStepEnumType(): typeof WorkflowStepEnumType {
    return WorkflowStepEnumType;
  }

  toggleMenu(menuExpanded: boolean) {
    this.isExpanded = menuExpanded;
    this.cdr.detectChanges();
  }

  trackByFn(index: number, item?: any) {
    return item.id;
  }
}
