import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { selectWorkflowStepsEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';

export const selectWorkflowStep = (store: Store<{}>, step: WorkflowStepEnumType) =>
  store.select(selectWorkflowStepsEnumeration).pipe(
    filterNullish$(),
    map(steps => {
      return steps.find(s => s.enumerationValue === step);
    }),
    filter(step => !!step)
  );

export const selectWorkflowStepName = (store: Store<{}>, step: WorkflowStepEnumType) =>
  selectWorkflowStep(store, step).pipe(map(step => step.displayText));

export const selectWorkflowStepsDropdown = (store: Store<{}>): Observable<IRadioButtonOption<WorkflowStepEnumType>[]> =>
  store.select(selectWorkflowStepsEnumeration).pipe(
    filterNullish$(),
    map(steps =>
      steps.map(step => ({
        label: step.displayText,
        value: step.enumerationValue,
      }))
    )
  );
