import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInclude',
})
export class IsIncludePipe implements PipeTransform {
  transform(value: string, values): boolean {
    if (Array.isArray(values)) {
      return values.includes(value);
    }
    return value === values;
  }
}
