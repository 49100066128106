import { createAction, props } from '@ngrx/store';

import { RestTicketModel } from '@shared/interfaces/turnover.interface';

/* GET punch by id */
export const loadPunchById = createAction('[Punch] Load Punch by id', props<{ punchId: number }>());
export const loadPunchByIdFinished = createAction(
  '[Punch] Load Punch by id finished',
  props<{ punch: RestTicketModel }>()
);
export const loadPunchByIdFailed = createAction('[Punch] Load Punch by id failed');
export const clearSelectedPunch = createAction('[Punch] Clear selected punch');

/* GET punch by turnoverId */
export const loadPunchesByTurnoverId = createAction(
  '[Punch] Load Punchesby turnoverId',
  props<{ turnoverId: number }>()
);
export const loadPunchesByTurnoverIdFinished = createAction(
  '[Punch] Load Punches by turnoverId finished',
  props<{ result: RestTicketModel[] }>()
);
export const loadPunchesByTurnoverIdFailed = createAction('[Punch] Load Punches by turnoverId failed');
export const clearPunchesByTurnoverId = createAction('[Punch] Clear Punches by turnoverId');

/* send punch */
export const sendPunch = createAction('[Punch] send Punch', props<{ punch: RestTicketModel }>());
export const sendPunchFinished = createAction('[Punch] send Punch finished', props<{ punch: RestTicketModel }>());
export const sendPunchFailed = createAction('[Punch] send Punch failed');
