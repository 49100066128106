import { Pipe, PipeTransform } from '@angular/core';
import { isInteger } from 'lodash';

@Pipe({
  name: 'toFixedValue',
})
export class ToFixedValuePipe implements PipeTransform {
  transform(value: number, fractionDigits = 2): string {
    if (isNaN(value)) {
      return null;
    }
    return isInteger(value) ? `${value}` : value?.toFixed(fractionDigits);
  }
}
