import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { InputAreaModule } from '@ui-components/controls/input-area/input-area.module';

import { PictureComponent } from './picture.component';

@NgModule({
  declarations: [PictureComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TooltipDirectiveModule,
    IconComponent,
    PipesModule,
    InputAreaModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [PictureComponent],
})
export class PictureModule {}
