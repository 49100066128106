import { CdkDrag } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appDragCursor]',
})
export class DragCursorDirective implements OnInit {
  @Input() cursor = 'grabbing';

  constructor(@Inject(DOCUMENT) private document: Document, private cdkDrag: CdkDrag) {}

  public ngOnInit(): void {
    this.cdkDrag.started.pipe(untilDestroyed(this)).subscribe(() => {
      this.document.body.style.cursor = this.cursor;
    });

    this.cdkDrag.ended.pipe(untilDestroyed(this)).subscribe(() => {
      this.document.body.style.cursor = 'auto';
    });
  }
}
