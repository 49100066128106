import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AttachmentsListComponent } from './attachments-list.component';
import { AttachmentItemModule } from '../attachment-item/attachment-item.module';

@NgModule({
  declarations: [AttachmentsListComponent],
  imports: [CommonModule, AttachmentItemModule],
  exports: [AttachmentsListComponent],
})
export class AttachmentsListModule {}
