import { createAction, props } from '@ngrx/store';

import { RestAddressModel } from '@shared/interfaces/address.interface';
import { PostCompanyAddressModel, RestCompanyModel, UpdateEmployeeItem } from '@shared/interfaces/companies.interface';
import { RestContractorModel } from '@shared/interfaces/contractors.interface';
import { InviteRestUserModel } from '@shared/interfaces/user.interface';

/**
 * get companies list
 */
export const getCompaniesList = createAction('[Companies] get companies list');
export const getCompaniesListSuccess = createAction(
  '[Companies] get companies list success',
  props<{ companyList: RestCompanyModel[] }>()
);
export const getCompaniesListFailed = createAction('[Companies] get companies list failed');

/**
 * add company
 */
export const addCompany = createAction('[Companies] add company', props<{ companyModel: RestCompanyModel }>());
export const addCompanySuccess = createAction(
  '[Companies] add company success',
  props<{ companyModel: RestCompanyModel }>()
);
export const addCompanyFailed = createAction('[Companies] add company failed');

export const addCompanyWithOwner = createAction(
  '[Companies] add company with owner',
  props<{ companyModel: RestCompanyModel; roleId: number; user: InviteRestUserModel }>()
);
export const addCompanyWithOwnerSuccess = createAction(
  '[Companies] add company with assignee success',
  props<{ company: RestCompanyModel }>()
);
export const addCompanyWithOwnerFailure = createAction('[Companies] add company with owner failure');
export const addCompanyWithOwnerFailureUserExist = createAction(
  '[Companies] add company with owner failure user exist',
  props<{ existedUserEmail: string }>()
);

/**
 * get company
 */
export const getCompany = createAction(
  '[Companies] get company',
  props<{ companyId: number; skipLoading?: boolean }>()
);
export const getCompanySuccess = createAction(
  '[Companies] get company success',
  props<{ companyModel: RestCompanyModel }>()
);
export const getCompanyFailed = createAction('[Companies] get company failed');
export const clearCompany = createAction('[Companies] clear company');

/**
 * update company
 */
export const updateCompany = createAction('[Companies] update company', props<{ companyModel: RestCompanyModel }>());
export const updateCompanySuccess = createAction(
  '[Companies] update company success',
  props<{ companyModel: RestCompanyModel }>()
);
export const updateCompanyFailed = createAction('[Companies] update company failed');

/**
 * delete company
 */
export const deleteCompany = createAction('[Companies] delete company', props<{ company: RestCompanyModel }>());
export const deleteCompanySuccess = createAction('[Companies] delete company success');
export const deleteCompanyFailed = createAction('[Companies] delete company failed');

/**
 * add employee to company
 */
export const addEmployeeToCompany = createAction(
  '[Companies] add employee to company',
  props<{ updateEmployeeItem: UpdateEmployeeItem }>()
);
export const addEmployeeToCompanySuccess = createAction('[Companies] add employee to company success');
export const addEmployeeToCompanyFailed = createAction('[Companies] add employee to company failed');

/**
 * delete employee from company
 */
export const deleteEmployeeFromCompany = createAction(
  '[Companies] delete employee from company',
  props<{ updateEmployeeItem: UpdateEmployeeItem }>()
);
export const deleteEmployeeFromCompanySuccess = createAction('[Companies] delete employee from company success');
export const deleteEmployeeFromCompanyFailed = createAction('[Companies] delete employee from company failed');

/**
 * get contractor list
 */
export const getContractorList = createAction('[Companies] get contractor listy');
export const getContractorListSuccess = createAction(
  '[Companies] get contractor list success',
  props<{ contractors: RestContractorModel[] }>()
);
export const getContractorListFailed = createAction('[Companies] get contractor list failed');

/**
 * add address to company
 */
export const addAddressToCompany = createAction(
  '[Companies] add address to company',
  props<{ postCompanyAddressModel: PostCompanyAddressModel }>()
);
export const addAddressToCompanySuccess = createAction('[Companies] add address to company success');
export const addAddressToCompanyFailed = createAction('[Companies] add address to company failed');

/**
 * update address in company
 */
export const updateAddressInCompany = createAction(
  '[Companies] update address in company',
  props<{ restAddressModel: RestAddressModel; companyId: number }>()
);
export const updateAddressInCompanySuccess = createAction('[Companies] update address in company success');
export const updateAddressInCompanyFailed = createAction('[Companies] update address in company failed');
