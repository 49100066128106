<ng-template #skeletonTitle>
  <div mat-dialog-title class="display-flex modal-header">
    <div class="w-50 flex skeleton-rectangle" style="height: 32px"></div>
  </div>
</ng-template>

<ng-template #skeletonContent>
  <div class="w-100 display-flex flex-column grid-gap-18 details-container" style="padding-top: 6px">
    <div class="w-100 display-flex grid-gap-16">
      <div class="display-flex flex-column grid-gap-6" style="width: calc(100% - 356px)">
        <div class="flex skeleton-rectangle" style="width: 72px; height: 18px"></div>
        <div class="w-100 flex skeleton-rectangle" style="height: 38px"></div>
      </div>
      <div class="display-flex flex-column grid-gap-6" style="width: 162px">
        <div class="flex skeleton-rectangle" style="width: 64px; height: 18px"></div>
        <div class="w-100 flex skeleton-rectangle" style="height: 38px"></div>
      </div>
      <div class="display-flex flex-column grid-gap-6" style="width: 162px">
        <div class="flex skeleton-rectangle" style="width: 42px; height: 18px"></div>
        <div class="w-100 flex skeleton-rectangle" style="height: 38px"></div>
      </div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 48px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 38px"></div>
      <div class="flex skeleton-rectangle" style="padding-left: 6px; width: 54px; height: 18px"></div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 84px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 92px"></div>
      <div class="w-100 display-flex flex-column grid-gap-12">
        <div
          class="flex skeleton-rectangle"
          style="padding-left: 6px; padding-left: 6px; width: 63px; height: 18px"></div>
        <div class="w-25 flex skeleton-rectangle" style="height: 24px"></div>
        <div class="w-100 flex skeleton-rectangle" style="height: 100px"></div>
      </div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-12" style="padding-top: 6px">
      <div class="flex skeleton-rectangle" style="width: 400px; height: 38px"></div>
      <div></div>
      <div class="w-100 display-flex grid-gap-16">
        <div class="w-100 display-flex flex-column grid-gap-6">
          <div class="flex skeleton-rectangle" style="width: 42px; height: 18px"></div>
          <div class="w-100 flex skeleton-rectangle" style="height: 36px"></div>
        </div>
        <div class="w-100 display-flex flex-column grid-gap-6">
          <div class="flex skeleton-rectangle" style="width: 36px; height: 18px"></div>
          <div class="w-100 flex skeleton-rectangle" style="height: 36px"></div>
        </div>
      </div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 80px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 92px"></div>
      <div class="flex skeleton-rectangle" style="padding-left: 6px; width: 63px; height: 18px"></div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 80px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 92px"></div>
      <div class="flex skeleton-rectangle" style="padding-left: 6px; width: 63px; height: 18px"></div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 80px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 92px"></div>
      <div class="flex skeleton-rectangle" style="padding-left: 6px; width: 63px; height: 18px"></div>
    </div>
    <div class="w-100 display-flex flex-column grid-gap-6">
      <div class="flex skeleton-rectangle" style="width: 80px; height: 18px"></div>
      <div class="w-100 flex skeleton-rectangle" style="height: 92px"></div>
      <div class="flex skeleton-rectangle" style="padding-left: 6px; width: 63px; height: 18px"></div>
    </div>
  </div>
</ng-template>

<ng-template #skeletonActions>
  <div class="mat-dialog-actions justify-content-space-between">
    <div class="flex skeleton-rectangle" style="width: 100px; height: 28px"></div>
    <div class="display-flex grid-gap-8">
      <div class="flex skeleton-rectangle" style="width: 147.67px; height: 28px"></div>
      <div class="flex skeleton-rectangle" style="width: 147.67px; height: 28px"></div>
    </div>
  </div>
</ng-template>
