import { createSelector } from '@ngrx/store';

import { selectCoreModule } from './core.selector';

const selectUserState = createSelector(selectCoreModule, coreState => coreState.user);
export const selectUserData = createSelector(selectUserState, userState => userState.userData);
export const selectUserId = createSelector(selectUserData, userData => userData.id);
export const selectUserName = createSelector(selectUserData, userData => userData.name);
export const selectUserGeneralRole = createSelector(selectUserData, userData => userData.generalRole);
export const selectUserPermissionLevel = createSelector(
  selectUserState,
  userState => userState.userData?.permissionLevel
);
export const selectIsAuthenticated = createSelector(selectUserState, userState => userState.isAuthenticated);
export const selectSelectedUser = createSelector(selectUserState, userState => userState.selectedUser);
export const selectSelectedUserLoading = createSelector(selectUserState, userState => userState.selectedUserLoading);
export const selectTenantData = createSelector(selectUserState, userState => userState.tenantData);
