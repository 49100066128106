<div class="property-unit-selector-modal-container">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle mr-4">Select property and unit to create ticket</div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="display-flex w-100">
        <app-radio-button-dropdown
          class="w-100"
          [items]="properties$ | async"
          [allowSearch]="(properties$ | async)?.length > 7"
          [attrDisable]="!(properties$ | async)?.length"
          [label]="'Property'"
          [formControlName]="'propertyId'"
          [allowClear]="true"
          [listLoading]="selectPropertiesLoading$ | async"
          [attrPlaceholder]="(selectPropertiesLoading$ | async) ? 'Loading properties…' : 'Select property'"
          [labelRequired]="true"
          [showValidationError]="submitted$ | async"
          attrAppendTo="body"></app-radio-button-dropdown>
      </div>
      <div class="display-flex w-100">
        <app-radio-button-dropdown
          class="w-100"
          [items]="units$ | async"
          [allowSearch]="(units$ | async)?.length > 7"
          [attrDisable]="!(units$ | async)?.length || !form.get('propertyId')?.value"
          [label]="'Unit'"
          [formControlName]="'unitId'"
          [allowClear]="false"
          [listLoading]="unitListLoading$ | async"
          [attrPlaceholder]="(unitListLoading$ | async) ? 'Loading units…' : 'Select unit'"
          [showValidationError]="(submitted$ | async) && form.get('propertyId')?.value"
          attrAppendTo="body"></app-radio-button-dropdown>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary" (click)="submit()" [disabled]="loadingUnit$ | async">
      <span class="btn-container">
        <span>Next</span>
        <mat-icon *ngIf="loadingUnit$ | async">
          <mat-spinner color="accent" diameter="20"></mat-spinner>
        </mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</div>
