import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { InputComponent } from '@ui-components/controls/input/input.component';

export interface PromptModalData {
  title: string;
  fieldName: string;
  fieldPlaceholder?: string;
  minLength?: number;
  okButtonText?: string;
  okButtonColor?: ThemePalette;
  cancelButtonColor?: ThemePalette;
}

export interface PromptModalResult {
  value: string;
}

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptModalComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  isSubmitted: boolean;

  @ViewChild(InputComponent) input: InputComponent;

  get displayName() {
    return this.form.controls.displayName as UntypedFormControl;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PromptModalComponent, PromptModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: PromptModalData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.data.okButtonText = this.data.okButtonText || 'Add';
    this.data.okButtonColor = this.data.okButtonColor || 'primary';
    this.data.cancelButtonColor = this.data.cancelButtonColor || 'warn';
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.input?.focus();
    }, 0);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    this.dialogRef.close({ value: this.form.controls.displayName.value });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      displayName: [null, [Validators.required]],
    });

    if (this.data.minLength > 0) {
      this.displayName.addValidators(Validators.minLength(this.data.minLength));
    }
  }
}
