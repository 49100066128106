import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-input-time-manual',
  templateUrl: './input-time-manual.component.html',
  styleUrls: ['./input-time-manual.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimeManualComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeManualComponent implements ControlValueAccessor, OnInit {
  daysControl: FormControl = new FormControl();
  hoursControl: FormControl = new FormControl();
  minutesControl: FormControl = new FormControl();
  errors: ValidationErrors;
  invalid = false;
  disabled = false;

  @Input() label = '';
  @Input() labelRequired = false;

  @Input() set attrDisable(attrDisable: boolean) {
    if (attrDisable) {
      this.daysControl.disable();
      this.hoursControl.disable();
      this.minutesControl.disable();
    } else {
      this.daysControl.enable();
      this.hoursControl.enable();
      this.minutesControl.enable();
    }
    this.disabled = attrDisable;
  }

  private onChange: (value: string) => void;
  private onTouched: () => void;

  ngOnInit(): void {
    this.daysControl.valueChanges.subscribe(() => this.onInputChange());
    this.hoursControl.valueChanges.subscribe(() => this.onInputChange());
    this.minutesControl.valueChanges.subscribe(() => this.onInputChange());
  }

  onInputChange(): void {
    const value = `${this.daysControl.value || '0'}::${this.hoursControl.value || '0'}::${
      this.minutesControl.value || '0'
    }`;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  pad(value: number | null): string {
    if (value === null || value === undefined) {
      return '0';
    }
    return value.toString();
  }

  writeValue(value: string): void {
    if (value) {
      const parts = value.split('::').map(Number);
      this.daysControl.setValue(parts[0] === 0 ? null : parts[0], { emitEvent: false });
      this.hoursControl.setValue(parts[1] === 0 ? null : parts[1], { emitEvent: false });
      this.minutesControl.setValue(parts[2] === 0 ? null : parts[2], { emitEvent: false });
    } else {
      this.daysControl.setValue(null, { emitEvent: false });
      this.hoursControl.setValue(null, { emitEvent: false });
      this.minutesControl.setValue(null, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.daysControl.disable();
      this.hoursControl.disable();
      this.minutesControl.disable();
    } else {
      this.daysControl.enable();
      this.hoursControl.enable();
      this.minutesControl.enable();
    }
  }

  getWidth(control: FormControl): string {
    const valueLength = control.value ? control.value.toString().length : 0;
    const length = control.value ? valueLength + 0.25 : 2.75;
    return `${length}ch`;
  }
}
