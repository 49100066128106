import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';

import { CustomTooltipComponent } from '@ui-components/components/custom-tooltip/custom-tooltip.component';

@Directive({
  selector: '[appCustomTooltip]',
})
export class CustomTooltipDirective implements OnDestroy {
  @Input() customToolTip: string;
  @Input() contentTemplate: TemplateRef<any>;

  _showToolTip = false;

  @Input() set showToolTip(showToolTip: boolean) {
    this._showToolTip = showToolTip;
    if (!showToolTip) {
      this.hide();
      return;
    }

    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 5,
      },
    ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  @HostListener('mousemove')
  show() {
    if (this._showToolTip && this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent)
      );
      tooltipRef.instance.text = this.customToolTip;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
