import { createSelector } from '@ngrx/store';

import { selectCoreModule } from './core.selector';

const selectEnumerationState = createSelector(selectCoreModule, coreState => coreState.enumeration);
export const selectLoadEnumerations = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations
);
export const selectLoadEnumerationsLoading = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerationsLoading
);
export const selectDashboardViewType = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.dashboardViewType
);
export const selectWorkflowPhasesEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.workflowPhases
);
export const selectDelinquencyResultsEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.delinquencyResultTypes
);
export const selectWorkflowStepsEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.workflowSteps
);
export const selectTicketStatusTypeEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.ticketStatusType
);
export const selectTicketPriorityTypeEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.ticketPriorityType
);
export const selectRenewalResultTypesEnumeration = createSelector(
  selectEnumerationState,
  enumerationState => enumerationState.enumerations?.renewalResultTypes
);
export const selectFinishTypesEnumeration = createSelector(
  selectLoadEnumerations,
  enumerationState => enumerationState?.finishType
);
export const selectTimerTypesEnumeration = createSelector(
  selectLoadEnumerations,
  enumerationState => enumerationState?.timerTypes
);
export const selectPmsTypesEnumeration = createSelector(
  selectLoadEnumerations,
  enumerationState => enumerationState?.pmsTypes
);
export const selectNativeLanguagesEnumeration = createSelector(
  selectLoadEnumerations,
  enumerationState => enumerationState?.nativeLanguage
);
export const selectUnitStatusesEnumeration = createSelector(
  selectLoadEnumerations,
  enumerationState => enumerationState?.moveOutTypes
);
