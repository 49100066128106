import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';

@Component({
  selector: 'app-read-only-field',
  templateUrl: './read-only-field.component.html',
  styleUrls: ['./read-only-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyFieldComponent extends ComponentAbstract {
  @Input() containerCss = 'display-flex flex-column';
  @Input() label = '';
  @Input() labelCss = 'nowrap body-small-bold';
  @Input() value: unknown;
  @Input() emptyValue = '–';
  @Input() valueContainerCss = 'value-container h-40';
  @Input() valueCss = 'ellipsis body-small text-color dark';
  @Input() customValue: TemplateRef<any>;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
