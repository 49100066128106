<div class="display-flex flex-column">
  <label *ngIf="label">
    <span>{{ label }}</span>
  </label>
  <div class="time-input-container" [ngClass]="disabled ? 'disabled' : ''">
    <input
      type="number"
      [formControl]="daysControl"
      placeholder="DD"
      class="time-input"
      [ngStyle]="{ width: getWidth(daysControl) }"
      min="0" />
    <span class="separator">d:</span>
    <input
      type="number"
      [formControl]="hoursControl"
      placeholder="HH"
      class="time-input"
      [ngStyle]="{ width: getWidth(hoursControl) }"
      min="0" />
    <span class="separator">h:</span>
    <input
      type="number"
      [formControl]="minutesControl"
      placeholder="mm"
      class="time-input"
      [ngStyle]="{ width: getWidth(minutesControl) }"
      min="0" />
    <span class="separator">m</span>
  </div>
</div>
