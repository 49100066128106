import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrencyShortWithSuffix',
})
export class CustomCurrencyShortWithSuffixPipe implements PipeTransform {
  transform(value: number, precision = 0): string {
    return this.abbreviateNumber(value, precision);
  }

  private abbreviateNumber(num, fixed) {
    // if (num === null) {
    //   return null;
    // } // terminate early
    // if (num === 0) {
    //   return '0';
    // } // terminate early
    if (num === null || num === 0) {
      return '$0';
    }
    fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    const b = num.toPrecision(2).split('e'), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at 1 decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(fixed) : (num / Math.pow(10, k * 3)).toFixed(fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'k', 'm', 'b', 't'][k]; // append power
    return '$' + e;
  }
}
