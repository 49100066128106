import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';

import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';

import { DateRangeDropdownComponent } from './date-range-dropdown.component';
import { InputModule } from '../../controls/input/input.module';
import { RadioButtonModule } from '../../controls/radio-button/radio-button.module';
import { CalendarHeaderModule } from '../calendar-header/calendar-header.module';
import { IconComponent } from '../icon/icon.component';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [DateRangeDropdownComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    IconComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatButtonModule,
    CalendarHeaderModule,
    InputModule,
    RadioButtonModule,
    InputErrorModule,
    StopPropagationDirective,
  ],
  exports: [DateRangeDropdownComponent],
})
export class DateRangeDropdownModule {}
