import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';

export type EmptyString<T> = T | '';

export interface SortEvent<T> {
  column: EmptyString<keyof T>;
  direction: SortDirection;
}

const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

@Directive({
  selector: '[appSortable]',
  // selector: 'div[puSortable]',
  // selector: 'th[puUiSortable]',
})
export class SortableDirective<T> {
  @Input()
  column: EmptyString<keyof T> = '';

  @Input()
  direction: SortDirection = '';

  @HostBinding('class.asc')
  get asc() {
    return this.direction === 'asc';
  }

  @HostBinding('class.desc')
  get desc() {
    return this.direction === 'desc';
  }

  @HostBinding('style.cursor')
  cursor = 'pointer';

  @Output()
  sort = new EventEmitter<SortEvent<T>>();

  @HostListener('click')
  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.column, direction: this.direction });
  }
}
