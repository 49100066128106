import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { CustomIconService } from '@app/services/custom-icon.service';

import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  environment = environment;
  isAuthenticated = false;

  constructor(
    private metaService: Meta,
    customIconService: CustomIconService,
    iconRegistry: MatIconRegistry,
    private router: Router
  ) {
    customIconService.registerIcons();
    iconRegistry.setDefaultFontSetClass('material-icons');
  }

  ngOnInit() {
    this.metaService.addTag(
      {
        name: 'author',
        content: this.environment.ver,
      },
      true
    );

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd),
        first(),
        tap(() => {
          document.getElementById('initial-app-loader').remove();
        })
      )
      .subscribe();
  }
}
