import { Pipe, PipeTransform } from '@angular/core';
import { compact } from 'lodash';

import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

@Pipe({
  name: 'compact',
})
export class CompactPipe implements PipeTransform {
  transform(value: any[], availableItems: IRadioButtonOption<any>[]): any[] {
    return compact(value).filter(x => availableItems.some(a => a.value === x));
  }
}
