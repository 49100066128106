import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { IActionMapping, ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { IDTypeDictionary } from '@circlon/angular-tree-component/lib/defs/api';
import { each, every, find, isEmpty, reduce, some, toLower } from 'lodash';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { PropertyType } from '@shared/enums/property-type.enum';
import { PropertyBasicInfo, PropertyBasicInfoNode } from '@shared/interfaces/propertyBasicInfo';
import { ISimpleChanges } from '@shared/types/simple-changes-typed.type';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { NODES_RE_INIT_DELAY, doForAllTreeNodes } from './functions/tree-utils';

@Component({
  selector: 'app-select-tree',
  templateUrl: './select-tree.component.html',
  styleUrls: ['./select-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
})
export class SelectTreeComponent extends ComponentAbstract implements OnChanges, OnDestroy {
  @Input() containerCss = 'display-flex flex-column';
  @Input() label = '';
  @Input() labelCss = 'body-small-bold nowrap';
  @Input() nodes;
  @Input() isSuperUser: boolean;
  @Input() selectedNodes: PropertyBasicInfoNode[];
  @Input() warningMessage: string;
  @Input() labelRequired = false;
  @Input() selectAllEvent = new EventEmitter();
  @Output() selectedRoots = new EventEmitter<PropertyBasicInfoNode[]>();
  @Output() clearAll = new EventEmitter();
  @ViewChild(TreeComponent) tree: TreeComponent;
  filterString = '';
  placeholder = 'Select property';

  actionMapping: IActionMapping = {
    mouse: {
      checkboxClick: this.checkboxClick.bind(this),
    },
  };

  options: ITreeOptions = {
    useCheckbox: true,
    useVirtualScroll: true,
    nodeHeight: 23,
    actionMapping: this.actionMapping,
  };
  readonly EColorPalette = EColorPalette;

  constructor(
    protected cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private snackbarService: SnackbarService
  ) {
    super(cdr);
    this.updatePlaceholderAndEmit = this.updatePlaceholderAndEmit.bind(this);
  }

  ngOnChanges(changes: ISimpleChanges<SelectTreeComponent>) {
    if (changes.nodes?.currentValue?.[0]?.children) {
      this.nodes[0].children = this.nodes[0].children.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (changes['isSuperUser']?.currentValue) {
      setTimeout(() => {
        this.selectedNodes = this.nodes;
        this.treeInitialized();
      });
    }
  }

  treeInitialized() {
    this.selectNodes(this.selectedNodes);
  }

  clearSelection() {
    this.tree.treeModel.selectedLeafNodeIds = {};
    this.updatePlaceholderAndEmit({});
    this.clearAll.emit();
    this.checkIsSuperUser();
  }

  hasNodesSelected(): boolean {
    if (!this.tree || !this.tree.treeModel) {
      return false;
    }
    const selectionObject = this.tree.treeModel.selectedLeafNodeIds;
    return some(Object.values(selectionObject), value => value);
  }

  selectNodes(propertiesToSelect: PropertyBasicInfo[]) {
    if (!this.tree) {
      console.error('missing tree, return ');
      return;
    }

    doForAllTreeNodes(this.tree.treeModel, treeNode => {
      const shouldSelectNode = find(propertiesToSelect, property => treeNode.data.id === property.id);
      if (shouldSelectNode) {
        this.setValue(this.tree.treeModel, treeNode, true);
      }
    });
  }

  filterTree(event: Event) {
    const text = (event.target as HTMLInputElement).value;
    this.tree.treeModel.filterNodes(node => {
      const allParentsName = this.getAncestorsName(node.data);
      return toLower(allParentsName).includes(toLower(text));
    });

    if (isEmpty(text)) {
      this.collapseAll();
      this.expandRoot();
      return;
    }
  }

  expandRoot() {
    const firstRoot = this.tree.treeModel.roots[0];
    firstRoot.expand();
  }

  getAncestorsName(node: PropertyBasicInfoNode) {
    if (node.parent) {
      return this.getAncestorsName(node.parent) + node.name;
    }

    return node.name;
  }

  toggleMenu(menuExpanded: boolean) {
    this.isExpanded = menuExpanded;

    if (this.isExpanded) {
      this.onListOpen();
    } else {
      this.onListClose();
    }

    this.cdr.detectChanges();
  }

  setValue(tree: TreeModel, node: TreeNode, selectionValue) {
    const selectedLeafNodeIds = tree.selectedLeafNodeIds;
    toggleNodeSelection(node, selectionValue, selectedLeafNodeIds);
    tree.selectedLeafNodeIds = Object.assign({}, selectedLeafNodeIds, { [node.id]: selectionValue });

    this.updatePlaceholderAndEmit(selectedLeafNodeIds);
  }

  checkboxClick(tree: TreeModel, node: TreeNode, $event: any) {
    if (!node) {
      return;
    }

    const selectionValue = !node.isSelected;
    const selectedLeafNodeIds = tree.selectedLeafNodeIds;
    toggleNodeSelection(node, selectionValue, selectedLeafNodeIds);
    tree.selectedLeafNodeIds = Object.assign({}, selectedLeafNodeIds, { [node.id]: selectionValue });

    this.updatePlaceholderAndEmit(selectedLeafNodeIds);

    this.checkIsSuperUser();
  }

  updatePlaceholderAndEmit(selectedNodeIds: IDTypeDictionary) {
    const { selectedLeaves, selectedRoots, isRootSelected } = this.getSelectedNodes(selectedNodeIds);
    this.selectedRoots.emit(selectedRoots);
    const allCount = this.decimalPipe.transform(selectedLeaves.length, '1.0-0');
    if (!isEmpty(selectedLeaves)) {
      this.placeholder = isRootSelected
        ? `All: ${allCount} units`
        : selectedLeaves.length === 1
        ? `Selected: ${selectedLeaves.length} unit`
        : `Selected: ${selectedLeaves.length} units`;
    } else {
      this.placeholder = 'Select property';
    }
  }

  collapseAll() {
    this.tree.treeModel.expandedNodeIds = {};
  }

  expanderClick(tree: TreeModel, node: TreeNode, $event: any) {
    if (!node || !node.hasChildren) {
      return;
    }
    const value = !node.isExpanded;
    if (node.hasChildren) {
      tree.expandedNodeIds = Object.assign({}, tree.expandedNodeIds, { [node.id]: value });
    }
  }

  getSelectedNodes(selectedNodeIds: IDTypeDictionary) {
    const entries = Object.entries(selectedNodeIds);
    let isRootSelected = false;

    const onlySelectedNodes = reduce(
      entries,
      (acc, [id, isChecked]) => {
        if (isChecked) {
          if (id === '-1') {
            isRootSelected = true;
          }
          acc.push(id);
        }
        return acc;
      },
      []
    );

    const selectedRoots = this.getSelectedRootsRecursive([], this.nodes[0], onlySelectedNodes);
    const selectedLeaves = this.getSelectedLeavesRecursive([], this.nodes[0], onlySelectedNodes);

    return { selectedRoots, selectedLeaves, isRootSelected };
  }

  private getSelectedLeavesRecursive(acc, node: PropertyBasicInfoNode, selectedNodeIds: string[]) {
    if (!node.children && selectedNodeIds.includes(node.id.toString()) && node.propertyType === PropertyType.Unit) {
      acc.push(node);
      return acc;
    }

    if (node.children) {
      each(node.children, childNode => this.getSelectedLeavesRecursive(acc, childNode, selectedNodeIds));
    }
    return acc;
  }

  private getSelectedRootsRecursive(acc, node: PropertyBasicInfoNode, selectedNodeIds: string[]) {
    if (node.parent && selectedNodeIds.includes(node.id.toString())) {
      acc.push(node);
      return acc;
    }

    if (node.children) {
      each(node.children, childNode => this.getSelectedRootsRecursive(acc, childNode, selectedNodeIds));
    }
    return acc;
  }

  private onListOpen() {
    this.tree.treeModel.clearFilter();
    this.collapseAll();
    this.expandRoot();
    this.tree.sizeChanged();
  }

  private onListClose(): void {
    this.filterString = '';
  }

  checkIsSuperUser(): void {
    if (this.isSuperUser) {
      this.snackbarService.error('Superusers are required to have all properties and portfolios');
      setTimeout(() => {
        this.selectedNodes = this.nodes;
        this.treeInitialized();
      }, NODES_RE_INIT_DELAY);
    }
  }

  ngOnDestroy(): void {
    this.snackbarService.dismiss();
  }
}

function toggleNodeSelection(node: TreeNode, selectionValue, selectedLeafNodeIds) {
  selectedLeafNodeIds[node.id] = selectionValue;

  if (selectionValue) {
    selectParentIfNeeded(node.parent, selectedLeafNodeIds);
  }

  if (!selectionValue) {
    deselectAncestorsIfNeeded(node.parent, selectedLeafNodeIds);
  }

  if (!isEmpty(node.children)) {
    node.children.forEach(childNode => toggleNodeSelection(childNode, selectionValue, selectedLeafNodeIds));
  }
}

function isNodeSelected(nodeToCheck: TreeNode, selectedLeafNodeIds) {
  return selectedLeafNodeIds[nodeToCheck.id];
}

function selectParentIfNeeded(parentNode: TreeNode, selectedLeafNodeIds) {
  if (every(parentNode.children, (childNode: TreeNode) => isNodeSelected(childNode, selectedLeafNodeIds))) {
    selectedLeafNodeIds[parentNode.id] = true;
    if (parentNode.parent) {
      selectParentIfNeeded(parentNode.parent, selectedLeafNodeIds);
    }
  }
}

function deselectAncestorsIfNeeded(parentNode: TreeNode, selectedLeafNodeIds) {
  if (
    isNodeSelected(parentNode, selectedLeafNodeIds) &&
    some(parentNode.children, (childNode: TreeNode) => !isNodeSelected(childNode, selectedLeafNodeIds))
  ) {
    selectedLeafNodeIds[parentNode.id] = false;
    if (parentNode.parent) {
      deselectAncestorsIfNeeded(parentNode.parent, selectedLeafNodeIds);
    }
  }
}
