import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestThresholdsConfiguration } from '@shared/interfaces/thresholds-configuration.interface';

@Pipe({
  name: 'thresholdStepConfiguration',
})
export class ThresholdStepConfigurationPipe implements PipeTransform {
  transform(value: number, stepType: WorkflowStepEnumType, configuration: RestThresholdsConfiguration): string {
    if (!isNumber(value) || !configuration || !stepType) {
      return '';
    }

    const step = configuration.stepThresholds.find(s => s.workflowStepEnumType === stepType);

    if (!step) {
      return '';
    }

    if (value > step.highThresholdValue) {
      return 'threshold-high';
    }

    if (value < step.lowThresholdValue) {
      return 'threshold-low';
    }

    return 'threshold-medium';
  }
}
