/**
 * returns Date object from date in format MM/DD/YYYY
 *
 * @param value - date in format MM/DD/YYYY
 */

export function getDate(value: string): Date {
  const dateItems = value.split('/');

  if (dateItems.length !== 3) {
    return null;
  }

  const month = dateItems[0];
  const day = dateItems[1];
  const year = dateItems[2];

  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}
