import { createReducer, on } from '@ngrx/store';

import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { RestContractorModel } from '@shared/interfaces/contractors.interface';

import {
  addAddressToCompany,
  addAddressToCompanyFailed,
  addAddressToCompanySuccess,
  addCompany,
  addCompanyFailed,
  addCompanySuccess,
  addCompanyWithOwner,
  addCompanyWithOwnerFailure,
  addCompanyWithOwnerSuccess,
  addEmployeeToCompany,
  addEmployeeToCompanyFailed,
  addEmployeeToCompanySuccess,
  clearCompany,
  deleteCompany,
  deleteCompanyFailed,
  deleteCompanySuccess,
  deleteEmployeeFromCompany,
  deleteEmployeeFromCompanyFailed,
  deleteEmployeeFromCompanySuccess,
  getCompaniesList,
  getCompaniesListFailed,
  getCompaniesListSuccess,
  getCompany,
  getCompanyFailed,
  getCompanySuccess,
  getContractorList,
  getContractorListFailed,
  getContractorListSuccess,
  updateAddressInCompany,
  updateAddressInCompanyFailed,
  updateAddressInCompanySuccess,
  updateCompany,
  updateCompanyFailed,
  updateCompanySuccess,
} from './companies.actions';

export const companiesFeatureKey = 'companies';

export interface State {
  companyList: RestCompanyModel[];
  companyListLoading: boolean;
  companyAddLoading: boolean;
  companyModel: RestCompanyModel;
  companyModelLoading: boolean;
  addEmployeeToCompanyLoading: boolean;
  deleteEmployeeFromCompanyLoading: boolean;
  contractors: RestContractorModel[];
  contractorsLoading: boolean;
  companyUpdateLoading: boolean;
  companyDeleteLoading: boolean;
  addAddressToCompany: boolean;
  updateAddressInProgress: boolean;
  addCompanyWithOwnerInProgress: boolean;
}

export const initialState: State = {
  companyList: [],
  companyListLoading: false,
  companyAddLoading: false,
  companyModel: null,
  companyModelLoading: false,
  addEmployeeToCompanyLoading: false,
  deleteEmployeeFromCompanyLoading: false,
  contractors: [],
  contractorsLoading: false,
  companyUpdateLoading: false,
  companyDeleteLoading: false,
  addAddressToCompany: false,
  updateAddressInProgress: false,
  addCompanyWithOwnerInProgress: false,
};

export const reducer = createReducer(
  initialState,
  /**
   * get companies list
   */
  on(getCompaniesList, (state, action) => ({
    ...state,
    companyListLoading: true,
  })),
  on(getCompaniesListSuccess, (state, action) => ({
    ...state,
    companyListLoading: false,
    companyList: action.companyList,
  })),
  on(getCompaniesListFailed, (state, action) => ({
    ...state,
    companyListLoading: false,
    companyList: [],
  })),

  /**
   * add company
   */
  on(addCompany, (state, action) => ({
    ...state,
    companyAddLoading: true,
  })),
  on(addCompanySuccess, (state, action) => ({
    ...state,
    companyAddLoading: false,
  })),
  on(addCompanyFailed, (state, action) => ({
    ...state,
    companyAddLoading: false,
  })),

  /**
   * update company
   */
  on(updateCompany, (state, action) => ({
    ...state,
    companyUpdateLoading: true,
  })),
  on(updateCompanySuccess, (state, action) => ({
    ...state,
    companyUpdateLoading: false,
    companyModel: action.companyModel,
  })),
  on(updateCompanyFailed, (state, action) => ({
    ...state,
    companyUpdateLoading: false,
  })),

  /**
   * delete company
   */
  on(deleteCompany, (state, action) => ({
    ...state,
    companyDeleteLoading: true,
  })),
  on(deleteCompanySuccess, (state, action) => ({
    ...state,
    companyDeleteLoading: false,
  })),
  on(deleteCompanyFailed, (state, action) => ({
    ...state,
    companyDeleteLoading: false,
  })),

  /**
   * get company
   */
  on(getCompany, (state, action) => ({
    ...state,
    companyModelLoading: !action.skipLoading,
  })),
  on(getCompanySuccess, (state, action) => ({
    ...state,
    companyModelLoading: false,
    companyModel: action.companyModel,
  })),
  on(getCompanyFailed, (state, action) => ({
    ...state,
    companyModelLoading: false,
    companyModel: null,
  })),
  on(clearCompany, (state, action) => ({
    ...state,
    companyModel: null,
  })),

  /**
   * add employee to company
   */
  on(addEmployeeToCompany, (state, action) => ({
    ...state,
    addEmployeeToCompanyLoading: true,
  })),
  on(addEmployeeToCompanySuccess, (state, action) => ({
    ...state,
    addEmployeeToCompanyLoading: false,
  })),
  on(addEmployeeToCompanyFailed, (state, action) => ({
    ...state,
    addEmployeeToCompanyLoading: false,
  })),

  /**
   * delete employee from company
   */
  on(deleteEmployeeFromCompany, (state, action) => ({
    ...state,
    deleteEmployeeFromCompanyLoading: true,
  })),
  on(deleteEmployeeFromCompanySuccess, (state, action) => ({
    ...state,
    deleteEmployeeFromCompanyLoading: false,
  })),
  on(deleteEmployeeFromCompanyFailed, (state, action) => ({
    ...state,
    deleteEmployeeFromCompanyLoading: false,
  })),

  /**
   * add employee to company
   */
  on(getContractorList, (state, action) => ({
    ...state,
    contractorsLoading: true,
  })),
  on(getContractorListSuccess, (state, action) => ({
    ...state,
    contractorsLoading: false,
    contractors: action.contractors,
  })),
  on(getContractorListFailed, (state, action) => ({
    ...state,
    contractorsLoading: false,
    contractors: [],
  })),

  /**
   * add address to company
   */
  on(addAddressToCompany, (state, action) => ({
    ...state,
    addAddressToCompany: true,
  })),
  on(addAddressToCompanySuccess, (state, action) => ({
    ...state,
    addAddressToCompany: false,
  })),
  on(addAddressToCompanyFailed, (state, action) => ({
    ...state,
    addAddressToCompany: false,
  })),
  /**
   * update address in company
   */
  on(updateAddressInCompany, (state, action) => ({
    ...state,
    updateAddressInProgress: true,
  })),
  on(updateAddressInCompanySuccess, (state, action) => ({
    ...state,
    updateAddressInProgress: false,
  })),
  on(updateAddressInCompanyFailed, (state, action) => ({
    ...state,
    updateAddressInProgress: false,
  })),

  on(addCompanyWithOwner, (state, action) => ({
    ...state,
    addCompanyWithOwnerInProgress: true,
  })),
  on(addCompanyWithOwnerSuccess, addCompanyWithOwnerFailure, (state, action) => ({
    ...state,
    addCompanyWithOwnerInProgress: false,
  }))
);
