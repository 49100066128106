import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { ModalData } from '@shared/interfaces/modal-data';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InfoModalComponent extends ComponentAbstract {
  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    super(cdr);
  }

  confirm() {
    this.dialogRef.close();
  }
}
