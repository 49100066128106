import { CdkDragStart, CdkDropList, DropListRef } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Subject, auditTime, filter, map } from 'rxjs';

export class DndEvent {
  event: 'start' | 'end';
  areaLevel: boolean;
}

export class ExpandEvent {
  isExpanded: boolean;
  areaId?: number;
}

@Injectable()
export class DndExpandableService {
  readonly extraBufferHeight = 250;
  readonly scrollSpeed = 5;
  readonly expandDuration = 0;
  readonly expandDurationMs = `${this.expandDuration}ms`;

  _dndEvent = new Subject<DndEvent>();
  dndAreaEvent$ = this._dndEvent.pipe(
    filter(e => e.areaLevel),
    map(e => e.event)
  );
  dndItemEvent$ = this._dndEvent.pipe(
    filter(e => !e.areaLevel),
    map(e => e.event)
  );
  expandAllEvent = new Subject<ExpandEvent>();
  expandAreaEvent$ = this.expandAllEvent.pipe(
    filter(e => e.areaId == null),
    map(e => e.isExpanded)
  );
  expandItemEvent$ = this.expandAllEvent.pipe(filter(e => e.areaId != null));

  viewportZoneChanging = new Subject();
  viewportZoneChanging$ = this.viewportZoneChanging.pipe(auditTime(100));

  fixDndCache(dropListRef: DropListRef<CdkDropList<any>>) {
    /* dnd cached all objects and its position instead of working with DOM directly
    Because of that we should recached it after collapsing by `_cacheItemPositions` method
    Also we should reset all transform state that can be already changed before collapsing (as collapsing take some time)
    Some more details: https://github.com/angular/components/issues/14703 */

    //We need siblings dropLists as we have `cdkDropListGroup`
    const allActiveDropLists: DropListRef<CdkDropList<any>>[] = [...dropListRef['_siblings'], dropListRef];

    allActiveDropLists.forEach(dropList => {
      const nodes = (dropList.element as HTMLElement).children;
      for (let i = 0; i < nodes.length; i++) {
        const e = nodes[i] as HTMLElement;
        e.style.transform = '';
      }
      dropList?.['_cacheParentPositions']();
      //check if this container was already inited
      if (dropList?.['_sortStrategy']?.['_activeDraggables']) {
        dropList?.['_sortStrategy']?.['_cacheItemPositions']();
      }
    });
  }

  startDnd(event: CdkDragStart<any>, areaLevel: boolean) {
    this._dndEvent.next({ event: 'start', areaLevel });
  }

  endDnd(areaLevel: boolean) {
    setTimeout(() => this._dndEvent.next({ event: 'end', areaLevel }), 100);
  }
}
