import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

import { TurnoverCommentPreviewModule } from '@main-application/turnovers/components/sections/turnover-comment-preview/turnover-comment-preview.module';
import { FadeTextComponent } from '@shared/components/ellipsis-one-row/fade-text.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ChartModule } from '@ui-components/components/chart/chart.module';
import { ChartCardModule } from '@ui-components/components/chart-card/chart-card.module';
import { CollapsibleSearchInputComponent } from '@ui-components/components/collapsible-search-input/collapsible-search-input.component';
import { CustomDashboardModule } from '@ui-components/components/custom-dashboard/custom-dashboard.module';
import { DashboardContainerModule } from '@ui-components/components/dashboard-container/dashboard-container.module';
import { DateRangeDropdownModule } from '@ui-components/components/date-range-dropdown/date-range-dropdown.module';
import { EmptyListComponent } from '@ui-components/components/empty-list/empty-list.component';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { SkeletonLoaderComponent } from '@ui-components/components/skeleton-loader/skeleton-loader.component';
import { TableEmptyContentModule } from '@ui-components/components/table-empty-content/table-empty-content.module';
import { TurnoverFilterMenuComponent } from '@ui-components/components/turnover-filter-menu/turnover-filter-menu.component';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';
import { SelectFilterComponent } from '@ui-components/modals/inspections-summary-report-modal/components/select-filter/select-filter.component';
import { SummaryChartComponent } from '@ui-components/modals/inspections-summary-report-modal/components/summary-chart/summary-chart.component';
import { SummaryTableComponent } from '@ui-components/modals/inspections-summary-report-modal/components/summary-table/summary-table.component';

import { SummaryExpandedTableComponent } from './components/summary-expanded-table/summary-expanded-table.component';
import { SummarySkeletonComponent } from './components/summary-skeleton/summary-skeleton.component';
import { InspectionsSummaryReportModalComponent } from './inspections-summary-report-modal.component';

@NgModule({
  declarations: [
    InspectionsSummaryReportModalComponent,
    SummaryTableComponent,
    SummaryExpandedTableComponent,
    SelectFilterComponent,
    SummaryChartComponent,
    SummarySkeletonComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    IconComponent,
    SlideToggleComponent,
    ReactiveFormsModule,
    CustomDashboardModule,
    MatIconModule,
    MatMenuModule,
    CollapsibleSearchInputComponent,
    DashboardContainerModule,
    MatSortModule,
    MatTableModule,
    PipesModule,
    RadioButtonDropdownModule,
    ScrollingModule,
    TableVirtualScrollModule,
    TurnoverFilterMenuComponent,
    SkeletonLoaderComponent,
    EmptyListComponent,
    ChartCardModule,
    ChartModule,
    MatTooltipModule,
    TurnoverCommentPreviewModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    InputErrorModule,
    InputModule,
    MatDatepickerModule,
    DateRangeDropdownModule,
    MatFormFieldModule,
    TableEmptyContentModule,
    FadeTextComponent,
    DateDropdownModule,
  ],
  exports: [InspectionsSummaryReportModalComponent],
})
export class InspectionsSummaryReportModalModule {}
