import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

import { SidePanelService } from '@app/services/side-panel.service';
import { EIcon } from '@shared/enums/icon.enum';

import { CustomizedSnackbarComponent } from './component/customized-snackbar.component';
import { SnackbarErrorMessage } from './snackbar-message.enum';

// enum Snackbar
enum SnackBarCss {
  Success = 'snackbar-success',
  Failure = 'snackbar-failure',
  Warning = 'snackbar-warning',
  Info = 'snackbar-info',
}

enum NotificationPositionCss {
  WithSidePanel = 'notification-with-side-panel',
  WithoutPanel = 'notification-closed-side-panel',
  NoPanel = 'notification-with-no-side-panel',
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  notificationClass = 'customized-notification';
  positionCss = NotificationPositionCss.WithSidePanel;
  snackBarRef: MatSnackBarRef<CustomizedSnackbarComponent>;
  defaultDuration = 10000;
  defaultClosable = true;
  private _snackBarActions$ = new BehaviorSubject<{ action: string; actionParam?: any }>({ action: '' });
  snackBarActions$ = this._snackBarActions$.asObservable();
  snackbarLoadingSpinner$ = new BehaviorSubject<boolean>(false);

  constructor(private snackBar: MatSnackBar) {}

  watchForSidePanel(sidePanelService: SidePanelService): Observable<boolean> {
    if (!sidePanelService) {
      this.positionCss = NotificationPositionCss.NoPanel;
      return of(null);
    }
    return sidePanelService.isSidePanelExpanded$.pipe(
      tap(isExpanded => {
        this.positionCss = isExpanded ? NotificationPositionCss.WithSidePanel : NotificationPositionCss.WithoutPanel;
      })
    );
  }

  setDefaultSettings(duration: number, closable: boolean) {
    this.defaultDuration = duration;
    this.defaultClosable = closable;
  }

  success(
    message: SnackbarErrorMessage | string,
    formatOptions: any[] = [],
    duration: number = undefined,
    closable: boolean = undefined
  ) {
    this.openNotification(
      this.formatMessage(message, formatOptions),
      SnackBarCss.Success,
      typeof duration === 'undefined' ? this.defaultDuration : duration,
      typeof closable === 'undefined' ? this.defaultClosable : closable
    );
  }

  error(message: SnackbarErrorMessage | string, duration: number = undefined, closable: boolean = undefined) {
    this.openNotification(
      message,
      SnackBarCss.Failure,
      typeof duration === 'undefined' ? this.defaultDuration : duration,
      typeof closable === 'undefined' ? this.defaultClosable : closable
    );
  }

  info(message: SnackbarErrorMessage | string, duration: number = undefined, closable: boolean = undefined) {
    this.openNotification(
      message,
      SnackBarCss.Info,
      typeof duration === 'undefined' ? this.defaultDuration : duration,
      typeof closable === 'undefined' ? this.defaultClosable : closable
    );
  }

  warning(
    message: SnackbarErrorMessage | string,
    duration: number = undefined,
    closable: boolean = undefined,
    icon?: EIcon
  ) {
    this.openNotification(
      message,
      SnackBarCss.Warning,
      typeof duration === 'undefined' ? this.defaultDuration : duration,
      typeof closable === 'undefined' ? this.defaultClosable : closable,
      icon
    );
  }

  defaultPermissionWarning(): void {
    this.warning('Access denied: You do not have the required permissions.');
  }

  private openNotification(message: string, cssClass: SnackBarCss, duration: number, closable: boolean, icon?: EIcon) {
    this._snackBarActions$.next({ action: '' });
    this.snackBarRef = this.snackBar.openFromComponent(CustomizedSnackbarComponent, {
      data: {
        message,
        icon,
        actions$: this._snackBarActions$,
      },
      duration: duration,
      panelClass: [`customized-notification`, this.positionCss, cssClass, !closable ? 'not-closable' : 'closable'],
      horizontalPosition: 'left',
    });
  }

  private formatMessage(message: string, formatOptions: any[]) {
    if (formatOptions?.length) {
      const args = formatOptions;
      return message.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
      });
    } else {
      return message;
    }
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  resetSnackbarActions(): void {
    this._snackBarActions$.next({ action: null, actionParam: null });
  }
}
