import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';

import { EIcon } from '@shared/enums/icon.enum';
import { parseNumber } from '@shared/functions/parse-number';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent extends CustomControlAbstract<number> implements OnInit, ControlValueAccessor {
  //ToDo: make this control to return to outside value without mask & separator as clear number
  @Input() prefix: string;
  @Input() postfix: string;
  @Input() attrPlaceholder: string;
  @Input() attrMask = 'separator.2';
  @Input() attrThousandSeparator = ',';
  @Input() min = 0;
  @Input() max: number = null;
  @Input() nullAllowed = false;
  @Input() isSmallFont = false;

  @Input() contentCss = 'display-flex align-items-start flex-column';
  @Input() containerCss = 'display-flex flex-column';
  @Input() inputCss = 'display-flex align-items-center';
  @Input() classList = '';

  @Input() markAsInvalid = false;
  @Input() errorSection = true;
  @Input() icon: EIcon;
  @Input() iconTooltip = '';
  @Input() showClearButton = false;

  @Input() label: string;
  @Input() labelCss = 'label-input body-small-bold';
  @Input() labelRequired = false;
  @Input() labelInside = false;
  @Input() labelTooltip = '';

  @Input() autoUpdate = true;

  @Output() blurEvent = new EventEmitter<FocusEvent>();
  @Output() keydownEnterEvent = new EventEmitter<Event>();
  @ViewChild('inputControl', { read: ElementRef }) inputControl: ElementRef<HTMLInputElement>;

  innerControl = new FormControl<string>(null);

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlBase();
    this.innerControl.setValue(this.control.value?.toString() ?? '0');

    if (this.autoUpdate) {
      this.innerControl.valueChanges
        .pipe(
          untilDestroyed(this),
          map(v => parseNumber(v.toString())),
          distinctUntilChanged(),
          filter(v => !isNaN(v) && v != this.control.value),
          tap(v => this.onChanged(v))
        )
        .subscribe(v => this.control.setValue(v));

      this.control.valueChanges
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(),
          filter(v => v != null && !isNaN(v) && v != parseNumber(this.control.value?.toString()))
        )
        .subscribe(v => this.innerControl.setValue(v.toString()));
    }

    setTimeout(() => this.cdr.detectChanges());
  }

  writeValue(value: any): void {
    if (value != this.control.value && !isNaN(value)) {
      this.control.setValue(value);
    }
  }

  onBlur($event: FocusEvent) {
    const value = this.innerControl.value;

    if (!this.autoUpdate) {
      this.updateControlValueManually();
    } else {
      if (value !== '0' && !value) {
        this.innerControl.setValue('0');
      } else {
        if (typeof value === 'string' && value.includes('.')) {
          const decimalPart = value.split('.')[1];

          if (decimalPart.length > 2) {
            this.innerControl.setValue(value.split('.')[0] + '.' + decimalPart.substring(0, 2));
          }
        }
      }
      this.blurEvent.emit($event);
      this.onTouched();
    }
  }

  focus() {
    this.inputControl?.nativeElement.focus();
  }

  keydownEnter($event: Event) {
    if (this.innerControl.value) {
      if (!this.autoUpdate) {
        this.updateControlValueManually();
      } else {
        $event.stopPropagation();
        $event.preventDefault();
        this.keydownEnterEvent.emit($event);
      }
    }
  }

  clear() {
    this.innerControl.setValue('0');
  }

  keyUp($event: KeyboardEvent, input: HTMLInputElement) {
    if (input.value === '' && $event.key === '.') {
      input.value = '0.';
    }
    if (input.value == '0' && ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key)) {
      input.value = $event.key;
      this.updateControlValueManually(input.value);
    }
  }

  private updateControlValueManually(simpleNumber?: string): void {
    let value = 0;
    if (simpleNumber) {
      value = +simpleNumber;
    } else {
      value = this.innerControl.value ? parseNumber(this.innerControl.value.toString()) : 0;
    }
    if (!isNaN(value)) {
      this.control.setValue(value);
      this.onChanged(value);
    }
  }
}
