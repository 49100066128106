import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'lodash';

import { FieldType } from '@shared/enums/field-type';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

@Pipe({
  name: 'fieldType',
})
export class FieldTypePipe implements PipeTransform {
  transform(value: string, fieldsList: IRadioButtonOption<FieldType>[]): FieldType {
    if (!value || !fieldsList?.length) {
      return null;
    }
    const result = first(fieldsList.filter(item => item?.label.toLowerCase() === value?.toLowerCase()))?.value;
    return isNaN(result) ? null : result;
  }
}
