import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaskModule } from 'ngx-mask';

import { InputErrorModule } from '@app/modules/ui-components/components/input-error/input-error.module';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@ui-components/components/icon/icon.component';

import { DateDropdownComponent } from './date-dropdown.component';

@NgModule({
  declarations: [DateDropdownComponent],
  imports: [
    CommonModule,
    InputErrorModule,
    IconComponent,
    MatMenuModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxMaskModule,
    ReactiveFormsModule,
    TooltipDirectiveModule,
    MatIconModule,
  ],
  exports: [DateDropdownComponent],
})
export class DateDropdownModule {}
