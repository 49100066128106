export interface InspectionsSummaryReportModalPeriod {
  startDate: string | null;
  endDate: string | null;
}

export interface ISelectFilterConfig {
  id: number;
  label: string;
}

export interface InspectionSummaryTableDTO {
  template: string;
  templateId?: number;
  assigneeId?: number;
  not_started: number;
  in_progress: number;
  late: number;
  completed: number;
  completed_late: number;
  expired: number;
  review: number;
  total: number;
  completedLateProcent: number;
  completedProcent: number;
}

export const InspectionSummaryShowByRecord = {
  assignee: 'assignee',
  type: 'type',
} as const;

export type InspectionSummaryShowBy = keyof typeof InspectionSummaryShowByRecord;

export interface InspectionSummaryChart {
  readonly code: string;
  readonly label: string;
  value: number;
  backgroundColor: string;
}

export interface InspectionSummaryChartDTO {
  not_started: number;
  in_progress: number;
  late: number;
  completed: number;
  completed_late: number;
}

export type InspectionSummaryClickFiltering = {
  templateId?: number;
  assigneeId?: number;
  status?: number;
  groupByPropertyId?: number;
};

export type InspectionSummaryCloseModalResult = {
  templateIds?: [number] | null;
  assigneeId?: [number] | null;
  status?: [number] | null;
  timeframe?: number;
  groupByPropertyId?: number;
  isCustomPeriod?: boolean;
  startDate?: string;
  endDate?: string;
};
