import { Pipe, PipeTransform } from '@angular/core';

import { EColorPalette } from '@shared/enums/color-palette.enum';
import { PriorityType } from '@shared/enums/priority-type';

@Pipe({
  name: 'priorityIcon',
})
export class PriorityIconPipe implements PipeTransform {
  transform(ticketPriorityType: PriorityType): EColorPalette {
    switch (ticketPriorityType) {
      case PriorityType.High: {
        return EColorPalette.cRed2;
      }

      case PriorityType.Medium: {
        return EColorPalette.cOrange;
      }

      case PriorityType.Low: {
        return EColorPalette.cGray2;
      }

      default: {
        return EColorPalette.cGray2;
      }
    }
  }
}
