import { filter, some } from 'lodash';

import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithProperties } from '@shared/interfaces/user-with-properties';
import { RestUserModel } from '@shared/interfaces/user.interface';

/* TODO: if Chris doesn't change back his mind change it to accept single user */
export function prepareSelectedUserToPropertyMappings(
  user: RestUserModel,
  selectedProperties: PropertyBasicInfo[],
  roleId: number
): UserToPropertyMapping[] {
  return selectedProperties.map(item => {
    return {
      id: 0,
      roleId,
      userId: user.id,
      propertyId: item.id,
    };
  });
}

export function prepareUserToPropertyMappingsToAdd(
  selectedUsersUserToPropertyCurrentMappings: UserToPropertyMapping[],
  newUserToPropertyMappings: UserToPropertyMapping[]
): UserToPropertyMapping[] {
  return filter(
    newUserToPropertyMappings,
    mapping =>
      !some(selectedUsersUserToPropertyCurrentMappings, { userId: mapping.userId, propertyId: mapping.propertyId })
  );
}

export function prepareUserToPropertyMappings(
  user: RestUserModel,
  selectedUsersWithProperties: UserWithProperties[]
): UserWithProperties[] {
  return selectedUsersWithProperties?.filter(item => item.id === user.id);
}

export function prepareUserToPropertyMappingsToRemove(
  selectedUsersUserToPropertyCurrentMappings: UserToPropertyMapping[],
  newUserToPropertyMappings: UserToPropertyMapping[]
) {
  const selectedUsersUserToPropertyMappingsToDelete = filter(
    selectedUsersUserToPropertyCurrentMappings,
    mapping => !some(newUserToPropertyMappings, { userId: mapping.userId, propertyId: mapping.propertyId })
  );

  return selectedUsersUserToPropertyMappingsToDelete;
}
