<div class="input-error-container" [ngClass]="containerCss" *ngIf="validationMessages?.length || message">
  <ng-container
    *ngIf="visible"
    [ngTemplateOutlet]="validationMessages?.length ? formErrorTemplate : defaultErrorTemplate"></ng-container>
</div>

<ng-template #formErrorTemplate>
  <div *ngFor="let itemMessage of validationMessages">
    {{ itemMessage }}
  </div>
</ng-template>

<ng-template #defaultErrorTemplate>
  <div>
    {{ message }}
  </div>
</ng-template>
