<div
  class="inspection-name"
  #headerName
  [attr.contenteditable]="editMode"
  (click)="enterEditMode()"
  (blur)="leaveEditMode(headerName.textContent)"
  (keydown)="nameChanged($event)"
  placeholder="Template name...">
  <ng-container *ngIf="header">{{ header }}</ng-container>
</div>
