import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { InputErrorModule } from '@app/modules/ui-components/components/input-error/input-error.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { AutoresizeModule } from '@ui-components/directives/autoresize/autoresize.module';

import { InputAreaComponent } from './input-area.component';

@NgModule({
  declarations: [InputAreaComponent],
  imports: [CommonModule, InputErrorModule, ReactiveFormsModule, AutoresizeModule, DirectivesModule, MatInputModule],
  exports: [InputAreaComponent],
})
export class InputAreaModule {}
