import { Pipe, PipeTransform } from '@angular/core';

import { NoValue } from '@shared/constants/none-radio-option.const';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value?: number, currency = '$', fractionDigits = 0): string {
    return isNaN(value) || value === null || value === undefined
      ? NoValue
      : value >= 0
      ? `${currency}${value.toFixed(fractionDigits)}`
      : `-${currency}${Math.abs(value).toFixed(fractionDigits)}`;
  }
}
