import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

import { TicketType } from '@shared/enums/ticket-type';
import { InputModule } from '@ui-components/controls/input/input.module';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-create-board-column-modal',
  templateUrl: './create-board-column-modal.component.html',
  styleUrls: ['./create-board-column-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, InputModule, MatButtonModule],
})
export class CreateBoardColumnModalComponent {
  form = this.formBuilder.group({
    columnName: [null, [Validators.required]],
  });

  formSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { boardName: string; ticketType: TicketType },
    public dialogRef: MatDialogRef<CreateBoardColumnModalComponent>
  ) {}

  ok() {
    this.formSubmitted = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
