import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';

import { AttachmentItemComponent } from './attachment-item.component';

@NgModule({
  declarations: [AttachmentItemComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    IconComponent,
    TooltipDirectiveModule,
    PipesModule,
    PipesModule,
    StopPropagationDirective,
  ],
  exports: [AttachmentItemComponent],
})
export class AttachmentItemModule {}
