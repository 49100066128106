export enum PortfolioAttachmentType {
  None = 'None',
  PrimaryPhoto = 'PrimaryPhoto',
  Photo = 'Photo',
  Avatar = 'Avatar',
  ArticlesOfIncorporation = 'ArticlesOfIncorporation',
  Prospectus = 'Prospectus',
  Brochure = 'Brochure',
}

export enum PropertyAttachmentType {
  None = 'None',
  PrimaryPhoto = 'PrimaryPhoto',
  Photo = 'Photo',
  Avatar = 'Avatar',
  RentalApplication = 'RentalApplication',
  LeaseAgreement = 'LeaseAgreement',
}

export enum UnitAttachmentType {
  None,
  PrimaryPhoto,
  Photo,
  CurrentLease,
  FloorPlan,
}

export enum TurnoverAttachmentType {
  None,
  Summary,
  ScopeOfWork,
  Photo,
  Other,
  InspectionReport,
}

export enum ApplianceAttachmentType {
  None = 'None',
  Order = 'Order',
  Warranty = 'Warranty',
  Manual = 'Manual',
  InstallationGuide = 'InstallationGuide',
}

export enum BidAttachmentType {
  None,
  FilledScopeOfWork,
  Photo,
}

export enum TicketAttachmentType {
  None,
  Attachment,
}
