import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'lodash';

import { EnumerationItem } from '@shared/interfaces/enumeration-item';

@Pipe({
  name: 'enumValueToDisplayText',
})
export class EnumValueToDisplayTextPipe implements PipeTransform {
  transform(value: number, enumList: EnumerationItem[]): string | number {
    if (!enumList?.length) {
      return value;
    }

    return first(enumList.filter(enumItem => enumItem.enumerationValue === value))?.displayText || value;
  }
}
