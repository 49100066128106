import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'elapsedDate',
})
export class ElapsedDatePipe implements PipeTransform {
  transform(value: string): boolean {
    if (value) {
      return moment().startOf('day').isAfter(moment(value).startOf('day'));
    }
    return false;
  }
}
