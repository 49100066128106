import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestTimerInstanceModel } from '@shared/interfaces/rest-timer-instance-model';

export function getTimeInStep(
  workflowStepEnumType: WorkflowStepEnumType,
  timerInstance: RestTimerInstanceModel,
  forAllProcess = false
): number {
  if (!timerInstance) {
    return 0;
  }

  if (forAllProcess) {
    return timerInstance.total;
  } else {
    switch (workflowStepEnumType) {
      case WorkflowStepEnumType.Notice: {
        return timerInstance.notice;
      }
      case WorkflowStepEnumType.MoveOut: {
        return timerInstance.moveOut;
      }
      case WorkflowStepEnumType.Bid: {
        return timerInstance.bid;
      }
      case WorkflowStepEnumType.MakeReady: {
        return timerInstance.makeReady;
      }
      case WorkflowStepEnumType.Reno: {
        return timerInstance.reno;
      }
      case WorkflowStepEnumType.Punch: {
        return timerInstance.punch;
      }
      case WorkflowStepEnumType.Approval: {
        return timerInstance.approval;
      }
      case WorkflowStepEnumType.Marketing: {
        return timerInstance.marketing;
      }
      case WorkflowStepEnumType.LeaseUp: {
        return timerInstance.leaseUp;
      }
      case WorkflowStepEnumType.MoveIn: {
        return timerInstance.moveIn;
      }
    }
  }
}
