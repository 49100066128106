import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { IconComponent } from '@ui-components/components/icon/icon.component';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

import { NewBulkScopeComponent } from './new-bulk-scope.component';

@NgModule({
  declarations: [NewBulkScopeComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    InputModule,
    ReactiveFormsModule,
    IconComponent,
    MatProgressSpinnerModule,
    RadioButtonDropdownModule,
  ],
})
export class NewBulkScopeModule {}
