import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackageService } from '@app/services/package.service';
import { selectPmsTypesEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { PackageType } from '@shared/enums/package-type';
import { Providers } from '@shared/interfaces/pms-credential-model.interface';

@Injectable({
  providedIn: 'root',
})
export class PmsService {
  constructor(private packageService: PackageService, private store: Store<{}>) {}

  isSyncWithPmsEnabled(pmsType: number): Observable<boolean> {
    return combineLatest([
      this.packageService.checkPackageAvailable(PackageType.Service),
      this.store.select(selectPmsTypesEnumeration),
    ]).pipe(
      map(([servicePackageEnabled, pmsTypes]) => {
        const allowedPms = pmsTypes
          .filter(pmsType =>
            [Providers.BUILDIUM, Providers.RESMAN, Providers.ENTRATA, Providers.YARDI].includes(
              pmsType.displayText as Providers
            )
          )
          .map(p => p.enumerationValue);
        return servicePackageEnabled && allowedPms.includes(pmsType);
      })
    );
  }
}
