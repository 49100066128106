<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle">Add new column to the board {{ data.boardName }}</div>
  </div>

  <mat-dialog-content>
    <div class="no-shadow modal-form" [formGroup]="form">
      <app-input
        [labelCss]="'nowrap pre-label'"
        [containerCss]="'display-flex flex-column grid-gap-4'"
        formControlName="columnName"
        [attrLabel]="(this.data.ticketType === 5 ? 'Custom Cost' : 'Schedule') + ' column name'"
        [showValidationError]="formSubmitted"></app-input>
    </div>
    <div class="remark">
      <b>Note:</b>
      &nbsp;
      <span>you can edit or delete this column anytime from the Board</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="display-flex grid-gap-8 justify-content-end w-100">
      <button mat-stroked-button class="modal-button" (click)="close()">Cancel</button>
      <button mat-raised-button color="primary" class="modal-button" (click)="ok()">Add</button>
    </div>
  </mat-dialog-actions>
</div>
