import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormatter]',
})
export class CurrencyFormatterDirective {
  @Input() appCurrencyFormatter = true;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    if (this.appCurrencyFormatter) {
      let sanitizedValue = value.replace(/[^0-9.]/g, '');

      const dotIndex = sanitizedValue.indexOf('.');
      if (dotIndex !== -1) {
        const beforeDot = sanitizedValue.substring(0, dotIndex + 1);
        let afterDot = sanitizedValue.substring(dotIndex + 1);

        if (afterDot.length > 2) {
          afterDot = afterDot.substring(0, 2);
        }

        sanitizedValue = beforeDot + afterDot;
      }

      const formattedValue = this.formatCurrency(sanitizedValue);
      this.el.nativeElement.value = formattedValue;
    }
  }

  private formatCurrency(value: string): string {
    if (!value) {
      return '';
    }
    const parts = value.split('.');
    const wholePart = parts[0];
    const fractionalPart = parts.length > 1 ? '.' + parts[1] : '';
    const withCommas = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return '$' + withCommas + fractionalPart;
  }
}
