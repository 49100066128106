import { compact } from 'lodash';

import { InspectionModel } from '@main-application/inspections/models/inspection.model';
import { RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import { RestInspectionsModel } from './rest-inspections-model.interface';

export class InspectionListItemModel extends InspectionModel {
  readonly notifyWhenDone: RestUserModel[];

  constructor(
    entityDetails: RestInspectionsModel,
    inspectionTemplate: RestTemplateModel,
    properties: RestPropertyModel[],
    users: Map<number, RestUserModel>
  ) {
    super(entityDetails, inspectionTemplate);
    if (this.propertyId && !this.propertyName) {
      this.propertyName = properties.find(x => x.id === this.propertyId)?.name;
    }

    const notifyWhenDoneIds = entityDetails.notifyWhenDoneUserIds?.split(',') || [];
    this.notifyWhenDone = compact(notifyWhenDoneIds.map(id => users.get(Number(id))));
    if (entityDetails.reviewRequiredByUserId) {
      this.reviewer = users.get(entityDetails.reviewRequiredByUserId);
      this.reviewCompletedByUser = users.get(entityDetails.completedBy);
    }
  }
}
