import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'kpiValue',
})
export class KpiValuePipe implements PipeTransform {
  transform(value: number, operator: string, unit?: string): string {
    if (isNil(value)) {
      return null;
    }
    return `${operator} ${value}${unit || ''}`;
  }
}
