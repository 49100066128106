import { Pipe, PipeTransform } from '@angular/core';

import { RestUserModel } from '@shared/interfaces/user.interface';

@Pipe({
  name: 'isUserExisting',
})
export class IsUserExistingPipe implements PipeTransform {
  transform(row: RestUserModel): boolean {
    return row.id !== 0;
  }
}
