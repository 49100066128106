import { EnvironmentConfig, EnvironmentType } from './environment-config';

const currentDomain = window.location.hostname.includes('beta.ineedservice.co')
  ? 'beta.ineedservice.co'
  : 'beta.propup-service.com';

export const environment: EnvironmentConfig = {
  type: EnvironmentType.Prod,
  ver: '1.5.1',
  authBaseUrl: 'https://propupb2c.b2clogin.com/propupb2c.onmicrosoft.com/oauth2/v2.0',
  apiUrl: 'https://api-beta.propup-service.com/api',
  openApiUrl: 'https://api-beta.propup-service.com/openApi',
  notificationsUrl: 'https://api-beta.propup-service.com/notifications',
  notificationsAllUrl: 'https://api-beta.propup-service.com/all_notifications',
  disableForm: true,
  showAuthorizeLogs: false,
  showBuildTime: false,
  showEasterEggs: true,
  azureAuthConfig: {
    b2cDirectory: 'propupb2c',
    stsBase: 'propupb2c.b2clogin.com',
    tenant: 'propupb2c.onmicrosoft.com',
    policyKey: 'B2C_1_SignUpSignIn',
    stsServer: 'https://propupb2c.b2clogin.com/propupb2c.onmicrosoft.com/B2C_1_SignUpSignIn/v2.0',
    clientID: '1fd8edf0-b9c5-4525-b18f-2199fc206a6b',
    b2cScopes: 'openid openid 1fd8edf0-b9c5-4525-b18f-2199fc206a6b',
    redirectUrl: `https://${currentDomain}/accept-token`,
    stsServerSignInEmail: 'https://propupb2c.b2clogin.com/propupb2c.onmicrosoft.com/B2C_1A_signin_with_email/v2.0',
    policyKeySignInEmail: 'B2C_1A_signin_with_email',
    postLogoutRedirectUrl: `https://${currentDomain}/login`,
    forgotPasswordUrl: '',
  },
};
