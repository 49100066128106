<div
  class="user-select-dropdown-container"
  [ngClass]="[containerCss, attrDisable ? 'disabled' : '']"
  [class.user-select--small]="isSmallFont"
  [class.user-select-dropdown-container--transparent]="isTransparent">
  <ng-container *ngIf="!labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
  <ng-container *ngIf="!displaySkeleton">
    <div
      class="user-select-dropdown-content display-flex flex-auto align-items-center"
      [class.user-select-dropdown-content--no-borders]="!hasBorders"
      [class.user-select-dropdown-content--transparent]="isTransparent"
      [ngClass]="[
        markAsInvalid || (errors && showValidationError) ? 'invalid' : '',
        attrDisable || _listLoading ? 'disabled' : '',
        hasValue && highlightWhenActive && !(attrDisable || _listLoading) ? 'active' : ''
      ]">
      <ng-container *ngIf="labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
      <mat-spinner *ngIf="_listLoading" class="ml-4" color="accent" diameter="20"></mat-spinner>
      <ng-select
        #selectRef
        [formControl]="control"
        class="select-container flex-1"
        [class.select-container--custom-width]="isCustomWidth"
        [ngClass]="[selectCss, containerWidthCss, showChevron ? '' : 'hide-chevron']"
        [ngStyle]="{
          'padding-left': labelInside ? labelWidth : 'initial',
          'margin-left': labelInside ? '-' + labelWidth : 'initial'
        }"
        [readonly]="attrDisable || _listLoading"
        [placeholder]="attrPlaceholder"
        [clearable]="allowClear"
        [searchable]="allowSearch"
        [appendTo]="attrAppendTo"
        [searchFn]="searchFunction.bind(this)"
        [matTooltipDisabled]="selectRef.isOpen && selectRef.focused"
        [matTooltip]="userTooltipInfo$ | async"
        (open)="onOpen()"
        (close)="onClose()">
        <ng-container *ngIf="defaultOptionItems.length">
          <ng-option
            *ngFor="let defaultAssigneeOptionItem of defaultOptionItems"
            [ngClass]="optionCss"
            [value]="defaultAssigneeOptionItem.value">
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ item: defaultAssigneeOptionItem }"></ng-container>
          </ng-option>
        </ng-container>
        <ng-container *ngIf="myOptionItem">
          <ng-option [ngClass]="optionCss" [value]="myOptionItem.value">
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ item: myOptionItem }"></ng-container>
          </ng-option>
        </ng-container>
        <ng-container *ngIf="allOptionItem">
          <ng-option [ngClass]="optionCss" [value]="allOptionItem.value">
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ item: allOptionItem }"></ng-container>
          </ng-option>
        </ng-container>
        <ng-container *ngIf="residentOptionItem">
          <ng-option [ngClass]="optionCss" [value]="residentOptionItem.value">
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ item: residentOptionItem }"></ng-container>
          </ng-option>
        </ng-container>

        <ng-container *ngIf="myOptionItem || allOptionItem || defaultOptionItems?.length">
          <ng-option [ngClass]="optionCss" disabled>
            <app-divider></app-divider>
          </ng-option>
        </ng-container>

        <ng-container *ngFor="let item of userList$ | async; trackByKey: 'value'">
          <ng-option *ngIf="!defaultOptions?.includes(item?.value)" [ngClass]="optionCss" [value]="item.value">
            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ng-option>
        </ng-container>

        <ng-option *ngIf="(vendorList$ | async)?.length" [ngClass]="optionCss" disabled="disabled">
          <app-divider [text]="'vendors'"></app-divider>
        </ng-option>
        <ng-option *ngFor="let item of vendorList$ | async" [ngClass]="optionCss" [value]="item?.value">
          <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </ng-option>

        <ng-template ng-label-tmp>
          <div
            class="display-flex grid-gap-8 align-items-center"
            [ngClass]="valueCss"
            [class.user-select-dropdown-container--small]="isSmallFont">
            {{ displayValue | escapeHtml }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </ng-container>
  <ng-container *ngIf="displaySkeleton">
    <div class="content-placeholder skeleton-loader w-100"></div>
  </ng-container>
  <app-input-error
    *ngIf="errorSection && errors"
    [errors]="errors"
    [visible]="markAsInvalid || (errors && showValidationError)"></app-input-error>

  <ng-template #labelTemplate>
    <ng-container *ngIf="!displaySkeleton">
      <div #labelElement class="label-container" *ngIf="label">
        <label
          [ngClass]="[
            labelCss,
            labelInside ? 'inside' : '',
            attrDisable || _listLoading ? 'disable' : '',
            labelRequired ? 'label-required' : ''
          ]">
          {{ label }}
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="displaySkeleton && label">
      <div class="label-placeholder skeleton-loader" [ngStyle]="{ 'width.px': label.length * 10 }"></div>
    </ng-container>
  </ng-template>

  <ng-template #optionTemplate let-item="item">
    <div class="display-flex grid-gap-8 align-items-center">
      <div
        class="fade-truncate w-100"
        [class.user-select__option--small]="isSmallFont"
        [ngClass]="[optionCss, !labelInside ? 'ml' : '', attrDisable || _listLoading ? 'disable' : '']"
        [innerHTML]="item.label | escapeHtml"></div>
    </div>
  </ng-template>
</div>
