import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';

@Component({
  selector: '[app-table-empty-content]',
  templateUrl: './table-empty-content.component.html',
  styleUrls: ['./table-empty-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableEmptyContentComponent extends ComponentAbstract {
  @Input() message = 'No data available';
  @Input() attrColspan = 1;
  @Output() action = new EventEmitter();

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
