import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TurnoverDateRangeModule } from '@main-application/turnovers/components/sections/turnover-date-range/turnover-date-range.module';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';

import { NewTurnoverModalComponent } from './new-turnover-modal.component';

@NgModule({
  declarations: [NewTurnoverModalComponent],
  imports: [
    InputModule,
    SlideToggleComponent,
    MatDialogModule,
    ReactiveFormsModule,
    TurnoverDateRangeModule,
    RadioButtonDropdownModule,
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DateDropdownModule,
  ],
  providers: [DatePipe],
})
export class NewTurnoverModalModule {}
