import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragCursorDirective } from '@ui-components/directives/drag-drop/drag-n-drop-cursor.directive';

@NgModule({
  declarations: [DragCursorDirective],
  imports: [CommonModule],
  exports: [DragCursorDirective],
})
export class DragNDropCursorDirectiveModule {}
