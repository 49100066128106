import { createSelector } from '@ngrx/store';

import { selectCoreModule } from '@main-application/store/selectors/core.selector';

import { windowResizeFeatureKey } from '../reducers/window-resize.reducer';

export const selectWindowResizeState = createSelector(selectCoreModule, coreState => coreState[windowResizeFeatureKey]);
export const selectWindowWidth = createSelector(
  selectWindowResizeState,
  windowResizeState => windowResizeState.windowWidth
);
