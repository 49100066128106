import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSubmitRolesAndUnitsDisabled',
})
export class IsSubmitRolesAndUnitsDisabledPipe implements PipeTransform {
  transform(isGeneralRoleChanged: boolean, arePropertiesChanged: boolean): boolean {
    return !isGeneralRoleChanged && !arePropertiesChanged;
  }
}
