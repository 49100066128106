import { Pipe, PipeTransform } from '@angular/core';

import { BidStatusType } from '@shared/enums/bid-status-type';

@Pipe({
  name: 'bidStatusType',
})
export class BidStatusTypePipe implements PipeTransform {
  transform(statusType: BidStatusType): string {
    switch (statusType) {
      case BidStatusType.SentToContractors: {
        return 'New';
      }
      case BidStatusType.SentToTurnover: {
        return 'Sent';
      }
      case BidStatusType.Accepted: {
        return 'Accepted';
      }
      case BidStatusType.Rejected: {
        return 'Rejected';
      }
      default: {
        return '';
      }
    }
  }
}
