import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueIfNonZero',
})
export class ValueIfNonZeroPipe implements PipeTransform {
  transform(value: number): number {
    if (!value) return null;

    return value;
  }
}
