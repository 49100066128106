import { Pipe, PipeTransform } from '@angular/core';

import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';

@Pipe({
  name: 'workflowPhaseType',
})
export class WorkflowPhaseTypePipe implements PipeTransform {
  transform(workflowPhaseType: WorkflowPhaseType): string {
    switch (workflowPhaseType) {
      case WorkflowPhaseType.NotReady: {
        return 'NOT READY';
      }
      case WorkflowPhaseType.Ready: {
        return 'READY';
      }
      default: {
        return '';
      }
    }
  }
}
