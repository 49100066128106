import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertiesToRadioInput',
})
export class PropertiesToRadioInputPipe implements PipeTransform {
  transform(properties: any[]): any[] {
    return properties?.map(property => {
      return {
        value: property,
        label: property.name,
      };
    });
  }
}
