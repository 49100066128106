import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWith',
})
export class StartsWithPipe implements PipeTransform {
  transform(value: string, pattern: string): boolean {
    if (!value) {
      return false;
    }
    return value.trim().toLowerCase().startsWith(pattern.trim().toLowerCase());
  }
}
