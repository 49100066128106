import { createReducer, on } from '@ngrx/store';

import { EnumerationConfig } from '@shared/interfaces/enumeration-config';

import { loadEnumerations, loadEnumerationsFailed, loadEnumerationsSuccess } from '../actions/enumerations.actions';

export const enumerationFeatureKey = 'enumeration';

export interface State {
  enumerationsLoading?: boolean;
  enumerations?: EnumerationConfig;
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(loadEnumerations, (state, action) => ({ ...state, enumerationsLoading: true })),
  on(loadEnumerationsSuccess, (state, action) => ({
    ...state,
    enumerationsLoading: false,
    enumerations: action.enumerations,
  })),
  on(loadEnumerationsFailed, (state, action) => ({
    ...state,
    enumerationsLoading: false,
    enumerations: null,
  }))
);
