export enum BoardColumnType {
  Unselected = 0,
  Unit = 'Unit',
  Comments = 'Comments',
  PropertyName = 'PropertyName',
  UnitStatus = 'UnitStatus',
  TurnStep = 'TurnStep',

  DropDown = 'DropDown',

  // == Date ==
  MoveIn = 'MoveIn',
  MoveOut = 'MoveOut',
  Available = 'Available',
  Showable = 'Showable',
  CustomDate = 'CustomDate',

  // == Dollar ==
  NewRent = 'NewRent',
  OldRent = 'OldRent',
  MarketRent = 'MarketRent',
  AdvertisedRent = 'AdvertisedRent',
  CustomAmount = 'CustomAmount',
  UnitTotal = 'UnitTotal',
  Budget = 'Budget',
  Delta = 'Variance',

  // == Info ==
  Assignee = 'Assignee',
  UnitType = 'UnitType',
  NewTenant = 'NewTenant',

  // == Enum ==
  NewFinish = 'NewFinish',
  OldFinish = 'OldFinish',
  Finish = 'Finish',

  // == Schedule ==
  Simple = 'Simple',
  Timed = 'Timed',
  Workorder = 'Workorder',

  // == Time ==
  Days = 'Days', // -- days in current step
  TotalDays = 'TotalDays', // -- days since move-out date
}

export enum BoardType {
  MakeReady,
  Reno,
  Marketing,
  Custom,
  Eviction,
  MoveOut,
  MoveIn,
  Approval,
  NonPms,
  Prelease,
  LeaseUp,
}

export enum BoardOutputType {
  SendToApproval,
  SendToLeaseUpOrMoveIn,
  None,
  SendToMoveOut,
  SendToMakeReady,
  SendToPunch,
  SendToReno,
  SendToArchive,
}

export enum BoardOutputBehaviour {
  Manually,
  Automatically,
}

export enum BoardProgressType {
  None,
  UpdateMakeReadyProgressBar,
  UpdateRENOProgressBar,
  UpdateNoticeProgressBar,
  UpdateMoveInProgressBar,
  UpdateMoveOutProgressBar,
  UpdateApprovalProgressBar,
  UpdateLeaseUpProgressBar,
}

export enum BoardSourceType {
  MakeReadyUnits,
  RenoUnits,
  UnleasedUnits,
  OnNoticeUnits,
  MoveOutUnits,
  MarketingUnits,
  OnNoticeAndUnleasedUnits,
  Manual,
  UnitsInNoticeUnderEviction,
  UnitsInMoveIn,
  UnitsInApproval,
  UnitsInPunch,
  None,
  NonPmsUnits,
  PreleaseUnits,
  LeaseUpUnits,
  UnleasedUnitsNotReady,
  UnleasedUnitsReady,
}

export enum BoardMakeReadySteps {
  TrashOut = 'Trash out',
  Painting = 'Painting',
  Hardware = 'Hardware',
  Flooring = 'Flooring',
  RemoveOldApp = 'Remove old app.',
  InstallNewApp = 'Install new app.',
}

export enum ScheduleCellStatus {
  Due,
  Skipped,
  Done,
  Late,
  Unscheduled,
  Pending,
  Declined,
  Started,
  Moved,
  StartedLate,
  StartedMoved,
  Review,
}

export enum StartedSubType {
  StartedLate,
  StartedMoved,
}

export enum ScheduleCellColor {
  Done = 0, // $tpp-green11
  MoveOut = 1, // $tpp-orange8
  Due = 2, // $tpp-purple
  Review = 5,
  Started = 6, // $tpp-orange6
  Expired = 13,
}

export enum BoardView {
  Unit,
  Calendar,
}
