import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoresizeDirective } from './autoresize.directive';

@NgModule({
  declarations: [AutoresizeDirective],
  imports: [CommonModule],
  exports: [AutoresizeDirective],
})
export class AutoresizeModule {}
