import { createAction, props } from '@ngrx/store';

import {
  AddUpdateTurnoverBid,
  RestTurnoverBid,
  RestTurnoverBidCategory,
} from '@shared/interfaces/turnover-bids.interface';

export const loadTurnoverBids = createAction('[Turnover] loadTurnoverBids', props<{ turnoverId: number }>());
export const loadTurnoverBidsSuccess = createAction(
  '[Turnover] createTurnoverBidSuccess',
  props<{ bids: RestTurnoverBid[] }>()
);
export const loadTurnoverBidsFailed = createAction('[Turnover] loadTurnoverBidsFailed');

export const createTurnoverBid = createAction('[Turnover] createTurnoverBid', props<{ bid: AddUpdateTurnoverBid }>());
export const createTurnoverBidSuccess = createAction(
  '[Turnover] createTurnoverBidSuccess success',
  props<{ bidId: number; turnoverId: number }>()
);
export const createTurnoverBidFailed = createAction('[Turnover] createTurnoverBidFailed');

export const acceptTurnoverBid = createAction(
  '[Turnover] acceptTurnoverBid',
  props<{ bidId: number; turnoverId: number }>()
);
export const acceptTurnoverBidSuccess = createAction('[Turnover] acceptTurnoverBidSuccess');
export const acceptTurnoverBidFailed = createAction('[Turnover] acceptTurnoverBidFailed');

export const rejectTurnoverBid = createAction(
  '[Turnover] rejectTurnoverBid',
  props<{ bidId: number; turnoverId: number; reason: string }>()
);
export const rejectTurnoverBidSuccess = createAction('[Turnover] rejectTurnoverBidSuccess');
export const rejectTurnoverBidFailed = createAction('[Turnover] rejectTurnoverBidFailed');

export const uploadTurnoverBidInvoice = createAction(
  '[Turnover] uploadTurnoverBidInvoice',
  props<{ bid: RestTurnoverBid; file: File }>()
);
export const uploadTurnoverBidInvoiceSuccess = createAction('[Turnover] uploadTurnoverBidInvoiceSuccess');
export const uploadTurnoverBidInvoiceFailed = createAction('[Turnover] uploadTurnoverBidInvoiceFailed');

export const updateTurnoverBid = createAction('[Turnover] updateTurnoverBid', props<{ bid: AddUpdateTurnoverBid }>());
export const updateTurnoverBidSuccess = createAction(
  '[Turnover] updateTurnoverBidSuccess',
  props<{ bid: AddUpdateTurnoverBid }>()
);
export const updateTurnoverBidFailed = createAction('[Turnover] updateTurnoverBidFailed');

export const removeTurnoverBid = createAction(
  '[Turnover] removeTurnoverBid',
  props<{ bidId: number; turnoverId: number }>()
);
export const removeTurnoverBidSuccess = createAction('[Turnover] removeTurnoverBidSuccess');
export const removeTurnoverBidFailed = createAction('[Turnover] removeTurnoverBidFailed');

export const loadTurnoverBidCategories = createAction('[Turnover] loadTurnoverBidCategories');
export const loadTurnoverBidCategoriesSuccess = createAction(
  '[Turnover] loadTurnoverBidCategoriesSuccess',
  props<{ categories: RestTurnoverBidCategory[] }>()
);
export const loadTurnoverBidCategoriesFailed = createAction('[Turnover] loadTurnoverBidCategoriesFailed');

export const addTurnoverBidCategory = createAction('[Turnover] addTurnoverBidCategory', props<{ name: string }>());
export const addTurnoverBidCategorySuccess = createAction(
  '[Turnover] addTurnoverBidCategorySuccess',
  props<{ categoryId: number }>()
);
export const addTurnoverBidCategoryFailed = createAction('[Turnover] addTurnoverBidCategoryFailed');

export const approveTurnoverBid = createAction(
  '[Turnover] approveTurnoverBid',
  props<{ bidId: number; turnoverId: number; approved: boolean | null; denyReason?: string }>()
);
export const approveTurnoverBidSuccess = createAction('[Turnover] approveTurnoverBidSuccess');
export const approveTurnoverBidFailed = createAction('[Turnover] approveTurnoverBidFailed');
