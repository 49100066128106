import { Pipe, PipeTransform } from '@angular/core';

import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';
import {
  EnumerationProperties,
  enumerationListToKeyValueMap,
} from '@shared/functions/enumeration-list-to-key-value-map';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';

const WorkflowPhaseToProgressMap = {
  [WorkflowPhaseType.NotReady]: 0,
  [WorkflowPhaseType.Ready]: 80,
};

@Pipe({
  name: 'workflowPhaseToProgressValue',
})
export class WorkflowPhaseToProgressValuePipe implements PipeTransform {
  transform(workflowPhase: WorkflowPhaseType, workflowPhases: EnumerationItem[]): number {
    const workflowPhasesEnumMap = enumerationListToKeyValueMap(
      workflowPhases,
      EnumerationProperties.simpleText,
      EnumerationProperties.enumerationValue
    );
    const workflowPhaseValue = workflowPhasesEnumMap[workflowPhase];
    return WorkflowPhaseToProgressMap[workflowPhaseValue];
  }
}
