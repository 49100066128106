import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursPast',
})
export class HoursPastPipe implements PipeTransform {
  transform(value: number, daysInStep: { days: number; hours: number }): string {
    const { days, hours } = daysInStep;
    return !days && !hours ? '' : days < 1000 ? `${value}h` : '';
  }
}
