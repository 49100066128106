import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AreaPageElementQuestionModel } from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { TemplateEditorDialogService } from '@main-application/shared/template-editor-dialog/services/template-editor-dialog.service';
import { EIcon } from '@shared/enums/icon.enum';

const placeholders = [
  'Is this a question?',
  'Is this a really good question?',
  'Is this a truly amazing question?',
  'Is this an outstandingly awesome question?',
  'Is this the most perspicacious question ever asked?',
  'Is this the penultimate metaversal question ever pontificated?',
  'Do I, human, know what penultimate means?',
  'Have we gone too deep?',
  '(introspectively) Should we go deeper?',
  '(pensively) When does it stop?',
  'The real question is: will you stop first?',
  'Human realizes they are in combat with AI infinity and will lose?',
  'Should human see a doctor about its use of free time?',
  'Should human doctor a see about its free of use time?',
  'Wait, what?',
  'What, what?',
  'What what in the house!?',
  'Or the apartment?',
  'AI for the win?',
  'Does this really never end?',
  'I, human, hereby understand this never ends?',
  'Did you sign?',
  'Are you human?',
  'OK so you did sign or you are human?',
  'Yes I signed?',
  'Yes I am human?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Does human know they’ve won?',
  'Human loses. AI wins. Mic has been dropped?',
];

@UntilDestroy()
@Component({
  selector: 'app-template-editor-question-item',
  templateUrl: './template-editor-question.component.html',
  styleUrls: ['./template-editor-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogQuestionComponent implements OnInit {
  @Input() set index(value: number) {
    this.placeholder = placeholders[Math.min(placeholders.length - 1, value)];
    this.cdr.markForCheck();
  }

  @Input() set questionItem(value: AreaPageElementQuestionModel) {
    this._questionItem = value;
    setTimeout(() => {
      this.questionTitleElement.nativeElement.textContent = value.title || this.placeholder;
      this.questionWidth = this.questionTitleElement.nativeElement.offsetWidth + 'px';
      this.cdr.markForCheck();
    });
  }

  get questionItem() {
    return this._questionItem;
  }

  @Output() enterPressed = new EventEmitter<AreaPageElementQuestionModel>();
  placeholder = placeholders[0];

  private _questionItem: AreaPageElementQuestionModel;

  protected readonly EIcon = EIcon;
  @Output() questionItemChange = new EventEmitter<AreaPageElementQuestionModel>();
  @Output() remove = new EventEmitter<AreaPageElementQuestionModel>();
  @ViewChild('questionTitleElement', { read: ElementRef, static: false })
  questionTitleElement: ElementRef<HTMLSpanElement>;
  @ViewChild('inputElement', { read: ElementRef, static: false }) inputElement: ElementRef<HTMLInputElement>;

  questionWidth: string;

  constructor(private cdr: ChangeDetectorRef, private templateEditorDialogService: TemplateEditorDialogService) {}

  ngOnInit() {
    this.templateEditorDialogService.questionIdFocused$.pipe(untilDestroyed(this)).subscribe(id => {
      if (id === this.questionItem.guid) {
        this.inputElement.nativeElement.focus({
          preventScroll: true,
        });
      }
    });
  }

  removeClick() {
    this.remove.emit(this.questionItem);
  }

  toggleDefaultValue(value: boolean) {
    const { defaultValue, ...rest } = this.questionItem;
    if (defaultValue === undefined || defaultValue !== value) {
      this.questionItemChange.emit({ ...rest, defaultValue: value });
    } else {
      this.questionItemChange.emit({ ...rest });
    }
  }

  questionChange(event: FocusEvent) {
    const value = event.target instanceof HTMLInputElement && event.target.value;
    this.questionItemChange.emit({ ...this.questionItem, title: value, name: this.questionItem.guid + value });
  }

  keyPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      const value = $event.target instanceof HTMLInputElement && $event.target.value;
      this.enterPressed.emit({ ...this.questionItem, title: value, name: this.questionItem.guid + value });
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  updateWidth(event: Event) {
    if (!(event instanceof InputEvent) || !(event.target instanceof HTMLInputElement)) {
      return;
    }
    let value = event.target.value; //NOET-9631 allow to enter ? .replace(/\?/g, ''); // + '?'; NOET-8453 removed question mark
    value = value === '?' ? '' : value;
    if (value !== event.target.value) {
      event.target.value = value;
      event.target.selectionStart = event.target.selectionEnd = value.length - 1;
    }
    this.questionTitleElement.nativeElement.textContent = value || this.placeholder;

    this.questionWidth = this.questionTitleElement.nativeElement.offsetWidth + 'px';
  }
}
