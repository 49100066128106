import { ChangeDetectorRef, Directive, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { setGeneralRoleRadioList } from '@main-application/administration/store/actions/administration.actions';
import {
  selectAllGeneralRoles,
  selectGeneralRoleRadioList,
} from '@main-application/administration/store/selectors/administration.selectors';
import { UserType } from '@shared/enums/user-type';
import { GeneralRole } from '@shared/interfaces/general-role';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

import { getGeneralRoleRadioList } from '../functions/get-general-roles-radio-list.function';

@UntilDestroy()
@Directive()
export abstract class AdministrationAbstract extends ComponentAbstract {
  route = inject(ActivatedRoute);
  router = inject(Router);
  isLoading = true;

  inProgress$: Observable<boolean>;

  userRoles: IRadioButtonOption<number>[] = [];

  userStates: IRadioButtonOption<boolean>[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  userTypes: IRadioButtonOption<UserType>[] = [
    {
      value: UserType.Contractor,
      label: 'Contractor',
    },
    {
      value: UserType.Employee,
      label: 'Employee',
    },
  ];
  isResident: boolean;

  protected constructor(protected cdr: ChangeDetectorRef, protected store: Store<{}>) {
    super(cdr);
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.isResident = !!this.route.snapshot?.data?.['isResident'];
    });
  }

  selectAllGeneralRoles(): Observable<GeneralRole[]> {
    return this.store.select(selectAllGeneralRoles).pipe(
      untilDestroyed(this),
      filter((generalRoles: GeneralRole[]) => !!generalRoles),
      tap((generalRoles: GeneralRole[]) => {
        this.store.dispatch(setGeneralRoleRadioList({ generalRoleRadioList: getGeneralRoleRadioList(generalRoles) }));
      })
    );
  }

  selectGeneralRoleRadioList(): Observable<IRadioButtonOption<number>[]> {
    return this.store.select(selectGeneralRoleRadioList).pipe(
      untilDestroyed(this),
      filter((generalRoleRadioList: IRadioButtonOption<number>[]) => !!generalRoleRadioList),
      tap((generalRoleRadioList: IRadioButtonOption<number>[]) => {
        this.userRoles = generalRoleRadioList;
        this.cdr.detectChanges();
      })
    );
  }
}
