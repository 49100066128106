import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTurnovers from '../reducers/turnovers.reducer';

export const selectTurnoversState = createFeatureSelector<fromTurnovers.State>(fromTurnovers.turnoversFeatureKey);
export const selectCreatedTurnover = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.createdTurnover
);
export const selectCreateTurnoverLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.createTurnoverLoading
);
export const selectTurnoverData = createSelector(selectTurnoversState, turnoversState => turnoversState.turnoverData);
export const selectTurnoverUnitName = createSelector(selectTurnoverData, turnoverData => turnoverData?.unitName);
export const selectTurnoverDataId = createSelector(selectTurnoverData, turnoverData => turnoverData?.id);
export const selectUpdatedTurnoverData = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.updatedTurnoverData
);
export const selectTurnoverDataLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.turnoverDataLoading
);
export const selectEmptyTurnoverData = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.emptyTurnoverData
);
export const selectUpdatedTurnoverTicket = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.editedTicket
);
export const selectSetWorkflowStepLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.setWorkflowStepLoading
);
export const selectSetWorkflowStepStatus = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.setWorkflowStepStatus
);
export const selectNextWorkflowStep = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.nextWorkflowStep
);
export const selectCurrentWorkflowStep = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.currentWorkflowStep
);
export const selectCurrentWorkflowStepModel = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.currentWorkflowStepModel
);
export const selectApplianceTypeList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.applianceTypeList
);
export const selectColorTypeList = createSelector(selectTurnoversState, turnoversState => turnoversState.colorTypeList);
export const selectUserList = createSelector(selectTurnoversState, turnoversState => turnoversState.userList);
export const selectAllUserRadioList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.allUserRadioList
);
export const selectAllUserList = createSelector(selectTurnoversState, turnoversState => turnoversState.allUserList);
export const selectListModel = createSelector(selectTurnoversState, turnoversState => turnoversState.userListModel);
export const selectCompanyRadioList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.companyRadioList
);
export const selectCompanyNameRadioList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.companyNameRadioList
);
export const selectCompanyEmailRadioList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.companyEmailRadioList
);
export const selectAddBidAttachmentInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.addBidAttachmentInProgress
);
export const selectAddBidAttachmentStatus = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.addBidAttachmentStatus
);
export const selectAddedTicketAttachment = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.addedTicketAttachment
);
export const selectAddTurnoverAttachmentStatus = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.addTurnoverAttachmentStatus
);
export const selectAddTurnoverAttachmentInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.addTurnoverAttachmentInProgress
);
export const selectUpdateTurnoverAttachmentInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.updateTurnoverAttachmentInProgress
);
export const selectDeleteTurnoverAttachmentInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.deleteTurnoverAttachmentInProgress
);
export const selectNewBidList = createSelector(selectTurnoversState, turnoversState => turnoversState.newBidList);
export const selectNewBidListInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.newBidListInProgress
);
export const selectTurnoverTicketList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.turnoverTicketList
);
export const selectTurnoverTicketListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.turnoverTicketListLoading
);
export const selectNewTurnoverTicket = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.newTurnoverTicket
);
export const selectNewTurnoverTicketLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.newTurnoverTicketLoading
);
export const selectCompanyList = createSelector(selectTurnoversState, turnoversState => turnoversState.companyList);
export const selectCompanyListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.companyListLoading
);
export const selectBidTurnoverList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.bidTurnoverList
);
export const selectBidTurnoverListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.bidTurnoverListLoading
);
export const selectAllBidList = createSelector(selectTurnoversState, turnoversState => turnoversState.allBidList);
export const selectAllBidListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.allBidListLoading
);
export const selectBidCompanyList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.bidCompanyList
);
export const selectBidCompanyListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.bidCompanyListLoading
);
export const selectAllAttachments = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.allAttachments
);
export const selectBid = createSelector(selectTurnoversState, turnoversState => turnoversState.bid);
export const selectUpdatedBid = createSelector(selectTurnoversState, turnoversState => turnoversState.updatedBid);
export const selectUpdatedBidLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.updatedBidLoading
);
export const selectRejectBidLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.rejectBidLoading
);
export const selectDeleteBidAttachmentInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.deleteBidAttachmentInProgress
);
export const selectBidAttachments = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.bidAttachments
);
export const selectWorkflowStepCommentList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.workflowStepCommentList
);
export const selectWorkflowStepCommentListLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.workflowStepCommentListLoading
);
export const selectDeleteTurnoverInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.deleteTurnoverInProgress
);
export const selectTurnoverDeleteStatus = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.turnoverDeleteStatus
);
export const selectStepInfo = createSelector(selectTurnoversState, turnoversState => turnoversState.stepInfo);
export const selectTicket = createSelector(selectTurnoversState, turnoversState => turnoversState.ticket);
export const selectTicketAttachments = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.ticketAttachments
);
export const selectTicketUpdateInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.ticketUpdateInProgress
);
export const selectTicketUpdateInProgressStatus = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.ticketUpdateInProgressStatus
);
export const selectInspectTurnoverId = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.inspectTurnoverId
);
export const selectValidationParams = createSelector(selectTurnoversState, turnoversState => ({
  lockFields: turnoversState.validationParams.lockFields,
  nextStepAvailable: turnoversState.validationParams.nextStepAvailable && turnoversState.validationParams.saveAvailable,
  nextStepChecking: turnoversState.validationParams.nextStepChecking,
  saveAvailable: turnoversState.validationParams.saveAvailable,
  customMessage: turnoversState.validationParams.customMessage,
}));
export const selectAllPropertyIds = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.allPropertyIds
);
export const selectActivePropertyIds = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.activePropertyIds
);
export const selectIsActiveSection = propertyId =>
  createSelector(selectActivePropertyIds, activePropertyIds => activePropertyIds.includes(propertyId));
export const selectInactiveFilteredPropertyIds = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.inactiveFilteredPropertyIds
);
export const selectIsInactiveFilteredSection = propertyId =>
  createSelector(selectInactiveFilteredPropertyIds, inactiveFilteredPropertyIds =>
    inactiveFilteredPropertyIds.includes(propertyId)
  );
export const selectUpdateStepAssigneeInProgress = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.updateStepAssigneeInProgress
);
export const selectDefaultTurnoversAssignees = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.defaultTurnoversAssignees
);
export const selectDefaultTurnoversAssigneesLoading = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.defaultTurnoversAssigneesLoading
);
export const selectDisplayedPropertyList = createSelector(
  selectTurnoversState,
  turnoversState => turnoversState.displayedPropertyList
);
export const selectTurnoverTimers = createSelector(selectTurnoversState, turnoverState => turnoverState.timers);
