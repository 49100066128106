<div
  class="inspection-description"
  #descriptionText
  [attr.contenteditable]="editMode"
  (click)="enterEditMode()"
  (blur)="leaveEditMode(descriptionText.textContent)"
  (keydown)="nameChanged($event)"
  placeholder="A really thoughtful description that you haven't thought about yet...">
  <ng-container *ngIf="description">{{ description }}</ng-container>
</div>
