<div class="display-flex flex-column">
  <label class="label-inner" [ngClass]="[labelRequired ? 'label-required' : '']" *ngIf="attrLabel">
    {{ attrLabel }}
  </label>
  <div class="display-flex grid-gap-8">
    <app-radio-button-dropdown
      class="w-30"
      [attrAppendTo]="'body'"
      [attrPlaceholder]="'Code'"
      [unsupportedValueLabel]="'Code'"
      [allowClear]="false"
      [allowSearch]="true"
      [autoSortList]="false"
      [items]="countryCodes"
      [formControl]="phoneCodeControl"></app-radio-button-dropdown>
    <app-input
      class="w-70"
      [attrMask]="'(000) 000-0000'"
      [attrDisable]="phoneNumberControl.disabled"
      [contentCss]="'display-flex flex-column fill'"
      [formControl]="phoneNumberControl"
      [attrPlaceholder]="'Enter phone number'"></app-input>
  </div>
  <app-input-error
    *ngIf="errors && displayErrorContent"
    [errors]="errors"
    [visible]="errors && showValidationErrorOrSubmitted"></app-input-error>
</div>
