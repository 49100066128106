import { Injectable } from '@angular/core';
import { Chart, ChartConfiguration, ChartOptions, ChartType, DefaultDataPoint, Plugin } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels/types/context';
import { first } from 'lodash';

import { ChartTypes } from '@dashboards/models/chart-types.enum';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartConfig, ChartDataItem } from '@shared/interfaces/chart-data-item';

@Injectable()
export class ChartService {
  labels: string[] = [];
  data: number[] = [];
  backgroundColor: string[] = [];
  labelColor: string[] = [];
  chartDataList: ChartDataItem[] = [];

  getChartConfig(
    chartType: ChartTypes,
    chartDataList: ChartDataItem[],
    chatConfig: ChartConfig
  ): ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    const config: ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> = {
      type: chartType,
      data: {
        labels: this.labels,
        datasets: [
          {
            maxBarThickness: chatConfig.maxBarThickness,
            offset: 0,
            spacing: chatConfig.spacing ?? 0,
            borderWidth: 0,
            label: '',
            data: this.data,
            backgroundColor: this.backgroundColor,
            datalabels: {
              color: this.labelColor,
            },
            hoverOffset: 10,
            borderRadius: chatConfig?.borderRadius || 0,
          },
        ],
      },
      options: this.getChartOptions(chartType, chatConfig),
      plugins: [],
    };

    if (chartType === ChartTypes.DOUGHNUT && chatConfig.showTextTooltip) {
      config.plugins.push(this.getDoughnutTextPlugin());
    }

    return config;
  }

  getChartOptions(chartType: ChartTypes, chartConfig: ChartConfig): ChartOptions<ChartTypes> {
    let options: ChartOptions<ChartTypes> = {
      responsive: true,

      layout: {
        padding: 10,
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: chartConfig.showTooltip,
        },
        datalabels: {
          color: EColorPalette.cWhite,
          font: {
            size: 10,
          },
          display: chartConfig.showDataLabel,
          formatter: this.getDataLabelFormatter(chartConfig),
        },
      },
    };

    if (chartType === ChartTypes.DOUGHNUT) {
      options = {
        ...options,
        cutout: `${chartConfig.cutoutLevel}%`,
        rotation: chartConfig.rotation,
        circumference: chartConfig.circumference,
      };
    }

    if (chartType === ChartTypes.BAR) {
      options = {
        ...options,
        scales: {
          xAxis: {
            display: true,
            grid: {
              display: false,
            },
          },
          yAxis: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      };
    }

    return options;
  }

  getDoughnutTextPlugin(): Plugin<ChartTypes> {
    const dataList = this.chartDataList;

    return {
      id: 'dougnut inner text',
      beforeDraw(chart: Chart, args, options) {
        const activeElement = first(chart.getActiveElements());
        if (!activeElement) {
          return;
        }

        const { label, additionalValue, additionalPercentage } = dataList[activeElement?.index];
        const {
          ctx,
          chartArea: { top, right, bottom, left, width, height },
        } = chart;
        ctx.save();
        ctx.fillStyle = EColorPalette.cGray;
        ctx.font = '10px lato';
        ctx.textAlign = 'center';
        ctx.fillText(`${(activeElement.element as any).$context.raw} units`, width / 2 + 10, top - 10 + height / 2);
        ctx.fillText(label, width / 2 + 10, top + height / 2);
        ctx.fillStyle = EColorPalette.cGray5;
        ctx.textAlign = 'center';
        ctx.fillText(`$${additionalValue.toFixed(2)}`, width / 2 + 10, top + 10 + height / 2);
        const textPercentage = `${additionalPercentage.toFixed(2)}%`;
        ctx.fillText(textPercentage, width / 2 + 10, top + 20 + height / 2);
      },
    };
  }

  getChartData(chartDataList: ChartDataItem[]) {
    this.labels = [];
    this.data = [];
    this.backgroundColor = [];
    this.labelColor = [];
    this.chartDataList = chartDataList;

    chartDataList.forEach((chartDataItem: ChartDataItem) => {
      this.labels.push(chartDataItem.label);
      this.data.push(chartDataItem.value);
      this.backgroundColor.push(chartDataItem.backgroundColor);
      this.labelColor.push(chartDataItem.labelColor);
    });
  }

  getDataSum(): number {
    return this.data.reduce((acc, value) => acc + value);
  }

  getDataLabelFormatter(config: ChartConfig) {
    if (config.dataLabelAbsolute) {
      return value => value;
    } else {
      const getContextSum = (context: Context): number => {
        return (context.dataset.data.reduce((prev, curr) => (prev as number) + (curr as number)) as number) || 0;
      };
      return (value, context) => {
        return `${value ? Math.round((value / getContextSum(context)) * 100) : ''}${value ? '%' : ''}`;
      };
    }
  }
}
