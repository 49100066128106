<div class="select-tree-container" [ngClass]="containerCss">
  <label [ngClass]="[labelCss, labelRequired ? 'label-required' : '']" *ngIf="label">{{ label }}</label>
  <div class="display-flex flex-column">
    <div
      mat-button
      [matMenuTriggerFor]="radioMenu"
      (menuOpened)="toggleMenu(true)"
      (menuClosed)="toggleMenu(false)"
      class="pre-title grid-gap-4 w-100 position-relative">
      <input
        class="body-small text-color dark"
        type="text"
        (input)="filterTree($event)"
        aria-label="Number"
        [placeholder]="placeholder"
        matInput
        autocomplete="off"
        [ngClass]="[warningMessage ? 'error' : '']" />
      <button *ngIf="hasNodesSelected() || warningMessage" (click)="clearSelection()" title="Clear all">
        <app-icon [svg]="EIcon.CLOSE" [iconColor]="EColorPalette.cRed2" [iconWidth]="22" [iconHeight]="22"></app-icon>
      </button>
      <span class="chevron" [class.expanded]="this.isExpanded"></span>
    </div>
    <app-input-error [visible]="!!warningMessage" [message]="warningMessage"></app-input-error>
  </div>

  <mat-menu #radioMenu="matMenu" class="select-tree">
    <div class="dropdown-select-tree-list-container select-tree display-flex justify-content-center">
      <div
        class="dropdown-list"
        (click)="$event.stopPropagation()"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()">
        <tree-root [nodes]="nodes" [options]="options" (initialized)="treeInitialized()"></tree-root>
      </div>
    </div>
  </mat-menu>
</div>
