import { Pipe, PipeTransform } from '@angular/core';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';

@Pipe({
  name: 'workflowStepDone',
})
export class WorkflowStepDonePipe implements PipeTransform {
  transform(step: WorkflowStepEnumType, currentStep: WorkflowStepEnumType): boolean {
    if (!step) {
      return false;
    }
    if (!currentStep) {
      return true;
    }
    return step <= currentStep;
  }
}
