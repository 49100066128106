import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

declare module 'rxjs' {
  interface Subscription {
    untilDestroyed: (component: PuSubscribable) => Subscription;
  }
}

@Directive()
export abstract class PuSubscribable implements OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

export class PuSubscribe {
  constructor() {
    Subscription.prototype.untilDestroyed = function (component: PuSubscribable) {
      component.addSubscription(this);
      return this;
    };
  }
}
