import { createAction, props } from '@ngrx/store';

import { VacancyLossAttrType } from '@main-application/turnovers/interfaces/vacancy-loss-attr';
import { VacancyLostThreshold } from '@main-application/turnovers/interfaces/vacancy-loss-threshold';
import { DashboardMode } from '@shared/enums/dashboard-mode';
import { DashboardViewType } from '@shared/enums/dashboard-view-type';
import { ActiveTurnoversDetailsCombined } from '@shared/interfaces/active-turnover-details';
import { BreadcrumbItem } from '@shared/interfaces/breadcrumb-item';
import { DelinquencyDetailsCombined } from '@shared/interfaces/delinquency.interface';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { MyProperties } from '@shared/interfaces/my-properties.interface';
import { RestUnitRenewalTargetModel } from '@shared/interfaces/renevals.interface';
import { RenewalDetailsCombined } from '@shared/interfaces/renewal.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/rest-unit-summary-model.interface';
import { RestTurnoverTaskModel } from '@shared/interfaces/turnover.interface';
import { UnitDetailsByType } from '@shared/interfaces/unit-details-by-type';
import { UnitDetails } from '@shared/interfaces/unit-details.interface';
import { RestUnitModel } from '@shared/interfaces/unit.interface';

import { ActiveTurnoversSummary } from '../../models/active-turnover-summary';
import { DelinquencySummary } from '../../models/delinquency-summary';
import { RenewalsSummary } from '../../models/renewals-summary';
import { UnitsSummary } from '../../models/units-summary';

export const loadTurnoverKanbanProperty = createAction(
  '[Dashboard] load Turnover Kanban Property',
  props<{ propertyId: number }>()
);
export const loadTurnoverKanbanPropertySuccess = createAction(
  '[Dashboard] load Turnover Kanban Property success',
  props<{ tasks: RestTurnoverTaskModel[] }>()
);
export const loadTurnoverKanbanPropertyError = createAction('[Dashboard] load Turnover Kanban Property error');
export const resetTurnoverKanbanProperty = createAction('[Dashboard] reset Turnover Kanban Property');

export const clearTurnoverKanbanPortfolio = createAction('[Dashboard] clear Turnover Kanban portfolio');
export const loadTurnoverKanbanPortfolio = createAction(
  '[Dashboard] load Turnover Kanban portfolio',
  props<{ portfolioId: number }>()
);
export const loadTurnoverKanbanPortfolioSuccess = createAction(
  '[Dashboard] load Turnover Kanban portfolio success',
  props<{ tasks: RestTurnoverTaskModel[] }>()
);
export const loadTurnoverKanbanPortfolioError = createAction('[Dashboard] load Turnover Kanban portfolio error');
export const resetTurnoverKanbanPortfolio = createAction('[Dashboard] reset Turnover Kanban portfolio');
export const kanbanListGenerateSuccess = createAction(
  '[Dashboard] kanban List Generate Success',
  props<{ kanbanListCount: number }>()
);

export const loadUserTurnovers = createAction('[Dashboard] load User Turnovers', props<{ userId: number }>());
export const loadUserTurnoversSuccess = createAction(
  '[Dashboard] load User Turnovers success',
  props<{ tasks: RestTurnoverTaskModel[] }>()
);
export const loadUserTurnoversError = createAction('[Dashboard] load User Turnovers error');
export const resetUserTurnoversError = createAction('[Dashboard] reset User Turnovers error');

export const loadMyTasks = createAction('[Dashboard] load My Tasks', props<{ portfolioId: number }>());
export const loadMyTasksSuccess = createAction(
  '[Dashboard] load My Tasks success',
  props<{ myTasks: RestTurnoverTaskModel[] }>()
);
export const loadMyTasksError = createAction('[Dashboard] load My Tasks error');
export const myTasksHeaderData = createAction(
  '[Dashboard] My Tasks header Data',
  props<{
    myTasksTurnoversCount: number;
    myTasksTicketsCount: number;
    staleTurnoversCount: number;
    lateTurnoversCount: number;
  }>()
);

export const clearMyTasks = createAction('[Dashboard] clear My Tasks');

export const loadPropertyKpi = createAction('[Dashboard] Load property kpi', props<{ propertyId: number }>());
export const loadPropertyKpiSucceded = createAction('[Dashboard] Load property kpi succeded', props<{ kpi: any }>());
export const loadPropertyKpiFailed = createAction('[Dashboard] Load property kpi failed');

export const loadUnitSummaryByProperty = createAction(
  '[Dashboard] Load unit summary by property',
  props<{ propertyId: number }>()
);
export const loadUnitSummaryByPropertySucceded = createAction(
  '[Dashboard] Load unit summary by property succeded',
  props<{ unitSummary: UnitsSummary }>()
);
export const loadUnitSummaryByPropertyFailed = createAction('[Dashboard] Load unit summary by property failed');

export const clearUnitSummaryByProperty = createAction('[Dashboard] clear unit summary by property');

export const loadActiveTurnoversSummary = createAction(
  '[Dashboard] Load active turnovers summary by property',
  props<{ propertyId: number }>()
);
export const loadActiveTurnoversSummarySucceded = createAction(
  '[Dashboard] Load active turnovers summary by property succeded',
  props<{ activeTurnoversSummary: ActiveTurnoversSummary }>()
);
export const loadActiveTurnoversSummaryFailed = createAction(
  '[Dashboard] Load active turnovers summary by property failed'
);

export const clearActiveTurnoversSummary = createAction('[Dashboard] clear Active Turnovers Summary');

export const loadActiveTurnoversDetails = createAction(
  '[Dashboard] Load active turnovers details by property',
  props<{ propertyId: number }>()
);
export const loadActiveTurnoversDetailsSucceded = createAction(
  '[Dashboard] Load active turnovers details by property succeded',
  props<{ activeTurnoversDetails: ActiveTurnoversDetailsCombined }>()
);
export const loadActiveTurnoversDetailsFailed = createAction(
  '[Dashboard] Load active turnovers details by property failed'
);

export const loadDelinquencyByUnit = createAction(
  '[Dashboard] Load delinquency by unit',
  props<{ propertyId: number }>()
);
export const loadDelinquencyByUnitSucceded = createAction(
  '[Dashboard] Load delinquency by unit succeded',
  props<{ delinquencyByUnit: DelinquencyDetailsCombined }>()
);
export const loadDelinquencyByUnitFailed = createAction('[Dashboard] Load delinquency by unit failed');

export const loadDelinquencySummary = createAction(
  '[Dashboard] Load delinquency summary',
  props<{ propertyId: number; delinquencyFilterType: string }>()
);
export const loadDelinquencySummarySucceded = createAction(
  '[Dashboard] Load delinquency succeded',
  props<{ delinquencySummary: DelinquencySummary }>()
);
export const loadDelinquencySummaryFailed = createAction('[Dashboard] Load delinquency failed');
export const clearDelinquencySummary = createAction('[Dashboard] clear delinquency summary');

export const loadRenewalsSummary = createAction('[Dashboard] Load renewals summary', props<{ propertyId: number }>());
export const loadRenewalsSummarySucceded = createAction(
  '[Dashboard] Load renewals summary succeded',
  props<{ renewalsSummary: RenewalsSummary }>()
);
export const loadRenewalsSummaryFailed = createAction('[Dashboard] Load renewals summary failed');
export const clearRenewalsSummary = createAction('[Dashboard] clear renewals summary');

export const loadUnitDetails = createAction(
  '[Dashboard] Load unit details',
  props<{ propertyId: number; dashboardViewType: DashboardViewType }>()
);
export const loadUnitDetailsSuccess = createAction(
  '[Dashboard] Unit details loaded successfully',
  props<{ unitDetails: UnitDetails[] }>()
);
export const loadUnitDetailsFailed = createAction('[Dashboard] Unit details loading failed');

export const loadUnitDetailsByType = createAction(
  '[Dashboard] Load unit details by type',
  props<{ propertyId: number }>()
);
export const loadUnitDetailsByTypeSuccess = createAction(
  '[Dashboard] Unit details by type loaded successfully',
  props<{ unitDetailsByType: UnitDetailsByType[] }>()
);
export const loadUnitDetailsByTypeFailed = createAction('[Dashboard] Unit details by type loading failed');

export const loadRenewalsDetails = createAction('[Dashboard] Load renewals details', props<{ propertyId: number }>());
export const loadRenewalsDetailsSuccess = createAction(
  '[Dashboard] Renewals details loaded successfully',
  props<{ renewalDetailsCombined: RenewalDetailsCombined }>()
);
export const loadRenewalsDetailsFailed = createAction('[Dashboard] renewals details loading failed');

export const selectedDashboardViewType = createAction(
  '[Dashboard] Selected dashboard type',
  props<{ dashboardViewType: EnumerationItem }>()
);

export const loadMyProperties = createAction('[Dashboard] Load my properties', props<{ portfolioId: number }>());
export const loadMyPropertiesSuccess = createAction(
  '[Dashboard] My properties loaded successfully',
  props<{ myProperties: MyProperties[] }>()
);
export const loadMyPropertiesFailed = createAction('[Dashboard] My properties loading failed');

export const selectedDashboardMode = createAction(
  '[Dashboard] Selected dashboard mode',
  props<{ dashboardMode: DashboardMode }>()
);

export const setCustomBreadcrumbs = createAction(
  '[Dashboard] Set custom breadcrumbs',
  props<{ breadcrumbList: BreadcrumbItem[] }>()
);

/**
 * load Units With Active Turnovers
 */
export const loadUnitsWithActiveTurnovers = createAction(
  '[Dashboard] load Units With Active Turnovers',
  props<{ propertyId: number }>()
);
export const loadUnitsWithActiveTurnoversSuccess = createAction(
  '[Dashboard] load Units With Active Turnovers success',
  props<{ unitList: RestUnitSummaryModel[] }>()
);
export const loadUnitsWithActiveTurnoversFailed = createAction('[Dashboard] load Units With Active Turnovers failed');
export const resetUnitsWithActiveTurnoversList = createAction('[Dashboard] reset Units With Active Turnovers list');
/**
 * load Units List
 */
export const loadUnitList = createAction('[Dashboard] load Units List', props<{ propertyId: number }>());
export const loadUnitListSuccess = createAction(
  '[Dashboard] load Units List success',
  props<{ unitList: RestUnitSummaryModel[] }>()
);
export const loadUnitListFailed = createAction('[Dashboard] load Units List failed');
export const resetUnitList = createAction('[Dashboard] reset Unit List');

/**
 * load Unit
 */
export const loadUnit = createAction('[Dashboard] load Unit', props<{ unitId: number }>());
export const loadUnitSuccess = createAction('[Dashboard] load Unit success', props<{ unit: RestUnitModel }>());
export const loadUnitFailed = createAction('[Dashboard] load Unit failed');
export const clearUnit = createAction('[Dashboard] load clear unit data');

/**
 * update unit
 */
export const updateUnit = createAction('[Dashboard] update unit', props<{ unit: Partial<RestUnitModel> }>());
export const updateUnitSuccess = createAction(
  '[Dashboard] update unit success',
  props<{ updatedUnit: RestUnitModel }>()
);
export const updateUnitFailed = createAction('[Dashboard] update unit failed');

export const updateUnitDoNotAutoCreateTurns = createAction(
  '[Dashboard] update unit DoNotAutoCreateTurns',
  props<{ unitId: number; doNotAutoCreateTurns: boolean }>()
);
export const updateUnitDoNotAutoCreateTurnsSuccess = createAction(
  '[Dashboard] update unit DoNotAutoCreateTurns success',
  props<{ doNotAutoCreateTurns: boolean }>()
);
export const updateUnitDoNotAutoCreateTurnsFailed = createAction('[Dashboard] update unit DoNotAutoCreateTurns failed');

export const updateUnitDownStatus = createAction(
  '[Dashboard] Update Down Unit Status',
  props<{
    unitId: number;
    isUnitDown?: boolean;
    isUnitDownReason?: string | null;
    isUnitOnHold?: boolean;
    isUnitOnHoldReason?: string | null;
  }>()
);
export const updateUnitDownStatusSuccess = createAction(
  '[Dashboard] Update Down Unit Status Success',
  props<{ updatedUnit: RestUnitModel }>()
);
export const updateUnitDownStatusFailed = createAction('[Dashboard] Update Down Unit Status Failed');

/**
 *  get renewal Strategy For Unit
 */
export const getRenewalStrategyForUnit = createAction(
  '[Dashboard] get renewal Strategy For Unit',
  props<{ unitId: number }>()
);
export const getRenewalStrategyForUnitSuccess = createAction(
  '[Dashboard] get renewal Strategy For Unit success',
  props<{ unitTypeRentTarget: RestUnitRenewalTargetModel }>()
);
export const getRenewalStrategyForUnitFailed = createAction('[Dashboard] get renewal Strategy For Unit failed');

/**
 * current property
 */
export const setCurrentProperty = createAction('[Dashboard] set current portfolio', props<{ propertyId: number }>());

export const setVacancyLossAttrType = createAction(
  '[Dashboard] setVacancyLossAttrType',
  props<{ attrType: VacancyLossAttrType }>()
);

export const setVacancyLossThreshold = createAction(
  '[Dashboard] setVacancyLossThreshold',
  props<{ thresholdType: VacancyLostThreshold }>()
);

export const setSelectedProperties = createAction(
  '[Dashboard] Set Selected Properties',
  props<{ selectedProperties: number[] }>()
);
