<div class="rating-group items-{{ ratingGroup.items.length }}">
  <app-template-editor-rating-group-item
    *ngFor="let item of ratingGroup.items; trackBy: trackBy"
    [ratingGroupItem]="item"
    [showResultScore]="greatGrandParent.scoringModel === 1"
    [parentTitle]="this.parent.title"
    [showEmoji]="showEmoji"
    (ratingGroupItemChange)="onRatingItemChange($event)"
    (ratingGroupItemDelete)="onRatingItemDeleted(item.item.id)"
    (makeDefault)="makeDefault(item)"></app-template-editor-rating-group-item>
  <button
    aria-label="add-button"
    type="button"
    *ngIf="ratingGroup.items.length < 6"
    class="add-rating"
    [matTooltip]="'Add rating'"
    matTooltipShowDelay="500"
    (click)="add()"
    mat-icon-button>
    <app-icon
      [iconWidth]="20"
      [iconHeight]="20"
      [iconColor]="EColorPalette.cGray7"
      class="add-button__icon"
      [svg]="EIcon.PLUS"></app-icon>
  </button>
</div>
