import { Injectable } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';

import { TenantService } from '@app/services/tenant.service';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { PackageType } from '@shared/enums/package-type';
import { checkIfPackageAvailable, checkTrialDaysLeft } from '@shared/functions/checkPackages.function';
import { TenantData } from '@shared/interfaces/tenant-data';

export type PackageTrialState = {
  packageType: PackageType;
  trialState: number | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private tenantService: TenantService, private timezoneService: TimezoneService) {}

  checkPackages(tenantData: TenantData, packages: PackageType[]): boolean {
    if (!tenantData) {
      return false;
    }

    if (!packages?.length) {
      return true;
    }

    return packages.some(p => checkIfPackageAvailable(tenantData, p, this.timezoneService.getCurrentDateOnly()));
  }

  checkPackageAvailable(packageType: PackageType): Observable<boolean> {
    return this.tenantService.getCachedTenant().pipe(map(tenantData => this.checkPackages(tenantData, [packageType])));
  }

  checkPackagesAvailable(packages: PackageType[]): Observable<boolean> {
    return this.tenantService.getCachedTenant().pipe(map(tenantData => this.checkPackages(tenantData, packages)));
  }

  checkPackageTrialDaysLeft(packageType: PackageType): Observable<PackageTrialState> {
    return this.tenantService.getCachedTenant().pipe(
      take(1),
      map(tenantData => ({
        packageType,
        trialState: checkTrialDaysLeft(tenantData, packageType, this.timezoneService.getCurrentDateOnly()),
      }))
    );
  }

  checkPackagesTrialDaysLeft(packages: PackageType[]): Observable<PackageTrialState[]> {
    if (!packages?.length) {
      return of([]);
    }

    return this.tenantService.getCachedTenant().pipe(
      take(1),
      map(tenantData => {
        return packages.map(packageType => ({
          packageType,
          trialState: checkTrialDaysLeft(tenantData, packageType, this.timezoneService.getCurrentDateOnly()),
        }));
      })
    );
  }
}
