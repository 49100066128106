import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

import { SortItem } from '@shared/interfaces/sort-item';

@Directive({
  selector: '[appSortHeader]',
})
export class SortHeaderDirective {
  currentDirection: SortDirection = '';
  ariaDirection = 'none';

  @Input() fieldName = '';
  @Output() sortEvent: EventEmitter<SortItem> = new EventEmitter<SortItem>();

  @HostListener('click', ['$event']) onClick() {
    this.changeDirection();
    this.sortAction();
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.elementRef.nativeElement, 'app-sort-header');
    this.renderer.setAttribute(this.elementRef.nativeElement, 'aria-sort', this.ariaDirection);
  }

  private changeDirection() {
    switch (this.currentDirection) {
      case '':
        {
          this.currentDirection = 'asc';
          this.ariaDirection = 'ascending';
        }
        break;
      case 'asc':
        {
          this.currentDirection = 'desc';
          this.ariaDirection = 'descending';
        }
        break;
      case 'desc':
        {
          this.currentDirection = '';
          this.ariaDirection = 'none';
        }
        break;
    }
    this.renderer.setAttribute(this.elementRef.nativeElement, 'aria-sort', this.ariaDirection);
  }

  private sortAction() {
    if (this.fieldName) {
      this.sortEvent.emit({
        field: this.fieldName,
        direction: this.currentDirection,
      });
    }
  }
}
