import { Pipe, PipeTransform } from '@angular/core';

import { replaceCommaToDotFunction } from '@shared/functions/replace-comma-to-dot.function';

@Pipe({
  name: 'parseFloat',
})
export class ParseFloatPipe implements PipeTransform {
  transform(value: any): number {
    if (!isNaN(value)) {
      return value;
    }

    if (!value) {
      return null;
    }
    return parseFloat(replaceCommaToDotFunction(value));
  }
}
