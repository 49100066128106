import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Viewer from 'viewerjs';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { deleteUnitAttachment } from '@portfolio/store/portfolio.actions';
import { selectDeleteUnitAttachmentInProgress } from '@portfolio/store/portfolio.selectors';
import { UnitAttachmentType } from '@shared/enums/attachment-type';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { GalleryItem } from '@shared/interfaces/gallery-item';

import Options = Viewer.Options;

@UntilDestroy()
@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent extends ComponentAbstract implements OnInit, AfterViewInit, OnDestroy {
  attachments: RestGenericTypedAttachment[] = [];
  title = '';
  content = '';

  deleteAttachmentInProgress$: Observable<boolean>;
  viewer: Viewer;
  viewerOptions: Options = {
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 4,
      play: {
        show: 4,
        size: 'large',
      },
      next: 4,
      rotateLeft: false,
      rotateRight: false,
      flipHorizontal: false,
      flipVertical: false,
    },
  };

  @ViewChild('imagesList', { read: ElementRef }) imagesList: ElementRef;

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<GalleryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GalleryItem<UnitAttachmentType, RestGenericTypedAttachment>,
    private store: Store<{}>
  ) {
    super(cdr);
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.deleteAttachmentInProgress$ = this.store.select(selectDeleteUnitAttachmentInProgress);

    this.data?.attachmentsBSubject
      .pipe(
        untilDestroyed(this),
        tap((attachments: RestGenericTypedAttachment[]) => {
          this.attachments = attachments;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.getDeleteConfirmationLabels();
  }

  ngAfterViewInit(): void {
    this.viewer = new Viewer(this.imagesList.nativeElement, this.viewerOptions);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }

  deleteItem(attachment: RestGenericTypedAttachment) {
    this.store.dispatch(deleteUnitAttachment({ unitId: this.data.unitId, attachment }));
  }

  private getDeleteConfirmationLabels() {
    switch (this.data.attachmentType) {
      case UnitAttachmentType.Photo:
        {
          this.title = `Removal of the unit photo`;
          this.content = 'Are you sure you want to remove the unit photo?';
        }
        break;

      case UnitAttachmentType.FloorPlan:
        {
          this.title = `Removal of the unit floor plan`;
          this.content = 'Are you sure you want to remove the unit floor plan?';
        }
        break;
    }
  }
}
