export function getStorageItem<T = string>(key: string): T | undefined {
  const storageValue = localStorage.getItem(key);
  if (storageValue) {
    try {
      const parsedValue = JSON.parse(storageValue);
      return parsedValue as T;
    } catch (err) {
      console.error(err);
    }
  }
}

export function setStorageItem<T>(key: string, value: T) {
  try {
    const storageValue = JSON.stringify(value);
    localStorage.setItem(key, storageValue);
  } catch (err) {
    console.error(err);
  }
}

export function parseJSON<T>(json: string) {
  return json != null ? (JSON.parse(json) as T) : undefined;
}
