import { ChangeDetectorRef, Directive, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlStatus,
  FormGroupDirective,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';

@Directive()
export abstract class CustomControlAbstract<T> extends ComponentAbstract implements ControlValueAccessor {
  control = new FormControl<T>(null);
  labelRequired: boolean;

  protected constructor(
    protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() private formDirective: FormGroupDirective
  ) {
    super(cdr);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get showValidationErrorOrSubmitted() {
    return this.formDirective?.submitted || this.showValidationError;
  }

  abstract writeValue(obj: any): void;

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected onChanged: (value: any) => void = () => {
    // to set later
  };
  onTouched: () => void = () => {
    // to set later
  };

  protected initControlBase() {
    if (this.ngControl?.control) {
      this.control = this.ngControl?.control as UntypedFormControl;

      if (typeof this.labelRequired === 'undefined') {
        this.labelRequired = this.control.hasValidator(Validators.required);
      }
    }

    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(status => this.checkControlStatus(status));

    this.listenControlTouched(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());

    if (this.ngControl?.control) {
      if (this.ngControl.control.errors) {
        this.errors = { ...this.ngControl.control.errors };
      }
      this.checkStatus();
    }
  }

  protected checkStatus() {
    if (this.ngControl?.control) {
      this.invalid = this.ngControl.control.invalid;

      if (this.control != this.ngControl.control) {
        //Remove this part than all controls will be done normal way
        if (this.ngControl.control.touched) {
          this.control.markAsTouched();
        } else {
          this.control.markAsUntouched();
        }
        if (this.ngControl.control.pristine) {
          this.control.markAsPristine();
        } else {
          this.control.markAsDirty();
        }
      }
    }
  }

  protected listenControlTouched(control: AbstractControl): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const originalMarkAsTouched = control.markAsTouched;
      const originalReset = control.reset;

      control.reset = (...args) => {
        observer.next(false);
        originalReset.call(control, ...args);
      };

      control.markAsTouched = (...args) => {
        observer.next(true);
        originalMarkAsTouched.call(control, ...args);
      };

      observer.next(control.touched);

      return () => {
        control.markAsTouched = originalMarkAsTouched;
        control.reset = originalReset;
      };
    });
  }

  protected checkControlStatus(status: FormControlStatus) {
    this.checkStatus();
    if (status === 'VALID' || status === 'DISABLED') {
      this.errors = null;
      this.cdr.markForCheck();
    } else if (status === 'INVALID') {
      this.errors = { ...this.ngControl?.control.errors };
      this.cdr.markForCheck();
    } else {
      this.errors = null;
    }
  }
}
