import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDashboards from '../reducers/dashboards.reducer';

export const selectDashboardsState = createFeatureSelector<fromDashboards.DashboardsState>(
  fromDashboards.dashboardsFeatureKey
);

export const selectPropertyUserTaskList = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.propertyUserTaskList
);
export const selectPropertyUserTaskListLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.propertyUserTaskListLoading
);

export const selectMyTasks = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.myTasks);
export const selectMyTasksLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.myTasksLoading
);
export const selectMyTasksTurnoversCount = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.myTasksTurnoversCount
);
export const selectStaleTurnoversCount = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.staleTurnoversCount
);
export const selectLateTurnoversCount = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.lateTurnoversCount
);

export const selectMyTasksTicketsCount = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.myTasksTicketsCount
);

export const selectUserTaskListLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.userTaskListLoading
);

export const selectKanbanListCount = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.kanbanListCount
);

export const selectPropertyKpiLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.propertyKpiLoading
);

export const selectPropertyKpi = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.propertyKpi);

export const selectUnitsSummaryByProperty = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitsSummaryByProperty
);
export const selectUnitsSummaryByPropertyLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitsSummaryByPropertyLoading
);

export const selectActiveTurnoversDetails = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.activeTurnoversDetails
);

export const selectDelinquencyByUnit = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.delinquencyByUnit
);
export const selectDelinquencyByUnitLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.delinquencyByUnitLoading
);

export const selectDelinquencySummary = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.delinquencySummary
);
export const selectDelinquencySummaryLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.delinquencySummaryLoading
);

export const selectRenewalsSummary = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.renewalsSummary
);
export const selectRenewalsSummaryLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.renewalsSummaryLoading
);

export const selectUnitDetails = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.unitDetails);
export const selectUnitDetailsLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitDetailsLoading
);

export const selectUnitDetailsByType = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitDetailsByType
);
export const selectUnitDetailsByTypeLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitDetailsByTypeLoading
);

export const selectCurrentDashboardViewType = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedDashboardViewType
);

export const selectCurrentDashboardViewTypeSimpleText = createSelector(
  selectCurrentDashboardViewType,
  selectedDashboardViewType => selectedDashboardViewType?.simpleText
);

export const selectCurrentDashboardViewTypeEnumerationValue = createSelector(
  selectCurrentDashboardViewType,
  selectedDashboardViewType => selectedDashboardViewType?.enumerationValue
);

export const selectMyProperties = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.myProperties
);

export const selectMyPropertiesLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.myPropertiesLoading
);

export const selectSelectedDashboardMode = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.dashboardMode
);

export const selectCustomBreadcrumbs = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.breadcrumbList
);
/**
 * select Units With Active Turnovers
 */

export const selectUnitListWithActiveTurnover = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitListWithActiveTurnover
);
export const selectUnitListWithActiveTurnoverLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitListWithActiveTurnoverLoading
);

/**
 * select Unit list
 */
export const selectUnitList = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.unitList);
export const selectUnitListLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitListLoading
);

/**
 * select unit
 */
export const selectUnit = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.unit);
export const selectUnitLoading = createSelector(selectDashboardsState, dashboardsState => dashboardsState?.unitLoading);

/**
 * select unit unitDoNotAutoCreateTurnsLoading
 */
export const selectUnitDoNotAutoCreateTurnsLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.unitDoNotAutoCreateTurnsLoading
);

/**
 * select renewal Strategy For Unit
 */
export const selectRenewalStrategyForUnit = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.renewalStrategyForUnit
);

export const selectRenewalStrategyForUnitLoading = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.renewalStrategyForUnitLoading
);

export const selectSelectedVacancyLossAttr = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedVacancyLossAttr
);

export const selectSelectedVacancyLossThreshold = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedVacancyLossThreshold
);

export const selectSelectedVacancyLossThresholdKpiType = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedVacancyLossThresholdKpiType
);

export const selectSelectedVacancyLossThresholdKpiTypePerTurn = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedVacancyLossThresholdKpiTypePerTurn
);

/**
 * select properties added from turnovers and boards
 */
export const selectSelectedProperties = createSelector(
  selectDashboardsState,
  dashboardsState => dashboardsState?.selectedProperties
);
