<div class="display-flex flex-column ticket-preview-modal-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div mat-dialog-title class="display-flex modal-header" cdkDragHandle *ngIf="!isLoading; else skeleton.skeletonTitle">
    <div class="w-100 display-flex align-items-center justify-content-space-between">
      <div class="w-100 display-flex align-items-center justify-content-space-between">
        <ng-container [ngTemplateOutlet]="ticketHeaderTemplate"></ng-container>
        <span *ngIf="ticketStatusLabel && this.data.ticketType !== 5" class="ticket-status text-color dark">
          {{ ticketStatusLabel }}
        </span>
      </div>
      <div class="flex-30" *ngIf="form">
        <app-radio-button-dropdown
          [attrDisable]="boardLayoutsId.disabled"
          [label]="'Board'"
          [isLabelInside]="true"
          [isSmallFont]="true"
          [dividerPosition]="0"
          [allowClear]="false"
          [allowSearch]="false"
          [autoSortList]="false"
          [showValidationError]="false"
          [optionCss]="'inspection-format-option'"
          [formControl]="boardLayoutsId"
          [items]="boardList$ | async"
          attrAppendTo="body"></app-radio-button-dropdown>
      </div>
    </div>
  </div>

  <mat-dialog-content appScrollToReqContainer>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="save()" appScrollToReq>
      <div class="display-flex justify-content-space-between overflow-auto">
        <ng-container *ngIf="!isLoading; else skeleton.skeletonContent">
          <div class="display-flex flex-column grid-gap-12 details-container">
            <div class="display-flex grid-gap-16">
              <app-user-select-dropdown
                class="flex-1"
                [containerCss]="'display-flex flex-column'"
                [allowClear]="false"
                [labelRequired]="false"
                [label]="'Assignee'"
                [labelCss]="'nowrap body-small-bold'"
                [formControl]="assigneeId"
                [users]="users"
                [attrPlaceholder]="'select assignee'"
                [listLoading]="propertyUserListLoading$ | async"></app-user-select-dropdown>
              <app-radio-button-dropdown
                [containerCss]="'w-162'"
                [autoSortList]="false"
                [items]="priorityTypeList"
                [label]="'Priority'"
                [containerWidthCss]="'w-123'"
                [formControl]="ticketPriorityType"
                [allowClear]="false"
                [allowSearch]="false"
                [attrPlaceholder]="'priority'"
                [labelRequired]="true"></app-radio-button-dropdown>
              <app-date-dropdown
                [formControl]="end"
                [buttonCss]="'date-dropdown-button-small w-162'"
                [containerCss]="'display-flex flex-column'"
                [label]="'Due'"
                [labelCss]="'body-small-bold nowrap'"
                [labelRequired]="true"
                [attrPlaceholder]="'select date'"></app-date-dropdown>
            </div>
            <app-input
              *ngIf="boardLayoutsId?.value === customBoardOption.value"
              class="w-100"
              [labelCss]="'body-small-bold nowrap'"
              [attrLabel]="'Title'"
              [contentCss]="'display-flex flex-column fill body-small'"
              [formControl]="name"
              [attrPlaceholder]="data?.isNew ? 'A mind-blowing ticket title...' : 'A next-level ticket title...'"
              [labelRequired]="true"
              [showValidationError]="formSubmitted"
              [displayCounter]="true"
              [maxCounter]="255"></app-input>

            <ng-container *ngIf="boardColumns$ | async as boardColumns">
              <app-radio-button-dropdown
                *ngIf="boardLayoutsId?.value !== customBoardOption.value"
                [autoSortList]="false"
                class="flex-1 board-columns"
                [items]="boardColumns"
                [allowSearch]="boardColumns?.length > 7"
                [dividerPosition]="boardColumns?.length - 3"
                [label]="'Custom Costs & Schedules'"
                [containerWidthCss]="'w-123'"
                [formControl]="boardColumnUId"
                [allowClear]="false"
                [attrPlaceholder]="'Select Cost or Schedule'"
                [labelRequired]="true"
                [listLoading]="newColumnLoading$ | async"></app-radio-button-dropdown>
            </ng-container>

            <app-input-area
              class="w-100"
              [label]="'Description'"
              [inputCss]="'hp-92'"
              [labelCss]="'body-small-bold nowrap'"
              [containerCss]="'fill body-small'"
              [formControl]="detailedInformation"
              [attrPlaceholder]="'A next-level ticket title...'"
              [showValidationError]="formSubmitted"
              [displayCounter]="true"
              [maxCounter]="1000"></app-input-area>
            <ng-container *ngIf="isSyncWithPmsEnabled(pmsType) | async">
              <div class="width-130">
                <mat-checkbox
                  color="primary"
                  matTooltip="Can't be changed after saving"
                  matTooltipPosition="right"
                  matTooltipShowDelay="1000"
                  [disabled]="isPushToPMSDisabled"
                  formControlName="pushToPMS">
                  Sync with {{ this.pmsType | pmsTypeEnumeration | async }}
                </mat-checkbox>
              </div>
              <ng-container *ngIf="form.get('pushToPMS').value">
                <div class="display-flex grid-gap-16">
                  <app-radio-button-dropdown
                    class="flex-1"
                    [containerCss]="'w-300'"
                    [autoSortList]="false"
                    [items]="categories"
                    [containerWidthCss]="'w-123'"
                    [formControl]="ticketCategoryId"
                    [allowClear]="false"
                    [allowSearch]="categories?.length > 7"
                    [attrPlaceholder]="'Topic Category'"
                    [labelRequired]="true"
                    [showValidationError]="formSubmitted"></app-radio-button-dropdown>
                  <app-radio-button-dropdown
                    class="flex-1"
                    [containerCss]="'w-300'"
                    [autoSortList]="false"
                    [items]="topics"
                    [containerWidthCss]="'w-123'"
                    [formControl]="ticketTopicId"
                    [allowClear]="false"
                    [allowSearch]="topics?.length > 7"
                    [attrPlaceholder]="'Topic'"
                    [labelRequired]="true"
                    [attrDisable]="!topics.length"
                    [showValidationError]="formSubmitted"></app-radio-button-dropdown>
                </div>
              </ng-container>
            </ng-container>
            <app-attachment
              [cleanInputAttachment]="cleanInputAttachment"
              (attachmentItemEvent)="addTicketAttachment($event)"></app-attachment>
            <div class="display-flex flex-column grid-gap-4 attachment-list-container" #attachments>
              <app-attachments-list
                [attachments]="attachmentItems"
                [showEmpty]="!!newAttachmentItem"
                (removeAttachment)="deleteTicketAttachment($event)"></app-attachments-list>
            </div>
            <div class="display-flex grid-gap-16" *ngIf="this.data?.ticketType === 3 && !!ticket?.sparePartId">
              <app-radio-button-dropdown
                class="flex-1"
                [autoSortList]="false"
                [items]="spareItems$ | async"
                [formControl]="sparePartId"
                [displayLabelAsValue]="true"
                [allowClear]="false"
                [allowSearch]="(spareItems$ | async)?.length > 7"
                [attrDisable]="!!ticket?.sparePartId && !!ticket?.id ? true : false"
                [attrPlaceholder]="'select replacement'"
                [label]="'Replacement part'"
                attrAppendTo="body"></app-radio-button-dropdown>
              <div class="display-flex flex-1 grid-gap-16">
                <app-input
                  class="flex-30"
                  [attrLabel]="'Quantity'"
                  [formControl]="quantity"
                  [attrDisable]="!!ticket?.sparePartId && !!ticket?.id ? true : false"
                  [showValidationError]="formSubmitted"></app-input>
                <app-radio-button-dropdown
                  class="flex-70"
                  [autoSortList]="false"
                  [items]="responsiblePartyType"
                  [formControl]="responsibleParty"
                  [displayLabelAsValue]="true"
                  [allowClear]="false"
                  [allowSearch]="false"
                  [attrPlaceholder]="'select responsible'"
                  [label]="'Responsibility'"
                  attrAppendTo="body"></app-radio-button-dropdown>
              </div>
            </div>
            <div class="display-flex grid-gap-16" *ngIf="this.data?.ticketType !== 5">
              <app-radio-button-dropdown
                class="flex-1"
                [autoSortList]="false"
                [items]="entryOptions"
                [formControl]="permissionToEnter"
                [displayLabelAsValue]="true"
                [allowClear]="false"
                [allowSearch]="false"
                [attrPlaceholder]="'Unknown'"
                [label]="'Entry'"></app-radio-button-dropdown>
              <app-radio-button-dropdown
                class="flex-1"
                [autoSortList]="false"
                [items]="petsOptions"
                [formControl]="havePets"
                [displayLabelAsValue]="true"
                [allowClear]="false"
                [allowSearch]="false"
                [attrPlaceholder]="'Unknown'"
                [label]="'Pets'"></app-radio-button-dropdown>
            </div>
            <app-input-area
              *ngIf="this.data?.ticketType !== 5"
              class="w-100"
              [label]="'Access notes'"
              [formControl]="accessNotes"
              [inputCss]="'hp-92'"
              [labelCss]="'body-small-bold nowrap'"
              [containerCss]="'fill body-small'"
              [displayCounter]="true"
              [maxCounter]="1000"></app-input-area>
            <app-input-area
              *ngIf="this.data.ticketType !== 5"
              class="w-100"
              [label]="'Problem description'"
              [formControl]="problemDescription"
              [inputCss]="'hp-92'"
              [labelCss]="'body-small-bold nowrap'"
              [containerCss]="'fill body-small'"
              [displayCounter]="true"
              [maxCounter]="1000"></app-input-area>
            <app-input-area
              class="w-100"
              [label]="'Technician notes'"
              [formControl]="technicalNotes"
              [inputCss]="'hp-92'"
              [labelCss]="'body-small-bold nowrap'"
              [containerCss]="'fill body-small'"
              [displayCounter]="true"
              [maxCounter]="1000"></app-input-area>
            <div class="display-flex grid-gap-16">
              <app-input-time-manual
                class="flex-1"
                [formControl]="timeSpentLoggedManuallyInput"
                [label]="'Time Spent'"></app-input-time-manual>
              <div class="flex-1 display-flex align-items-end grid-gap-1">
                <app-input
                  class="w-100 total-cost-container"
                  [matFormCss]="'border-radius-partial-left'"
                  [attrLabel]="'Total Cost'"
                  [formControl]="totalCostFormatted"
                  [useCurrencyFormatter]="true"
                  [showValidationError]="formSubmitted"></app-input>
                <div class="flex-1 display-flex align-items-center grid-gap-8">
                  <app-radio-button-dropdown
                    class="total-cost-status-container"
                    [ngSelectCss]="'border-radius-partial-right'"
                    [autoSortList]="false"
                    [items]="costStatus"
                    [formControl]="totalCostStatus"
                    [displayLabelAsValue]="true"
                    [allowClear]="false"
                    [allowSearch]="false"></app-radio-button-dropdown>
                  <div class="display-flex align-items-center">
                    <mat-checkbox color="primary" formControlName="paymentStatusBool">Paid</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <app-input-area
              *ngIf="this.data.ticketType !== 5"
              class="w-100"
              [label]="'Resolution comment'"
              [formControl]="resolutionComment"
              [inputCss]="'hp-92'"
              [labelCss]="'body-small-bold nowrap'"
              [containerCss]="'fill body-small'"
              [displayCounter]="true"
              [maxCounter]="1000"></app-input-area>
          </div>
        </ng-container>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-space-between" *ngIf="!isLoading; else skeleton.skeletonActions">
    <button
      type="button"
      mat-stroked-button
      color="warn"
      class="modal-button modal-button-cancel-width"
      (click)="cancel()">
      Cancel
    </button>
    <div class="display-flex grid-gap-8">
      <ng-container *ngIf="!data?.isNew && this.data.ticketType !== 5">
        <ng-container *ngIf="ticketStatusOptions$ | async as ticketStatusOptions">
          <app-button-menu
            class="modal-button"
            size="sm"
            [isInProgress]="updateInProgress$ | async"
            [options]="ticketStatusOptions"
            [selectedOption]="selectedStatus"
            [isRaisedButton]="selectedStatus?.id === ticketStatusTypeValues.Resolved"
            [mainButtonAction]="selectedStatus?.id"
            [mainButtonDisabled]="updateInProgress$ | async"
            [arrowDropdownDisabled]="updateInProgress$ | async"
            [submitOnMainButtonClick]="true"
            [title]="selectedStatus?.label"
            [isLowercase]="true"
            [titlePrefix]="'Mark as'"
            (submitAction)="onSave($event)"
            (action)="markAs($event)"></app-button-menu>
        </ng-container>
      </ng-container>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="modal-button modal-button-save-width"
        [class.save]="!data?.isNew"
        (click)="onSubmit()"
        [disabled]="(updateInProgress$ | async) || !ableToResolve?.value">
        <div class="display-flex justify-content-center align-items-center grid-gap-4">
          {{ data.isNew ? 'Create' : 'Save changes' }}
          <mat-spinner *ngIf="updateInProgress$ | async" color="accent" diameter="20"></mat-spinner>
        </div>
      </button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #ticketHeaderTemplate>
  <div
    class="subtitle"
    [ngStyle]="{ 'width.px': 420 - (this.data.ticketType !== 5 ? ticketStatusLabel?.length * 6 : 6) }"
    [ngClass]="{
      'fade-truncate': this.getHeader().length > 45 - (this.data.ticketType !== 5 ? ticketStatusLabel?.length : 1)
    }"
    [matTooltip]="
      this.getHeader().length > 45 - (this.data.ticketType !== 5 ? ticketStatusLabel?.length : 1)
        ? this.getHeader()
        : ''
    ">
    {{ this.getHeader() }}
  </div>
</ng-template>

<app-skeleton-modals #skeleton></app-skeleton-modals>
