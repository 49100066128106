import {
  Directive,
  Input,
  IterableDiffer,
  IterableDiffers,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngForTrackByKey]', standalone: true })
export class NgForTrackByKeyDirective<T> {
  private items: T[] = [];
  private differ: IterableDiffer<T> | undefined;
  private trackByKey: keyof T | 'index' | null = null;

  private templateRef = inject(TemplateRef<any>);
  private viewContainer = inject(ViewContainerRef);
  private iterableDiffers = inject(IterableDiffers);

  @Input()
  set ngForTrackByKey(key: keyof T) {
    this.trackByKey = key;
    if (this.items) {
      this.items = [...this.items]; // Trigger re-render
    }
  }

  @Input()
  set ngForOf(items: T[]) {
    this.items = items;
    if (this.differ) {
      this.differ = this.iterableDiffers.find(items).create(this.trackByFn);
    }
    this.updateView();
  }

  private trackByFn = (index: number, item: T) => {
    if (this.trackByKey === 'index') {
      return index;
    }
    return this.trackByKey ? item[this.trackByKey] : item;
  };

  private updateView() {
    if (this.differ) {
      const changes = this.differ.diff(this.items);
      if (changes) {
        changes.forEachAddedItem(record => {
          this.viewContainer.createEmbeddedView(this.templateRef, { $implicit: record.item });
        });

        changes.forEachRemovedItem(record => {
          const view = this.viewContainer.get(record.currentIndex!);
          if (view) {
            this.viewContainer.remove(this.viewContainer.indexOf(view));
          }
        });
      }
    }
  }
}
