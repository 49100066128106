import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { getStorageItem, setStorageItem } from '../utils/extensions';

/**
 * The service helps us to share and cache data between components and reduce HTTP calls
 * Use it when: you have to do multiple low-level requests for the same urls
 *
 * !! We have to unsubscribe manually to avoid memory leaks;
 * !! Example: assignee-cell.component.ts | inspections-add-dynamic
 * !! Do not use it to cache common single requests.
 */

interface ICacheEntity {
  state: BehaviorSubject<any>;
  associateKey?: string;
  localStorageKey?: string;
}

interface ICacheEntityOptions {
  associateKey?: string;
  lsKey?: string;
}

interface ICacheEntityLocalStorage extends Omit<ICacheEntityOptions, 'lsKey'> {
  state: any;
}

interface ICacheGetEntityOptions<T> {
  initialState?: T;
  lsKey?: string;
}

const localStoragePrefix = '[lcs]__';

@Injectable({ providedIn: 'root' })
export class LocalCacheService {
  private _cache: Map<string, ICacheEntity> = new Map();

  get$<T>(key: string, unsubscriber$: Subject<void>, options?: ICacheGetEntityOptions<T>): Observable<T> | null {
    const entry = this._cache.get(key);

    if (!entry && options?.lsKey) {
      const item = getStorageItem<ICacheEntityLocalStorage>(options.lsKey) ?? null;
      if (item) {
        this.set(key, item.state, { associateKey: item.associateKey });
        return this._cache.get(key).state.asObservable();
      }
    }

    if (!entry && options?.initialState !== undefined) {
      this.set(key, options.initialState);
      return this._cache.get(key).state.asObservable();
    }
    return entry ? entry.state.asObservable().pipe(takeUntil(unsubscriber$)) : null;
  }

  set(key: string, value: any, options?: ICacheEntityOptions): void {
    const entry = this._cache.get(key);

    if (entry) {
      entry.state.next(value);
      options?.lsKey && setStorageItem(localStoragePrefix + options.lsKey, mapCacheToLocalStorage(entry));
    } else {
      const newEntry: ICacheEntity = { state: new BehaviorSubject(value), ...options };
      this._cache.set(key, newEntry);
      options?.lsKey && setStorageItem(localStoragePrefix + options.lsKey, mapCacheToLocalStorage(newEntry));
    }
  }

  remove(key: string): void {
    if (this._cache.has(key)) {
      this._cache.get(key)?.state.complete();
      this._cache.delete(key);
    }
  }

  removeByAssociateKey(associateKey: string): void {
    this._cache.forEach((entity, key) => {
      if (entity.associateKey === associateKey) {
        this.remove(key);
      }
    });
  }

  clear(): void {
    this._cache.forEach((value, key) => {
      value.state.complete();
    });
    this._cache.clear();
  }

  get<T>(key: string): T {
    const entry = this._cache.get(key);
    return entry ? entry.state.getValue() : null;
  }
}

// create local key => const localPropertyKey = localCachePropertyUsersId + propertyId;
// get cache => const cachedPropertyUserList$ = this.localCacheService.get<RestUserModel[]>(localPropertyKey, this.destroy$);
// use it => cachedPropertyUserList$ ?? this.userService.getPropertyUserList(propertyId)
// set key => tap(v => this.localCacheService.set(localPropertyKey, v))

function mapCacheToLocalStorage(cache: ICacheEntity): ICacheEntityLocalStorage {
  return {
    state: cache?.state.getValue(),
    associateKey: cache?.associateKey,
  };
}
