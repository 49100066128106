<div
  class="button-menu__container"
  [class.button-menu__container-resident]="btnResident"
  [class]="['button-menu__container--' + size]">
  <ng-container *ngIf="!isDisabled; else strokeButtonTemplate">
    <ng-container *ngIf="isRaisedButton; else isStrokedButton">
      <button
        mat-raised-button
        color="primary"
        class="button-menu"
        [disabled]="mainButtonDisabled"
        [class.button-menu--with-actions]="!!options"
        [class.button-menu--disabled]="isDisabled"
        [ngStyle]="{ 'width.px': btnWidth }"
        (click)="onAction(mainButtonAction)">
        <div class="button-menu__label">
          <mat-spinner *ngIf="isInProgress" color="accent" diameter="20"></mat-spinner>
          <ng-container *ngIf="titlePrefix">{{ titlePrefix + '' }}</ng-container>
          {{ isLowercase ? (title | lowercase) : title }}
        </div>
      </button>
    </ng-container>
    <ng-template #isStrokedButton>
      <button
        mat-stroked-button
        color="primary"
        class="button-menu"
        [disabled]="mainButtonDisabled"
        [class.button-menu--with-actions]="!!options"
        [class.button-menu--disabled]="isDisabled"
        [class.reports]="btnReports"
        [class.resident-button]="btnResident"
        [ngClass]="btnResident ? 'resident-button' : ''"
        [ngStyle]="{ 'width.px': btnWidth - 2 }"
        (click)="onAction(mainButtonAction)">
        <div class="button-menu__label">
          <mat-spinner *ngIf="isInProgress" color="accent" diameter="20"></mat-spinner>
          <ng-container *ngIf="titlePrefix">{{ titlePrefix + '' }}</ng-container>
          {{ isLowercase ? (title | lowercase) : title }}
        </div>
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!isDisabled; else strokeMenuButtonTemplate">
    <button
      *ngIf="options"
      class="button-menu__options drop-down-button"
      [class.drop-down-button--stroke]="!isRaisedButton"
      [class.drop-down-button--disabled]="mainButtonDisabled"
      [class.reports]="btnReports"
      [class.resident-button-options]="btnResident"
      [disabled]="arrowDropdownDisabled"
      [matMenuTriggerFor]="dropdownMenu"
      #menuTrigger="matMenuTrigger">
      <mat-icon *ngIf="!btnResident">arrow_drop_down</mat-icon>
      <app-icon
        *ngIf="btnResident"
        [iconColor]="EColorPalette.cWhite"
        [iconHeight]="7.5"
        [iconWidth]="12"
        [svg]="menuTrigger.menuOpen ? EIcon.ANGLE_UP : EIcon.ANGLE_DOWN"></app-icon>
    </button>
  </ng-container>
</div>

<mat-menu #dropdownMenu="matMenu" [xPosition]="xPosition" class="menu-with-backdrop">
  <button
    *ngFor="let option of optionsToDisplay"
    mat-menu-item
    [disabled]="option.disabled"
    (click)="onAction(option.id, true)">
    {{ option.label }}
  </button>
</mat-menu>

<ng-template #strokeButtonTemplate>
  <button
    mat-stroked-button
    color="primary"
    class="button-menu"
    [class.button-menu--with-actions]="!!options"
    [class.button-menu--disabled]="isDisabled"
    (click)="onAction(mainButtonAction)">
    <div class="button-menu__label">
      <mat-spinner *ngIf="isInProgress" color="accent" diameter="20"></mat-spinner>
      {{ title }}
    </div>
  </button>
</ng-template>

<ng-template #strokeMenuButtonTemplate>
  <button
    *ngIf="options"
    class="button-menu__options drop-down-button"
    [class.drop-down-button--stroke]="isDisabledStroke"
    mat-stroked-button
    [matMenuTriggerFor]="dropdownMenu">
    <mat-icon class="options-icon">arrow_drop_down</mat-icon>
  </button>
</ng-template>
