import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { ButtonType } from '@shared/enums/button-type';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends ComponentAbstract {
  @Input() buttonType = ButtonType.DEFAULT;
  @Input() buttonColor: ThemePalette = 'primary';
  @Input() buttonLabel = '';
  @Input() buttonLabelCss = 'letter-spacing';
  @Input() buttonCss = 'step-button';
  @Input() attrDisabled = false;
  @Input() isLoading = false;
  @Input() type: 'button' | 'reset' | 'submit';
  @Input() leftIcon: string;

  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  buttonClick() {
    this.clickEvent.emit();
  }
}
