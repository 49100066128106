import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestTurnoverBidCategory } from '@shared/interfaces/turnover-bids.interface';

export function getBidCategoriesRadioList(categories: RestTurnoverBidCategory[]): IRadioButtonOption<number>[] {
  return categories.map<IRadioButtonOption<number>>(item => {
    return {
      value: item.id,
      label: `${item.name}`,
    };
  });
}
