import { createAction, props } from '@ngrx/store';

import { RestTemplateAreaModel, RestTemplateModel } from '@template/models/rest-template-model.interface';

/* Templates List */
export const loadAllUserTemplates = createAction('[Template] Load all template');
export const loadAllUserTemplatesSuccess = createAction(
  '[Template] All template loaded successfully',
  props<{ listItems: RestTemplateModel[] }>()
);
export const loadAllUserTemplatesFailed = createAction('[Template] All template loading failed');

/* Add new template */
export const createTemplateDetails = createAction(
  '[Template] Add new template',
  props<{ entityDetails: RestTemplateModel }>()
);
export const updateTemplateDetails = createAction(
  '[Template] Update new template',
  props<{ entityDetails: RestTemplateModel }>()
);
export const updateTemplateDetailsFailed = createAction('[Template] Update template failed');

/* Delete template */
export const deleteTemplate = createAction('[Template] Remove template', props<{ id: number }>());
export const clearTemplateData = createAction('[Template] Clear template data');

/* Delete template */
export const removeAreaFromTemplate = createAction('[Template] Remove area from template', props<{ areaId: number }>());
/* Load unit types */
export const loadAllUnitTypes = createAction(
  '[Template] Load unit types of exact property',
  props<{ propertyId: number }>()
);
export const loadAllUnitTypesSuccess = createAction(
  '[Template] All unit types loaded successfully',
  props<{ unitTypes: string[] }>()
);
export const loadAllUnitTypesFailed = createAction('[Template] All unit types loading failed');

/* Inspection survey template */
export const addInspectionSurveyTemplate = createAction(
  '[Surveys] addInspectionSurveyTemplate',
  props<{ survey: RestTemplateAreaModel }>()
);

export const updateInspectionSurveyTemplate = createAction(
  '[Surveys] updateInspectionSurveyTemplate',
  props<{ survey: RestTemplateAreaModel }>()
);

export const saveInspectionSurveyTemplateSuccess = createAction(
  '[Surveys] saveInspectionSurveyTemplateSuccess',
  props<{ surveyId: number }>()
);
