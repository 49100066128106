import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { RestTypedTurnoverAttachment } from '@shared/interfaces/turnover.interface';

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsListComponent {
  @Input() showEmpty = false;
  @Input() attachments: RestTypedTurnoverAttachment[];
  @Input() lockFields: boolean;
  @Output() removeAttachment = new EventEmitter<RestTypedTurnoverAttachment>();
}
