/*
 * DON'T USE
 * deprecated file, to be removed
 */
export enum TurnoverForm {
  START = 'Start',
  END = 'End',
  RANGE = 'Range',

  PROPERTY_ID = 'PropertyId',
  UNIT_ID = 'UnitId',
  GENERAL = 'General',

  COMPANY_ID_LIST = 'CompanyIdList',
  ID = 'Id',

  NAME = 'Name',
  DETAILED_INFORMATION = 'DetailedInformation',
  ASSIGNEE_ID = 'AssigneeId',

  TICKET_STATUS_TYPE = 'TicketStatusType',
  TICKET_PRIORITY_TYPE = 'TicketPriorityType',
  WHOLE_OBJECT = 'WholeObject',
  MATERIAL_COST = 'MaterialsCost',
  LABOR_COST = 'LaborCost',
  BID_STATUS_TYPE = 'BidStatusType',
  URL = 'Url',

  UNIT_PHOTO = 'UnitPhoto',
  ABLE_TO_RESOLVE = 'AbleToResolve',
  SCOPE_OF_WORK = 'ScopeOfWork',

  MOVE_OUT = 'MoveOutDate',

  BOARD_LAYOUTS_ID = 'boardLayoutsId',
  BOARD_COLUMN_UID = 'boardColumnUID',
}
