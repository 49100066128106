export function scrollToElement(id: string): void {
  if (!window || !document) {
    console.warn(`scrollToElement was called on the server. This operation is not supported in SSR.`);
    return;
  }

  const element = document.getElementById(id);
  if (!element) {
    console.warn(`There is no element on the page with id ${id}`);
    return;
  }
  element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
}
