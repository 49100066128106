import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { TemplateEditorBase } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-base/template-editor-base.component';
import { TemplateEditorDefaultValue } from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-header',
  templateUrl: './template-editor-header.component.html',
  styleUrls: ['./template-editor-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogHeaderComponent extends TemplateEditorBase {
  editMode = false;

  @Input() isEditable: boolean;
  @Input() header: string;
  @ViewChild('headerName', { read: ElementRef, static: false }) headerName: ElementRef<HTMLDivElement>;
  @Output() headerChanged = new EventEmitter<string>();

  constructor(protected store: Store<{}>, protected cdr: ChangeDetectorRef, snackbarService: SnackbarService) {
    super(store, snackbarService);
  }

  enterEditMode() {
    if (!this.checkEditRight() || this.editMode) {
      return;
    }
    this.editMode = true;
    this.cdr.detectChanges();
    const div = this.headerName.nativeElement;
    setTimeout(() => {
      const range = document.createRange();
      range.selectNodeContents(div);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  nameChanged($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.headerName.nativeElement.blur();
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  leaveEditMode(value: string) {
    value = value.trim();
    this.editMode = false;
    if (this.header !== value) {
      if (!value) {
        value = TemplateEditorDefaultValue;
        this.headerName.nativeElement.textContent = TemplateEditorDefaultValue;
      }
      this.headerChanged.emit(value);
    }

    setTimeout(() => {
      const div = this.headerName.nativeElement;
      div.focus();
      div.blur();
    }, 1);
  }

  checkEditRight(notify = true): boolean {
    return super.checkEditRight(this.isEditable, notify);
  }
}
