import { Pipe, PipeTransform } from '@angular/core';

import { ThresholdType } from '@shared/enums/threshold-type';

@Pipe({
  name: 'kpiHeader',
})
export class KpiHeaderPipe implements PipeTransform {
  transform(thresholdType: ThresholdType): string {
    switch (thresholdType) {
      case ThresholdType.GeneralOccupancy: {
        return 'Occupancy (%)';
      }
      case ThresholdType.PendingMoveouts: {
        return 'move outs';
      }
      case ThresholdType.PreleasedUnits: {
        return 'pre-leased';
      }
      case ThresholdType.AnticipatedOccupancy: {
        return 'anticipated (%)';
      }
      case ThresholdType.DelinquentUnit: {
        return 'delinquent';
      }
      case ThresholdType.PendingExpirations: {
        return 'expires';
      }
      case ThresholdType.MoveoutsConfirmations: {
        return 'confirms';
      }
      case ThresholdType.DelayedPayment: {
        return 'delayed payment';
      }
      case ThresholdType.Eviction: {
        return 'eviction';
      }
      case ThresholdType.PaymentPlan: {
        return 'payment plan';
      }
      case ThresholdType.PaidInFull: {
        return 'paid in full';
      }
      case ThresholdType.Asstance: {
        return 'receiving assistance';
      }
      case ThresholdType.MovedOut: {
        return 'moving out';
      }
      case ThresholdType.SmallBalance: {
        return 'small balance';
      }
      case ThresholdType.PendingCredit: {
        return 'pending credit';
      }
      case ThresholdType.Negotiation: {
        return 'negotiating (number of units)';
      }
      case ThresholdType.Holdover: {
        return 'on holdover';
      }
      case ThresholdType.MonthToMonth: {
        return 'month to month';
      }
      case ThresholdType.TenantNotice: {
        return 'tenant notice';
      }
      case ThresholdType.Renewal: {
        return 'renewing';
      }
      case ThresholdType.MgmtNotice: {
        return 'mgmt notice';
      }
      case ThresholdType.Transfer: {
        return 'unit transfer';
      }
      case ThresholdType.TurnoverRootNode: {
        return 'Turnover Root Node';
      }
      case ThresholdType.TurnoverSubNode: {
        return 'Turnover Sub Node';
      }
      default: {
        return '';
      }
    }
  }
}
