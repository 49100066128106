import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstLetterOfWords',
})
export class UppercaseFirstLetterOfWordsPipe implements PipeTransform {
  transform(text: string): string {
    return !!text
      ? text
          .toLowerCase()
          .split(' ')
          .map(word => word[0].toUpperCase() + word.substring(1))
          .join(' ')
      : '';
  }
}
