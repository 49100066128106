export enum PackageType {
  Turns = 1,
  Inspections = 2,
  Service = 3,
  InspectionsPro = 4,
  Residents = 5,
  CannedAnswers = 6,
  SpareParts = 7,
  ResidentPortalPackageEnabled = 8,
  ResidentInspectionEnabled = 9,
}
