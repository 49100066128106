import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

import { RestThresholdsConfiguration } from '@shared/interfaces/thresholds-configuration.interface';

@Pipe({
  name: 'thresholdConfiguration',
})
export class ThresholdConfigurationPipe implements PipeTransform {
  transform(value: number, configuration: RestThresholdsConfiguration): string {
    if (!isNumber(value) || !configuration) {
      return '';
    }

    const low =
      configuration.readyPhaseThreshold.lowThresholdValue + configuration.notReadyPhaseThreshold.lowThresholdValue;
    const high =
      configuration.readyPhaseThreshold.highThresholdValue + configuration.notReadyPhaseThreshold.highThresholdValue;

    if (value > high) {
      return 'threshold-high';
    }

    if (value < low) {
      return 'threshold-low';
    }

    return 'threshold-medium';
  }
}
