import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boardCurrency',
})
export class BoardCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: number | string | null | undefined,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = ''
  ): string | null {
    if (!value) return null;
    const valueFormat = +value % 1 === 0 ? '1.0-0' : '1.2-2';
    return this.currencyPipe.transform(value, 'USD', display, valueFormat);
  }
}
