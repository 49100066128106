import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DividerModule } from '@ui-components/components/divider/divider.module';
import { SelectTreeModule } from '@ui-components/components/select-tree/select-tree.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

import { AddVendorModalComponent } from './add-vendor-modal.component';

@NgModule({
  declarations: [AddVendorModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    InputModule,
    ReactiveFormsModule,
    RadioButtonDropdownModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    DividerModule,
    SelectTreeModule,
  ],
  exports: [AddVendorModalComponent],
})
export class AddVendorModalModule {}
