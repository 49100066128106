import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Component({
  selector: 'app-external-route',
  template: '',
  standalone: true,
})
export class ExternalRouteComponent {}

@Injectable()
export class ExternalRouteResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot): any {
    window.location.href = route.queryParamMap.get('targetUri');
    return true;
  }
}
