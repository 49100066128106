import { createAction, props } from '@ngrx/store';

import { EnumerationConfig } from '@shared/interfaces/enumeration-config';

export const loadEnumerations = createAction('[Dashboard] Load enumerations');
export const loadEnumerationsSuccess = createAction(
  '[Dashboard] Enumerations loaded successfully',
  props<{ enumerations: EnumerationConfig }>()
);
export const loadEnumerationsFailed = createAction('[Dashboard] Enumerations loading failed');
