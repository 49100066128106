import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';

import { TurnoverDateRangeComponent } from './turnover-date-range.component';

@NgModule({
  declarations: [TurnoverDateRangeComponent],
  imports: [CommonModule, InputErrorModule, DateDropdownModule, ReactiveFormsModule],
  exports: [TurnoverDateRangeComponent],
})
export class TurnoverDateRangeModule {}
