import { CompulsoryItems } from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { RestTicketModel } from '@shared/interfaces/turnover.interface';
import { parseJSON } from '@shared/utils/extensions';

import { InspectionStepModel } from './inspection-step.model';
import { InspectionItemStatus, RestInspectionContents } from './rest-inspections-model.interface';

export interface InspectionAnswer {
  title: string;
  pages: InspectionPage[];
}

export interface InspectionPage {
  id: number;
  name: string;
  title: string;
  mainImage?: InspectionImage[];
  isCompleted: boolean;
  elements?: InspectionElement[];
}

export interface InspectionImage {
  fileUploadId: number;
  filePath: string;
}

export interface InspectionElementItem {
  item: {
    id: string;
    text: string;
    emoji: string;
    backgroundColor: string;
    isDefault: boolean;
    showQtyWidget: boolean;
    onSelect: CompulsoryItems[];
  };
}

export interface InspectionElement {
  title: string;
  name: string;
  type: string;
  isRequired: boolean;
  items?: InspectionElementItem[];
  guid: string;
  value: string;
  choices: string[];
  visibleIf: null;
  requiredIf: null;
  elements: InspectionElement[];
}

export class InspectionAreaModel {
  steps: InspectionStepModel[];
  readonly stepsCount: number;
  readonly completedStepsCount: number;
  readonly isCompleted: boolean;
  readonly isSkipped: boolean;
  readonly title: string;
  readonly id: number;

  constructor(public readonly content: RestInspectionContents, public readonly tickets: RestTicketModel[]) {
    const answer = parseJSON<InspectionAnswer>(content.answerJSON);
    this.id = this.content.id;
    this.title =
      this.content.title || this.content.inspectionTemplateAreaTitle || this.content.sharedInspectionLibrarySpaceTitle;
    this.steps =
      answer?.pages?.map((page, idx) => new InspectionStepModel(page, tickets, this.title, this.id, idx)) ?? [];
    this.stepsCount = answer?.pages?.length ?? 1;
    this.completedStepsCount = this.steps?.filter(step => step.page.isCompleted).length ?? 0;

    this.isCompleted = content.status === InspectionItemStatus.Completed;
    this.isSkipped = content.status === InspectionItemStatus.Skipped;

    if (this.steps.length === 1 && this.steps[0].isCustomStep) {
      this.title = this.steps[0].page.title;
    }
  }
}
