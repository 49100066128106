import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, filter } from 'rxjs';

import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';

class CacheInfo {
  cachedSubject: ReplaySubject<any>;
  expired: boolean;
  id: number;
  timestamp: Date;
  version?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cacheInfoDictionary: { [key: string]: CacheInfo } = {};

  getCachedInfo<T>(
    key: string,
    id: number,
    f: (id: number) => Observable<T>,
    version?: number,
    forceUpdate?: boolean
  ): Observable<T> {
    if (!this.cacheInfoDictionary[key]) {
      this.cacheInfoDictionary[key] = {
        cachedSubject: new ReplaySubject<T>(1),
        expired: true,
        id: id,
        timestamp: new Date(),
        version: undefined,
      };
    }

    const cacheInfo = this.cacheInfoDictionary[key];

    if (
      cacheInfo.expired ||
      cacheInfo.id !== id ||
      cacheInfo.version !== version ||
      this.expiredFromLocalStarage(key, cacheInfo.timestamp) ||
      forceUpdate
    ) {
      if (cacheInfo.id !== id) {
        cacheInfo.cachedSubject.complete();
        cacheInfo.cachedSubject = new ReplaySubject<T>(1);
      } else {
        cacheInfo.cachedSubject.next(undefined);
      }

      cacheInfo.expired = false;
      cacheInfo.timestamp = new Date();
      cacheInfo.version = version;
      cacheInfo.id = id;
      f(id).subscribe({
        next: data => cacheInfo.cachedSubject.next(data),
        error: error => {
          console.error('Error: ', error);
          cacheInfo.cachedSubject.error(error);
          window.location.reload();
        },
      });
    }
    return cacheInfo.cachedSubject.pipe(filter(data => typeof data !== 'undefined'));
  }

  private expiredFromLocalStarage(key: string, timestamp: Date) {
    const storageTimestamp = localStorage.getItem(LocalStorageDataEnum.CACHE_KEYS.replace('{0}', key));
    return storageTimestamp && new Date(storageTimestamp) > timestamp;
  }

  expireCachedInfo(key: string): void {
    const cacheInfo = this.cacheInfoDictionary[key];
    if (cacheInfo) {
      cacheInfo.expired = true;
      localStorage.setItem(LocalStorageDataEnum.CACHE_KEYS.replace('{0}', key), new Date().toString());
    }
  }
}
