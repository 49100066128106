import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  /** Humanize PascalCase string into human readable string with spaces */
  transform(value: string) {
    return HumanizePipe.transform(value);
  }

  static transform(value: string) {
    if (typeof value !== 'string') {
      return value;
    }

    return (
      value[0].toUpperCase() +
      value
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .toLowerCase()
        .slice(1)
    );
  }
}
