import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { NumberInputModule } from '@ui-components/controls/number-input/number-input.module';

@Component({
  selector: 'app-custom-weight-modal',
  templateUrl: './custom-weight-modal.component.html',
  standalone: true,
  imports: [FormsModule, CommonModule, MatDialogModule, NumberInputModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomWeightModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CustomWeightModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customWeight: number }
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.data.customWeight);
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onSave();
    }
  }
}
