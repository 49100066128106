import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

import { parsePhoneNumber } from '@ui-components/validators/phone.validator';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  constructor(private mask: MaskPipe) {}

  transform(value: string): string {
    const { phoneNumber, phoneCode } = parsePhoneNumber(value);
    return `${phoneCode ? `+${phoneCode}` : ''} ${this.mask.transform(phoneNumber, '(000) 000-0000')}`;
  }
}
