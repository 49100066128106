import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCompanies from './companies.reducer';

export const selectCompaniesState = createFeatureSelector<fromCompanies.State>(fromCompanies.companiesFeatureKey);

/**
 * company List
 */
export const selectCompanyList = createSelector(selectCompaniesState, companiesState => companiesState.companyList);
export const selectCompanyListLoading = createSelector(
  selectCompaniesState,
  companiesState => companiesState.companyListLoading
);

/**
 * get company
 */
export const selectCompany = createSelector(selectCompaniesState, companiesState => companiesState.companyModel);
export const selectCompanyLoading = createSelector(
  selectCompaniesState,
  companiesState => companiesState.companyModelLoading
);
export const selectUpdateAddressInProgress = createSelector(
  selectCompaniesState,
  companiesState => companiesState.updateAddressInProgress
);

/**
 * update company
 */
export const selectCompanyUpdateLoading = createSelector(
  selectCompaniesState,
  companiesState => companiesState.companyUpdateLoading
);

export const selectContractors = createSelector(selectCompaniesState, companiesState => companiesState.contractors);

/**
 * get addCompanyWithOwner
 */
export const selectAddCompanyWithOwnerLoading = createSelector(
  selectCompaniesState,
  companiesState => companiesState.addCompanyWithOwnerInProgress
);
