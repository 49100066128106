import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';

import { DirectivesModule } from '@shared/directives/directives.module';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PlaceholderPipe } from '@shared/pipes/placeholder.pipe';

import { InputComponent } from './input.component';
import { IconComponent } from '../../components/icon/icon.component';
import { InputErrorModule } from '../../components/input-error/input-error.module';

@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    IconComponent,
    InputErrorModule,
    MatIconModule,
    TooltipDirectiveModule,
    PlaceholderPipe,
    DirectivesModule,
  ],
  exports: [InputComponent, ReactiveFormsModule],
})
export class InputModule {}
