import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { TemplateEditorBase } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-base/template-editor-base.component';
import { handleMaxLimitContentEditable } from '@main-application/shared/template-editor-dialog/models/share-methods.function';
import { TemplateMaxDescriptionLength } from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-description',
  templateUrl: './template-editor-description.component.html',
  styleUrls: ['./template-editor-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogDescriptionComponent extends TemplateEditorBase {
  editMode = false;
  initialized = false;
  private _description: string;
  @Input() set description(value: string) {
    if (!this.initialized) {
      this._description = value;
      this.initialized = true;
    }
  }

  get description() {
    return this._description;
  }

  @ViewChild('descriptionText', { read: ElementRef, static: false }) descriptionText: ElementRef<HTMLDivElement>;
  @Output() descriptionChanged = new EventEmitter<string>();
  @Input() isEditable: boolean;

  constructor(protected store: Store<{}>, protected cdr: ChangeDetectorRef, snackbarService: SnackbarService) {
    super(store, snackbarService);
  }

  enterEditMode() {
    if (!this.checkEditRight() || this.editMode) {
      return;
    }
    this.editMode = true;
    this.cdr.detectChanges();
    const div = this.descriptionText.nativeElement;
    setTimeout(() => {
      const range = document.createRange();
      range.selectNodeContents(div);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  nameChanged(event: KeyboardEvent) {
    if (!this.checkEditRight()) {
      return;
    }
    handleMaxLimitContentEditable(event, this.descriptionText.nativeElement, TemplateMaxDescriptionLength);
    if (event.key === 'Enter') {
      this.descriptionText.nativeElement.blur();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  leaveEditMode(value: string) {
    value = value.trim();
    this.editMode = false;
    if (this.description !== value) {
      this.descriptionChanged.emit(value);
    }

    setTimeout(() => {
      const div = this.descriptionText.nativeElement;
      div.focus();
      div.blur();
    }, 1);
  }

  checkEditRight(notify = true): boolean {
    return super.checkEditRight(this.isEditable, notify);
  }
}
