import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { videoExtensions } from '@shared/constants/file-extensions.const';

@Directive({
  standalone: true,
  selector: '[appIsVideo]',
})
export class IsVideoDirective implements OnInit {
  private show = false;

  @Input() set appIsVideo(value: string) {
    this.show = this.checkIfVideo(value);
    this.displayTemplate();
  }

  @Input() appIsVideoElse?: TemplateRef<unknown>;

  constructor(private templateRef: TemplateRef<any>, private vcr: ViewContainerRef) {}

  ngOnInit(): void {
    this.displayTemplate();
  }

  private displayTemplate(): void {
    this.vcr.clear();
    if (this.show) {
      this.vcr.createEmbeddedView(this.templateRef);
    } else if (this.appIsVideoElse) {
      this.vcr.createEmbeddedView(this.appIsVideoElse);
    }
  }

  private checkIfVideo(url: string): boolean {
    const extension = this.getFileExtension(url);
    return videoExtensions.includes(extension);
  }

  private getFileExtension(url: string): string {
    const match = url.match(/\.[^\.]+$/);
    return match ? match[0].toLowerCase() : '';
  }
}
