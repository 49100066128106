import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';

import { IconComponent } from '../icon/icon.component';

@UntilDestroy()
@Component({
  selector: 'app-collapsible-search-input',
  templateUrl: './collapsible-search-input.component.html',
  styleUrls: ['./collapsible-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, ReactiveFormsModule],
})
export class CollapsibleSearchInputComponent extends ComponentAbstract implements OnInit, ControlValueAccessor {
  control = new UntypedFormControl();

  @Input() attrPlaceholder = '';
  @Input() inputCss = 'text-color dark';
  @Input() containerCss = 'display-flex align-items-center';
  @Output() clear = new EventEmitter<void>();
  @ViewChild('input', { read: ElementRef, static: false }) input: ElementRef<HTMLInputElement>;
  @HostBinding('class.collapsed') collapsed = true;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    super(cdr);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.collapsed && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggle();
    }
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        tap(value => {
          this.onChanged(value);
        })
      )
      .subscribe();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched();
  }

  writeValue(value: any): void {
    this.control.setValue(value);
    if (value && this.collapsed) {
      this.toggle();
    }
  }

  toggle() {
    if (!this.collapsed && this.control.value) {
      return;
    }
    this.collapsed = !this.collapsed;
    if (!this.collapsed && this.input) {
      this.input.nativeElement.focus();
    }
    this.cdr.markForCheck();
  }

  expand() {
    if (!this.collapsed) {
      return;
    }
    this.toggle();
  }

  private onChanged = (value: any) => {
    //
  };

  private onTouched = () => {
    //
  };
}
