export enum BreadcrumbTheme {
  LIGHT_GRAY = 'LIGHT_GRAY',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  KANBAN_GRAY1 = 'KANBAN_GRAY1',
  KANBAN_GRAY2 = 'KANBAN_GRAY2',
  KANBAN_GRAY3 = 'KANBAN_GRAY3',
  KANBAN_GRAY4 = 'KANBAN_GRAY4',
  KANBAN_GREEN1 = 'KANBAN_GREEN1',
  KANBAN_GREEN2 = 'KANBAN_GREEN2',
  KANBAN_GREEN3 = 'KANBAN_GREEN3',
  KANBAN_GREEN4 = 'KANBAN_GREEN4',
  KANBAN_GREEN5 = 'KANBAN_GREEN5',
  KANBAN_GREEN6 = 'KANBAN_GREEN6',
  KANBAN_GREEN7 = 'KANBAN_GREEN7',
  KANBAN_GREEN8 = 'KANBAN_GREEN8',
  KANBAN_GREEN9 = 'KANBAN_GREEN9',
  KANBAN_SELECTED = 'KANBAN_SELECTED',
}
