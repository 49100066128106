import { Pipe, PipeTransform } from '@angular/core';

import { PriorityType } from '@shared/enums/priority-type';

@Pipe({
  name: 'priorityCss',
})
export class PriorityCssPipe implements PipeTransform {
  transform(ticketPriorityType: PriorityType, fontColor = false): string {
    let result = '';
    switch (ticketPriorityType) {
      case PriorityType.High: {
        result = 'priority-high';
        break;
      }
      case PriorityType.Medium: {
        result = 'priority-medium';
        break;
      }
      case PriorityType.Low: {
        result = 'priority-low';
        break;
      }
      default: {
        result = 'priority-low';
        break;
      }
    }

    return fontColor ? result + '-color' : result;
  }
}
