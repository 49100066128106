import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SkeletonLoaderComponent } from '@ui-components/components/skeleton-loader/skeleton-loader.component';

import { ChartCardComponent } from './chart-card.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [ChartCardComponent],
  imports: [CommonModule, IconComponent, MatProgressSpinnerModule, SkeletonLoaderComponent],
  exports: [ChartCardComponent],
})
export class ChartCardModule {}
