import { every, filter, find, first, map, size, some } from 'lodash';

import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithProperties } from '@shared/interfaces/user-with-properties';

export function combineUserWithProperties(
  userId: number,
  allProperties: PropertyBasicInfo[],
  propertyMapping: UserToPropertyMapping[]
) {
  const userPropertiesMappings = filter(propertyMapping, { userId });
  const userMappedProperties = map(userPropertiesMappings, mapping => {
    return find(allProperties, { id: mapping.propertyId });
  });
  return filter(userMappedProperties, userProperty => !!userProperty);
}

export function compareAllUsersProperties(users: UserWithProperties[]) {
  const firstUser = first(users);
  return every(users, user => {
    return (
      !!user.userProperties === !!firstUser.userProperties &&
      size(user.userProperties) === size(firstUser.userProperties) &&
      every(user.userProperties, property => some(firstUser.userProperties, { id: property.id }))
    );
  });
}
