import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { InputErrorService } from '@app/modules/ui-components/components/input-error/services/input-error.service';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent {
  @Input() errors: ValidationErrors;
  @Input() visible = false;
  @Input() message = '';
  @Input() containerCss = '';

  constructor(private inputErrorService: InputErrorService) {}

  get validationMessages(): string[] {
    return this.visible && this.errors
      ? (Object.entries(this.errors) || [])
          .filter(([key, errorParams]) => !!errorParams)
          .map<string>(([key, errorParams]) => {
            return this.inputErrorService.getValidationMessage(key, errorParams);
          })
      : [];
  }
}
