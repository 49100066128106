import { Pipe, PipeTransform } from '@angular/core';

import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { AttachmentType } from '@shared/types/attachment-type.type';

@Pipe({
  name: 'turnoverAttachment',
})
export class TurnoverAttachmentPipe implements PipeTransform {
  transform(
    attachmentList: RestGenericTypedAttachment[],
    attachmentType: AttachmentType
  ): RestGenericTypedAttachment[] {
    if (!attachmentList) {
      return [];
    }
    return attachmentList.filter(value => value.targetAttachmentType === attachmentType);
  }
}
