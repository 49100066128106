import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';

import { TimezoneHelper } from './timezone.helper';

export type TimezoneConversionFunction<T> = (data: T, timezone: RestTimezone) => T;

export class TimezoneEntityHelperCommon {
  /**
   * Change timezone for date feilds of data before sending to server
   *
   * @param data - object
   * @param timezone - local timezone of Client app
   * @param vanilaDates - array of fields that should contain vanila date without time part
   * @param nestedObjects - array of fields that contain complicated objects that should use custom function for field correction
   */
  public static readonly fixTimezoneForModelToServer = <T>(
    data: T,
    timezone: RestTimezone,
    vanilaDates: (keyof T)[] = [],
    nestedObjects: { field: keyof T; f: TimezoneConversionFunction<any> }[] = []
  ) => {
    if (!data || (typeof data !== 'object' && !Array.isArray(data))) {
      return data;
    }
    const dataWithoutTimezone = Array.isArray(data) ? ([...data] as T) : { ...data };
    for (const field in dataWithoutTimezone) {
      if (nestedObjects.some(e => e.field == field)) {
        dataWithoutTimezone[field] = nestedObjects
          .find(e => e.field == field)
          .f(dataWithoutTimezone[field], timezone) as any;
      } else if (vanilaDates.includes(field)) {
        dataWithoutTimezone[field] = TimezoneHelper.removeTimeZone(dataWithoutTimezone[field] as Date) as any;
      } else if (
        dataWithoutTimezone[field] instanceof Date ||
        this.isStringLikeServerDate(dataWithoutTimezone[field])
      ) {
        dataWithoutTimezone[field] = TimezoneHelper.replaceTimezoneToServer(
          dataWithoutTimezone[field] as Date,
          timezone
        ) as any;
      } else if (typeof dataWithoutTimezone[field] === 'object') {
        dataWithoutTimezone[field] = this.fixTimezoneForModelToServer(dataWithoutTimezone[field], timezone, [], []);
      }
    }
    return dataWithoutTimezone;
  };

  /**
   * Change timezone for date feilds of data that was just received from server
   *
   * @param data - object
   * @param timezone - local timezone of Client app
   * @param vanilaDates - array of fields that should contain vanilla date without time part
   * @param nestedObjects - array of fields that contain complicated objects that should use custom function for field correction
   */
  public static readonly fixTimezoneForModelToClient = <T>(
    data: T,
    timezone: RestTimezone,
    vanilaDates: (keyof T)[] = [],
    nestedObjects: { field: keyof T; f: TimezoneConversionFunction<any> }[] = []
  ) => {
    if (!data) return data;
    for (const field in data) {
      if (nestedObjects.some(e => e.field == field)) {
        data[field] = nestedObjects.find(e => e.field == field).f(data[field], timezone) as any;
      } else if (vanilaDates.includes(field)) {
        data[field] = TimezoneHelper.addTimeZoneAndKeepOnlyDatePart(data[field] as Date) as any;
      } else if (data[field] instanceof Date || this.isStringLikeServerDate(data[field])) {
        data[field] = TimezoneHelper.replaceTimezoneToClient(data[field] as Date, timezone) as any;
      } else if (typeof data[field] === 'object') {
        this.fixTimezoneForModelToClient(data[field], timezone, [], []);
      }
    }
    return data;
  };

  private static isStringLikeServerDate(dateString: any) {
    return (
      typeof dateString === 'string' &&
      /^[0-9]{4}-[01][0-9]-[0-3][0-9]T[0-5][0-9]:[0-5][0-9]:[0-5][0-9](\.[0-9][0-9]?)?Z$/.test(dateString)
      //Valid examples of server date string:
      //2023-07-06T00:00:00Z
      //2023-07-06T09:37:48.2Z
      //2023-07-06T09:37:21.85Z
    );
  }
}
