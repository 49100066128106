import { createReducer, on } from '@ngrx/store';

import { UpdateStatus } from '@shared/enums/update-status';
import { RestThresholdsConfiguration } from '@shared/interfaces/thresholds-configuration.interface';
import { TurnoverConfigSection } from '@shared/interfaces/turnover-config.interface';

import { RestFinishType } from '../../components/turnover-custom-fields/turnover-custom-fields.component';
import {
  addFinish,
  addFinishFailure,
  addFinishSuccess,
  deleteFinish,
  deleteFinishFailure,
  deleteFinishSuccess,
  getElapsedDaysThresholds,
  getElapsedDaysThresholdsFailure,
  getElapsedDaysThresholdsSuccess,
  getTurnoverConfigurationByWorkflowStep,
  getTurnoverConfigurationByWorkflowStepFailure,
  getTurnoverConfigurationByWorkflowStepSuccess,
  getTurnoverConfigurationList,
  getTurnoverConfigurationListFailure,
  getTurnoverConfigurationListSuccess,
  loadFinishSucceded,
  updateElapsedDaysThresholds,
  updateFinish,
  updateFinishFailure,
  updateFinishSuccess,
  updateTurnoverFieldConfiguration,
  updateTurnoverFieldConfigurationFailure,
  updateTurnoverFieldConfigurationSuccess,
} from '../actions/turnover-configuration.actions';

export const turnoverConfigurationFeatureKey = 'turnoverConfiguration';

export interface TurnoverConfigurationState {
  isLoading: boolean;
  turnoverConfig: TurnoverConfigSection[];
  turnoverConfigLoading: boolean;
  turnoverConfigUpdateInProgress: boolean;

  turnoverStepConfigLoading: boolean;
  turnoverStepConfig: TurnoverConfigSection;

  thresholdsConfiguration: RestThresholdsConfiguration;
  thresholdsConfigurationLoading: boolean;

  thresholdsConfigurationUpdate: UpdateStatus;
  thresholdsConfigurationUpdateInProgress: boolean;

  finishTypes: RestFinishType[];
  finishInProgress: boolean;
}

export const initialState: TurnoverConfigurationState = {
  isLoading: false,
  turnoverConfig: [],
  turnoverConfigLoading: false,
  turnoverConfigUpdateInProgress: false,

  turnoverStepConfigLoading: false,
  turnoverStepConfig: null,

  thresholdsConfiguration: null,
  thresholdsConfigurationLoading: false,

  thresholdsConfigurationUpdate: null,
  thresholdsConfigurationUpdateInProgress: false,

  finishTypes: [],
  finishInProgress: false,
};

export const reducer = createReducer(
  initialState,

  /**
   * get turnover configuration list
   */
  on(getTurnoverConfigurationList, (state, action) => ({ ...state, turnoverConfigLoading: true })),
  on(getTurnoverConfigurationListSuccess, (state, action) => ({
    ...state,
    turnoverConfigLoading: false,
    turnoverConfig: action.turnoverConfig,
  })),
  on(getTurnoverConfigurationListFailure, (state, action) => ({
    ...state,
    turnoverConfigLoading: false,
    turnoverConfig: [],
  })),

  /**
   *
   */
  on(getTurnoverConfigurationByWorkflowStep, (state, action) => ({ ...state, turnoverStepConfigLoading: true })),
  on(getTurnoverConfigurationByWorkflowStepSuccess, (state, action) => ({
    ...state,
    turnoverStepConfigLoading: false,
    turnoverStepConfig: action.turnoverConfig,
  })),
  on(getTurnoverConfigurationByWorkflowStepFailure, (state, action) => ({
    ...state,
    turnoverStepConfigLoading: false,
    turnoverStepConfig: null,
  })),

  /**
   * update turnover field configuration
   */
  on(updateTurnoverFieldConfiguration, (state, action) => ({ ...state, turnoverConfigUpdateInProgress: true })),
  on(updateTurnoverFieldConfigurationSuccess, (state, action) => ({
    ...state,
    turnoverConfigUpdateInProgress: false,
  })),
  on(updateTurnoverFieldConfigurationFailure, (state, action) => ({
    ...state,
    turnoverConfigUpdateInProgress: false,
  })),

  /**
   * get elapsed days thresholds
   */
  on(getElapsedDaysThresholds, (state, action) => ({
    ...state,
    thresholdsConfigurationLoading: true,
  })),
  on(getElapsedDaysThresholdsSuccess, (state, action) => ({
    ...state,
    thresholdsConfiguration: action.thresholdsConfiguration,
    thresholdsConfigurationLoading: false,
  })),
  on(getElapsedDaysThresholdsFailure, (state, action) => ({
    ...state,
    thresholdsConfiguration: null,
    thresholdsConfigurationLoading: false,
  })),

  /**
   * update elapsed days thresholds
   */
  on(updateElapsedDaysThresholds, (state, action) => ({
    ...state,
    thresholdsConfigurationUpdate: UpdateStatus.IN_PROGRESS,
    thresholdsConfigurationUpdateInProgress: true,
  })),
  on(getElapsedDaysThresholdsSuccess, (state, action) => ({
    ...state,
    thresholdsConfigurationUpdate: UpdateStatus.UPDATED,
    thresholdsConfigurationUpdateInProgress: false,
  })),
  on(getElapsedDaysThresholdsFailure, (state, action) => ({
    ...state,
    thresholdsConfigurationUpdate: UpdateStatus.ERROR,
    thresholdsConfigurationUpdateInProgress: false,
  })),

  on(loadFinishSucceded, (state, action) => ({
    ...state,
    finishTypes: action.finishTypes,
  })),
  on(addFinish, updateFinish, deleteFinish, (state, action) => ({
    ...state,
    finishInProgress: true,
  })),
  on(
    addFinishSuccess,
    addFinishFailure,
    updateFinishSuccess,
    updateFinishFailure,
    deleteFinishSuccess,
    deleteFinishFailure,
    (state, action) => ({
      ...state,
      finishInProgress: false,
    })
  )
);
