import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SearchInputComponent } from '@app/modules/ui-components/components/search-input/search-input.component';
import { IconComponent } from '@ui-components/components/icon/icon.component';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, IconComponent, ReactiveFormsModule],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
