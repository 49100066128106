import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';
import { RestAssociateTicketInfo, RestTurnoverDataModelNew } from '@shared/interfaces/turnover.interface';

import { RestTurnStepScheduleBaseModel, RestTurnStepScheduleModel } from '../interfaces/board';
import { ScheduleCellStatus } from '../interfaces/board.enums';

@Injectable({
  providedIn: 'root',
})
export class TurnStepScheduleService {
  private readonly route = 'turnStepSchedule/';

  constructor(private service: RestapiService) {}

  private static fixTimezoneForTurnStepScheduleModel(data: RestTurnStepScheduleModel[], timezone: RestTimezone) {
    data.forEach(e => TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToClient(e, timezone));
    return data;
  }

  getList(turnoverId: number) {
    return this.service.getData<RestTurnStepScheduleModel[]>(
      this.route + 'list/' + turnoverId,
      TurnStepScheduleService.fixTimezoneForTurnStepScheduleModel
    );
  }

  add(entity: RestTurnStepScheduleModel, turn: RestTurnoverDataModelNew) {
    const addEntity = {
      ...entity,
      pushToPMS: entity.pushToPMS,
      ticketTopicId: entity.ticketTopicId,
    };
    return this.service
      .post<RestTurnStepScheduleModel>(
        this.route + 'add',
        addEntity,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToServer,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToClient
      )
      .pipe(tap(data => this.updateDates(data, turn, entity)));
  }

  update(entity: RestTurnStepScheduleModel, turn: RestTurnoverDataModelNew) {
    const correctedStatusEntity =
      entity.status === ScheduleCellStatus.Moved ? { ...entity, status: ScheduleCellStatus.Due } : entity;
    //BE do not know that we have Moved status and store it as Due
    const updateEntity = {
      ...correctedStatusEntity,
      pushToPMS: entity.pushToPMS,
      ticketTopicId: entity.ticketTopicId,
    };
    return this.service
      .post<RestTurnStepScheduleModel>(
        this.route + 'update',
        updateEntity,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToServer,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToClient
      )
      .pipe(tap(data => this.updateDates(data, turn, entity)));
  }

  doneAll(entity: RestTurnStepScheduleModel, turn: RestTurnoverDataModelNew, boardId: number) {
    return this.service
      .post<RestTurnStepScheduleModel>(
        this.route + 'doneAll',
        { turnoverId: entity.turnoverId, boardLayoutId: boardId },
        null,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToClient
      )
      .pipe(tap(data => this.updateDates(data, turn, entity)));
  }

  delete(turnStepScheduleId: number, turn: RestTurnoverDataModelNew) {
    return this.service
      .post<RestTurnStepScheduleBaseModel>(
        this.route + `delete/${turnStepScheduleId}`,
        null,
        null,
        TimezoneEntityHelper.fixTimezoneForTurnStepScheduleModelToClient
      )
      .pipe(tap(data => this.updateDates(data, turn, null)));
  }

  associateTicket(ticket: RestAssociateTicketInfo) {
    return this.service.post<RestTurnStepScheduleModel>(this.route + `associateTicket`, ticket);
  }

  private updateDates(
    stepScheduleModel: RestTurnStepScheduleBaseModel,
    turn: RestTurnoverDataModelNew,
    schedule: RestTurnStepScheduleModel
  ) {
    if (stepScheduleModel.dateCompleted && schedule) {
      schedule.dateCompleted = stepScheduleModel.dateCompleted;
    }
    if (stepScheduleModel.turnoverDateAvailable && turn) {
      turn.dateAvailable = stepScheduleModel.turnoverDateAvailable;
    }
    if (stepScheduleModel.turnoverDateShowable && turn) {
      turn.dateShowable = stepScheduleModel.turnoverDateShowable;
    }

    if (stepScheduleModel.originalDueDate && schedule) {
      schedule.originalDueDate = stepScheduleModel.originalDueDate;
    }
  }
}
