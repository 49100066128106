import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';

@Pipe({
  name: 'timeFromDate',
  pure: false,
})
export class TimeFromDatePipe implements PipeTransform {
  constructor(public timezoneService: TimezoneService) {}

  transform(date: Date | string, showFullDate = false): string {
    if (!date) {
      return '';
    }

    const currrentDate = this.timezoneService.getCurrentDate();

    const dt = moment(date);
    const diff = moment(currrentDate).diff(dt, 'days');

    if (showFullDate || diff > 30) {
      return moment(date).format('M/D');
    } else {
      return moment(date).from(moment(currrentDate)); //  TODO - to check in the future
    }
  }
}
