import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTurnoverConfiguration from '../reducers';

export const selectTurnoverConfigurationState =
  createFeatureSelector<fromTurnoverConfiguration.TurnoverConfigurationState>(
    fromTurnoverConfiguration.turnoverConfigurationFeatureKey
  );

export const selectTurnoverConfigLoading = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.turnoverConfigLoading
);
export const selectTurnoverConfigUpdateInProgress = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.turnoverConfigUpdateInProgress
);

export const selectTurnoverStepConfigLoading = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.turnoverStepConfigLoading
);

export const selectTurnoverStepConfig = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.turnoverStepConfig
);

/**
 * get elapsed days thresholds
 */

export const selectThresholdsConfigurationLoading = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.thresholdsConfigurationLoading
);

export const selectThresholdsConfiguration = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.thresholdsConfiguration
);

export const selectFinishes = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.finishTypes
);

export const selectFinishInProgress = createSelector(
  selectTurnoverConfigurationState,
  turnoverConfigState => turnoverConfigState.finishInProgress
);
