import { Pipe, PipeTransform } from '@angular/core';

import { PriorityType } from '@shared/enums/priority-type';

@Pipe({
  name: 'thresholdPriorityCss',
})
export class ThresholdPriorityCssPipe implements PipeTransform {
  transform(ticketPriorityType: PriorityType): string {
    switch (ticketPriorityType) {
      case PriorityType.High: {
        return 'threshold-high';
      }

      case PriorityType.Medium: {
        return 'threshold-medium';
      }

      case PriorityType.Low: {
        return 'threshold-low';
      }

      default: {
        return 'threshold-low';
      }
    }
  }
}
