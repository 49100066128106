<div class="skeleton-loader-container" [ngClass]="containerCss" #container>
  <svg
    [attr.id]="loaderId"
    *ngIf="_loader"
    role="img"
    [attr.width]="svgViewportWidth"
    [attr.height]="svgViewportHeight"
    aria-labelledby="loading-aria"
    [attr.viewBox]="viewBox"
    preserveAspectRatio="none">
    <title id="loading-aria">Loading...</title>
    <rect x="0" y="0" width="100%" height="100%" [attr.clip-path]="clipPathUrl" class="rect-fill"></rect>
    <defs>
      <clipPath [attr.id]="'clip-path' + loaderId">
        <ng-container [ngSwitch]="_loader">
          <ng-container *ngSwitchCase="LoaderType.List" [ngTemplateOutlet]="listTemplate"></ng-container>
          <ng-container *ngSwitchCase="LoaderType.Board" [ngTemplateOutlet]="boardTemplate"></ng-container>
          <ng-container *ngSwitchCase="LoaderType.PieChart" [ngTemplateOutlet]="pieChartTemplate"></ng-container>
          <ng-container *ngSwitchCase="LoaderType.Kanban" [ngTemplateOutlet]="kanbanTemplate"></ng-container>
          <ng-container *ngSwitchCase="LoaderType.Comment_List" [ngTemplateOutlet]="commentListTemplate"></ng-container>
          <ng-container
            *ngSwitchCase="LoaderType.Recipients_Section"
            [ngTemplateOutlet]="recipientsSectionTemplate"></ng-container>
          <ng-container
            *ngSwitchCase="LoaderType.Details_Single"
            [ngTemplateOutlet]="detailsSingleTemplate"></ng-container>
          <ng-container
            *ngSwitchCase="LoaderType.System_Config"
            [ngTemplateOutlet]="systemConfigTemplate"></ng-container>
          <ng-container *ngSwitchCase="LoaderType.Calendar" [ngTemplateOutlet]="calendarTemplate"></ng-container>
        </ng-container>
      </clipPath>
      <linearGradient id="fill">
        <stop offset="0.599964" stop-color="#EEEEEE" stop-opacity="0.3">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"></animate>
        </stop>
        <stop offset="1.59996" stop-color="#eaeaea" stop-opacity="0.8">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"></animate>
        </stop>
        <stop offset="2.59996" stop-color="#EEEEEE" stop-opacity="0.3">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"></animate>
        </stop>
      </linearGradient>
    </defs>

    <ng-template #listTemplate>
      <ng-container *ngFor="let i of listSections; let rowIndex = index">
        <rect
          [attr.x]="listTemplateItemOffset"
          [attr.y]="listItemOffset * rowIndex + listOffset"
          rx="4"
          ry="4"
          [attr.width]="listTemplateWidth"
          height="38" />
        <rect x="8" [attr.y]="listItemOffset * rowIndex + listOffset" rx="4" ry="4" width="35" height="38" />
      </ng-container>
    </ng-template>

    <ng-template #boardTemplate>
      <ng-container *ngFor="let i of boardSections; let rowIndex = index">
        <rect x="8" [attr.y]="(boardItemOffset + boardOffset) * rowIndex" rx="4" ry="4" width="100" height="55" />
      </ng-container>
    </ng-template>

    <ng-template #pieChartTemplate>
      <rect [attr.x]="pieChartTitleX" y="5" rx="0" ry="0" [attr.width]="pieChartTitleWidth" height="15" />
      <circle [attr.cx]="cXPosition" [attr.cy]="cYPosition" [attr.r]="cRadius" />
      <rect [attr.x]="pieChartLegendDotX" y="50" rx="0" ry="0" width="7" height="7" />
      <rect [attr.x]="pieChartLegendItemX" y="50" rx="0" ry="0" width="30" height="7" />
      <rect [attr.x]="pieChartLegendDotX" y="64" rx="0" ry="0" width="7" height="7" />
      <rect [attr.x]="pieChartLegendItemX" y="64" rx="0" ry="0" width="30" height="7" />
      <rect [attr.x]="pieChartLegendDotX" y="78" rx="0" ry="0" width="7" height="7" />
      <rect [attr.x]="pieChartLegendItemX" y="78" rx="0" ry="0" width="30" height="7" />
      <rect [attr.x]="pieChartLegendDotX" y="92" rx="0" ry="0" width="7" height="7" />
      <rect [attr.x]="pieChartLegendItemX" y="92" rx="0" ry="0" width="30" height="7" />
    </ng-template>

    <ng-template #commentListTemplate>
      <ng-container *ngFor="let i of commentItems; let rowIndex = index">
        <circle [attr.cx]="20" [attr.cy]="commentListItemsOffset * rowIndex + commentListCYOffset" [attr.r]="18" />
        <rect
          [attr.x]="40"
          [attr.y]="commentListItemsOffset * rowIndex + commentListAuthorOffset"
          rx="4"
          ry="6"
          [attr.width]="80"
          height="12" />
        <rect
          [attr.x]="150"
          [attr.y]="commentListItemsOffset * rowIndex + commentListDateOffset"
          rx="4"
          ry="4"
          [attr.width]="60"
          height="8" />
        <rect
          [attr.x]="40"
          [attr.y]="commentListItemsOffset * rowIndex + commentListCommentOffset"
          rx="8"
          ry="8"
          [attr.width]="commentListCommentWidth"
          height="30" />
      </ng-container>
    </ng-template>

    <ng-template #kanbanTemplate>
      <rect x="5" [attr.y]="kanbanTitleYPos" rx="2" ry="2" [attr.width]="kanbanTitleSectionWidth" height="10" />
      <ng-container *ngFor="let i of kanbanRowSections; let rowIndex = index">
        <ng-container *ngFor="let i of kanbanSections; let columnIndex = index">
          <rect
            [attr.x]="kanbanTileXOffset * columnIndex"
            [attr.y]="kanbanTileYOffset * rowIndex + kanbanTitleYPosOffset"
            rx="4"
            ry="4"
            [attr.width]="kanbanTileWidth"
            [attr.height]="kanbanTileHeight" />
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #detailsSingleTemplate>
      <rect x="0" y="0" rx="8" ry="8" [attr.width]="detailsSingleWidth" [attr.height]="detailsSingleHeight" />
    </ng-template>

    <ng-template #recipientsSectionTemplate>
      <rect x="0" y="0" rx="8" ry="8" [attr.width]="recSectionVendorWidth" [attr.height]="30" />
      <circle cx="10" cy="55" [attr.r]="8" />
      <circle cx="40" cy="55" [attr.r]="8" />
      <rect x="60" y="40" rx="8" ry="8" [attr.width]="recSectionAttachmentsWidth" [attr.height]="30" />
      <rect
        [attr.x]="recSectionAttachmentsBtnOffset"
        y="40"
        rx="8"
        ry="8"
        [attr.width]="recSectionAttachmentsBtnWidth"
        [attr.height]="30" />
      <rect x="0" y="80" rx="8" ry="8" [attr.width]="recSectionExpWidth" [attr.height]="30" />
      <rect x="0" y="120" rx="8" ry="8" [attr.width]="recSectionNoteWidth" [attr.height]="80" />

      <ng-container *ngFor="let i of recSectionAttachmentsItems; let rowIndex = index">
        <rect
          [attr.x]="recSectionVendorsItemsXOffset"
          [attr.y]="recSectionVendorsItemsYOffset * rowIndex + kanbanTitleYPosOffset"
          rx="4"
          ry="4"
          [attr.width]="recSectionVendorsItemsWidth"
          [attr.height]="20" />
      </ng-container>
    </ng-template>

    <ng-template #systemConfigTemplate>
      <ng-container *ngFor="let i of systemConfigSections; let rowIndex = index">
        <rect
          x="20"
          [attr.y]="systemConfigSectionOffset * rowIndex + systemConfigLabelOffset"
          rx="4"
          ry="4"
          width="60"
          height="8" />
        <rect
          x="20"
          [attr.y]="systemConfigSectionOffset * rowIndex + systemConfigControlOffset"
          rx="4"
          ry="4"
          width="261"
          height="25" />
      </ng-container>
    </ng-template>
    <ng-template #calendarTemplate>
      <ng-container *ngFor="let i of calendarRowSections; let rowIndex = index">
        <ng-container *ngFor="let i of calendarSections; let columnIndex = index">
          <rect
            [attr.x]="calendarItemXOffset * columnIndex"
            [attr.y]="calendarItemYOffset * rowIndex"
            rx="4"
            ry="4"
            [attr.width]="calendarItemWidth"
            [attr.height]="calendarItemHeight" />
        </ng-container>
      </ng-container>
    </ng-template>
  </svg>
</div>
