import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '@shared/pipes/pipes.module';
import { ChartCardModule } from '@ui-components/components/chart-card/chart-card.module';
import { LineChartModule } from '@ui-components/components/line-chart/line-chart.module';
import { PillListModule } from '@ui-components/components/pill-list/pill-list.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';

import { VacancyLossChartCardComponent } from './vacancy-loss-chart-card.component';

@NgModule({
  declarations: [VacancyLossChartCardComponent],
  exports: [VacancyLossChartCardComponent],
  imports: [
    SlideToggleComponent,
    CommonModule,
    LineChartModule,
    ChartCardModule,
    PillListModule,
    ReactiveFormsModule,
    PipesModule,
    RadioButtonDropdownModule,
    FormsModule,
  ],
})
export class VacancyLossChartCardModule {}
