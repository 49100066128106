import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SendInspectionReportRequest } from '@main-application/inspections/models/rest-inspections-model.interface';
import { ButtonType } from '@shared/enums/button-type';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { InputErrorService } from '@ui-components/components/input-error/services/input-error.service';
import { EmailPatternValidator } from '@ui-components/validators/email.validator';

@Component({
  selector: 'app-send-inspection',
  templateUrl: './send-inspection.component.html',
  styleUrls: ['./send-inspection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendInspectionComponent {
  ButtonType = ButtonType;

  form = this.formBuilder.group({
    to: [<string>null, [Validators.required, EmailPatternValidator, Validators.email]],
    subject: ['', [Validators.required]],
    body: [''],
  });
  isSubmitted = false;

  @ViewChildren(NgControl) controls: QueryList<NgControl>;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any, SendInspectionReportRequest>,
    private inputErrorService: InputErrorService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) inspectionTitle: string
  ) {
    this.form.patchValue({ subject: inspectionTitle });
  }

  ok() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.dialogRef.close({
        to: [this.form.value.to],
        subject: this.form.value.subject || '',
        body: this.form.value.body || '',
      });
    } else {
      const errors: ValidationErrors = this.controls
        .toArray()
        .flatMap(control => control.control.errors)
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});

      const strErrors = (Object.entries(errors) || [])
        .filter(([key, errorParams]) => !!errorParams)
        .map(([key, errorParams]) => {
          return this.inputErrorService.getValidationMessage(key, errorParams);
        });
      this.snackbarService.error(strErrors.join('<br />'));
    }
    this.cdr.detectChanges();
  }
}
