import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelDaySinceStep',
})
export class LabelDaySinceStepPipe implements PipeTransform {
  transform(label: string, daySinceStep: number): string {
    if (!daySinceStep) {
      return `${label} ??`;
    }
    return `${label} ${Math.abs(daySinceStep)}d${daySinceStep < 0 ? '' : ' ago'}`;
  }
}
