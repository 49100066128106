import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'notEmptyPropsAmount',
})
export class NotEmptyPropsAmountPipe implements PipeTransform {
  transform(value: any, showZero = false, excludedProps?: string[]): number {
    if (!value || typeof value !== 'object') {
      return !showZero ? null : 0;
    }

    const props = Object.keys(value).filter(key => {
      const val = value[key];
      const isValid =
        val !== null && val !== undefined && (!isArray(val) || val.some(e => e)) && (typeof val !== 'boolean' || val);

      return isValid && (!excludedProps?.length || !excludedProps.includes(key));
    });

    const amount = props.length;

    return !showZero && !amount ? null : amount;
  }
}
