import { Pipe, PipeTransform } from '@angular/core';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { getTimeInStep } from '@shared/functions/get-time-in-step.function';
import { RestTimerInstanceModel } from '@shared/interfaces/rest-timer-instance-model';
import { TimeInStep } from '@shared/interfaces/time-in-step.interface';

@Pipe({
  name: 'dayInStep',
})
export class DaysInStepPipe implements PipeTransform {
  transform(
    workflowStepEnumType: WorkflowStepEnumType,
    timerInstance: RestTimerInstanceModel,
    forAllProcess = false
  ): TimeInStep {
    const time = getTimeInStep(workflowStepEnumType, timerInstance, forAllProcess);

    const allHours = Math.floor(time / (60 * 60)) || 0;
    const days = Math.floor(allHours / 24) || 0;
    const hours = allHours - days * 24 || 0;
    return { days, hours };
  }
}
