import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DelinquencyFields } from '@main-application/dashboard-reports/delinquencies/models/delinquency-fields.enum';
import { DelinquencyResultType } from '@shared/enums/delinquency-result-type';

@Pipe({
  name: 'delinquencyPristineGroup',
  pure: false,
})
export class DelinquencyPristineGroupPipe implements PipeTransform {
  transform(form: UntypedFormGroup, delinquencyResultType: DelinquencyResultType): boolean {
    const delinquencyResult = form.get([DelinquencyFields.DELINQUENCY_RESULT_TYPE]);
    const notes = form.get([DelinquencyFields.NOTES]);
    const group = form.get([delinquencyResultType]);
    return delinquencyResult.pristine && notes.pristine && group.pristine;
  }
}
