import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import { NumberInputComponent } from './number-input.component';
import { IconComponent } from '../../components/icon/icon.component';
import { InputErrorModule } from '../../components/input-error/input-error.module';

@NgModule({
  declarations: [NumberInputComponent],
  imports: [
    CommonModule,
    IconComponent,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    IconComponent,
    InputErrorModule,
    MatIconModule,
    TooltipDirectiveModule,
    PipesModule,
  ],
  exports: [NumberInputComponent],
})
export class NumberInputModule {}
