import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule, LogLevel, OidcSecurityService } from 'angular-auth-oidc-client';

import { OidcStorageService } from '@app/services/oidc-storage.service';
import { AppRoutes } from '@shared/constants/app-routes.const';

import { environment } from '../../../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule.forRoot({
      config: [
        {
          authority: environment.azureAuthConfig.stsServer,
          redirectUrl: environment.azureAuthConfig.redirectUrl,
          clientId: environment.azureAuthConfig.clientID,
          responseType: 'id_token token',
          autoUserInfo: false,
          scope: environment.azureAuthConfig.b2cScopes,
          postLogoutRedirectUri: environment.azureAuthConfig.postLogoutRedirectUrl,
          silentRenew: true,
          useRefreshToken: true,
          forbiddenRoute: AppRoutes.NO_PERMISSION,
          unauthorizedRoute: AppRoutes.NO_PERMISSION,
          silentRenewUrl: environment.azureAuthConfig.redirectUrl,
          renewTimeBeforeTokenExpiresInSeconds: 43200,
          logLevel: environment.showAuthorizeLogs ? LogLevel.Debug : LogLevel.None,
          maxIdTokenIatOffsetAllowedInSeconds: 86400,
          disableIdTokenValidation: false,
          triggerAuthorizationResultEvent: true,
          configId: 'main',
        },
        {
          authority: environment.azureAuthConfig.stsServerSignInEmail,
          redirectUrl: environment.azureAuthConfig.redirectUrl,
          clientId: environment.azureAuthConfig.clientID,
          responseType: 'id_token token',
          autoUserInfo: false,
          scope: environment.azureAuthConfig.b2cScopes,
          postLogoutRedirectUri: environment.azureAuthConfig.postLogoutRedirectUrl,
          silentRenew: false,
          useRefreshToken: false,
          forbiddenRoute: AppRoutes.NO_PERMISSION,
          unauthorizedRoute: AppRoutes.NO_PERMISSION,
          silentRenewUrl: environment.azureAuthConfig.redirectUrl,
          renewTimeBeforeTokenExpiresInSeconds: 43200,
          logLevel: environment.showAuthorizeLogs ? LogLevel.Debug : LogLevel.None,
          maxIdTokenIatOffsetAllowedInSeconds: 86400,
          disableIdTokenValidation: false,
          triggerAuthorizationResultEvent: true,
          configId: 'email',
        },
      ],
    }),
  ],
  providers: [OidcSecurityService, { provide: AbstractSecurityStorage, useClass: OidcStorageService }],
})
export class OidcAuthModule {}
