import { Injectable } from '@angular/core';
import { isEmpty, isEqual, isUndefined } from 'lodash';

import {
  SparePartsPageAreaModel,
  SparePartsPageModel,
  SparePartsPageModelExtendedParams,
  SparePartsSetterParamsModel,
} from '../models/spare-parts.model';
import { AreaModel, AreaPageModel } from '../models/template-editor-model';

@Injectable()
export class SparePartsService {
  // Item Method
  public isUseSameForParentSparePartsButtonActive({ parent, item }: SparePartsPageModel): boolean {
    if (!item?.overrideSparePartsSettings && item?.showSpareParts) return true;

    // If parent sparePartsGroups is empty
    if (isEmpty(parent?.areaSurvey?.sparePartsGroups)) return false;
    // If sparePartsGroups is not equal
    if (!isEqual(parent?.areaSurvey?.sparePartsGroups, item?.sparePartsGroups)) return false;
    // if overrideSparePartsSettings is false and showSpareParts is true
    return !item?.overrideSparePartsSettings && item?.showSpareParts;
  }
  // Item Method
  public isDoNotUseSparePartsButtonActive({ parent, item }: SparePartsPageModel): boolean {
    const isEmptyParent = isEmpty(parent?.areaSurvey?.sparePartsGroups);
    const isEmptyTarget = isEmpty(item?.sparePartsGroups);

    if (isEmptyParent && isEmptyTarget) return true;

    return item?.overrideSparePartsSettings && !item?.showSpareParts;
  }
  // Item Method
  public isSparePartsGroupButtonActive({ parent, item }: SparePartsPageModel): boolean {
    if (this.isUseSameForParentSparePartsButtonActive({ parent, item })) return true;
    if (!this.isDoNotUseSparePartsButtonActive({ parent, item })) return true;
    if (!isEmpty(item?.sparePartsGroups)) return true;

    return false;
  }
  // Item Method
  public useSetOrUnsetSparePartsGroupItem({ item, id }: SparePartsPageModelExtendedParams) {
    const sparePartsGroups = this.useHelperSetter({ sparePartsGroups: item?.sparePartsGroups, id });
    const showSpareParts = sparePartsGroups.length > 0;

    return {
      sparePartsGroups,
      showSpareParts,
      overrideSparePartsSettings: true,
    };
  }

  // Area Method

  public useSetOrUnsetSparePartsGroupsArea({ parent, id }: SparePartsPageAreaModel): AreaModel {
    const sparePartsGroups = this.useHelperSetter({ sparePartsGroups: parent?.areaSurvey?.sparePartsGroups, id });
    const getCopyedArea = this.useCopySparePartsGroupsArea({
      ...parent,
      areaSurvey: {
        ...parent?.areaSurvey,
        sparePartsGroups,
      },
    });
    return getCopyedArea;
  }

  public useCopySparePartsGroupsArea(parent: AreaModel): AreaModel {
    const getAreaSurvey = parent?.areaSurvey;
    const getPages = getAreaSurvey.pages;
    const sparePartsGroups = getAreaSurvey?.sparePartsGroups;
    const showSpareParts = sparePartsGroups.length > 0;

    const getPageOverrideSparePartsSettings = (page: AreaPageModel) => {
      // If nothiing to copy
      if (!showSpareParts) return false;

      // By Default Value if not setted
      if (isUndefined(page?.overrideSparePartsSettings)) return false;

      return page?.overrideSparePartsSettings;
    };

    const getPageSparePartsGroupsItem = (page: AreaPageModel) => {
      // If nothiing to copy
      if (!showSpareParts) return [];

      // By Default Value if not setted
      if (isUndefined(page?.sparePartsGroups)) return sparePartsGroups;
      if (this.isUseSameForParentSparePartsButtonActive({ parent, item: page })) return sparePartsGroups;
      if (this.isDoNotUseSparePartsButtonActive({ parent, item: page })) return [];
      return page?.sparePartsGroups ?? sparePartsGroups;
    };

    const getNewPages = getPages.map(page => {
      const getShowSpareParts = !showSpareParts ? false : page?.showSpareParts ?? true;
      const getOverrideSparePartsSettings = getPageOverrideSparePartsSettings(page);

      const getTransformedPage = {
        ...page,
        showSpareParts: getShowSpareParts,
        overrideSparePartsSettings: getOverrideSparePartsSettings,
      };
      return {
        ...getTransformedPage,
        sparePartsGroups: getPageSparePartsGroupsItem(getTransformedPage),
      };
    });

    return {
      ...parent,
      areaSurvey: {
        ...getAreaSurvey,
        sparePartsGroups,
        showSpareParts,
        pages: getNewPages,
      },
    };
  }

  // Private Global Method
  private useHelperSetter(params: SparePartsSetterParamsModel): number[] {
    const sparePartsGroups = [...(params?.sparePartsGroups ?? [])];

    const index = sparePartsGroups.indexOf(params.id);
    if (index === -1) {
      sparePartsGroups.push(params.id);
    } else {
      sparePartsGroups.splice(index, 1);
    }
    return sparePartsGroups;
  }
}
