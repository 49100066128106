import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

import { TableFilterItem } from '@dashboards/components/dashboard-table-filter/config/interfaces/table-filter-item.interface';
import { paramsCompare } from '@shared/functions/params-compare.function';
import { ParamsCompareItem } from '@shared/interfaces/params-compare-item';
import { RestTicketModel } from '@shared/interfaces/turnover.interface';

@Pipe({
  name: 'taskTableSubsectionResultCount',
})
export class TaskTableSubsectionResultCountPipe implements PipeTransform {
  transform(tickets: RestTicketModel[], filterItem: TableFilterItem): number {
    if (!tickets?.length || !filterItem) {
      return 0;
    }

    return (
      tickets.filter(data => {
        if (isEmpty(filterItem) || !filterItem?.assigneeId) {
          return true;
        }

        const compareParams: ParamsCompareItem[] = [];

        if (filterItem?.assigneeId) {
          compareParams.push({
            currentValue: data.assigneeId,
            expectValue: filterItem.assigneeId,
          });
        }

        return paramsCompare(compareParams);
      })?.length || 0
    );
  }
}
