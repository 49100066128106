<div class="gallery-modal-container display-flex flex-column">
  <div class="display-flex justify-content-space-between">
    <h2 mat-dialog-title class="modal-header">Pictures ({{ attachments?.length || 0 }})</h2>
    <button mat-button color="primary" class="modal-close" (click)="cancel()">
      <app-icon [iconColor]="EColorPalette.cGreen3" [iconHeight]="34" [iconWidth]="34" [svg]="EIcon.CLOSE"></app-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="display-flex flex-column grid-gap-16">
      <div class="gallery-list">
        <ul #imagesList class="img-container display-flex flex-wrap grid-gap-8">
          <li *ngFor="let item of attachments; let index = index" class="img-content">
            <img [attr.src]="item?.url" [attr.alt]="item?.title" class="img-control" />
            <button
              *ngIf="!data?.readOnly"
              class="delete-btn button-icon-delete"
              mat-icon-button
              color="primary"
              [disabled]="deleteAttachmentInProgress$ | async"
              (click)="deleteItem(item)"
              [matTooltip]="'Delete'">
              <app-icon
                [iconHeight]="24"
                [iconWidth]="24"
                [svg]="EIcon.TRASH"
                [iconColor]="EColorPalette.cRed2"></app-icon>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
</div>
