import { Pipe, PipeTransform } from '@angular/core';
import { first, trim } from 'lodash';

@Pipe({
  name: 'formatToAvatar',
})
export class FormatToAvatarPipe implements PipeTransform {
  transform(firstName = '', lastName = ''): string {
    const result = (first(trim(firstName || '')) || '') + (first(trim(lastName || '')) || '');
    return result || ' ';
  }
}
