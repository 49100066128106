import { Pipe, PipeTransform } from '@angular/core';

import { BreadcrumbTheme } from '@shared/enums/breadcrumb-theme.enum';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';

@Pipe({
  name: 'kanbanHeaderWorkflowPhase',
})
export class KanbanHeaderWorkflowPhasePipe implements PipeTransform {
  transform(
    currentPhase: WorkflowPhaseType,
    defaultPhase: WorkflowPhaseType,
    defaultTheme: BreadcrumbTheme
  ): BreadcrumbTheme {
    if (currentPhase > defaultPhase) {
      return defaultTheme;
    }

    if (currentPhase === defaultPhase) {
      return BreadcrumbTheme.KANBAN_GREEN1;
    }

    return BreadcrumbTheme.KANBAN_GRAY2;
  }
}
