<ng-container *ngIf="iconTemplate">
  <div [ngClass]="cssIcon" [innerHTML]="iconTemplate | trustHtml"></div>
</ng-container>

<ng-container *ngIf="!iconTemplate">
  <mat-icon
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
    [svgIcon]="icon"
    [style]="{ width: width + 'px', height: height + 'px', '--icon-color': color }"
    [ngClass]="[cssIcon, color ? 'colored' : '']"></mat-icon>
</ng-container>
