import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectPmsTypesEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { EnumerationConfig } from '@shared/interfaces/enumeration-config';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';

@Pipe({
  name: 'pmsTypeEnumeration',
})
export class PmsTypeEnumerationPipe implements PipeTransform {
  constructor(private store: Store<{ enumeration: EnumerationConfig }>) {}

  transform(value: number): Observable<string> {
    return this.store.select(selectPmsTypesEnumeration).pipe(
      map((pmsTypes: EnumerationItem[]) => {
        const pmsType = (pmsTypes || []).find(type => type.enumerationValue === value);
        return pmsType ? pmsType.simpleText : 'non-PMS';
      })
    );
  }
}
