import { InspectionsListConstants } from '@main-application/inspections/components/inspections-list/constants';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartDataItem } from '@shared/interfaces/chart-data-item';
import { ISelectFilterConfig } from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

export const FILTER_PERIOD_RECORD = {
  sevenDays: 'past 7 days',
  thirtyDays: 'past 30 days',
  threeMonths: 'past 3 months',
  sixMonths: 'past 6 months',
  year: 'past year',
  allTime: 'all time',
  custom: 'custom',
};

export const SELECT_FILTER_PERIOD_CONFIG: ISelectFilterConfig[] = [
  {
    id: InspectionsListConstants.TimeFrame.Week,
    label: FILTER_PERIOD_RECORD.sevenDays,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month,
    label: FILTER_PERIOD_RECORD.thirtyDays,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month_3,
    label: FILTER_PERIOD_RECORD.threeMonths,
  },
  {
    id: InspectionsListConstants.TimeFrame.Month_6,
    label: FILTER_PERIOD_RECORD.sixMonths,
  },
  {
    id: InspectionsListConstants.TimeFrame.Year,
    label: FILTER_PERIOD_RECORD.year,
  },
  {
    id: InspectionsListConstants.TimeFrame.AllTime,
    label: FILTER_PERIOD_RECORD.allTime,
  },
  {
    id: InspectionsListConstants.TimeFrame.Custom,
    label: FILTER_PERIOD_RECORD.custom,
  },
];

export const SELECT_FILTER_SHOW_BY_CONFIG: ISelectFilterConfig[] = [
  {
    id: 1,
    label: 'Assignee',
  },
  {
    id: 2,
    label: 'Type',
  },
];

export const INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD = {
  0: 'completed',
  1: 'completed_late',
  2: 'expired',
  3: 'in_progress',
  4: 'not_started',
  5: 'late',
  6: 'review',
}; // Id by ths id record below

export const INSPECTION_SUMMARY_CHART: ChartDataItem[] = [
  {
    label: 'Completed',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Completed Late',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Expired',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'In Progress',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Not Started',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Late',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
  {
    label: 'Review',
    value: 0,
    backgroundColor: EColorPalette.cGray8,
  },
];

export const INSPECTION_SUMMARY_EXPANDED_TABLE_DATA_BASE = {
  expandedTitle: '',
  total: {
    notStarted: 0,
    inProgress: 0,
    late: 0,
    completed: 0,
    completedLate: 0,
    total: 0,
  },
  data: [],
};

export const INSPECTION_SUMMARY_ERROR = {
  errorWrong: 'Something went wrong. Give it another try!',
  errorNoData: 'No data to display.',
};

export const Unassigned = 'Unassigned';
export const System = 'System';
