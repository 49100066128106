import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { selectWindowWidth } from '@main-application/store/selectors/window-resize.selectors';
import { LoaderType } from '@shared/enums/loader-type';
import { SkeletonLoaderCounter } from '@ui-components/components/skeleton-loader/services/skeleton-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class SkeletonLoaderComponent extends ComponentAbstract implements OnInit, AfterViewInit {
  readonly containerPadding = 10;
  readonly listTemplateItemOffset = 50;
  readonly listSections = Array(40).fill(0);
  readonly boardSections = Array(9).fill(0);
  readonly kanbanSections = Array(8).fill(0);
  readonly kanbanRowSections = Array(30).fill(0);
  readonly commentItems = Array(3).fill(0);
  readonly recSectionAttachmentsItems = Array(3).fill(0);
  readonly listOffset = 6;
  readonly listItemOffset = 50;
  readonly tileOffset = 4;
  readonly calendarItemOffset = 4;
  readonly kanbanTitleYPos = 5;
  readonly kanbanTitleYPosOffset = 25;

  readonly boardItemOffset = 55;
  readonly boardOffset = 15;

  readonly systemConfigSections = Array(4).fill(0);
  readonly systemConfigLabelOffset = 6;
  readonly systemConfigControlOffset = 20;
  readonly systemConfigSectionOffset = 50;

  readonly calendarRowSections = Array(9).fill(0);
  readonly calendarSections = Array(7).fill(0);

  loaderId = '';
  clipPathUrl = '#';
  clipPath = '';
  fill = '';

  viewBox = '0 0 300 200';

  svgViewportWidth = 300;
  svgViewportHeight = 200;

  listTemplateWidth = 0;

  pieChartTitleWidth = 200;
  pieChartTitleX = 100;
  pieChartLegendDotX = 230;
  pieChartLegendItemX = 250;
  cXPosition = 140;
  cYPosition = 140;
  cRadius = 70;

  kanbanTitleSectionWidth = 40;
  kanbanTileHeight = 50;
  kanbanTileWidth = 100;
  kanbanTileXOffset = 104;
  kanbanTileYOffset = 54;

  calendarItemHeight = 56;
  calendarItemWidth = 100;
  calendarItemXOffset = 104;
  calendarItemYOffset = 60;

  detailsSingleHeight = 100;
  detailsSingleWidth = 100;

  commentListCYOffset = 20;
  commentListAuthorOffset = 6;
  commentListDateOffset = 5;
  commentListCommentOffset = 24;
  commentListItemsOffset = 70;
  commentListCommentWidth = 100;

  recSectionVendorWidth = 100;
  recSectionAttachmentsWidth = 100;
  recSectionAttachmentsBtnWidth = 100;
  recSectionAttachmentsBtnOffset = 100;
  recSectionExpWidth = 100;
  recSectionNoteWidth = 100;
  recSectionVendorsItemsXOffset = 100;
  recSectionVendorsItemsYOffset = 25;
  recSectionVendorsItemsWidth = 100;

  _loader: LoaderType = LoaderType.List;

  @Input() containerCss = 'align-items-center justify-content-center display-flex h-100 w-100';

  @Input() set loaderType(loaderType: LoaderType) {
    this._loader = loaderType;
    this.cdr.detectChanges();
  }

  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  constructor(protected cdr: ChangeDetectorRef, private store: Store<{}>) {
    super(cdr);
  }

  ngOnInit(): void {
    this.loaderId = SkeletonLoaderCounter.getLoaderId();
    this.clipPathUrl = `url(#clip-path${this.loaderId})`;
    this.clipPath = `clip-path${this.loaderId}`;
    this.fill = `fill${this.loaderId}`;
    this.updateWidthParams();

    this.store
      .select(selectWindowWidth)
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap(() => {
          this.updateWidthParams();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateWidthParams();
    }, 1);
  }

  private updateWidthParams() {
    if (this.container) {
      const containerWidth = this.container?.nativeElement.clientWidth;
      const containerHeight = this.container?.nativeElement.clientHeight;

      this.svgViewportWidth = Math.max(0, containerWidth - 2 * this.containerPadding);
      this.svgViewportHeight = Math.max(0, containerHeight - 2 * this.containerPadding);

      if (this.svgViewportWidth < 0 || this.svgViewportHeight < 0) {
        return;
      }

      this.viewBox = `0 0 ${this.svgViewportWidth} ${this.svgViewportHeight}`;

      switch (this._loader) {
        case LoaderType.List:
          {
            this.listTemplateWidth = Math.max(0, this.svgViewportWidth - this.listTemplateItemOffset);
          }
          break;
        case LoaderType.PieChart:
          {
            this.pieChartTitleWidth = this.svgViewportWidth / 4;
            this.pieChartTitleX = this.svgViewportWidth / 2.67;

            this.cYPosition = this.svgViewportHeight * 0.5;
            this.cXPosition = this.svgViewportWidth * 0.4;
            this.cRadius = this.svgViewportHeight * 0.3;

            this.pieChartLegendDotX = this.cXPosition + this.cRadius + 20;
            this.pieChartLegendItemX = this.pieChartLegendDotX + 20;
          }
          break;
        case LoaderType.Kanban:
          {
            this.kanbanTitleSectionWidth = this.svgViewportWidth / (this.kanbanSections.length - 1);
            this.kanbanTileWidth = Math.max(
              0,
              (this.svgViewportWidth - (this.kanbanSections.length - 1) * this.tileOffset) / this.kanbanSections.length
            );
            this.kanbanTileXOffset = this.kanbanTileWidth + this.tileOffset;
          }
          break;
        case LoaderType.Details_Single:
          {
            this.detailsSingleHeight = this.svgViewportHeight;
            this.detailsSingleWidth = this.svgViewportWidth;
          }
          break;
        case LoaderType.Comment_List:
          {
            this.commentListCommentWidth = this.svgViewportWidth * 0.8;
          }
          break;
        case LoaderType.Recipients_Section:
          {
            this.recSectionVendorWidth = this.svgViewportWidth * 0.2;
            this.recSectionAttachmentsWidth = this.svgViewportWidth * 0.2;
            this.recSectionAttachmentsBtnWidth = this.svgViewportWidth * 0.05;
            this.recSectionAttachmentsBtnOffset = this.svgViewportWidth * 0.1 + 80;
            this.recSectionExpWidth = this.svgViewportWidth * 0.1;
            this.recSectionNoteWidth = this.svgViewportWidth * 0.48;
            this.recSectionVendorsItemsXOffset = this.svgViewportWidth * 0.85;
            this.recSectionVendorsItemsWidth = this.svgViewportWidth * 0.1;
          }
          break;
        case LoaderType.Calendar:
          {
            this.calendarItemWidth = Math.max(
              0,
              (this.svgViewportWidth - (this.calendarSections.length - 1) * this.calendarItemOffset) /
                this.calendarSections.length
            );
            this.calendarItemXOffset = this.calendarItemWidth + this.calendarItemOffset;
          }
          break;
      }

      this.cdr.detectChanges();
    }
  }
}
