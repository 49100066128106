import { Pipe, PipeTransform } from '@angular/core';

import { EIcon } from '@shared/enums/icon.enum';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';

@Pipe({
  name: 'workflowPhaseDone',
})
export class WorkflowPhaseDonePipe implements PipeTransform {
  transform(currentPhase: WorkflowPhaseType, defaultPhase: WorkflowPhaseType): EIcon {
    if (!currentPhase) {
      return null;
    }
    if (defaultPhase < currentPhase) {
      return EIcon.DONE_WB;
    }
    return null;
  }
}
