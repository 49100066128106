import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RestapiService } from '@app/services/restapi.service';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateManagerService {
  constructor(private restApiService: RestapiService, private snackbarService: SnackbarService) {}

  getGlobalTemplates(): Observable<RestTemplateModel[]> {
    return this.restApiService.getData<RestTemplateModel[]>(`inspections/template/global`);
  }

  getViewedTemplates(): Observable<number[]> {
    return this.restApiService.getData<number[]>(`inspections/template/viewed`);
  }

  markTemplatesAsViewed(templateIds: number[]) {
    return this.restApiService.post(`inspections/template/markAsViewed`, templateIds);
  }

  markAsNew(templateId: number) {
    return this.restApiService.post(`inspections/template/global/${templateId}/flagAsNew`);
  }

  getTemplate(templateId: number) {
    return this.restApiService.getData<RestTemplateModel>(`inspections/template/global/${templateId}`);
  }

  installTemplate(templateId: number): Observable<RestTemplateModel> {
    return this.restApiService.post(`inspections/template/installGlobalTemplate/${templateId}`);
  }

  removeTemplate(template: RestTemplateModel) {
    return this.restApiService.delete(`inspections/template/global/${template.id}`).pipe(
      catchError(err => {
        if (err?.error?.['ErrorCode'] === 'inspection_template_delete_unavailable') {
          this.snackbarService.error(SnackbarErrorMessage.RemovingTemplateInUse);
        } else {
          this.snackbarService.error(SnackbarErrorMessage.RemovingTemplate);
        }
        return throwError(() => err);
      }),
      tap(() => {
        this.snackbarService.success(`${template.name} deleted`);
      })
    );
  }

  promoteTemplateToGlobal(templateId: number) {
    return this.restApiService.post<number>(`inspections/template/promoteTemplateToGlobal/${templateId}`);
  }

  cloneTemplate(templateId: number) {
    return this.restApiService.post<number>(`inspections/template/${templateId}/clone`);
  }

  cloneGlobalTemplate(templateId: number) {
    return this.restApiService.post<number>(`inspections/template/global/${templateId}/clone`);
  }
}
