import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNil } from 'lodash';

@Pipe({
  name: 'elapsedDaysMidRange',
  pure: false,
})
export class ElapsedDaysMidRangePipe implements PipeTransform {
  transform(group: FormGroup, reversed = false): string {
    if (group.invalid) {
      return '';
    }

    const { low, high } = group.value;

    if (isNil(low) || isNil(high) || low === '' || high === '') {
      return '';
    }

    const lowValue = parseInt(low, 10);
    const highValue = parseInt(high, 10);

    const range = Math.abs(highValue - lowValue);

    if (range === 1) {
      return reversed ? `${lowValue - 1}` : `${lowValue + 1}`;
    }

    return reversed ? `${highValue} - ${lowValue}` : `${lowValue + 1} - ${highValue}`;
  }
}
