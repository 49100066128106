import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first, isNil } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { UnitNonPmsService } from '@app/services/unit-non-pms.service';
import { loadUnitDetails, loadUnitList, resetUnitList } from '@dashboards/store/actions/dashboard.actions';
import {
  selectUnitDetails,
  selectUnitList,
  selectUnitListLoading,
} from '@dashboards/store/selectors/dashboards.selectors';
import {
  selectSelectedPortfolioId,
  selectSelectedPropertyIds,
} from '@dashboards/store/selectors/property-selector.selectors';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { selectTenantData } from '@main-application/store/selectors/user.selectors';
import { TurnoverForm } from '@main-application/turnovers/config/enums/turnover-form';
import { createNewTurnover, resetCreateNewTurnover } from '@main-application/turnovers/store/actions/turnovers.actions';
import {
  selectCreateTurnoverLoading,
  selectCreatedTurnover,
} from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { selectProperties, selectPropertiesLoading } from '@portfolio/store/portfolio.selectors';
import { AppRoutes } from '@shared/constants/app-routes.const';
import { WorkflowStepListActive } from '@shared/constants/workflow-step-list.const';
import { DashboardViewType } from '@shared/enums/dashboard-view-type';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { getPortfolioProperties } from '@shared/functions/get-portfolio-properties.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestPortfolioModel, RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/rest-unit-summary-model.interface';
import { PostTurnoverModel } from '@shared/interfaces/turnover.interface';
import { UnitDetails } from '@shared/interfaces/unit-details.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { DialogResult } from '../config/dialog-result.enum';
import { ModalsService } from '../modals.service';

export class NewTurnoverModalData {
  activePropertyIds: number[];
  isNonPms: boolean;
  defaultWorkflowStepType?: WorkflowStepEnumType;
}

@UntilDestroy()
@Component({
  selector: 'app-new-turnover-modal',
  templateUrl: './new-turnover-modal.component.html',
  styleUrls: ['./new-turnover-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NewTurnoverModalComponent extends ComponentAbstract implements OnInit {
  form = this.formBuilder.group({
    [TurnoverForm.PROPERTY_ID]: [null as number, [Validators.required]],
    [TurnoverForm.UNIT_ID]: [null as number, [Validators.required]],
    unitName: ['non-PMS unit', [Validators.required]],
    [TurnoverForm.MOVE_OUT]: [this.timezoneService.getCurrentDateOnly(), [Validators.required]],
    startWorkflowStepType: [WorkflowStepEnumType.MoveOut, [Validators.required, this.noticeValidation()]],
    isNonPms: false,
  });

  portfolioItems: RestPortfolioModel[] = [];
  userPropertiesList: IRadioButtonOption<number>[] = [];
  userProperties: IRadioButtonOption<number>[] | IRadioButtonOption<number>[][] = [];
  userUnits: IRadioButtonOption<number>[] = [];

  selectPropertiesLoading$: Observable<boolean>;
  unitListLoading$: Observable<boolean>;
  unitList: UnitDetails[];
  newTurnoverLoadingInProgress$: Observable<boolean>;
  systemConfigPmsName$ = this.store.select(selectTenantData).pipe(
    untilDestroyed(this),
    filter(tenantData => !!tenantData),
    take(1),
    map(tenantData => tenantData.pmses.split(',')[0])
  );
  nonPmsUnitCost$ = this.store.select(selectTenantData).pipe(
    untilDestroyed(this),
    filter(tenantData => !!tenantData),
    take(1),
    map(tenantData => tenantData.nonPmsUnitCost)
  );
  workflowStepTypes = WorkflowStepListActive;

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<NewTurnoverModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewTurnoverModalData,
    private formBuilder: FormBuilder,
    private store: Store<{}>,
    private router: Router,
    private unitNonPmsService: UnitNonPmsService,
    private timezoneService: TimezoneService,
    private modalService: ModalsService,
    private datePipe: DatePipe,
    private snackbarService: SnackbarService
  ) {
    super(cdr);

    if (data.defaultWorkflowStepType) {
      this.form.controls.startWorkflowStepType.setValue(data.defaultWorkflowStepType);
    }
  }

  noticeValidation(): ValidatorFn {
    const today = this.timezoneService.getCurrentDateOnly();
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    return (control: FormControl<WorkflowStepEnumType>): ValidationErrors | null => {
      if (!this.form?.value || control.value == WorkflowStepEnumType.Notice || today >= this.form.value.MoveOutDate) {
        return null;
      }

      return {
        customError: `NOTICE required if MOD < ${this.datePipe.transform(tomorrow, 'MM/dd')}`,
      };
    };
  }

  ngOnInit(): void {
    this.store.dispatch(resetCreateNewTurnover());

    this.selectPropertiesLoading$ = this.store.select(selectPropertiesLoading);
    this.unitListLoading$ = this.store.select(selectUnitListLoading);
    this.newTurnoverLoadingInProgress$ = this.store.select(selectCreateTurnoverLoading);

    this.form.controls.MoveOutDate.valueChanges.subscribe(() =>
      this.form.controls.startWorkflowStepType.updateValueAndValidity()
    );

    this.form.controls.isNonPms.valueChanges.subscribe(value => {
      this.form.patchValue({
        startWorkflowStepType: value ? WorkflowStepEnumType.Reno : WorkflowStepEnumType.MoveOut,
      });
      if (value) {
        this.form.controls.unitName.enable();
        this.form.controls.UnitId.disable();
        this.modalService
          .getAcceptChargesText()
          .pipe(
            switchMap(acceptChargesText =>
              this.modalService
                .openConfirmationModal({
                  cancelLabel: 'No, go back',
                  confirmColor: 'warn',
                  confirmLabel: 'I accept',
                  title: 'Accept charges?',
                  content: acceptChargesText,
                })
                .afterClosed()
            ),
            take(1),
            untilDestroyed(this),
            filter(result => result !== DialogResult.Success)
          )
          .subscribe(() => this.form.controls.isNonPms.setValue(false));
      } else {
        this.form.controls.UnitId.addValidators(Validators.required);
        this.form.controls.unitName.disable();
        this.form.controls.UnitId.enable();
      }
    });

    this.form.controls.isNonPms.setValue(this.data.isNonPms);

    this.store
      .select(selectSelectedPortfolioId)
      .pipe(
        untilDestroyed(this),
        filter(portfolioId => !!portfolioId),
        take(1)
      )
      .subscribe(portfolioId => {
        this.store.dispatch(
          loadUnitDetails({ propertyId: portfolioId, dashboardViewType: DashboardViewType.PORTFOLIO_WIDE_VIEW })
        );
      });

    combineLatest([this.store.select(selectUnitDetails), this.store.select(selectUnitList)])
      .pipe(
        filter(() => !!this.form.value.PropertyId),
        untilDestroyed(this)
      )
      .subscribe(([unitDetails, unitList]) => {
        this.userUnits = this.getUnitList(unitList, unitDetails);

        if (unitDetails) {
          this.unitList = unitDetails;
          const nonPmsUnitMaxNumber = unitDetails
            .flatMap(e => e.unitName)
            .filter(unitName => /^non-PMS unit[0-9]*$/.test(unitName))
            .map(unitName => +unitName.replace('non-PMS unit', ''))
            .reduce((max, nextValue) => (nextValue >= max ? nextValue + 1 : max), 0);

          const unitName = 'non-PMS unit' + (nonPmsUnitMaxNumber == 0 ? '' : nonPmsUnitMaxNumber);

          this.form.controls.unitName.setValue(unitName);
        }

        this.cdr.markForCheck();
      });

    this.store
      .select(selectProperties)
      .pipe(
        untilDestroyed(this),
        filter((userProperties: RestPropertyModel[]) => !!userProperties)
      )
      .subscribe((userProperties: RestPropertyModel[]) => {
        this.userPropertiesList = getPortfolioProperties(userProperties);

        if (this.data?.activePropertyIds?.length) {
          const activeProperties: IRadioButtonOption<number>[] = [];
          const otherProperties: IRadioButtonOption<number>[] = [];

          this.userPropertiesList.forEach((item: IRadioButtonOption<number>) => {
            if (this.data.activePropertyIds?.includes(item.value)) {
              activeProperties.push(item);
            } else {
              otherProperties.push(item);
            }
          });

          this.userProperties = [activeProperties, otherProperties];
        } else {
          this.userProperties = [...this.userPropertiesList];
        }

        this.cdr.detectChanges();
      });

    this.store
      .select(selectCreatedTurnover)
      .pipe(
        untilDestroyed(this),
        filter(createdTurnover => !!createdTurnover)
      )
      .subscribe(createdTurnover => {
        let successMessage: string;
        if (this.form.value.isNonPms) {
          const unitName = this.form.value.unitName;
          successMessage = `Turn created for ${unitName}`;
        } else {
          const unitName = this.userUnits.find(e => e.value === this.form?.value?.UnitId)?.label;
          const propertyName = this.userPropertiesList.find(e => e.value === this.form.value.PropertyId)?.label;
          successMessage = `Turn created for ${propertyName} - ${unitName}`;
        }
        this.store.dispatch(resetUnitList());

        this.dialogRef.close();
        this.reloadRoute().then(() =>
          this.router
            .navigate([AppRoutes.TURNOVERS, `${createdTurnover.id}`], {
              queryParamsHandling: 'merge',
            })
            .then(() => this.snackbarService.success(successMessage))
        );
      });

    this.form.controls.PropertyId.valueChanges.pipe(untilDestroyed(this)).subscribe(propertyId => {
      if (!isNil(propertyId)) {
        this.store.dispatch(loadUnitList({ propertyId }));
      }
      this.form.controls.UnitId.reset();
    });

    this.store
      .select(selectSelectedPropertyIds)
      .pipe(untilDestroyed(this), take(1), filter(Boolean))
      .subscribe(propertyIds => {
        if (this.userProperties.length) {
          if (this.data.activePropertyIds.length) {
            this.form.controls.PropertyId.setValue(this.userProperties[0][0].value);
          } else {
            this.form.controls.PropertyId.setValue(first(propertyIds));
          }
        }
        this.cdr.detectChanges();
      });

    if (!this.data.defaultWorkflowStepType) {
      this.form.controls.MoveOutDate.valueChanges.pipe(untilDestroyed(this)).subscribe(moveOutDate => {
        if (moveOutDate > this.timezoneService.getCurrentDateOnly()) {
          this.form.controls.startWorkflowStepType.setValue(WorkflowStepEnumType.Notice);
        } else {
          this.form.controls.startWorkflowStepType.setValue(WorkflowStepEnumType.MoveOut);
        }
      });
    }

    this.unitControlChanges();
  }

  cancel() {
    this.dialogRef.close();
  }

  newTurnover() {
    if (this.form.invalid) {
      this.showValidationError = true;
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    const unitId$ = this.form.value.isNonPms
      ? this.unitNonPmsService.add({
          propertyId: this.form.value.PropertyId,
          name: this.form.value.unitName,
        })
      : of(this.form.value.UnitId);

    unitId$.subscribe(unitId => {
      const postTurnoverModel: PostTurnoverModel = {
        unitId: unitId,
        propertyId: this.form.value.PropertyId,
        dateMoveOut: this.form.value.MoveOutDate,
        startWorkflowStepType: this.form.value.startWorkflowStepType,
      };

      this.store.dispatch(createNewTurnover({ postTurnoverModel }));
    });
  }

  private getUnitList(unitList: RestUnitSummaryModel[] = [], unitDetails: UnitDetails[]): IRadioButtonOption<number>[] {
    if (!unitList?.length) {
      return [];
    }
    let unitDetailsMap = new Map();
    if (unitDetails) {
      unitDetailsMap = new Map(
        unitDetails.map(el => [el.unitId, { isInTurnover: el.isInTurnover, workflowStep: el.workflowStep }])
      );
    }

    return unitList
      .filter(u => !u.doNotAutoCreateTurns)
      .map<IRadioButtonOption<number>>(item => {
        return {
          value: item.id,
          label: this.getLabel(unitDetailsMap, item),
        };
      });
  }

  private getLabel(
    unitDetailsMap: Map<number, { isInTurnover: boolean; workflowStep: string }>,
    item: RestUnitSummaryModel
  ): string {
    return unitDetailsMap.has(item.id) && unitDetailsMap.get(item.id).isInTurnover
      ? `${item.name} - ${unitDetailsMap.get(item.id).workflowStep}`
      : item.name;
  }

  private reloadRoute(): Promise<boolean> {
    return this.router.navigateByUrl('/', { skipLocationChange: true });
  }

  private unitControlChanges(): void {
    this.form.controls.UnitId.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(el => !!el),
        untilDestroyed(this)
      )
      .subscribe(unitId => {
        const unit = this.unitList.find(el => el.unitId === unitId);
        if (!unit) return;

        if (unit.isInTurnover) {
          this.snackbarService.error(
            `${unit?.unitName} is an active turn in ${unit?.workflowStep}. Please select another unit`
          );
          this.form.controls.UnitId.setValue(null, { emitEvent: false });
          this.form.updateValueAndValidity();
          return;
        }
        this.snackbarService.dismiss();
      });
  }

  get allowSearchUserProperties(): boolean {
    const secondElement = this.userProperties?.[1];
    return Array.isArray(secondElement) && secondElement.length > 7;
  }
}
