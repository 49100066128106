import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { firstValueFrom } from 'rxjs';

import { TemplateEditorEmojiContent } from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { AnimatedEmojiService } from '@main-application/shared/template-editor-dialog/services/animated-emoji.service';

@Component({
  selector: 'app-template-editor-animation',
  templateUrl: './template-editor-animation.component.html',
  styleUrls: ['./template-editor-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogAnimationComponent implements OnInit {
  options: AnimationOptions;

  @Input() set animationItem(value) {
    this._animationItem = value;
    if (this.initialized) {
      this.updateConfig().then();
    }
  }

  get animationItem() {
    return this._animationItem;
  }

  private _animationItem: string;

  @Input() set assetRelativeUrl(value) {
    this._assetRelativeUrl = value;
    if (this.initialized) {
      this.updateConfig().then();
    }
  }

  get assetRelativeUrl() {
    return this._assetRelativeUrl;
  }

  private _assetRelativeUrl: string;

  @Input() attrWidth = '32px';
  @Input() attrHeight = '32px';
  @Input() loop = true;

  @Input() set autoplay(value) {
    this._autoplay = value;
    if (this.initialized) {
      if (value) {
        this.animationItemCreated.play();
      } else {
        this.animationItemCreated.stop();
      }
    }
  }

  get autoplay() {
    return this._autoplay;
  }

  private _autoplay = true;
  private initialized = false;
  emojis: TemplateEditorEmojiContent[];
  private animationItemCreated: AnimationItem;

  constructor(
    private animatedEmojiService: AnimatedEmojiService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.updateConfig().then();
  }

  async updateConfig() {
    this.options = await this.getConfig();
    this.cdr.markForCheck();
  }

  private async getConfig(): Promise<AnimationOptions> {
    if (this.animationItem) {
      this.emojis = await firstValueFrom(this.animatedEmojiService.getEmojiContent());
      const animationData = await firstValueFrom(this.animatedEmojiService.loadEmoji(this.getConfigPath()));
      return {
        loop: this.loop,
        animationData,
        autoplay: this.autoplay,
      };
    } else if (this.assetRelativeUrl) {
      return { loop: this.loop, path: `/${this.assetRelativeUrl}`, autoplay: this.autoplay };
    }
  }

  private getConfigPath(): string {
    const animation = this.emojis.find(emoji => emoji.emojiCode === this.animationItem);
    return `/${animation?.assetRelativeUrl}`;
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItemCreated = animationItem;
    this.initialized = true;
  }

  stop(): void {
    if (this.autoplay) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.animationItemCreated?.stop();
    });
  }

  play(): void {
    if (this.autoplay) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.animationItemCreated?.play();
    });
  }
}
