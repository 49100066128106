<div class="confirmation-modal-container display-flex flex-column">
  <h2 mat-dialog-title class="modal-header">Add vendor {{ data.addOnlyVendor ? '' : 'and employee' }}</h2>
  <mat-dialog-content *ngIf="form" class="display-flex flex-column grid-gap-16">
    <ng-container *ngIf="!data.addOnlyVendor">
      <app-divider [text]="'Vendor'"></app-divider>
    </ng-container>
    <div class="pl-8">
      <app-input
        class="w-100"
        [attrLabel]="'Company'"
        [contentCss]="'display-flex flex-column fill'"
        [formControl]="company"
        [showValidationError]="formSubmitted"
        [attrPlaceholder]="'enter company name'"
        [labelRequired]="true"></app-input>
    </div>
    <ng-container *ngIf="!data.addOnlyVendor">
      <app-divider [text]="'Employee'"></app-divider>
      <div class="pl-8">
        <div class="display-flex grid-gap-16">
          <app-input
            class="w-50"
            [attrLabel]="'First name'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="firstName"
            [showValidationError]="formSubmitted"
            [attrPlaceholder]="'enter first name'"
            [labelRequired]="true"></app-input>
          <app-input
            class="w-50"
            [attrLabel]="'Last name'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="lastName"
            [showValidationError]="formSubmitted"
            [attrPlaceholder]="'enter last name'"
            [labelRequired]="true"></app-input>
        </div>
        <div class="display-flex flex-fill grid-gap-16">
          <app-input
            class="w-50"
            [attrLabel]="'Phone number'"
            [contentCss]="'display-flex flex-column fill'"
            [attrMask]="'(000) 000-0000'"
            [formControl]="phoneNumber"
            [showValidationError]="formSubmitted"
            [attrPlaceholder]="'enter phone number'"></app-input>
          <app-radio-button-dropdown
            class="w-50 language-dropdown"
            [label]="'Native language'"
            [attrAppendTo]="'body'"
            [allowClear]="false"
            [allowSearch]="false"
            [showValidationError]="formSubmitted"
            [items]="nativeLanguages"
            [formControl]="nativeLanguage"></app-radio-button-dropdown>
        </div>
        <div class="display-flex flex-fill grid-gap-16">
          <app-input
            class="w-100"
            [attrLabel]="'Email'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="email"
            [showValidationError]="formSubmitted"
            [attrPlaceholder]="'enter email'"
            [labelRequired]="true"></app-input>

          <app-select-tree
            *ngIf="data.withPortfolioPropertiesSelector"
            class="w-100"
            [label]="'Portfolio/Properties'"
            (selectedRoots)="selectedRootsChange($event)"
            [nodes]="allProperties$ | async"
            [selectedNodes]="allProperties$ | async"
            [warningMessage]="warningMessage"
            [showValidationError]="formSubmitted"></app-select-tree>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-space-between">
    <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="inProgress$ | async" (click)="send()">
      <div class="display-flex align-items-center grid-gap-8">
        {{ data.onlyAddBtn ? 'Add' : ' Add and Assign' }}
        <mat-spinner *ngIf="inProgress$ | async" color="accent" diameter="20"></mat-spinner>
      </div>
    </button>
  </mat-dialog-actions>
</div>
