import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, take } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppRoutes } from '@shared/constants/app-routes.const';
import { AuthService } from '@shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private oidcService: OidcSecurityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const currentUrl = window.location.href;

    if (this.isAuthenticationInProgress()) {
      return of(true);
    }

    return this.authService.isAuthenticated().pipe(
      mergeMap(result => {
        if (result) {
          return of(true);
        }

        return this.oidcService.checkAuth(this.authService.getConfigId()).pipe(
          take(1),
          mergeMap(() =>
            this.oidcService.getAuthenticationResult(this.authService.getConfigId()).pipe(
              map(accessToken => {
                if (accessToken) {
                  return true;
                }

                const loginUrlTree = this.router.parseUrl(AppRoutes.LOGIN);
                loginUrlTree.queryParams['returnUrl'] = currentUrl;
                return loginUrlTree;
              })
            )
          )
        );
      })
    );
  }

  private isAuthenticationInProgress() {
    return (
      this.location.path() === AppRoutes.LOGIN ||
      this.location.path() === AppRoutes.ACCEPT_TOKEN ||
      this.location.path() === AppRoutes.ROOT ||
      !!window.location.hash
    );
  }
}
