import { Pipe, PipeTransform } from '@angular/core';

import { TimeInStep } from '@shared/interfaces/time-in-step.interface';

@Pipe({
  name: 'lostRevenue',
})
export class LostRevenuePipe implements PipeTransform {
  transform(dayInStep: TimeInStep, rent: number): number {
    if (!rent) return 0;

    const pricePerDay = rent / 30;
    const pricePerHour = pricePerDay / 24;
    return parseFloat(((dayInStep.days || 0) * pricePerDay + (dayInStep.hours || 0) * pricePerHour).toFixed(0));
  }
}
