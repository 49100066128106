import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PipesModule } from '@shared/pipes/pipes.module';
import { ChartService } from '@ui-components/components/chart/services/chart.service';

import { ChartComponent } from './chart.component';

@NgModule({
  declarations: [ChartComponent],
  exports: [ChartComponent],
  imports: [CommonModule, PipesModule, MatProgressSpinnerModule],
  providers: [ChartService],
})
export class ChartModule {}
