import * as moment from 'moment';
import 'moment-timezone';

import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';

export class TimezoneHelper {
  /**
   * Remove timezone & time part before sending value to server
   */
  static removeTimeZone(date: Date | string): Date {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(date);
    return new Date(+convertedDate - convertedDate.getTimezoneOffset() * 60 * 1000);
  }

  /**
   * Add timezone for data which came from server, also remove timepart if it was
   */
  static addTimeZoneAndKeepOnlyDatePart(date: Date | string): Date {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(date);
    const correctedDate = new Date(+convertedDate + convertedDate.getTimezoneOffset() * 60 * 1000);
    return new Date(correctedDate.toDateString());
  }

  /**
   * Change timezone for data that was just received from server
   *
   * @param date - date
   * @param newTimezone - timezone
   * @param oldTimezone - timezone
   */
  static replaceTimezoneToClient(
    date: Date | string,
    newTimezone: RestTimezone = null,
    oldTimezone: RestTimezone = null
  ): Date {
    let newTimezoneOffset = 0,
      oldTimezoneOffset = -new Date(date).getTimezoneOffset();
    if (newTimezone) {
      newTimezoneOffset = TimezoneHelper.getUiTimezoneOffset(date, newTimezone);
    }
    if (oldTimezone) {
      oldTimezoneOffset = TimezoneHelper.getUiTimezoneOffset(date, oldTimezone);
    }
    return TimezoneHelper.replaceTimezone(date, newTimezoneOffset, oldTimezoneOffset);
  }

  /**
   * Change timezone for data before sending to server
   *
   * @param date - date
   * @param oldTimezone - timezone
   * @param newTimezone - timezone
   */
  static replaceTimezoneToServer(
    date: Date | string,
    oldTimezone: RestTimezone = null,
    newTimezone: RestTimezone = null
  ): Date {
    let oldTimezoneOffset = 0,
      newTimezoneOffset = -new Date(date).getTimezoneOffset();

    if (oldTimezone) {
      oldTimezoneOffset = TimezoneHelper.getUiTimezoneOffset(date, oldTimezone);
    }
    if (newTimezone) {
      newTimezoneOffset = TimezoneHelper.getUiTimezoneOffset(date, newTimezone);
    }
    return TimezoneHelper.replaceTimezone(date, newTimezoneOffset, oldTimezoneOffset);
  }

  /**
   * Change timezone for data for displaying
   *
   * @param date - date
   * @param newTimezone - timezone
   * @param oldTimezone - timezone
   */
  private static replaceTimezone(date: Date | string, newTimezone: number, oldTimezone: number): Date {
    if (!date) {
      return null;
    }
    // console.log(date, ' ', newTimezone, oldTimezone);
    const convertedDate = new Date(date);
    return new Date(+convertedDate + (newTimezone - oldTimezone) * 60 * 1000);
  }

  private static getUiTimezoneOffset(date: Date | string, timezone: RestTimezone): number {
    const m = moment.tz(date, timezone.uiName);
    return m.utcOffset();
  }
}
