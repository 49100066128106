import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';

import { TextOverflowCheckDirective } from '../../directives/text-overflow-check.directive';
import { ISimpleChanges } from '../../types/simple-changes-typed.type';

@Component({
  selector: 'app-fade-text',
  templateUrl: './fade-text.component.html',
  styleUrls: ['./fade-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextOverflowCheckDirective, NgIf, TooltipDirectiveModule],
})
export class FadeTextComponent implements OnChanges {
  @Input() text!: string;
  @Input() tooltip?: string;
  @Input() maxLines = 1;
  @Input() isEnabled = true;
  @Input() isInline = false;

  isOverflowed = new BehaviorSubject(false); // TODO refactor to signals then Angular version > 16
  isTooltipMode = new BehaviorSubject(false); // TODO refactor to signals then Angular version > 16

  ngOnChanges(changes: ISimpleChanges<FadeTextComponent>): void {
    if ('isEnabled' in changes) {
      const isEnabled = changes.isEnabled.currentValue;
      if (!isEnabled) {
        this.isTooltipMode.next(false);
      }
    }
    if ('tooltip' in changes) {
      const tooltipValue = changes.tooltip.currentValue;
      if (tooltipValue) {
        this.isTooltipMode.next(true);
      }
    }
  }

  enableTooltipMode(): void {
    this.isOverflowed.next(true);
    this.isTooltipMode.next(true);
  }
}
