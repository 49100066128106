export function getNextNumberItemName<T extends object>(
  name: string,
  array: T[],
  f: (e: T) => string = e => (e as any).name
) {
  const maxNumber = array
    .filter(e => new RegExp(`^${name}[0-9]*$`).test(f(e)))
    .map(e => +f(e).replace(name, ''))
    .reduce((prev, max) => (prev > max ? prev : max), 0);

  return `${name}${maxNumber + 1}`;
}
