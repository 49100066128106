import { createAction, props } from '@ngrx/store';

export const initPropertySelector = createAction(
  '[PropertySelector] initPropertySelector',
  props<{
    userId: number;
    tenantId: number;
  }>()
);

export const setSelectedPortfolioId = createAction(
  '[PropertySelector] Selected portfolio id',
  props<{
    portfolioId: number | null;
  }>()
);

export const setSelectedPropertyIds = createAction(
  '[PropertySelector] Selected property ids',
  props<{
    propertyIds: number[] | null;
    allPropertiesSelected: boolean;
  }>()
);

export const setJoinedProperties = createAction('[PropertySelector] join properties', props<{ state: boolean }>());
