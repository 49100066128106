<div class="confirmation-modal-container display-flex flex-column">
  <h2 mat-dialog-title class="modal-header">
    <div class="subtitle mr-4" [ngClass]="data?.titleCss">{{ data?.title }}</div>
  </h2>
  <mat-dialog-content class="display-flex flex-column grid-gap-16">
    <div *ngIf="!data?.contentTemplate; else customTemplate" class="display-flex grid-gap-16">
      {{ data?.content }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-space-between align-items-center">
    <div>
      <mat-checkbox color="primary" *ngIf="data.doNotShowAgain" [formControl]="doNotShowAgain">
        {{ doNotShowAgainMessage }}
      </mat-checkbox>
    </div>
    <div class="display-flex">
      <button class="flex-1 modal-button" mat-stroked-button [color]="cancelColor" (click)="cancel()">
        <div class="display-flex align-items-center justify-content-center">
          {{ cancelLabel }}
        </div>
      </button>
      <button class="flex-1 modal-button" mat-raised-button [color]="confirmColor" (click)="send()">
        <div class="display-flex align-items-center justify-content-center">
          <span>{{ confirmLabel }}</span>
        </div>
      </button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #customTemplate>
  <ng-container *ngTemplateOutlet="data.contentTemplate; context: data.contentData"></ng-container>
</ng-template>
