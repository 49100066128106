<div
  class="chart-container display-flex grid-gap-32 align-items-end position-relative"
  [ngClass]="[
    containerCss
      ? containerCss
      : itemCount
      ? displayLegend
        ? 'justify-content-space-between'
        : 'justify-content-end'
      : 'justify-content-center'
  ]">
  <ng-container *ngIf="displayLegend && itemCount" [ngTemplateOutlet]="legendTemplate"></ng-container>
  <ng-container *ngIf="itemCount" [ngTemplateOutlet]="chartTemplate"></ng-container>
  <ng-container *ngIf="!itemCount" [ngTemplateOutlet]="emptyChartTemplate"></ng-container>
</div>

<ng-template #chartTemplate>
  <div [class]="!displayLegendBottom ? 'chart-content' : 'chart-content-bottom'">
    <canvas
      [ngClass]="[!itemCount ? 'display-none' : '']"
      [style.max-width]="chartMaxWidth ? chartMaxWidth : 'none'"
      #canvas
      [id]="id"></canvas>
    <div
      class="display-flex flex-column align-items-center justify-content-center chart-content-legend-inside"
      *ngIf="displayLegendInside">
      <h3>{{ activeLegend?.label }}</h3>
      <span>{{ activeLegend?.value }}</span>
    </div>
  </div>
</ng-template>

<ng-template #legendTemplate>
  <div [class]="!displayLegendBottom ? 'legend-wrapper' : 'legend-wrapper-bottom'">
    <div
      class="display-flex grid-gap-8 align-items-center legend-items"
      [ngClass]="legendCss"
      (mouseover)="showActiveElement(index)"
      (mouseout)="reset()"
      (click)="changeActiveSelection(item)"
      *ngFor="let item of _chartDataList; let index = index">
      <div class="assigned-color" [style.background-color]="item.backgroundColor"></div>
      <div class="legend-label">{{ item.label }}</div>
      <div *ngIf="!displayPercentageInLegend && !displayLegendInside">({{ item.value }})</div>
      <div *ngIf="displayPercentageInLegend">{{ item.value | getPercentageValue: dataSum }}</div>
    </div>
  </div>
</ng-template>

<ng-template #emptyChartTemplate>
  <ng-container [ngTemplateOutlet]="emptyTemplate ? emptyTemplate : predefinedEmptyChartTemplate"></ng-container>
</ng-template>

<ng-template #predefinedEmptyChartTemplate>
  <ng-container [ngSwitch]="chartType">
    <ng-container *ngSwitchCase="ChartTypes.PIE" [ngTemplateOutlet]="emptyChartPieTemplate"></ng-container>
    <ng-container *ngSwitchCase="ChartTypes.DOUGHNUT" [ngTemplateOutlet]="emptyChartDoughnutTemplate"></ng-container>
    <ng-container *ngSwitchCase="ChartTypes.BAR" [ngTemplateOutlet]="emptyChartBarTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #emptyChartPieTemplate>
  <div class="display-flex grid-gap-20 align-items-center justify-content-start empty-chart-container">
    <div class="empty-chart"></div>
    <div class="body-small-bold text-color gray nowrap">{{ emptyChartLabel }}</div>
  </div>
</ng-template>

<ng-template #emptyChartDoughnutTemplate>
  <div class="display-flex grid-gap-20 align-items-center justify-content-start empty-chart-container">
    <div class="empty-chart display-flex align-items-center justify-content-center">
      <div class="empty-chart inner display-flex align-items-center justify-content-center">
        <div class="body-small-bold text-color gray text-centered">{{ emptyChartLabel }}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyChartBarTemplate>
  <div class="display-flex grid-gap-20 align-items-center justify-content-start empty-chart-container">
    <div class="body-small-bold text-color gray nowrap">{{ emptyChartLabel }}</div>
  </div>
</ng-template>
