import { createAction, props } from '@ngrx/store';

import { GeneralRoleModel } from '@shared/enums/general-role-model';
import { GeneralRole } from '@shared/interfaces/general-role';
import { GeneralRoleToPermissionLevel } from '@shared/interfaces/general-role-to-permission-level';
import { PermissionLevel } from '@shared/interfaces/permission-level';
import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserToGeneralRoleMapping } from '@shared/interfaces/user-to-general-role-mapping';
import { UserToGeneralRoleModel } from '@shared/interfaces/user-to-general-role-model';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import {
  InviteResidentUserModel,
  InviteRestUserModel,
  RestUserModel,
  UpdateResidentUserModel,
  UpdateRestUserModel,
  UserFilter,
} from '@shared/interfaces/user.interface';

export const loadAllUsers = createAction('[Administration] Load all users', props<{ filter?: UserFilter }>());

export const loadPortfolioUsersSucceeded = createAction(
  '[Administration] loadPortfolioUsersSucceeded succeeded',
  props<{ activeUsers: RestUserModel[]; inactiveUsers: RestUserModel[] }>()
);

export const loadPortfolioUsers = createAction('[Administration] loadPortfolioUsers', props<{ portfolioId: number }>());

export const loadAllUsersSucceeded = createAction(
  '[Administration] Load all users succeeded',
  props<{ activeUsers: RestUserModel[]; inactiveUsers: RestUserModel[]; filter: UserFilter }>()
);

export const loadAllUsersFailed = createAction(
  '[Administration] Load all users failed',
  props<{ users: RestUserModel[] }>()
);

export const loadPropertyUsers = createAction(
  '[Administration] Load property users',
  props<{ propertyId: number; userTypes?: number[] }>()
);

export const loadPropertyUsersSucceeded = createAction(
  '[Administration] Load property users succeeded',
  props<{ activePropertyUsers: RestUserModel[]; inactivePropertyUsers: RestUserModel[] }>()
);
export const loadPropertyUsersFailed = createAction('[Administration] Load property users failed');

export const clearAllUsers = createAction('[Administration] Clear all users');

export const loadAllPropertyRoles = createAction('[Administration] Load all properties roles');

export const clearAllPropertiesRoles = createAction('[Administration] Clear all properties roles');

export const loadAllPropertiesRolesSucceeded = createAction(
  '[Administration] Load all properties roles succeeded',
  props<{ result: any }>()
);

export const loadAllUserToPropertyMappings = createAction('[Administration] Load all user to property mappings');

export const clearAllUserToPropertyMappings = createAction('[Administration] Clear all user to property mappings');

export const loadAllUserToProperyMappingsSucceeded = createAction(
  '[Administration] Load all user to property mappings succeeded',
  props<{ userToPropertyMapping: UserToPropertyMapping[] }>()
);

export const loadAllPermissionLevels = createAction('[Administration] Load all permission levels');

export const loadAllPermissionLevelsSucceeded = createAction(
  '[Administration] Load all permission levels succeeded',
  props<{ result: PermissionLevel[] }>()
);

export const loadAllGeneralRolesToPermissionMapping = createAction(
  '[Administration] Load all general roles to permission mapping'
);

export const loadAllGeneralRolesToPermissionMappingSucceeded = createAction(
  '[Administration] Load all general roles to permission mapping succeeded',
  props<{ result: GeneralRoleToPermissionLevel[] }>()
);

export const loadUserToRoleMapping = createAction('[Administration] Load user to role mapping');

export const loadUserToRoleMappingSucceeded = createAction(
  '[Administration] Load user to general role mapping succeeded',
  props<{ userToGeneralRole: UserToGeneralRoleMapping[] }>()
);

export const clearUserToRoleMapping = createAction('[Administration] Clear user to role mapping');

export const setSelectedUsers = createAction(
  '[Administration] Set selected users',
  props<{ users: UserWithGeneralRole[] }>()
);

export const loadAllGeneralRoles = createAction('[Administration] Load all general roles');

export const loadAllGeneralRolesSucceeded = createAction(
  '[Administration] Load all general roles succeeded',
  props<{ generalRoles: GeneralRole[] }>()
);

export const clearAllGeneralRoles = createAction('[Administration] Clear all general roles');

export const setAllUsersCombinedWithGeneralRoles = createAction(
  '[Administration] Set all users combined with general roles',
  props<{ usersWithGeneralRoles: UserWithGeneralRole[] }>()
);

export const loadPropertiesStructure = createAction('[Administration] Load properties structure');

export const clearPropertiesStructure = createAction('[Administration] Clear all properties structure');

export const loadPropertiesStructureSucceeded = createAction(
  '[Administration] Load properties structure succeeded',
  props<{ propertiesStructure: PropertyBasicInfo[] }>()
);

export const startAddNewUser = createAction('[Administration] Start adding new user', props<{ user: RestUserModel }>());

export const addingNewUserSucceeded = createAction(
  '[Administration] Adding new user succeeded',
  props<{ user: RestUserModel }>()
);

export const addingNewUserFailed = createAction('[Administration] Adding new user failed');

export const clearAddedUser = createAction('[Administration] clear Added User');

/**
 * assign user into property
 */
export const assignUserToProperty = createAction(
  '[Administration] assign User to Property',
  props<{ userToPropertyRoleList: UserToPropertyMapping[] }>()
);
export const assignUserToPropertySuccess = createAction('[Administration] assign User to Property Success');
export const assignUserToPropertyError = createAction('[Administration] assign User to Property Error');

/**
 * removing property assigned user
 */
export const removingPropertyAssignedUser = createAction(
  '[Administration] removing property assigned user',
  props<{ deleteUserToPropertyMappings: number[] }>()
);
export const removingPropertyAssignedUserSuccess = createAction(
  '[Administration] removing property assigned user success'
);
export const removingPropertyAssignedUserError = createAction('[Administration] removing property assigned user error');

export const requestFailure = createAction('[Administration] Request failure', props<any>());

export const saveUsersRolesAndUnits = createAction(
  '[Administration] Start saving users roles and units',
  props<{ userToGeneralRole: UserToGeneralRoleModel[]; userToPropertyRole: UserToPropertyMapping[] }>()
);

export const saveUsersRolesAndUnitsSucceeded = createAction('[Administration] Saving users roles and units succeeded');

export const selectGeneralRoleToEdit = createAction(
  '[Administration] Select general role to edit',
  props<{ generalRole: GeneralRole }>()
);

export const startDeleteGeneralRole = createAction('[Administration] Start delete general role', props<GeneralRole>());

export const deleteGeneralRoleSucceeded = createAction(
  '[Administration] Delete general role succeded',
  props<GeneralRole>()
);

export const deleteGeneralRoleFailed = createAction('[Administration] Delete general role failed', props<any>());

export const clearSelectedGeneralRole = createAction('[Administration] Clear selected general role');

export const startSavingGeneralRole = createAction(
  '[Administration] Start saving general role',
  props<{ generalRole: GeneralRoleModel }>()
);

export const savingGeneralRoleSucceeded = createAction('[Administration] Saving general role succeded');

export const savingGeneralRoleFailed = createAction('[Administration] Saving general role failed');

export const startUpdatingGeneralRole = createAction(
  '[Administration] Start updating general role',
  props<{ generalRole: GeneralRoleModel }>()
);

export const updatingGeneralRoleSucceeded = createAction('[Administration] Updating general role succeded');

export const updatingGeneralRoleFailed = createAction('[Administration] Updating general role failed');

export const setGeneralRoleRadioList = createAction(
  '[Administration] set General Role Radio List',
  props<{ generalRoleRadioList: IRadioButtonOption<number>[] }>()
);

/**
 * invite new user
 */
export const inviteNewUser = createAction(
  '[Administration] invite new user',
  props<{
    user: InviteRestUserModel;
    roleId: number;
    selectedProperty: number[];
  }>()
);
export const inviteNewUserSuccess = createAction(
  '[Administration] invite new user success',
  props<{ user: RestUserModel }>()
);
export const inviteNewUserError = createAction('[Administration] invite new user error');

/**
 * Update user data
 */
export const updateUserData = createAction(
  '[Administration] update User Data',
  props<{
    user: UpdateRestUserModel;
    roleId: number;
    userToPropertyRoleList: UserToPropertyMapping[];
    deleteUserToPropertyMappings: number[];
  }>()
);
export const updateUserDataSuccess = createAction('[Administration] update User Data success');
export const updateUserDataError = createAction('[Administration] update User Data error');

/**
 * save User To General Role
 */
export const saveUserToGeneralRole = createAction(
  '[Administration] save User To General Role',
  props<{ usersToGeneralRoleList: UserToGeneralRoleModel[] }>()
);
export const saveUserToGeneralRoleSuccess = createAction('[Administration] save User To General Role success');
export const saveUserToGeneralRoleError = createAction('[Administration] save User To General Role error');

export const clearUserPreviewData = createAction('[Administration] clear User Preview Data');

export const removeUser = createAction('[Administration] remove user', props<{ user: UserWithGeneralRole }>());
export const reactivateUser = createAction('[Administration] reactivate user', props<{ user: UserWithGeneralRole }>());
export const removeUserSucceeded = createAction('[Administration] remove user succeeded');
export const reactivateUserSucceeded = createAction('[Administration] reactivate user succeeded');
export const removeUserFailed = createAction('[Administration] remove user failed');

/**
 * invite new resident user
 */
export const inviteNewResidentUser = createAction(
  '[Administration] invite new resident user',
  props<{ user: InviteResidentUserModel; roleId: number }>()
);
export const inviteNewResidentUserSuccess = createAction(
  '[Administration] invite new resident user success',
  props<{ user: RestUserModel }>()
);
export const inviteNewResidentUserError = createAction('[Administration] invite new resident user error');

/**
 * Update resident user data
 */
export const updateResidentUserData = createAction(
  '[Administration] update resident user data',
  props<{ user: UpdateResidentUserModel; roleId: number }>()
);
export const updateResidentUserDataSuccess = createAction('[Administration] update resident user data success');
export const updateResidentUserDataError = createAction('[Administration] update resident user data error');
