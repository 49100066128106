import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[appScrollElement]' })
export class ScrollElementDirective {
  @Output() scrollChanged = new EventEmitter<Event>();

  @HostListener('scroll', ['$event'])
  onScroll($event: Event) {
    this.scrollChanged.next($event);
  }
}
