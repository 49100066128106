import { NgIfContext } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-skeleton-modals',
  templateUrl: './skeleton-modals.component.html',
  styleUrls: ['./skeleton-modals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonModalsComponent {
  @ViewChild('skeletonTitle') skeletonTitle: TemplateRef<NgIfContext<boolean>>;
  @ViewChild('skeletonContent') skeletonContent: TemplateRef<NgIfContext<boolean>>;
  @ViewChild('skeletonActions') skeletonActions: TemplateRef<NgIfContext<boolean>>;
}
