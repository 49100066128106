import { Injectable } from '@angular/core';

import { RestapiService } from '@app/services/restapi.service';
import { NonPmsModelModel } from '@shared/interfaces/turnover.interface';

@Injectable({ providedIn: 'root' })
export class UnitNonPmsService {
  constructor(private restApiService: RestapiService) {}

  add(data: NonPmsModelModel) {
    return this.restApiService.create<number>(`Units/nonPms`, data);
  }

  update(data: NonPmsModelModel) {
    return this.restApiService.update<NonPmsModelModel>(`Units/nonPms`, data);
  }

  delete(id: number) {
    return this.restApiService.delete<NonPmsModelModel>(`Units/nonPms/${id}`);
  }
}
