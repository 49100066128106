<div class="select-filter-container" *ngIf="selectFiltersConfig?.length">
  <div [matMenuTriggerFor]="menu">
    <div *ngIf="selectedFilter" class="selected-value">
      <span>{{ showBrackets ? '(' + selectedFilter?.label + ')' : selectedFilter?.label }}</span>

      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </div>

  <mat-menu #menu="matMenu" class="menu-with-backdrop">
    <ng-container *ngFor="let item of selectFiltersConfig; let index = index">
      <button *ngIf="item.id !== selectedFilter.id" (click)="selectedTab(item)" mat-menu-item>
        {{ item.label }}
      </button>
    </ng-container>
  </mat-menu>
</div>
