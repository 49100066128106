<div
  class="chart-card display-flex h-100"
  [style.box-shadow]="chartCardBoxShadow"
  [style.background-color]="chartCardBackgroundColor">
  <div
    class="chart-card-container grid-gap-16 display-flex flex-column"
    *ngIf="!isLoading; else spinner"
    [style.padding]="chartCardPadding">
    <div class="display-flex grid-gap-8">
      <div class="chart-icon" *ngIf="chartIcon">
        <app-icon [svg]="chartIcon" [iconHeight]="24" [iconWidth]="24"></app-icon>
      </div>
      <div class="subtitle-column w-100">
        <div class="display-flex align-items-start grid-gap-8 justify-content-space-between">
          <div *ngIf="title || subTitle">
            <div class="title" *ngIf="title">{{ title }}</div>
            <div class="subtitle" [style.color]="subTitleColor" *ngIf="subTitle">{{ subTitle }}</div>
          </div>
          <ng-container *ngIf="customDescriptionTemplate" [ngTemplateOutlet]="customDescriptionTemplate"></ng-container>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div [ngClass]="chartContainerCss" *ngIf="showChartColumn">
      <ng-content select="[chart]"></ng-content>
    </div>
  </div>
</div>

<ng-template #spinner>
  <app-skeleton-loader class="w-100" [loaderType]="LoaderType.PieChart"></app-skeleton-loader>
</ng-template>
