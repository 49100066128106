<div class="display-flex flex-column">
  <h2 mat-dialog-title class="modal-header">{{ isNewPunch ? 'Create New Punch' : 'Edit Punch' }}</h2>
  <mat-dialog-content>
    <form [formGroup]="form" *ngIf="form">
      <div class="display-flex grid-gap-32">
        <div class="form-container display-flex flex-column grid-gap-8 flex-1">
          <div class="display-flex grid-gap-8 flex-column">
            <div class="body-small-bold">Unit</div>
            <div class="body-small">{{ turnoverUnitName$ | async }}</div>
          </div>

          <div class="display-flex grid-gap-8">
            <app-date-dropdown
              label="Due date"
              [labelRequired]="true"
              [displayMask]="'MMMM d'"
              [formControl]="dueDate"
              [attrDisable]="isCompleted || !isNewPunch"
              containerCss="display-flex flex-column"></app-date-dropdown>
            <app-radio-button-dropdown
              label="Priority"
              [allowClear]="false"
              [labelRequired]="true"
              [attrDisable]="isCompleted"
              [autoSortList]="false"
              [items]="priorityItems"
              [formControl]="priority"></app-radio-button-dropdown>
          </div>
          <app-input
            attrLabel="Title"
            [hidden]="true"
            [attrDisable]="isCompleted || !isNewPunch"
            [formControl]="title"
            contentCss="display-flex flex-column"
            [displayCounter]="true"
            [maxCounter]="50"></app-input>
          <app-input-area
            class="w-100"
            [label]="'Description'"
            [attrDisable]="isCompleted || !isNewPunch"
            [formControl]="description"
            [labelCss]="'body-small-bold nowrap'"
            [containerCss]="'fill body-small'"
            [attrPlaceholder]="'Description'"
            [displayCounter]="true"
            [maxCounter]="500"
            [showValidationError]="description.touched"></app-input-area>
          <app-attachment
            [lockFields]="isCompleted"
            [labelRequired]="false"
            [isEmpty]="!hasAttachments()"
            (attachmentItemEvent)="addAttachment($event)"
            [cleanInputAttachment]="cleanInputAttachment"
            [showValidationError]="attachment.touched"></app-attachment>
        </div>
        <div>
          <app-attachments-list
            [showEmpty]="true"
            [attachments]="allAttachments | turnoverAttachment: TicketAttachmentType.Attachment"
            (removeAttachment)="deletePunchAttachment($event)"
            [lockFields]="isCompleted"></app-attachments-list>

          <app-attachment-item
            *ngIf="newAttachment"
            [lockFields]="isCompleted"
            [newAttachmentItem]="newAttachment"
            (deleteEvent)="deleteNewPunchAttachment()"></app-attachment-item>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="error" class="modal-button" (click)="cancel()">Cancel</button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="inProgress || isCompleted"
      class="modal-button"
      (click)="send()">
      <div class="display-flex align-items-center grid-gap-4">
        <span>{{ isNewPunch ? 'Create and go to PUNCH' : 'Edit' }}</span>
        <mat-spinner *ngIf="inProgress" color="accent" diameter="20"></mat-spinner>
      </div>
    </button>
  </mat-dialog-actions>
</div>
