import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';

import { InputErrorModule } from '@app/modules/ui-components/components/input-error/input-error.module';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';

import { InputTypeFileComponent } from './input-type-file.component';

@NgModule({
  declarations: [InputTypeFileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMaskModule,
    IconComponent,
    InputErrorModule,
    MatButtonModule,
    PipesModule,
    TooltipDirectiveModule,
    StopPropagationDirective,
  ],
  exports: [InputTypeFileComponent],
})
export class InputTypeFileModule {}
