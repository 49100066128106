import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';

@Pipe({
  name: 'todayDate',
})
export class TodayDatePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  datePipe = new DatePipe('en-US');

  transform(value: string | Date, format = 'MM/dd/yy', showToday = true): string {
    if (!value) {
      return '';
    }

    const dateValue = new Date(value);
    return isToday(dateValue, this.timezoneService.getCurrentDate()) && showToday
      ? `Today`
      : this.datePipe.transform(dateValue, format);
  }
}

function isToday(someDate, today) {
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}
