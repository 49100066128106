<div *ngIf="chartData">
  <app-chart-card
    class="w-100"
    [chartContainerCss]="'grid-gap-10'"
    [chartCardBoxShadow]="'none'"
    [chartCardBackgroundColor]="eColorPalette.cGray6"
    [chartCardPadding]="'0 0 0 16px'">
    <div class="chart-container-new">
      <div class="chart-wrapper">
        <app-chart
          [chartDataList]="chartData"
          [chartType]="ChartTypes.DOUGHNUT"
          [chartConfig]="chartConfig"
          [displayLegend]="true"
          [displayLegendInside]="true"
          [displayLegendBottom]="true"
          [legendCss]="'legend-min-width'"
          [clickableLabel]="true"
          [labelActiveColor]="eColorPalette.cGreen3"
          [labelBlurColor]="eColorPalette.cGreen11"
          [chartMaxWidth]="'270px'"
          class="chart"></app-chart>
      </div>
    </div>
  </app-chart-card>
</div>
