export enum FieldType {
  STRING,
  DATE,
  CURRENCY,
  BOOLEAN,
  NUMBER,
  ASSISTANCE_TYPE,
  DELINQUENCY_RESULT_TYPE,
  RENEWAL_RESULT_TYPE,
  MOVE_OUT_TYPE,
  UNIT_CONDITION_TYPE,
  FINISH,
  TURNOVER_PRIORITY_TYPE,
}
