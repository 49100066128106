import { Pipe, PipeTransform } from '@angular/core';
import mime from 'mime';

import { FileMimeType } from '@shared/interfaces/file-mime-types';

@Pipe({
  name: 'fileMimeType',
})
export class FileMimeTypePipe implements PipeTransform {
  transform(fileName: string): FileMimeType {
    const type = mime.getType(fileName);
    if (type) {
      if (type.startsWith('image')) {
        return FileMimeType.Image;
      }
      if (type.startsWith('video')) {
        return FileMimeType.Video;
      }
    }
    return FileMimeType.Other;
  }
}
