import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCardComponent extends ComponentAbstract {
  @Input() chartIcon: EIcon;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() subTitleColor: EColorPalette = EColorPalette.cBlack;
  @Input() isLoading: boolean;
  @Input() showChartColumn = true;
  @Input() customDescriptionTemplate: TemplateRef<any>;
  @Input() chartContainerCss = 'display-flex justify-content-center chart-container h-100';
  @Input() chartCardPadding = '16px';
  @Input() chartCardBoxShadow = '4px 4px 6px rgb(72 72 72 / 12%)';
  @Input() chartCardBackgroundColor = EColorPalette.cWhite;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
