import { AbstractControl, ValidatorFn } from '@angular/forms';

export const UserExistsValidator = (emailAddressList: string[]): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value || !emailAddressList?.length) {
      return null;
    }

    if (emailAddressList.includes(control.value)) {
      return {
        userAlreadyExists: true,
      };
    }
    return null;
  };
};
