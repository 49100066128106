import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export function getCompanyRadioList(companyList: RestCompanyModel[]): IRadioButtonOption<number>[] {
  return (companyList || [])
    .filter(item => item.isActive)
    .map<IRadioButtonOption<number>>(item => {
      return {
        value: item.id,
        label: `${item.displayName}`,
      };
    });
}
