import { isArray } from 'lodash';

import { ParamsCompareFunc, ParamsCompareItem, isParamsCompareFunc } from '@shared/interfaces/params-compare-item';

export function paramsCompare(params: (ParamsCompareItem | ParamsCompareFunc)[]): boolean {
  const someFalse = params.some(item => {
    if (isParamsCompareFunc(item)) {
      return !item.compare();
    } else {
      if (isArray(item.expectValue)) {
        if (item.expectValue.length) {
          return (item.expectValue as Array<unknown>).find(i => i === item.currentValue) === undefined;
        }
      } else {
        return item.expectValue !== item.currentValue;
      }
    }
  });
  return !someFalse;
}
