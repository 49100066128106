import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TurnoverBidsState, turnoverBidsFeatureKey } from '../reducers/turnover-bids.reducer';

const selectTurnoverBidsState = createFeatureSelector<TurnoverBidsState>(turnoverBidsFeatureKey);

export const selectTurnoverBids = createSelector(selectTurnoverBidsState, state => state.bids);

export const selectTurnoverBidCategories = createSelector(selectTurnoverBidsState, state => state.bidCategories);

export const selectTurnoverBidAdding = createSelector(selectTurnoverBidsState, state => state.bidAdding);

export const selectTurnoverBidsLoading = createSelector(selectTurnoverBidsState, state => state.bidsLoading);
