<h2 mat-dialog-title>Email copy of inspection (as a .pdf)</h2>
<mat-dialog-content>
  <form class="form" [formGroup]="form">
    <app-input
      [contentCss]="'display-flex flex-column grid-gap-4'"
      [attrPlaceholder]="'somebody@important.com'"
      formControlName="to"
      [attrLabel]="'Email Address'"
      [showValidationError]="isSubmitted"></app-input>
    <app-input
      [contentCss]="'display-flex flex-column grid-gap-4'"
      [attrPlaceholder]="'An amazing subject crafted by you...'"
      formControlName="subject"
      [attrLabel]="'Subject'"
      [showValidationError]="isSubmitted"></app-input>
    <app-input-area
      [containerCss]="'grid-gap-4'"
      [attrPlaceholder]="'Attached is a copy of your inspection.'"
      formControlName="body"
      [label]="'Message'"
      [showValidationError]="isSubmitted"></app-input-area>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="actions justify-content-end grid-gap-16">
  <app-button
    [buttonType]="ButtonType.STROKED"
    mat-dialog-close
    [buttonLabel]="'Cancel'"
    [buttonColor]="'warn'"></app-button>
  <app-button [buttonType]="ButtonType.RAISED" (clickEvent)="ok()" [buttonLabel]="'Send Email'"></app-button>
</mat-dialog-actions>
