import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { PipesModule } from '@shared/pipes/pipes.module';

import { IconService } from './services/icon.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PipesModule, MatIconModule],
})
export class IconComponent extends ComponentAbstract implements OnInit {
  iconTemplate = '';
  @Input() cssIcon = 'icon-container';

  constructor(protected cdr: ChangeDetectorRef, private iconService: IconService) {
    super(cdr);
  }

  @Input() set svg(icon: EIcon) {
    this.icon = icon;
    this.getIconTemplate();
  }

  @Input() set iconHeight(height: number) {
    this.height = height;
    this.getIconTemplate();
  }

  @Input() set iconWidth(width: number) {
    this.width = width;
    this.getIconTemplate();
  }

  @Input() set iconColor(color: EColorPalette) {
    this.color = color;
    this.getIconTemplate();

    this.baseColor = color;
  }

  @Input() set iconSecondColor(color: EColorPalette) {
    this.secondColor = color;
    this.getIconTemplate();
  }

  @Input() hoverColor: EColorPalette;

  icon: EIcon;
  width: number;
  height: number;
  color: EColorPalette;
  secondColor: EColorPalette;

  baseColor: EColorPalette;

  ngOnInit(): void {
    this.getIconTemplate();
  }

  private getIconTemplate() {
    if (this.icon) {
      this.iconTemplate = this.iconService.getIconTemplate(
        this.icon,
        this.width,
        this.height,
        this.color,
        this.secondColor
      );
      this.cdr.markForCheck();
    }
  }

  mouseEnter() {
    if (this.hoverColor) {
      this.color = this.hoverColor;
    }
  }

  mouseLeave() {
    if (this.hoverColor) {
      this.color = this.baseColor;
    }
  }
}
