import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';

import { PillListComponent } from './pill-list.component';

@NgModule({
  declarations: [PillListComponent],
  imports: [CommonModule, MatChipsModule, TooltipDirectiveModule],
  exports: [PillListComponent],
})
export class PillListModule {}
