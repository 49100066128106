import { createReducer, on } from '@ngrx/store';

import { UpdateStatus } from '@shared/enums/update-status';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';
import {
  addInspectionSurveyTemplate,
  clearTemplateData,
  createTemplateDetails,
  deleteTemplate,
  loadAllUnitTypesFailed,
  loadAllUnitTypesSuccess,
  loadAllUserTemplates,
  loadAllUserTemplatesFailed,
  loadAllUserTemplatesSuccess,
  removeAreaFromTemplate,
  saveInspectionSurveyTemplateSuccess,
  updateInspectionSurveyTemplate,
  updateTemplateDetails,
  updateTemplateDetailsFailed,
} from '@template/store/template.actions';

export const templateFeatureKey = 'template';

export interface TemplateState {
  listItemsLoading?: boolean;
  listItems?: RestTemplateModel[];
  unitTypes?: string[];

  entityUpdateOngoing?: boolean;
  deleteEntityOngoing?: boolean;

  templateAreaUpdateOngoing?: boolean;
  templateAreaRemoveOngoing?: boolean;
  templateAreaUpdateStatus?: UpdateStatus;
}

export const initialState: TemplateState = {
  listItemsLoading: false,
  entityUpdateOngoing: false,
  deleteEntityOngoing: false,
  templateAreaUpdateOngoing: false,
  templateAreaRemoveOngoing: false,
  templateAreaUpdateStatus: null,
};

export const reducer = createReducer(
  initialState,
  /* Templates List */
  on(loadAllUserTemplates, (state, action) => ({ ...state, listItemsLoading: true })),
  on(loadAllUserTemplatesSuccess, (state, action) => ({
    ...state,
    listItemsLoading: false,
    listItems: action.listItems,
    entityUpdateOngoing: false,
    deleteEntityOngoing: false,
    templateAreaUpdateOngoing: false,
    templateAreaRemoveOngoing: false,
  })),
  on(updateTemplateDetails, createTemplateDetails, (state, action) => ({ ...state, entityUpdateOngoing: true })),
  on(deleteTemplate, (state, action) => ({ ...state, deleteEntityOngoing: true })),
  on(updateTemplateDetailsFailed, (state, action) => ({ ...state, deleteEntityOngoing: false })),
  on(clearTemplateData, (state, action) => ({
    ...state,
    listItemsLoading: false,
    entityUpdateOngoing: false,
    deleteEntityOngoing: false,
    templateAreaUpdateOngoing: false,
    templateAreaRemoveOngoing: false,
    templateAreaUpdateStatus: null,
  })),
  on(loadAllUserTemplatesFailed, (state, action) => ({
    ...state,
    listItemsLoading: false,
    listItems: [],
    deleteEntityOngoing: false,
  })),

  /* Template Survey & Area */
  on(addInspectionSurveyTemplate, updateInspectionSurveyTemplate, (state, action) => ({
    ...state,
    templateAreaUpdateOngoing: true,
    templateAreaUpdateStatus: UpdateStatus.IN_PROGRESS,
  })),
  on(saveInspectionSurveyTemplateSuccess, (state, action) => ({
    ...state,
    templateAreaUpdateStatus: UpdateStatus.UPDATED,
  })),
  on(removeAreaFromTemplate, (state, action) => ({ ...state, templateAreaRemoveOngoing: true })),

  /* Unit types */
  on(loadAllUnitTypesSuccess, (state, action) => ({
    ...state,
    unitTypes: action.unitTypes,
  })),
  on(loadAllUnitTypesFailed, (state, action) => ({
    ...state,
    unitTypes: [],
  }))
);
