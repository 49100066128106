import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';

import { TurnoverCommentPreviewComponent } from './turnover-comment-preview.component';

@NgModule({
  declarations: [TurnoverCommentPreviewComponent],
  imports: [CommonModule, IconComponent, PipesModule, TooltipDirectiveModule],
  exports: [TurnoverCommentPreviewComponent],
})
export class TurnoverCommentPreviewModule {}
