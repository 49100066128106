import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelDayInStep',
})
export class LabelDayInStepPipe implements PipeTransform {
  transform(label: string, dayInStep: number): string {
    if (!dayInStep) {
      return `${label} now`;
    }
    return `${label} ${Math.abs(dayInStep)}d${dayInStep < 0 ? ' ago' : ''}`;
  }
}
