import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { getNextNumberItemName } from '@shared/functions/get-next-number-item-name';

import {
  AreaModel,
  AreaPageElementAny,
  AreaPageElementHoursModel,
  AreaPageElementQuantityModel,
  AreaPageElementQuestionModel,
  AreaPageElementRatingGroupModel,
  AreaPageModel,
  AreaSurveyModel,
  TemplateEditorRatingGroup,
} from './template-editor-model';

const quantityItem: AreaPageElementQuantityModel = {
  title: 'Enter a quantity:',
  name: 'quantity:',
  type: 'quantity',
  isRequired: null,
  guid: '{put random guide here}',
  value: null,
};

const hoursItem: AreaPageElementHoursModel = {
  title: 'Enter hours:',
  name: 'hours:',
  type: 'hours',
  isRequired: null,
  guid: '{put random guide here}',
  value: null,
};

export class AreaHelper {
  static addItem(area: AreaModel, title: string, isParentless = false) {
    const isParentlessExist = area.areaSurvey.pages?.length === 1 && !area.areaSurvey.pages[0]?.pageType;
    const name = isParentlessExist ? 'page1' : getNextNumberItemName('page', area.areaSurvey.pages);
    const item: AreaPageModel = {
      uuid: uuidv4(),
      name,
      title,
      sparePartsGroups: area.areaSurvey?.sparePartsGroups ?? [],
      showSpareParts: area.areaSurvey?.showSpareParts ?? false,
      overrideSparePartsSettings: false,
      compulsoryItems: [],
      ...(!isParentless && { pageType: 'area' }),
      elements: [
        // default invisible items for mobile
        {
          type: 'file',
          name: 'Pictures:',
          title: 'Pictures:',
          description: 'Pictures:',
        },
        {
          type: 'comment',
          name: 'Enter a note:',
          title: 'Enter a note:',
        },
      ],
    };
    // replace if parentless
    if (isParentlessExist) {
      area.areaSurvey.pages = [item];
    } else {
      area.areaSurvey.pages.push(item);
    }
    return name;
  }

  static removeItem(area: AreaModel, name: string) {
    area.areaSurvey.pages = [...area.areaSurvey.pages.filter(item => item.name !== name)];
  }

  static getClonedItem(item: AreaPageModel, areaTitle: string = null): AreaPageModel {
    return {
      ...item,
      ...(areaTitle && !item.pageType ? { title: areaTitle } : {}),
      elements: item.elements?.map(element => ({
        ...element,
        ...(element.guid ? { guid: uuidv4() } : {}),
      })),
    };
  }

  static cloneItem(area: AreaModel, item: AreaPageModel) {
    const maxCloneIndex = area.areaSurvey.pages
      .filter(e => new RegExp(`^Clone( #[0-9]*)* - ${item.title}$`).test(e.title))
      .map(c => {
        const matches = /Clone #([0-9]+)/.exec(c.title);
        if (!matches?.length) {
          return 1;
        } else {
          return +matches?.[1];
        }
      })
      .reduce((prev, max) => (prev > max ? prev : max), 0);

    const name = getNextNumberItemName('page', area.areaSurvey.pages);
    const newItem: AreaPageModel = {
      ...AreaHelper.getClonedItem(item),
      uuid: uuidv4(),
      name,
      title: `Clone${maxCloneIndex + 1 === 1 ? '' : ` #${maxCloneIndex + 1}`} - ${item.title}`,
    };
    area.areaSurvey.pages.push(newItem);
    return name;
  }

  static addOrReplaceRatingGroup(areaSurvey: AreaSurveyModel, item: AreaPageModel, group: TemplateEditorRatingGroup) {
    const items = cloneDeep(group.items);

    const newRatingElement: AreaPageElementRatingGroupModel = {
      type: 'propertystate',
      name: uuidv4() + group.name,
      title: group.name,
      isRequired: true,
      guid: uuidv4(),
      ratingGroupId: group.id,
      items: items,
    };
    this.addNewElementToItem(
      areaSurvey,
      { ...item, elements: (item?.elements ?? []).filter(element => element.type !== 'propertystate') },
      newRatingElement
    );
  }

  static addQuestion(areaSurvey: AreaSurveyModel, item: AreaPageModel, question: string, defaultValue = true) {
    const guid: string = uuidv4();
    const newQuestionAreaPageElementModel: AreaPageElementQuestionModel = {
      type: 'boolean',
      name: guid + question,
      title: question,
      guid: guid,
      ...(defaultValue != null ? { defaultValue } : {}),
    };

    this.addNewElementToItem(areaSurvey, item, newQuestionAreaPageElementModel);

    return guid;
  }

  private static addNewElementToItem(areaSurvey: AreaSurveyModel, item: AreaPageModel, element: AreaPageElementAny) {
    const foundItem = areaSurvey.pages.find(innerItem => item.name === innerItem.name);
    if (!foundItem) {
      return;
    }
    const elements = [...(item.elements ?? [])];
    const fileElementIndex = elements.findIndex(el => el.type.toLowerCase() === 'file');
    const commentElementIndex = elements.findIndex(el => el.type.toLowerCase() === 'comment');
    const minIndex = Math.min(commentElementIndex, fileElementIndex);

    AreaHelper.updateItem(areaSurvey, {
      ...foundItem,
      elements:
        minIndex === -1
          ? [...elements, element]
          : [...elements.slice(0, minIndex), element, ...elements.slice(minIndex)],
    });
  }

  static updateItem(areaSurvey: AreaSurveyModel, item: AreaPageModel) {
    (item.elements.find(e => e.type == 'propertystate') as AreaPageElementRatingGroupModel)?.items.forEach(e => {
      if (e.item.showHoursWidget != null) {
        if (e.item.showHoursWidget && !e.item.onSelect.includes('showHoursWidget')) {
          e.item.onSelect = e.item.onSelect.concat('showHoursWidget');
        }

        if (!e.item.showHoursWidget && e.item.onSelect.includes('showHoursWidget')) {
          e.item.onSelect = e.item.onSelect.filter(x => x != 'showHoursWidget');
        }

        e.item.showHoursWidget = null;
      }
      if (e.item.ticketRequired != null) {
        if (e.item.ticketRequired && !e.item.onSelect.includes('ticketRequired')) {
          e.item.onSelect = e.item.onSelect.concat('ticketRequired');
        }

        if (!e.item.ticketRequired && e.item.onSelect.includes('ticketRequired')) {
          e.item.onSelect = e.item.onSelect.filter(x => x != 'ticketRequired');
        }

        e.item.ticketRequired = null;
      }
      if (e.item.showSpareParts != null) {
        if (e.item.showSpareParts && !e.item.onSelect.includes('showSpareParts')) {
          e.item.onSelect = e.item.onSelect.concat('showSpareParts');
        }

        if (!e.item.showSpareParts && e.item.onSelect.includes('showSpareParts')) {
          e.item.onSelect = e.item.onSelect.filter(x => x != 'showSpareParts');
        }

        e.item.showSpareParts = null;
      }
      if (e.item.sparePartsSelectionRequired != null) {
        if (e.item.sparePartsSelectionRequired && !e.item.onSelect.includes('sparePartsSelectionRequired')) {
          e.item.onSelect = e.item.onSelect.concat('sparePartsSelectionRequired');
        }

        if (!e.item.sparePartsSelectionRequired && e.item.onSelect.includes('sparePartsSelectionRequired')) {
          e.item.onSelect = e.item.onSelect.filter(x => x != 'sparePartsSelectionRequired');
        }

        e.item.sparePartsSelectionRequired = null;
      }
    });

    const shouldHaveQuantity =
      (item.elements.find(e => e.type == 'propertystate') as AreaPageElementRatingGroupModel)?.items.some(
        e => e.item.showQtyWidget
      ) ?? false;
    const shouldHaveHours =
      (item.elements.find(e => e.type == 'propertystate') as AreaPageElementRatingGroupModel)?.items.some(e =>
        e.item.onSelect.includes('showHoursWidget')
      ) ?? false;

    const quantityIndex = item.elements.findIndex(e => e.type == 'quantity');
    const hoursIndex = item.elements.findIndex(e => e.type == 'hours');

    if (shouldHaveQuantity && quantityIndex == -1) {
      item.elements.push({ ...quantityItem, guid: uuidv4() });
    }
    if (!shouldHaveQuantity && quantityIndex != -1) {
      item.elements.splice(quantityIndex, 1);
    }

    if (shouldHaveHours && hoursIndex == -1) {
      item.elements.push({ ...hoursItem, guid: uuidv4() });
    }
    if (!shouldHaveHours && hoursIndex != -1) {
      item.elements.splice(hoursIndex, 1);
    }
    areaSurvey.pages = areaSurvey.pages.map(innerItem =>
      innerItem.uuid !== item.uuid ? innerItem : { ...innerItem, ...item }
    );
  }
}
