import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export enum VacancyLostThreshold {
  None,
  Medium,
  High,
}
export const VacancyLostThresholdList: IRadioButtonOption<VacancyLostThreshold>[] = [
  {
    label: 'none',
    value: VacancyLostThreshold.None,
  },
  {
    label: 'medium',
    value: VacancyLostThreshold.Medium,
  },
  {
    label: 'high',
    value: VacancyLostThreshold.High,
  },
];
