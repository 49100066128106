import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { PipesModule } from '@shared/pipes/pipes.module';
import { ReadOnlyFieldModule } from '@ui-components/components/read-only-field/read-only-field.module';
import { UserSelectDropdownModule } from '@ui-components/controls/user-select-dropdown/user-select-dropdown.module';

import { NextStepAssigneeModalComponent } from './next-step-assignee-modal.component';

@NgModule({
  declarations: [NextStepAssigneeModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    ReadOnlyFieldModule,
    UserSelectDropdownModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    RouterModule,
    FormsModule,
  ],
})
export class NextStepAssigneeModalModule {}
