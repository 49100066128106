import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PipesModule } from '@shared/pipes/pipes.module';

import { LineChartComponent } from './line-chart.component';

@NgModule({
  declarations: [LineChartComponent],
  exports: [LineChartComponent],
  imports: [CommonModule, PipesModule, MatProgressSpinnerModule],
})
export class LineChartModule {}
