<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex justify-content-center modal-header">Add Custom Weight</div>

  <mat-dialog-content>
    <app-number-input
      [autoUpdate]="false"
      [postfix]="'%'"
      [max]="3"
      [attrMask]="'separator.0'"
      [(ngModel)]="data.customWeight"
      (keydown)="onKeydown($event)"></app-number-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="w-100 display-flex justify-content-space-between">
      <button
        mat-stroked-button
        type="button"
        aria-label="button-cancel"
        class="modal-button flex-1"
        (click)="onCancel()">
        Cancel
      </button>
      <button
        mat-raised-button
        type="button"
        aria-label="add-cancel"
        color="primary"
        class="modal-button flex-1"
        (click)="onSave()">
        Add
      </button>
    </div>
  </mat-dialog-actions>
</div>
