import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

import { EnumerationItem } from '@shared/interfaces/enumeration-item';

@Pipe({
  name: 'turnoverPhaseToStatus',
})
export class TurnoverPhaseToStatusPipe implements PipeTransform {
  transform(value: number, enumerations: EnumerationItem[]): string {
    const item = find(enumerations, { enumerationValue: value });
    return item.displayText;
  }
}
