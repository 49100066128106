import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DelinquencyFields } from '@main-application/dashboard-reports/delinquencies/models/delinquency-fields.enum';
import { DelinquencyResultType } from '@shared/enums/delinquency-result-type';

@Pipe({
  name: 'delinquencyGroupInvalid',
  pure: false,
})
export class DelinquencyGroupInvalidPipe implements PipeTransform {
  transform(form: UntypedFormGroup, delinquencyResultType: DelinquencyResultType): boolean {
    const notes = form.get([DelinquencyFields.NOTES]);
    const group = form.get([delinquencyResultType]);
    return notes.invalid || !delinquencyResultType || group.invalid;
  }
}
