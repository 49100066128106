import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableEmptyContentComponent } from './table-empty-content.component';

@NgModule({
  declarations: [TableEmptyContentComponent],
  exports: [TableEmptyContentComponent],
  imports: [CommonModule],
})
export class TableEmptyContentModule {}
