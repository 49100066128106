import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { TemplateEditorDialogModule } from '@main-application/shared/template-editor-dialog/template-editor-dialog.module';

import { IconComponent } from '../icon/icon.component';

export enum EmptyListEmoji {
  Cat = 'cat',
  Unicorn = 'unicorn',
}

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, TemplateEditorDialogModule],
})
export class EmptyListComponent extends ComponentAbstract {
  @Input() header = 'No match found';
  @Input() emoji: EmptyListEmoji = EmptyListEmoji.Cat;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
