import { PermissionLevelType } from '@shared/enums/permission-level.enum';

export function checkPermissionLevel(
  permissionList: PermissionLevelType[],
  permissionLevel: PermissionLevelType | PermissionLevelType[],
  check: 'all' | 'any' = 'all'
): boolean {
  if (!permissionList?.length) {
    return false;
  }

  if (!permissionLevel) {
    return true;
  }

  if (!Array.isArray(permissionLevel)) {
    permissionLevel = [permissionLevel];
  }

  /**
   * Check if superuser
   */
  if (permissionList.filter(value => value === PermissionLevelType.AllPermissions).length) {
    return true;
  }

  if (check == 'any') {
    return permissionLevel.some(v => permissionList.includes(v));
  }
  return !permissionLevel.some(v => !permissionList.includes(v));
}
