import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, patterns: string[]): string {
    let result = value || '';
    patterns.forEach(pattern => {
      const regex = new RegExp(`${pattern}`, 'gmi');
      result = result.replace(regex, '');
    });
    return result.trim();
  }
}
