import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sectionId',
})
export class SectionIdPipe implements PipeTransform {
  transform(value: number, idPrefix = 'section_id_'): string {
    return `${idPrefix}${value}`;
  }
}
