import { Pipe, PipeTransform } from '@angular/core';

import { getUserDisplayName } from '@shared/functions/get-user-list.function';
import { RestUserModel } from '@shared/interfaces/user.interface';

@Pipe({
  name: 'userDisplayName',
})
export class UserDisplayNamePipe implements PipeTransform {
  transform(user: RestUserModel, defaultValue = '', dateCreated = ''): string {
    if (!user) {
      return defaultValue;
    }
    return dateCreated ? getUserDisplayName(user) + ' ' + dateCreated : getUserDisplayName(user);
  }
}
