import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appAutoresize]',
})
export class AutoresizeDirective implements AfterViewInit {
  @Input() maxHeight: number;
  @Input() appAutoresize: boolean;
  @Input() formControl: UntypedFormControl;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.resize();

    this.formControl.valueChanges.subscribe(() => {
      this.resize();
    });
  }

  resize() {
    if (!this.appAutoresize) return;

    const textArea = this.elementRef.nativeElement;
    let newHeight;

    textArea.style.height = 'auto';
    if (this.maxHeight) {
      newHeight = Math.min(textArea.scrollHeight, this.maxHeight);
    } else {
      newHeight = textArea.scrollHeight;
    }
    textArea.style.height = newHeight + 4 + 'px';
  }
}
