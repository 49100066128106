import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TreeModule } from '@circlon/angular-tree-component';

import { InputErrorModule } from '@app/modules/ui-components/components/input-error/input-error.module';
import { SelectTreeComponent } from '@app/modules/ui-components/components/select-tree/select-tree.component';

import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [SelectTreeComponent],
  imports: [CommonModule, TreeModule, InputErrorModule, MatMenuModule, MatInputModule, IconComponent],
  exports: [SelectTreeComponent],
})
export class SelectTreeModule {}
