<div class="read-only-field-container" [ngClass]="[containerCss]">
  <label [ngClass]="[labelCss]" *ngIf="label">{{ label }}</label>
  <div [ngClass]="valueContainerCss" class="display-flex align-items-center">
    <ng-container [ngTemplateOutlet]="customValue ? customValue : defaultValue"></ng-container>
  </div>
</div>

<ng-template #defaultValue>
  <div [ngClass]="valueCss">
    {{ value || emptyValue }}
  </div>
</ng-template>
