<div class="display-flex flex-column">
  <div *ngIf="data?.title" mat-dialog-title class="display-flex modal-header">
    <div class="display-flex align-items-center grid-gap-10 subtitle mr-4">
      <app-icon
        *ngIf="data?.icon"
        [svg]="data?.icon"
        [iconWidth]="50"
        [iconHeight]="50"
        [iconColor]="EColorPalette.cYellow"></app-icon>
      {{ data?.title }}
    </div>
  </div>

  <mat-dialog-content *ngIf="data.contentTemplate || data?.content">
    <div class="description">
      <ng-container
        [ngTemplateOutlet]="data?.contentTemplate ? data.contentTemplate : defaultContentTemplate"></ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center" [ngClass]="{ 'justify-content-end': data?.icon }">
    <button mat-raised-button color="primary" (click)="confirm()">{{ data?.buttonText ?? 'OK' }}</button>
  </mat-dialog-actions>
</div>

<ng-template #defaultContentTemplate>
  <div class="display-flex" *ngIf="data?.content">{{ data?.content || '' }}</div>
</ng-template>
