import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

@Pipe({
  name: 'findByProp',
})
export class FindByPropPipe implements PipeTransform {
  transform<T>(propValue: any, list: T[], propName: string): T {
    return find(list, i => i[propName] === propValue) as T;
  }
}
