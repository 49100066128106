<ng-container *ngIf="text; else divider">
  <div class="divider">
    <mat-divider class="width-10"></mat-divider>
    <span>{{ text }}</span>
    <mat-divider class="flex-1"></mat-divider>
  </div>
</ng-container>

<ng-template #divider>
  <mat-divider></mat-divider>
</ng-template>
