import { Pipe, PipeTransform } from '@angular/core';

import { parseNumber } from '@shared/functions/parse-number';

@Pipe({
  name: 'numberValidation',
})
export class NumberValidationPipe implements PipeTransform {
  transform(value: string | number, min = 0, max?: number): unknown {
    const parsed = parseNumber(`${value}`);
    if (isNaN(parsed)) {
      return '';
    }
    if (parsed < min || (max !== undefined && parsed > max)) {
      return 'invalid';
    }
    return '';
  }
}
