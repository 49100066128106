import { createAction, props } from '@ngrx/store';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestThresholdsConfiguration } from '@shared/interfaces/thresholds-configuration.interface';
import { TurnoverConfigSection } from '@shared/interfaces/turnover-config.interface';

/**
 * get turnover configuration list
 */
export const getTurnoverConfigurationList = createAction('[Management] get turnover configuration list');
export const getTurnoverConfigurationListSuccess = createAction(
  '[Management] get turnover configuration list success',
  props<{ turnoverConfig: TurnoverConfigSection[] }>()
);
export const getTurnoverConfigurationListFailure = createAction('[Management] get turnover configuration list failure');

/**
 * get turnover configuration by workflow type
 */
export const getTurnoverConfigurationByWorkflowStep = createAction(
  '[Management] get turnover configuration by workflow type',
  props<{ workflowStep: WorkflowStepEnumType }>()
);
export const getTurnoverConfigurationByWorkflowStepSuccess = createAction(
  '[Management] get turnover configuration by workflow type success',
  props<{ turnoverConfig: TurnoverConfigSection }>()
);
export const getTurnoverConfigurationByWorkflowStepFailure = createAction(
  '[Management] get turnover configuration by workflow type failure'
);

/**
 * update turnover field configuration
 */
export const updateTurnoverFieldConfiguration = createAction(
  '[Management] update turnover field configuration',
  props<{ turnoverConfig: TurnoverConfigSection }>()
);
export const updateTurnoverFieldConfigurationSuccess = createAction(
  '[Management] update turnover field configuration success'
);
export const updateTurnoverFieldConfigurationFailure = createAction(
  '[Management] update turnover field configuration failure'
);

/**
 * get elapsed days thresholds
 */
export const getElapsedDaysThresholds = createAction('[Management] get elapsed days thresholds');
export const getElapsedDaysThresholdsSuccess = createAction(
  '[Management] get elapsed days thresholds success',
  props<{ thresholdsConfiguration: RestThresholdsConfiguration }>()
);
export const getElapsedDaysThresholdsFailure = createAction('[Management] get elapsed days thresholds failure');

/**
 * update elapsed days thresholds
 */
export const updateElapsedDaysThresholds = createAction(
  '[Management] update elapsed days thresholds',
  props<{ thresholdsConfiguration: RestThresholdsConfiguration }>()
);
export const updateElapsedDaysThresholdsSuccess = createAction('[Management] update elapsed days thresholds success');
export const updateElapsedDaysThresholdsFailure = createAction('[Management] update elapsed days thresholds failure');

export const loadFinish = createAction('[Management] load finish');
export const loadFinishSucceded = createAction('[Management] load finish succeeded', props<{ finishTypes: any }>());
export const loadFinishFailed = createAction('[Management] load finish failed');

export const addFinish = createAction('[Management] add finish', props<{ finish: string }>());
export const addFinishSuccess = createAction('[Management] add finish success');
export const addFinishFailure = createAction('[Management] add finish failure');

export const updateFinish = createAction('[Management] update finish', props<{ id: number; name: string }>());
export const updateFinishSuccess = createAction('[Management] update finish success');
export const updateFinishFailure = createAction('[Management] update finish failure');

export const deleteFinish = createAction('[Management] delete finish', props<{ id: number }>());
export const deleteFinishSuccess = createAction('[Management] delete finish success');
export const deleteFinishFailure = createAction('[Management] delete finish failure');
