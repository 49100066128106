<td class="mat-cell table-empty-content-container" [attr.colspan]="attrColspan">
  <div class="content">
    <span class="body-small" *ngIf="!action.observed; else buttonTemplate">{{ message }}</span>
    <ng-content></ng-content>
  </div>
  <ng-template #buttonTemplate>
    <button class="button-text content" (click)="action.emit()">
      {{ message }}
    </button>
  </ng-template>
</td>
