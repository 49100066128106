import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';

import { TemplateEditorEmojiContent } from '../models/template-editor-model';

class EmojiData {
  assets: any[];
  ddd: number;
  fr: number;
  h: number;
  ip: number;
  layers: any[];
  markers: any[];
  nm: string;
  op: number;
  v: string;
  w: number;
}

@Injectable({
  providedIn: 'root',
})
export class AnimatedEmojiService {
  protected readonly CACHE: { [assetPath: string]: ReplaySubject<EmojiData> } = {};
  private _emojiContent: ReplaySubject<TemplateEditorEmojiContent[]>;

  constructor(private service: RestapiService, private http: HttpClient) {}

  getEmojiContent() {
    if (!this._emojiContent) {
      this._emojiContent = new ReplaySubject<TemplateEditorEmojiContent[]>(1);
      this.service.getData<TemplateEditorEmojiContent[]>('emojiContent').subscribe({
        next: emojiContent => this._emojiContent.next(emojiContent),
        error: error => this._emojiContent.error(error),
      });
    }
    return this._emojiContent.asObservable();
  }

  preCacheAllEmoji() {
    this.getEmojiContent().subscribe(emojis => {
      emojis.forEach(e => this.loadEmoji(e.assetRelativeUrl).subscribe());
    });
  }

  loadEmoji(assetPath: string): Observable<EmojiData> {
    if (!this.CACHE[assetPath]) {
      this.CACHE[assetPath] = new ReplaySubject<EmojiData>(1);
      this.http.get<EmojiData>(assetPath).subscribe(data => this.CACHE[assetPath].next(data));
    }

    return this.CACHE[assetPath];
  }
}
