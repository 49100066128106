export enum DelinquencyResultType {
  None,
  DelayedPayment,
  Eviction,
  PaymentPlan,
  PaidInFull,
  Assistance,
  MovedOut,
  SmallBalance,
  PendingCredit,
  NTV,
}
