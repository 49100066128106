export enum TicketStatusType {
  Todo,
  InProgress,
  Resolved,
  Canceled,
  Pending,
  Declined,
  WaitingParts,
  Reopened,
}

export enum TicketStatus {
  NA = 0,
  NotReady = 1,
  InProgress = 2,
  Review = 3,
  Completed = 4,
  Canceled = 5,
}

export enum TicketCompletedStatusType {
  IsCompleted = 1,
  IsNotCompleted = 2,
}

export enum SyncWithPMSTypes {
  Yes = 'yes',
  No = 'no',
  Any = 'any ',
}

export const TICKET_STATUS_TYPES = [
  {
    label: 'Canceled',
    id: TicketStatusType.Canceled,
  },
  {
    label: 'Declined',
    id: TicketStatusType.Declined,
  },
  {
    label: 'In progress',
    id: TicketStatusType.InProgress,
  },
  {
    label: 'Done',
    id: TicketStatusType.Resolved,
  },
  {
    label: 'Pending',
    id: TicketStatusType.Pending,
  },
  {
    label: 'Reopened',
    id: TicketStatusType.Reopened,
  },
  {
    label: 'To do',
    id: TicketStatusType.Todo,
  },
  {
    label: 'Waiting parts',
    id: TicketStatusType.WaitingParts,
  },
];
