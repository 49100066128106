import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { EIcon } from '@shared/enums/icon.enum';

import { SnackbarService } from '../snackbar.service';

export interface CustomizedSnackbarComponentData {
  message: string;
  icon?: EIcon;
  actions$: BehaviorSubject<{ action: string; actionParam?: any }>;
}

@UntilDestroy()
@Component({
  selector: 'app-customized-snackbar',
  templateUrl: './customized-snackbar.component.html',
  styleUrls: ['./customized-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomizedSnackbarComponent extends ComponentAbstract {
  loadingSpinner$: BehaviorSubject<boolean> = this.snackbarService.snackbarLoadingSpinner$;

  constructor(
    private snackbarService: SnackbarService,
    protected cdr: ChangeDetectorRef,
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomizedSnackbarComponentData
  ) {
    super(cdr);
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const action = (event.target as HTMLElement).getAttribute('data-toast-action');
    const actionParam = (event.target as HTMLElement).getAttribute('data-toast-action-param');
    if (action) {
      this.data.actions$.next({ action, actionParam });
      this.dismiss();
    }
  }

  dismiss() {
    this.snackbarService.dismiss();
  }
}
