import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonMenuComponent } from '@shared/components/button/button-menu/button-menu.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { AttachmentModule } from '@ui-components/components/attachment/attachment.module';
import { AttachmentItemModule } from '@ui-components/components/attachment-item/attachment-item.module';
import { AttachmentsListModule } from '@ui-components/components/attachments-list/attachments-list.module';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { PictureModule } from '@ui-components/components/picture/picture.module';
import { SkeletonModalsComponent } from '@ui-components/components/skeleton-modals/skeleton-modals.component';
import { TableStatusToggleModule } from '@ui-components/components/table-status-toggle/table-status-toggle.module';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { InputAreaModule } from '@ui-components/controls/input-area/input-area.module';
import { InputTimeManualModule } from '@ui-components/controls/input-time-manual/input-time-manual.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { UserSelectDropdownModule } from '@ui-components/controls/user-select-dropdown/user-select-dropdown.module';

import { AddTicketModalComponent } from './add-ticket-modal.component';
import { PipesModule } from '../../../shared/pipes/pipes.module';

@NgModule({
  declarations: [AddTicketModalComponent, SkeletonModalsComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    InputModule,
    RadioButtonDropdownModule,
    InputErrorModule,
    DateDropdownModule,
    AttachmentItemModule,
    AttachmentModule,
    TableStatusToggleModule,
    MatButtonModule,
    PictureModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    UserSelectDropdownModule,
    InputAreaModule,
    AttachmentsListModule,
    DragDropModule,
    MatCheckboxModule,
    PipesModule,
    ButtonMenuComponent,
    InputTimeManualModule,
    DirectivesModule,
  ],
})
export class AddTicketModalModule {}
