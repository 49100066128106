import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';

import { FadeTextComponent } from '@shared/components/ellipsis-one-row/fade-text.component';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { NgForTrackByKeyDirective } from '@shared/directives/track-by-key.directive';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DividerModule } from '@ui-components/components/divider/divider.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';

import { UserSelectDropdownComponent } from './user-select-dropdown.component';

@NgModule({
  declarations: [UserSelectDropdownComponent],
  exports: [UserSelectDropdownComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    IconComponent,
    PipesModule,
    InputErrorModule,
    MatProgressSpinnerModule,
    TooltipDirectiveModule,
    FadeTextComponent,
    NgForTrackByKeyDirective,
    DividerModule,
  ],
})
export class UserSelectDropdownModule {}
