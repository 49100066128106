<div class="question-item">
  <div class="default-values">
    <button
      aria-label="toggle-button"
      type="button"
      [matTooltipClass]="'multi-line-tooltip'"
      [matTooltip]="
        'Default answer: yes\n\nNOTE: this question will only appear in report if \'no\' is chosen by mobile user'
      "
      matTooltipShowDelay="500"
      class="thumb-icon"
      mat-icon-button
      (click)="toggleDefaultValue(true)">
      <app-icon [svg]="questionItem.defaultValue === true ? EIcon.YES_ACTIVE : EIcon.YES"></app-icon>
    </button>
    <button
      aria-label="toggle-button"
      type="button"
      [matTooltipClass]="'multi-line-tooltip'"
      [matTooltip]="
        'Default answer: no.\n\nNOTE: this question will only appear in report if \'yes\' is chosen by mobile user'
      "
      matTooltipShowDelay="500"
      class="thumb-icon"
      mat-icon-button
      (click)="toggleDefaultValue(false)">
      <app-icon [svg]="questionItem.defaultValue === false ? EIcon.NO_ACTIVE : EIcon.NO"></app-icon>
    </button>
  </div>
  <span #questionTitleElement class="title-hidden"></span>
  <input
    aria-label="question-input"
    #inputElement
    class="input"
    [value]="questionItem.title"
    (blur)="questionChange($event)"
    (keydown)="keyPressed($event)"
    [maxLength]="70"
    (input)="updateWidth($event)"
    [style.width]="questionWidth"
    [placeholder]="placeholder" />

  <div class="actions">
    <button
      aria-label="delete-button"
      type="button"
      class="remove"
      mat-icon-button
      [matTooltip]="'Delete question'"
      matTooltipShowDelay="500"
      (click)="removeClick()">
      <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
    </button>
  </div>
</div>
