export enum DelinquencyFields {
  NOTES = 'notes',
  ASSISTANCE_TYPE = 'assistanceType',
  DATE_TENANT_CONTACTED = 'dateTenantContacted',
  DATE_PAYMENT_EXPECTED = 'datePaymentExpected',
  PAYMENT_AMOUNT = 'paymentAmount',
  DELINQUENCY_RESULT_TYPE = 'delinquencyResultType',
  IS_JUDGMENT_WON = 'isJudgementWon',
  WRIT_DATE = 'writDate',
  COURT_DATE = 'courtDate',
  APPEAL_END_DATE = 'appealEndDate',
  DATE_MOVED_OUT = 'dateMovedOut',
  DATE_PLAN_BEGINS = 'datePlanBegins',
  PAYMENT_FORM = 'paymentForm',
  NUMBER_OF_INSTALLMENTS = 'numberOfInstallments',
  INSTALLMENT_AMOUNT = 'installmentAmount',
  DATE_PAYMENT_RECEIVED = 'datePaymentReceived',
  SMALL_BALANCE_CONSISTS_OF = 'smallBalanceConsistOf',
  CREDIT_REASON = 'creditReason',
  CREDIT_AMOUNT = 'creditAmount',
  NTV_POSTED = 'nTVPosted',
}
