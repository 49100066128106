import { Pipe, PipeTransform } from '@angular/core';

import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';

@Pipe({
  name: 'enumerationUser',
})
export class EnumerationUserPipe implements PipeTransform {
  transform(
    userId: number,
    userList: IRadioButtonOption<RestUserModel>[]
  ): IRadioButtonOption<RestUserModel> | undefined {
    return userList?.find(enumItem => enumItem.value.id === userId);
  }
}
