import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'kpiTaskValue',
})
export class KpiTaskValuePipe implements PipeTransform {
  transform(value: number): string {
    if (isNil(value)) {
      return 'Type or choose';
    }
    return `${value} ${value === 1 ? 'day' : 'days'}`;
  }
}
