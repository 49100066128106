import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { PipesModule } from '@shared/pipes/pipes.module';
import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';

import { RadioButtonComponent } from './radio-button.component';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [CommonModule, MatRadioModule, ReactiveFormsModule, PipesModule, InputErrorModule],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
