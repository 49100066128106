import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { CommentTooLongModalComponent } from './comment-too-long-modal.component';

@NgModule({
  declarations: [CommentTooLongModalComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [CommentTooLongModalComponent],
})
export class CommentTooLongModalModule {}
