import { Directive, HostListener } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[preventKeyboardEvents]', standalone: true })
export class PreventKeyboardEventsDirective {
  @HostListener('keydown', ['$event'])
  @HostListener('keypress', ['$event'])
  @HostListener('keyup', ['$event'])
  onKeyboardEvent(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}
