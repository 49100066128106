import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollToReqContainer]',
})
export class ScrollToRequiredContainerDirective {
  // defines the scroll container for [appScrollToReq]

  readonly containerEl: HTMLElement = this.el.nativeElement;

  constructor(private el: ElementRef) {}
}
