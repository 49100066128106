<div class="input-file-container display-flex flex-column grid-gap-8">
  <div class="display-flex" *ngIf="label">
    <label
      class="input-file-container__label"
      [class.input-file-container__label--tooltip]="labelTooltipText"
      [matTooltip]="labelTooltipText"
      [ngClass]="[labelCss, labelRequired ? 'label-required' : '']">
      <span>{{ label }}</span>
      <app-tooltip-help
        *ngIf="labelTooltipText"
        [text]="null"
        marginLeftType="xs"
        [label]="labelTooltipText"></app-tooltip-help>
    </label>
    <ng-content select="[label]"></ng-content>
  </div>
  <div
    class="input-file__content display-flex flex-column input-file-wrapper"
    [ngClass]="[attrDisable ? 'disabled' : '', isResident || useSlotContent ? 'input-file-wrapper-custom' : '']"
    appDropZone
    [useCustomStyle]="isResident || useSlotContent"
    (appDropZone)="filesDropped($event)">
    <input
      title="input"
      #fileUpload
      class="display-none"
      tabIndex="-1"
      type="file"
      [accept]="acceptedFormats?.join(', ')"
      (change)="inputFileChanged($event)"
      [attr.aria-invalid]="invalid && control.touched"
      [attr.multiple]="multiselect ? 'multiple' : ''" />
    <div class="inner-input-state">
      <ng-container
        [ngTemplateOutlet]="
          isFileLoaded === null
            ? control.value
              ? uploadedStateTemplate
              : initialStateTemplate
            : isFileLoaded
            ? uploadedStateTemplate
            : initialStateTemplate
        "></ng-container>
    </div>
  </div>
</div>

<ng-template #initialStateTemplate>
  <ng-container
    [ngTemplateOutlet]="(uploadingFile$ | async) !== null ? uploadingTemplate : uploadButtonTemplate"></ng-container>
</ng-template>

<ng-template #uploadingTemplate>
  <div class="display-flex flex-column grid-gap-32 align-items-center w-100 justify-content-center">
    <div class="uploading-progress" [ngStyle]="{ width: (uploadingProgress$ | async) + '%' }"></div>
    <div class="uploading-info display-flex flex-column justify-content-center align-items-center grid-gap-4">
      <app-icon
        *ngIf="!(isResident || useSlotContent)"
        [iconHeight]="48"
        [iconWidth]="48"
        [svg]="EIcon.ICON_FILE_UPLOADING"
        [iconColor]="EColorPalette.cGreen"></app-icon>
      <div>Uploading file...</div>
      <div class="file-name fade-truncate">{{ (uploadingFile$ | async).name }}</div>
    </div>
  </div>
</ng-template>

<ng-template #uploadButtonTemplate>
  <div *ngIf="isResident" class="w-100 display-flex align-items-center justify-content-center">
    <div class="display-flex align-items-center justify-content-center icon-upload-custom" (click)="browseFile()">
      <app-icon
        [iconHeight]="48"
        [iconWidth]="48"
        [svg]="EIcon.PHOTO_CAMERA"
        [iconColor]="attrDisable ? EColorPalette.cGray2 : EColorPalette.cOrange2"></app-icon>
    </div>
  </div>

  <div
    class="display-flex grid-gap-32 align-items-center w-100 justify-content-center"
    *ngIf="useSlotContent"
    (click)="browseFile()">
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="!(isResident || useSlotContent)"
    class="display-flex grid-gap-32 align-items-center w-100 justify-content-center">
    <div class="display-flex flex-column grid-gap-4 align-items-center">
      <app-icon
        [iconHeight]="48"
        [iconWidth]="48"
        [svg]="EIcon.ICON_UPLOAD"
        [iconColor]="attrDisable ? EColorPalette.cGray2 : EColorPalette.cGreen"></app-icon>
      <div>Drag & Drop</div>
    </div>
    <div>or</div>
    <button mat-stroked-button color="primary" class="upload-button" (click)="browseFile()" [disabled]="attrDisable">
      Choose file
    </button>
  </div>
</ng-template>

<ng-template #uploadedStateTemplate>
  <div class="display-flex flex-column grid-gap-32 align-items-center w-100 justify-content-center">
    <div class="uploading-progress" [ngStyle]="{ width: '100%' }"></div>
    <div class="uploading-info display-flex flex-column justify-content-center align-items-center grid-gap-4">
      <app-icon
        [iconHeight]="48"
        [iconWidth]="48"
        [svg]="EIcon.ICON_FILE_UPLOADED"
        [iconColor]="EColorPalette.cGreen"></app-icon>
      <div *ngIf="!useSlotContent">File uploaded</div>
      <div *ngIf="!useSlotContent" class="file-name fade-truncate">{{ control.value.originalFileName }}</div>
    </div>
  </div>
</ng-template>
