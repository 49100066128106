import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PipesModule } from '@shared/pipes/pipes.module';
import { AttachmentModule } from '@ui-components/components/attachment/attachment.module';
import { AttachmentItemModule } from '@ui-components/components/attachment-item/attachment-item.module';
import { AttachmentsListModule } from '@ui-components/components/attachments-list/attachments-list.module';
import { ButtonModule } from '@ui-components/components/button/button.module';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { InputAreaModule } from '@ui-components/controls/input-area/input-area.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { UserSelectDropdownModule } from '@ui-components/controls/user-select-dropdown/user-select-dropdown.module';

import { TurnoverPunchModalComponent } from './turnover-punch-modal.component';

@NgModule({
  declarations: [TurnoverPunchModalComponent],
  imports: [
    CommonModule,
    AttachmentItemModule,
    AttachmentModule,
    InputAreaModule,
    InputModule,
    DateDropdownModule,
    RadioButtonDropdownModule,
    UserSelectDropdownModule,
    MatDialogModule,
    ButtonModule,
    MatButtonModule,
    ReactiveFormsModule,
    AttachmentsListModule,
    AttachmentItemModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
})
export class TurnoverPunchModalModule {}
