<div class="skeleton-wrapper">
  <div class="skeleton-wrapper__table">
    <div class="skeleton-wrapper__table__header skeleton-wrapper__grid">
      <p>Name</p>
      <p>Not Started</p>
      <p>In Progress</p>
      <p>Late</p>
      <p>Completed(Late)</p>
      <p>Expired</p>
      <p>Review</p>
      <p>Total</p>
    </div>

    <div *ngFor="let row of rows" class="skeleton-wrapper__table__body skeleton-wrapper__grid">
      <p *ngFor="let col of columns" class="skeleton-wrapper__table__body__cell"></p>
    </div>
  </div>

  <div class="skeleton-wrapper__chart">
    <div class="skeleton-wrapper__chart__block"></div>

    <div class="skeleton-wrapper__chart__legend">
      <div *ngFor="let legend of legendList" class="skeleton-wrapper__chart__legend__cell">
        <div></div>
        <div>{{ legend }}</div>
      </div>
    </div>
  </div>
</div>
