import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';

@Pipe({
  name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: Date | string): string {
    const date = new Date(value);
    const diffInDays = Math.abs(moment(this.timezoneService.getCurrentDate()).diff(moment(date), 'days'));

    return diffInDays === 1 ? `${diffInDays} day` : `${diffInDays} days`;
  }
}
