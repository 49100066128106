import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

@Pipe({
  name: 'findById',
})
export class FindByIdPipe implements PipeTransform {
  transform<T>(id: number, list: T[]): T {
    return find(list, { id }) as T;
  }
}
