import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReadOnlyFieldComponent } from './read-only-field.component';

@NgModule({
  declarations: [ReadOnlyFieldComponent],
  imports: [CommonModule],
  exports: [ReadOnlyFieldComponent],
})
export class ReadOnlyFieldModule {}
