import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import { DateDropdownModule } from '../../controls/date-dropdown/date-dropdown.module';
import { RadioButtonDropdownModule } from '../../controls/radio-button-dropdown/radio-button-dropdown.module';
import { IconComponent } from '../icon/icon.component';
import { SearchInputModule } from '../search-input/search-input.module';

@UntilDestroy()
@Component({
  selector: 'app-turnover-filter-menu',
  templateUrl: './turnover-filter-menu.component.html',
  styleUrls: ['./turnover-filter-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DateDropdownModule,
    IconComponent,
    MatButtonModule,
    MatMenuModule,
    TooltipDirectiveModule,
    PipesModule,
    RadioButtonDropdownModule,
    SearchInputModule,
    ReactiveFormsModule,
    StopPropagationDirective,
  ],
})
export class TurnoverFilterMenuComponent extends ComponentAbstract implements OnInit {
  @Input() filter: { [field: string]: any };
  @Input() showClearFilters = true;
  @Input() excludedProps: string[] = [];
  @Input() padding = '0';

  @Output() filterCleared = new EventEmitter<void>();

  constructor(protected cdr: ChangeDetectorRef, protected elementRef: ElementRef) {
    super(cdr);
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  clearFilter() {
    this.filterCleared.emit();
  }
}
