import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent extends CustomControlAbstract<any> implements OnInit, AfterViewInit, ControlValueAccessor {
  disabled = false;

  @Input() prefix: string;
  @Input() attrPlaceholder: string;
  @Input() attrLabel: string;
  @Input() infoTooltip: string;
  @Input() attrType = 'text';
  @Input() labelCss = 'label-input body-small-bold';
  @Input() contentCss = 'display-flex align-items-start flex-column';
  @Input() containerCss = 'display-flex flex-column';
  @Input() inputCss = 'display-flex align-items-center';
  @Input() matFormCss = '';
  @Input() attrAutocomplete = false;
  @Input() attrValidation = true;
  @Input() attrMask = '';
  @Input() attrThousandSeparator = '';
  @Input() attrDropSpecialCharacters = null;
  @Input() errorSection = true;
  @Input() markAsInvalid = false;
  @Input() labelRequired: boolean;
  @Input() displayCounter = false;
  @Input() isFocused = false;
  @Input() maxCounter = 100;
  @Input() maxLength: number;
  @Input() readonly = false;
  @Input() useCurrencyFormatter = false;
  @Input() displaySkeleton = false;

  @Output() blurEvent = new EventEmitter<FocusEvent>();
  @Output() keydownEnterEvent = new EventEmitter<Event>();
  @ViewChild('inputControl', { read: ElementRef }) inputControl: ElementRef<HTMLInputElement>;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  @Input() set attrDisable(attrDisable: boolean) {
    if (attrDisable) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.control.markAsUntouched();
    this.control.markAsPristine();
    this.disabled = attrDisable;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.initControlBase();
    // The logic was provided to hide placeholders via re rendering view. ; otherwise, both placeholders and values are binged displayed together.
    // the problem was related to mask
    // find better solution using placeholder.pipe.ts
    // This part can be removed after becoming familiar.
    // setTimeout(() => this.cdr.detectChanges()); //has no idea what for, check if it can be deleted
  }

  writeValue(value: any): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  onBlur($event: FocusEvent) {
    this.blurEvent.emit($event);
    this.onTouched();
  }

  focus() {
    this.inputControl?.nativeElement.focus();
  }

  keydownEnter($event: Event) {
    if (this.control.value) {
      $event.stopPropagation();
      $event.preventDefault();
      this.keydownEnterEvent.emit($event);
    }
  }

  removeFocus() {
    this.inputControl.nativeElement.blur();
  }

  ngAfterViewInit() {
    if (this.inputControl && this.isFocused) {
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
    if (this.useCurrencyFormatter) {
      const initialValue = this.control.value;
      this.control.setValue(initialValue);
    }
  }
}
