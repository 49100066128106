import { Pipe, PipeTransform } from '@angular/core';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { getCurrentWorkflowStep } from '@shared/functions/get-current-workflow-step.function';
import { RestTurnoverDataModel, RestWorkflowInstanceModel } from '@shared/interfaces/turnover.interface';

@Pipe({
  name: 'getWorkflowStep',
})
export class GetWorkflowStepPipe implements PipeTransform {
  transform(turnoverData: RestTurnoverDataModel, step: WorkflowStepEnumType): RestWorkflowInstanceModel {
    return getCurrentWorkflowStep(step, turnoverData.workflow);
  }
}
