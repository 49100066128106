import { createReducer, on } from '@ngrx/store';

import { RestTicketModel } from '@shared/interfaces/turnover.interface';

import {
  clearPunchesByTurnoverId,
  clearSelectedPunch,
  loadPunchByIdFinished,
  loadPunchesByTurnoverId,
  loadPunchesByTurnoverIdFinished,
} from '../actions/punch.actions';

export const punchFeatureKey = 'punch';

export interface PunchState {
  punchesByTurnoverId?: RestTicketModel[];
  punchesByTurnoverIdLoading?: boolean;
  selectedPunch?: RestTicketModel;
}

export const initialState: PunchState = {};

export const reducer = createReducer(
  initialState,
  on(loadPunchesByTurnoverId, (state, action) => ({ ...state, punchesByTurnoverIdLoading: true })),
  on(loadPunchesByTurnoverIdFinished, (state, action) => ({
    ...state,
    punchesByTurnoverIdLoading: false,
    punchesByTurnoverId: action.result,
  })),
  on(clearPunchesByTurnoverId, (state, action) => ({
    ...state,
    punchesByTurnoverIdLoading: false,
    punchesByTurnoverId: null,
  })),
  on(loadPunchByIdFinished, (state, action) => ({ ...state, selectedPunch: action.punch })),
  on(clearSelectedPunch, (state, action) => ({ ...state, selectedPunch: null }))
);
