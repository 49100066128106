import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

const OIDC_STORAGE_KEY_PREFIX = 'oidc_key';

@Injectable({
  providedIn: 'root',
})
export class OidcStorageService implements AbstractSecurityStorage {
  read(key: string) {
    return localStorage.getItem(this.getKey(key));
  }

  write(key: string, value: any): void {
    localStorage.setItem(this.getKey(key), value);
  }

  remove(key: string): void {
    localStorage.removeItem(this.getKey(key));
  }

  clear(): void {
    Object.keys(localStorage)
      .filter(key => key.startsWith(OIDC_STORAGE_KEY_PREFIX))
      .forEach(key => {
        localStorage.removeItem(key);
      });
  }

  private getKey(key: string) {
    return `${OIDC_STORAGE_KEY_PREFIX}_${key}`;
  }
}
