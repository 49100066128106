<div class="button-container">
  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="ButtonType.DEFAULT" [ngTemplateOutlet]="defaultButtonTemplate"></ng-container>
    <ng-container *ngSwitchCase="ButtonType.STROKED" [ngTemplateOutlet]="strokedButtonTemplate"></ng-container>
    <ng-container *ngSwitchCase="ButtonType.RAISED" [ngTemplateOutlet]="raisedButtonTemplate"></ng-container>
  </ng-container>
</div>

<ng-template #defaultButtonTemplate>
  <button
    mat-button
    [color]="buttonColor"
    [disabled]="attrDisabled"
    [type]="type"
    (click)="buttonClick()"
    [ngClass]="buttonCss">
    <ng-container [ngTemplateOutlet]="buttonValueTemplate"></ng-container>
  </button>
</ng-template>
<ng-template #strokedButtonTemplate>
  <button
    mat-stroked-button
    [color]="buttonColor"
    [disabled]="attrDisabled"
    [type]="type"
    (click)="buttonClick()"
    [ngClass]="buttonCss">
    <ng-container [ngTemplateOutlet]="buttonValueTemplate"></ng-container>
  </button>
</ng-template>
<ng-template #raisedButtonTemplate>
  <button
    mat-raised-button
    [color]="buttonColor"
    [disabled]="attrDisabled"
    (click)="buttonClick()"
    [type]="type"
    [ngClass]="buttonCss">
    <ng-container [ngTemplateOutlet]="buttonValueTemplate"></ng-container>
  </button>
</ng-template>

<ng-template #buttonValueTemplate>
  <div class="display-flex align-items-center grid-gap-4">
    <mat-icon *ngIf="leftIcon">{{ leftIcon }}</mat-icon>
    <span [ngClass]="buttonLabelCss">{{ buttonLabel }}</span>
    <mat-spinner *ngIf="isLoading" color="primary" diameter="20"></mat-spinner>
  </div>
</ng-template>
