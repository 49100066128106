import { Pipe, PipeTransform } from '@angular/core';

import { TimeInStep } from '@shared/interfaces/time-in-step.interface';

@Pipe({
  name: 'dayFromHours',
})
export class DayFromHoursPipe implements PipeTransform {
  transform(allHours: number): TimeInStep {
    const days = Math.floor(allHours / 24) || 0;
    const hours = allHours - days * 24 || 0;
    return { days, hours };
  }
}
