<ng-container *ngIf="isEnabled; else content">
  <ng-container *ngIf="(isTooltipMode | async) === false; else tooltipContent">
    <div
      [class.fade-truncate]="isOverflowed | async"
      appTextOverflowCheck
      [appTextOverflowCheckDisabled]="!!tooltip || !isEnabled"
      (textOverflowed)="enableTooltipMode()">
      {{ text }}
    </div>
  </ng-container>

  <ng-template #tooltipContent>
    <div [class.fade-truncate]="isOverflowed | async" [matTooltip]="tooltip ?? text">{{ text }}</div>
  </ng-template>
</ng-container>

<ng-template #content>
  {{ text }}
</ng-template>
