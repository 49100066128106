import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { DividerComponent } from './divider.component';

@NgModule({
  declarations: [DividerComponent],
  exports: [DividerComponent],
  imports: [CommonModule, MatDividerModule],
})
export class DividerModule {}
