import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SelectTreeModule } from '@ui-components/components/select-tree/select-tree.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonModule } from '@ui-components/controls/radio-button/radio-button.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

import { AddVendorUserModalComponent } from './add-vendor-user-modal.component';

@NgModule({
  declarations: [AddVendorUserModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    InputModule,
    RadioButtonDropdownModule,
    SelectTreeModule,
    RadioButtonModule,
  ],
})
export class AddVendorUserModalModule {}
