import { Pipe, PipeTransform } from '@angular/core';

import { RestUserModel } from '@shared/interfaces/user.interface';

@Pipe({
  name: 'mentionComment',
})
export class MentionCommentPipe implements PipeTransform {
  transform(value: string, users: RestUserModel[], userId: number): string {
    if (!value) {
      return value;
    }
    return value.replace(/\[~(\S*)\]/gim, (m, g) => {
      const user = users?.find(u => u.email === g);
      return user
        ? `<span class="user-mention ${userId === user.id ? 'me' : ''}" email="${user.email}">@${
            user.displayName
          }</span>`
        : m;
    });
  }
}
