import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeHtml',
})
export class EscapeHtmlPipe implements PipeTransform {
  transform(value: string, allowAngelBrackets = false): string {
    if (!value) {
      return '';
    }

    if (allowAngelBrackets) return value;

    return value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }
}
