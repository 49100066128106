export interface FileUploadModel {
  id: number;
  url: string;
  internalName: string;
  originalFileName: string;
  dateUploaded: string;
  size: number;
  cloudUri: string;
  attachmentLinks: string[];
  childRestResources: string[];
  hasChildRestResources: boolean;
  videoDurationInSeconds?: number;
  thumbnailFileUploadCloudUri?: string;
  imageOrientation: ImageOrientation;
}

export enum ImageOrientation {
  NA,
  PortraitUp,
  LandscapeRight,
}
