import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { LastItemParam } from '@shared/interfaces/last-item-param';

@Directive({
  selector: '[appLastElement]',
})
export class LastElementDirective {
  @Output() lastElementLoaded = new EventEmitter();

  @Input() set isLastElement(lastItemParam: LastItemParam) {
    if (lastItemParam.value && lastItemParam.itemsCount) {
      this.lastElementLoaded.emit();
    }
  }
}
