<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle">{{ header }}</div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" *ngIf="form">
      <div class="display-flex flex-column grid-gap-16">
        <div class="display-flex grid-gap-16">
          <app-input
            class="w-50"
            [attrLabel]="'First name'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="firstName"
            [attrPlaceholder]="'enter first name'"
            [labelRequired]="true"
            [showValidationError]="formSubmitted"></app-input>
          <app-input
            class="w-50"
            [attrLabel]="'Last name'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="lastName"
            [attrPlaceholder]="'enter last name'"
            [labelRequired]="true"
            [showValidationError]="formSubmitted"></app-input>
        </div>
        <app-input
          [attrLabel]="'Email'"
          [contentCss]="'display-flex flex-column fill'"
          [formControl]="email"
          [attrPlaceholder]="'enter email'"
          [labelRequired]="true"
          [showValidationError]="formSubmitted"></app-input>

        <div class="display-flex grid-gap-16">
          <app-input
            class="w-50"
            [attrLabel]="'Phone number'"
            [contentCss]="'display-flex flex-column fill'"
            [formControl]="phoneNumber"
            [attrMask]="'(000) 000-0000'"
            [attrPlaceholder]="'enter phone number'"
            [showValidationError]="formSubmitted"></app-input>
          <app-radio-button-dropdown
            [label]="'Native language'"
            [attrAppendTo]="'body'"
            [allowClear]="false"
            [allowSearch]="false"
            [items]="nativeLanguages"
            [formControl]="nativeLanguage"
            [showValidationError]="formSubmitted"></app-radio-button-dropdown>
        </div>

        <app-select-tree
          [label]="'Portfolio/Properties'"
          (selectedRoots)="selectedRootsChange($event)"
          [nodes]="root"
          [selectedNodes]="propertiesToSelect"
          [warningMessage]="warningMessage"
          [showValidationError]="formSubmitted"></app-select-tree>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-space-between">
    <button mat-stroked-button color="warn" class="modal-button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" class="modal-button" [disabled]="inProgress$ | async" (click)="save()">
      <div class="display-flex align-items-center grid-gap-4">
        {{ saveButtonText }}
        <mat-spinner *ngIf="inProgress$ | async" color="accent" diameter="20"></mat-spinner>
      </div>
    </button>
  </mat-dialog-actions>
</div>
