import { Pipe, PipeTransform } from '@angular/core';

import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

@Pipe({
  name: 'enumerationValue',
})
export class EnumerationValuePipe implements PipeTransform {
  transform<T>(
    value: T | string,
    enumList: IRadioButtonOption<T>[],
    showEmptyValue = false,
    defaultEmptyValue = ''
  ): T | string {
    return enumList?.find(enumItem => enumItem.value === value)?.label || (showEmptyValue ? defaultEmptyValue : value);
  }
}
