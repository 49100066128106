import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LeasingUnitsChartCardComponent } from '@dashboards/components/leasing-units-chart-card/leasing-units-chart-card.component';
import { ChartModule } from '@ui-components/components/chart/chart.module';
import { ChartCardModule } from '@ui-components/components/chart-card/chart-card.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';

@NgModule({
  declarations: [LeasingUnitsChartCardComponent],
  imports: [SlideToggleComponent, CommonModule, ChartCardModule, ChartModule, ReactiveFormsModule],
  exports: [LeasingUnitsChartCardComponent],
})
export class LeasingUnitsChartCardModule {}
