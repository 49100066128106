import { Injectable } from '@angular/core';
import { Observable, catchError, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { TurnoverConfigurationStepAssigneesService } from '@main-application/management/pages/turnover-configuration/components/turnover-configuration-step-assignees/services/turnover-configuration-step-assignees.service';
import { TurnoverTransitionSource } from '@shared/enums/turnover-transition-source';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import {
  RestTurnoverDataModel,
  RestTurnoverTaskModel,
  isRestTurnoverTaskModel,
} from '@shared/interfaces/turnover.interface';
import { WorkflowAction } from '@shared/interfaces/workflow-action.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { ModalsService } from '@ui-components/modals/modals.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowActionsService {
  isModalOpen = false;

  constructor(
    private restApiService: RestapiService,
    private snackbarService: SnackbarService,
    private responsiblePartiesService: TurnoverConfigurationStepAssigneesService,
    protected modalsService: ModalsService
  ) {}

  transitWorkflowStepAssignee(
    turnoverId: number,
    stepType: WorkflowStepEnumType,
    assigneeId: number,
    source: TurnoverTransitionSource
  ): Observable<boolean> {
    return this.restApiService
      .create<boolean>(`turnovers/${turnoverId}/transit/${stepType}/${assigneeId}?source=${source}`, {})
      .pipe(
        catchError(err => {
          this.snackbarService.error(SnackbarErrorMessage.ErrorUpdatingTurnoverStepAssignee);
          throw err;
        })
      );
  }

  setWorkflowStepAssignee(
    turnoverId: number,
    stepType: WorkflowStepEnumType,
    assigneeId: number,
    rememberIt = false
  ): Observable<boolean> {
    return this.restApiService
      .associate<boolean>(`WorkflowActions/${turnoverId}/${stepType}/${assigneeId}?rememberIt=${rememberIt}`, null)
      .pipe(
        catchError(err => {
          this.snackbarService.error(SnackbarErrorMessage.ErrorUpdatingTurnoverStepAssignee);
          throw err;
        })
      );
  }

  moveToNextStep(
    turnoverId: number,
    stepType: WorkflowStepEnumType,
    assigneeId: number,
    boardId: number = null,
    rememberIt = false
  ) {
    if (typeof assigneeId === 'number')
      return this.restApiService.post(
        `turnovers/${turnoverId}/transit/${stepType}/${assigneeId}?rememberIt=${rememberIt}&source=${
          boardId ? `1&sourceId=${boardId}` : '0'
        }`
      );
    return this.restApiService.post(
      `turnovers/${turnoverId}/transit/${stepType}?source=${boardId ? `1&sourceId=${boardId}` : '0'}`
    );
  }

  moveToNextStepWithDefaultAssignee(
    turnover: RestTurnoverDataModel | RestTurnoverTaskModel,
    stepType: WorkflowStepEnumType,
    boardId: number = null
  ) {
    return this.getAssignee(turnover, stepType).pipe(
      take(1),
      mergeMap(assigneeInfo => {
        if (!assigneeInfo) {
          return of(false);
        }
        return this.moveToNextStep(
          turnover.id,
          stepType,
          assigneeInfo.assigneeId,
          boardId,
          assigneeInfo.rememberIt ?? false
        ).pipe(map(() => true));
      })
    );
  }

  getAssignee(
    turnover: RestTurnoverDataModel | RestTurnoverTaskModel,
    stepType: WorkflowStepEnumType
  ): Observable<WorkflowAction> {
    if (stepType === WorkflowStepEnumType.Archive) {
      return of({} as WorkflowAction);
    }

    let turnoverData: RestTurnoverDataModel;
    let propertyId: number;
    let propertyName: string;
    if (isRestTurnoverTaskModel(turnover)) {
      turnoverData = turnover.turnoverData as RestTurnoverDataModel;
      propertyId = turnover.propertyId;
      propertyName = turnover.propertyName;
    } else {
      turnoverData = turnover;
    }
    propertyId = propertyId ?? turnoverData.property?.id;
    propertyName = propertyName ?? turnoverData.property?.name;

    const currentAssigneeId = turnoverData.workflow.find(e => e.workflowStepEnumType === stepType)?.responsiblePartyId;
    if (currentAssigneeId) {
      return of({ assigneeId: currentAssigneeId } as WorkflowAction);
    }
    return this.responsiblePartiesService.getResponsiblePartiesByPropertyId(propertyId).pipe(
      map(list => list?.find(step => step.workflowStepEnumType === stepType)?.responsiblePartyId),
      switchMap(defaultAssigneeId => {
        if (defaultAssigneeId) {
          return of({ assigneeId: defaultAssigneeId } as WorkflowAction);
        } else {
          if (this.isModalOpen) {
            return of(null);
          }
          this.isModalOpen = true;
          return this.modalsService
            .openNextStepAssigneeModal(turnoverData, stepType, propertyId, propertyName)
            .afterClosed()
            .pipe(tap(() => (this.isModalOpen = false)));
        }
      })
    );
  }
}
