import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';
import { TenantSettingsModel } from '@main-application/management/pages/turnover-configuration/components/turnover-configuration-elapsed-days-section/models/config.models';
import { TenantData } from '@shared/interfaces/tenant-data';

import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private restApiService: RestapiService, private cacheService: CacheService) {}

  getCachedTenant(): Observable<TenantData> {
    return this.cacheService.getCachedInfo('getTenant', 0, () => this.getTenant());
  }

  private getTenant(): Observable<TenantData> {
    return this.restApiService.getData<TenantData>(
      'system/tenantInfo',
      TimezoneEntityHelper.fixTimezoneForTenantDataToClient
    );
  }

  updateTenantSettings(settings: Partial<TenantSettingsModel>): Observable<TenantSettingsModel> {
    return this.restApiService.post<TenantSettingsModel>('adminPanel/tenantSettings', settings);
  }
}
