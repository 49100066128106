export function resizeObserver(element: HTMLElement, callback: () => void): ResizeObserver | null {
  if (typeof ResizeObserver === 'undefined') {
    console.warn('ResizeObserver is not supported in this browser.');
    return null;
  }

  const resizeObserver = new ResizeObserver(callback);
  resizeObserver.observe(element);

  return resizeObserver;
}
