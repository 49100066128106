import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysPast',
})
export class DaysPastPipe implements PipeTransform {
  transform(daysInStep: { days: number; hours: number }, discardHoursPart = true): string {
    const { days, hours } = daysInStep;
    return !days && !hours ? null : `${discardHoursPart || hours <= 12 ? daysInStep.days : daysInStep.days + 1}`;
  }
}
