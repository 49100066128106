export enum ChartTypes {
  PIE = 'pie',
  BAR = 'bar',
  DOUGHNUT = 'doughnut',
  LINE = 'line',
}

export enum ChartSelectorTypes {
  DashboardLeftSelector = 'dashboardLeft',
  DashboardRightSelector = 'dashboardRight',
}

export enum WorkflowActionType {
  Hover,
  Leave,
  Select,
}
