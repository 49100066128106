import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestWorkflowInstanceModel } from '@shared/interfaces/turnover.interface';

export function getCurrentWorkflowStep(
  workflowStep: WorkflowStepEnumType,
  workflow: RestWorkflowInstanceModel[]
): RestWorkflowInstanceModel {
  return (workflow || []).find(value => value.workflowStepEnumType === workflowStep);
}

export function getCompletedWorkflowStep(
  workflowStep: WorkflowStepEnumType,
  workflow: RestWorkflowInstanceModel[]
): RestWorkflowInstanceModel {
  return (workflow || []).find(value => value.workflowStepEnumType === workflowStep && value.isCompleted);
}
