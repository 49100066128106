import { Pipe, PipeTransform } from '@angular/core';

import { getUtc } from '@shared/functions/get-utc.function';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(date: any): Date {
    if (!date) {
      return null;
    }
    return getUtc(date);
  }
}
