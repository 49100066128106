export enum ThresholdType {
  None = 0,

  //PropertyKPI
  GeneralOccupancy = 1,
  PendingMoveouts = 2,
  PreleasedUnits = 3,
  AnticipatedOccupancy = 4,
  DelinquentUnit = 5,
  PendingExpirations = 6,
  MoveoutsConfirmations = 7,

  //DelinquencyKPI
  DelayedPayment = 8,
  Eviction = 9,
  PaymentPlan = 10,
  PaidInFull = 11,
  Asstance = 12,
  MovedOut = 13,
  SmallBalance = 14,
  PendingCredit = 15,
  NTVMet = 16,

  //RenewalsKPI
  Negotiation = 17,
  Holdover = 18,
  MonthToMonth = 19,
  TenantNotice = 20,
  Renewal = 21,
  MgmtNotice = 22,
  Transfer = 23,

  //Turnovers
  TurnoverRootNode = 24,
  TurnoverSubNode = 25,
}
