import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppVersion } from '@shared/interfaces/app-version';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const versionInfo: AppVersion = require('../../version/app-version.json');

export const APP_VERSION = 'APP_VERSION';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  constructor(private http: HttpClient) {}

  private readonly version = new BehaviorSubject<AppVersion>({ version_timestamp: 0, version_number: '' });
  readonly version$ = this.version.asObservable();

  pull() {
    this.emitVersion();
    setInterval(() => {
      this.emitVersion();
    }, 3 * 60 * 1000);
  }

  getCurrentVersion(): string {
    const version = sessionStorage.getItem(APP_VERSION);
    if (version !== null) {
      try {
        const appVersion = JSON.parse(version) as AppVersion;
        return `${appVersion.version_number} (${appVersion.version_timestamp})`;
      } catch (e) {}
    }
    return '';
  }

  getBuiltInVersionInfo(): AppVersion {
    return versionInfo;
  }

  private emitVersion() {
    this.getVersion().subscribe(data => {
      this.version.next(data);
      this.saveCurrentVersion(data);
    });
  }

  private getVersion(): Observable<AppVersion> {
    return this.http.get<AppVersion>(`/version/app-version.json?dt=${Date.now()}`);
  }

  private saveCurrentVersion(data: AppVersion) {
    sessionStorage.setItem(APP_VERSION, JSON.stringify(data));
  }
}
