import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, mergeMap } from 'rxjs';

import { AppVersionService } from '@app/services/app-version.service';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';

import { environment } from '../../environments/environment';

export type RequiredRatioType = '5:1' | '16:9' | '4:1';

@Injectable({
  providedIn: 'root',
})
export class RestapiServiceWithoutTimezone {
  constructor(protected http: HttpClient, protected appVersionService: AppVersionService) {}

  /**
   * @title Repository Service
   *
   * TODO: Add pagination support. https://medium.com/@JeremyLaine/server-side-pagination-and-filtering-with-angular-6-280a7909e783
   */

  public getData<T>(
    route: string,
    customTimezoneFixer: (data: T, timezone: RestTimezone) => T = null,
    type: 'json' | 'html' | null = null,
    apiVersion?: string
  ): Observable<T> {
    const options = { ...this.generateHeaders() };
    if (type === 'html') options['responseType'] = 'text';

    const url = apiVersion ? environment.apiUrl + `/${apiVersion}` : environment.apiUrl;

    return this.http.get<T>(this.composeRoute(route, url), options);
  }

  public customPatchData<T>(route: string, body: unknown): Observable<T> {
    return this.http.patch<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public post<T>(route: string, body?: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public create<T>(route: string, body: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public associate<T>(route: string, body: unknown): Observable<T> {
    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public update<T>(route: string, body: unknown): Observable<T> {
    return this.http.put<T>(this.composeRoute(route, environment.apiUrl), body, this.generateHeaders());
  }

  public delete<T>(route: string): Observable<T> {
    return this.http.delete<T>(this.composeRoute(route, environment.apiUrl), this.generateHeaders());
  }

  public upload<T>(
    route: string,
    file: File,
    options: { reportProgress?: boolean } = {},
    requiredRatio?: '5:1' | '16:9' | '4:1',
    requiredRationMin?: '5:1' | '16:9' | '4:1'
  ): Observable<T> {
    const formData = new FormData();
    formData.append('file', file);

    let params = new HttpParams();
    if (requiredRatio) {
      params = params.set('requiredRatio', requiredRatio);
    }
    if (requiredRationMin) {
      params = params.set('requiredRationMin', requiredRationMin);
    }
    const requestOptions = { ...options, params };

    return this.http.post<T>(this.composeRoute(route, environment.apiUrl), formData, requestOptions);
  }

  public uploadProgress(route: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(this.composeRoute(route, environment.apiUrl), formData, {
      reportProgress: true,
      responseType: 'json',
      observe: 'events',
    });
  }

  public download(route: string): Observable<Blob> {
    return this.http.get(this.composeRoute(route, environment.apiUrl), {
      responseType: 'blob',
    });
  }

  public downloadWithPost<T>(route: string, payload: T): Observable<Blob> {
    return this.http.post(this.composeRoute(route, environment.apiUrl), payload, {
      responseType: 'blob',
    });
  }

  public getUrl(route: string): string {
    return this.composeRoute(route, environment.apiUrl);
  }

  private composeRoute(route: string, environmentUrl: string): string {
    return `${environmentUrl}/${route}`;
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({ 'X-PU-VERSION': this.appVersionService.getCurrentVersion() }),
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class RestapiService extends RestapiServiceWithoutTimezone {
  constructor(http: HttpClient, private timezoneService: TimezoneService, appVersionService: AppVersionService) {
    super(http, appVersionService);
  }

  public getData<T>(
    route: string,
    customTimezoneFixer: (data: T, timezone: RestTimezone) => T = null,
    type: 'json' | 'html' | null = null,
    v?: string
  ): Observable<T> {
    return super
      .getData<T>(route, null, type, v)
      .pipe(
        this.timezoneService.addTimezoneForEntity(
          customTimezoneFixer ?? TimezoneEntityHelper.fixTimezoneForModelToClient
        )
      );
  }

  public customPatchData<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: RestTimezone) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: RestTimezone) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.customPatchData<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public post<T, TBody = any>(
    route: string,
    body?: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: RestTimezone) => TBody = null,
    customTimezoneFixerResponse: (data: T, timezone: RestTimezone) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.post<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponse ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public create<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: RestTimezone) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: RestTimezone) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.create<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }

  public update<T, TBody = any>(
    route: string,
    body: TBody,
    customTimezoneFixerRequest: (data: TBody, timezone: RestTimezone) => TBody = null,
    customTimezoneFixerResponce: (data: T, timezone: RestTimezone) => T = null
  ): Observable<T> {
    return this.timezoneService.currentTimezone$.pipe(
      map(timezone => (customTimezoneFixerRequest ?? TimezoneEntityHelper.fixTimezoneForModelToServer)(body, timezone)),
      mergeMap(newBody => super.update<T>(route, newBody)),
      this.timezoneService.addTimezoneForEntity(
        customTimezoneFixerResponce ?? TimezoneEntityHelper.fixTimezoneForModelToClient
      )
    );
  }
}
