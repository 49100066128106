<div mat-dialog-title class="display-flex modal-header">
  <div class="subtitle">Add bid</div>
</div>
<mat-dialog-content>
  <form [formGroup]="form" class="display-flex flex-column grid-gap-16">
    <app-attachment
      [label]="'Upload bid'"
      [labelRequired]="true"
      [keepSelection]="true"
      (attachmentItemEvent)="fileUploaded($event)"
      [isEmpty]="form.controls.file.invalid"
      [allowLink]="false"
      [showValidationError]="formSubmitted"></app-attachment>

    <div class="display-flex grid-gap-16">
      <app-radio-button-dropdown
        class="flex-1"
        [containerCss]="'grid-gap-4'"
        labelCss="'nowrap"
        [label]="'Vendor'"
        [labelRequired]="true"
        [allowClear]="true"
        formControlName="vendorId"
        [items]="vendorList"
        [attrPlaceholder]="'select vendor'"
        [attrAppendTo]="'body'"
        [allowAddNew]="true"
        [showValidationError]="formSubmitted"
        [required]="true"
        [addNewLabel]="'+ Add new vendor'"
        (addNewClicked)="addNewVendor()"></app-radio-button-dropdown>
      <app-radio-button-dropdown
        class="flex-1"
        [containerCss]="'grid-gap-4'"
        labelCss="'nowrap"
        [label]="'Category'"
        [labelRequired]="true"
        [allowClear]="true"
        formControlName="categoryId"
        [items]="categoryList"
        [attrPlaceholder]="'select category'"
        [attrAppendTo]="'body'"
        [allowAddNew]="true"
        [showValidationError]="formSubmitted"
        [required]="true"
        [addNewLabel]="'+ Add category'"
        (addNewClicked)="addNewCategory()"></app-radio-button-dropdown>
    </div>
    <div class="display-flex grid-gap-16">
      <app-input
        class="flex-1"
        [attrLabel]="'Bid amount'"
        [labelRequired]="true"
        [prefix]="'$'"
        labelCss="'nowrap"
        [contentCss]="'display-flex flex-column grid-gap-2'"
        formControlName="bidAmount"
        [required]="true"
        [attrMask]="'separator.2'"
        attrThousandSeparator=","
        [showValidationError]="formSubmitted"
        [inputCss]="'display-flex align-items-center h-40'"></app-input>
      <app-date-dropdown
        class="flex-1"
        matTooltip="Date of bid"
        formControlName="bidDate"
        labelCss="'nowrap"
        [label]="'Date'"
        [valueCss]="'pre-option text-color dark'"
        [buttonCss]="'w-100'"
        [min]="today"
        [required]="true"
        [containerCss]="'display-flex flex-column'"
        [labelRequired]="true"
        [editable]="true"
        [showValidationError]="formSubmitted"
        [attrPlaceholder]="'select date'"></app-date-dropdown>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="display-flex grid-gap-8 justify-content-space-between w-100">
    <button mat-stroked-button color="warn" class="modal-button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" class="modal-button" [disabled]="inProgress$ | async" (click)="save()">
      <div class="display-flex align-items-center grid-gap-4">
        Add
        <mat-spinner *ngIf="inProgress$ | async" color="accent" diameter="20"></mat-spinner>
      </div>
    </button>
  </div>
</mat-dialog-actions>
