import { createReducer, on } from '@ngrx/store';

import { TenantData } from '@shared/interfaces/tenant-data';
import { UserData } from '@shared/interfaces/user-data';
import { RestUserModel } from '@shared/interfaces/user.interface';

import {
  loadUserById,
  loadUserByIdFailed,
  loadUserByIdSucceded,
  loadUserDataSucceded,
  loadUserTenantSucceeded,
  setAuthenticated,
  setUserGeneralRole,
} from '../actions/user.actions';

export const userFeatureKey = 'user';
export interface IUserStateTree {
  readonly [userFeatureKey]: UserState;
}

export interface UserState {
  tenantData?: TenantData;
  userData?: UserData;
  isAuthenticated?: boolean;
  selectedUser?: RestUserModel;
  selectedUserLoading?: boolean;
}

export const initialState = {
  isAuthenticated: false,
  userData: {},
  tenantData: {},
};

export const reducer = createReducer(
  initialState,
  on(loadUserDataSucceded, (state, action) => ({
    ...state,
    userData: action,
  })),

  on(loadUserTenantSucceeded, (state, action) => ({
    ...state,
    tenantData: action,
  })),

  on(setUserGeneralRole, (state, action) => ({
    ...state,
    userData: {
      ...state.userData,
      generalRole: action.roleName,
    },
  })),

  on(setAuthenticated, (state, action) => ({
    ...state,
    isAuthenticated: action.isAuthenticated,
  })),
  on(loadUserById, state => ({
    ...state,
    selectedUserLoading: true,
  })),
  on(loadUserByIdSucceded, (state, action) => ({
    ...state,
    selectedUser: action,
    selectedUserLoading: false,
  })),
  on(loadUserByIdFailed, (state, action) => ({
    ...state,
    selectedUserLoading: false,
  }))
);
