import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomTooltipComponent } from './custom-tooltip.component';

@NgModule({
  declarations: [CustomTooltipComponent],
  imports: [CommonModule],
  exports: [CustomTooltipComponent],
})
export class CustomTooltipModule {}
