import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTemplate from './template.reducer';

export const selectTemplateState = createFeatureSelector<fromTemplate.TemplateState>(fromTemplate.templateFeatureKey);

export const selectTemplates = createSelector(selectTemplateState, templateState => templateState.listItems);

export const selectAvailableTemplates = createSelector(selectTemplateState, templateState =>
  (templateState?.listItems || []).filter(t => t.isAvailable && !t.isScheduledForDeletion)
);

export const selectTemplatesLoading = createSelector(
  selectTemplateState,
  templateState => templateState.listItemsLoading
);

export const selectTemplateUpdate = createSelector(
  selectTemplateState,
  templateState => templateState.entityUpdateOngoing
);

export const selectTemplateAreaUpdateOngoing = createSelector(
  selectTemplateState,
  templateState => templateState.templateAreaUpdateOngoing
);

export const selectTemplateAreaDeleteOngoing = createSelector(
  selectTemplateState,
  templateState => templateState.templateAreaRemoveOngoing
);

export const selectUnitTypes = createSelector(selectTemplateState, templateState => templateState.unitTypes);
