import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textMask',
})
export class TextMaskPipe implements PipeTransform {
  transform(value: string, limit = 3, sign = '...'): string {
    if (!value) {
      return '';
    }

    if (value?.length <= limit) {
      return value;
    }

    return `${value.slice(0, limit)}${sign}`;
  }
}
