import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { FileUploadsService } from '@app/services/file-uploads.service';
import { copyToClipboard } from '@shared/functions/copy-to-clipboard.function';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { FileMimeType } from '@shared/interfaces/file-mime-types';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { SnackbarErrorMessage } from '../customized-snackbar/snackbar-message.enum';

@UntilDestroy()
@Component({
  selector: 'app-attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentItemComponent extends ComponentAbstract {
  @Input() containerCss = '';
  @Input() attachmentItem: RestGenericTypedAttachment;
  @Input() newAttachmentItem: AttachmentItem;
  @Input() showDeleteBtn = true;
  @Input() trimLink = true;
  @Input() lockFields = false;
  @Input() lockDelete = false;
  @Input() labelCss = 'body-small';
  @Input() showFileSize = true;
  @Input() showFileName = true;
  @Input() showPreview = true;
  @Output() deleteEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private fileUploadService: FileUploadsService
  ) {
    super(cdr);
  }

  deleteConfirmation() {
    this.deleteEvent.emit();
  }

  copyLink() {
    const result = copyToClipboard(this.attachmentItem?.externalUrl || this.newAttachmentItem?.link);
    result
      ? this.snackbarService.success(SnackbarErrorMessage.WebsiteAddressCopied)
      : this.snackbarService.error(SnackbarErrorMessage.CopyingWebsiteAddress);
  }

  download() {
    if (this.attachmentItem) {
      this.fileUploadService.download(this.attachmentItem.fileUploadId);
    } else if (this.newAttachmentItem) {
      this.fileUploadService.download(this.newAttachmentItem.upload.id);
    }
  }

  getLink() {
    const link = this.attachmentItem?.externalUrl || this.newAttachmentItem?.link || '';
    return link.startsWith('http') ? link : `http://${link}`;
  }

  protected readonly FileMimeType = FileMimeType;
}
