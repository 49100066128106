import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@ui-components/components/icon/icon.component';

import { CustomDashboardComponent } from './custom-dashboard.component';

@NgModule({
  declarations: [CustomDashboardComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    IconComponent,
    TooltipDirectiveModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [CustomDashboardComponent],
})
export class CustomDashboardModule {}
