<div class="customized-snackbar-container position-relative">
  <button class="close-button" mat-icon-button (click)="dismiss()">
    <div class="display-flex justify-content-center align-items-center">
      <app-icon [iconHeight]="15" [iconWidth]="15" [iconColor]="EColorPalette.cWhite" [svg]="EIcon.CLOSE"></app-icon>
    </div>
  </button>
  <div class="display-flex grid-gap-16">
    <div *ngIf="data.icon">
      <mat-icon class="icon" [svgIcon]="data.icon"></mat-icon>
    </div>
    <div class="message-container">
      <span class="body-small" [innerHTML]="data.message | trustHtml"></span>
      <div *ngIf="loadingSpinner$ | async" class="circle-loading"></div>
    </div>
  </div>
</div>
