import { ActionReducer } from '@ngrx/store';

export const patchInitialValue = <T>(initialState: T, key: string): T => {
  const storageValue = localStorage.getItem(key);
  if (storageValue) {
    try {
      const parsedValue = JSON.parse(storageValue);
      return { ...initialState, ...parsedValue };
    } catch {
      localStorage.removeItem(key);
      return initialState;
    }
  }
  return initialState;
};

export const hydrationMetaReducer =
  <T>(featureKey: string) =>
  (reducer: ActionReducer<T>): ActionReducer<T> => {
    return (state, action) => {
      const nextState = reducer(state, action);
      localStorage.setItem(featureKey, JSON.stringify(nextState));
      return nextState;
    };
  };

export const dynamicHydrationMetaReducer =
  <T>(getDynamicFeatureKey: () => string, featureKey: string) =>
  (reducer: ActionReducer<T>): ActionReducer<T> => {
    return (state, action) => {
      const nextState = reducer(state, action);
      const key = getDynamicFeatureKey();
      if (key) {
        localStorage.setItem(key, JSON.stringify(nextState));
        localStorage.removeItem(featureKey);
      }
      return nextState;
    };
  };
