<div class="number-input-container" [ngClass]="[containerCss, classList]">
  <ng-container *ngIf="!labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
  <div class="display-flex flex-column fill">
    <div class="input-wrapper fill" [ngClass]="inputCss">
      <mat-form-field floatLabel="never">
        <ng-container *ngIf="labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
        <div class="input-container">
          <div class="prefix" [ngClass]="{ 'small-font': isSmallFont }">{{ prefix }}</div>
          <div class="input-text-resizer" [ngClass]="{ 'small-font': isSmallFont }">
            {{ inputControl.value }}
            <input
              aria-label="number-input"
              #inputControl
              matInput
              type="text"
              [formControl]="innerControl"
              [placeholder]="attrPlaceholder"
              (paste)="onPaste($event, inputControl)"
              [mask]="attrMask"
              [thousandSeparator]="attrThousandSeparator"
              [validation]="true"
              [allowNegativeNumbers]="true"
              [maxlength]="max" />
          </div>
          <div class="postfix" [ngClass]="{ 'small-font': isSmallFont }">{{ postfix }}</div>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>

<ng-template #labelTemplate>
  <div #labelElement class="label-container" *ngIf="label || icon">
    <label
      class="dropdown-label"
      [ngClass]="[
        labelCss,
        labelInside ? 'inside' : '',
        innerControl.disabled ? 'disable' : '',
        labelRequired ? 'label-required' : ''
      ]"
      [matTooltip]="labelTooltip"
      *ngIf="label">
      {{ label }}
    </label>
    <app-icon [matTooltip]="iconTooltip" *ngIf="icon" [svg]="icon" [iconHeight]="24" [iconWidth]="24"></app-icon>
  </div>
</ng-template>
