import { createReducer, on } from '@ngrx/store';

import {
  createTurnoverBid,
  createTurnoverBidFailed,
  createTurnoverBidSuccess,
  loadTurnoverBidCategoriesSuccess,
  loadTurnoverBids,
  loadTurnoverBidsFailed,
  loadTurnoverBidsSuccess,
} from '@main-application/turnovers/store/actions/turnover-bids.actions';
import { RestTurnoverBid, RestTurnoverBidCategory } from '@shared/interfaces/turnover-bids.interface';

export const turnoverBidsFeatureKey = 'turnover-bids';

export interface TurnoverBidsState {
  bidsLoading: boolean;
  bids: RestTurnoverBid[];
  bidCategories: RestTurnoverBidCategory[];
  bidAdding: boolean;
}

export const initialState: TurnoverBidsState = {
  bidsLoading: false,
  bids: [],
  bidCategories: [],
  bidAdding: false,
};

export const turnoverBidsReducer = createReducer(
  initialState,

  // load
  on(loadTurnoverBids, state => ({
    ...state,
    bidsLoading: true,
  })),
  on(loadTurnoverBidsSuccess, (state, action) => ({
    ...state,
    bids: action.bids,
    bidsLoading: false,
  })),
  on(loadTurnoverBidsFailed, state => ({
    ...state,
    bids: [],
    bidsLoading: false,
  })),

  // create
  on(createTurnoverBid, state => ({
    ...state,
    bidAdding: true,
  })),
  on(createTurnoverBidSuccess, state => ({
    ...state,
    bidAdding: false,
  })),
  on(createTurnoverBidFailed, state => ({
    ...state,
    bidAdding: false,
  })),
  on(loadTurnoverBidCategoriesSuccess, (state, action) => ({
    ...state,
    bidCategories: action.categories,
  }))
);
