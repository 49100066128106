import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { InputErrorModule } from '@app/modules/ui-components/components/input-error/input-error.module';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { AttachmentItemModule } from '@ui-components/components/attachment-item/attachment-item.module';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { InputModule } from '@ui-components/controls/input/input.module';
import { InputFileModule } from '@ui-components/controls/input-file/input-file.module';
import { RadioButtonModule } from '@ui-components/controls/radio-button/radio-button.module';

import { MultipleAttachmentsComponent } from './multiple-attachments.component';

@NgModule({
  declarations: [MultipleAttachmentsComponent],
  exports: [MultipleAttachmentsComponent],
  imports: [
    CommonModule,
    RadioButtonModule,
    ReactiveFormsModule,
    InputFileModule,
    InputModule,
    MatButtonModule,
    IconComponent,
    InputErrorModule,
    TooltipDirectiveModule,
    AttachmentItemModule,
    MatProgressSpinnerModule,
  ],
})
export class MultipleAttachmentsModule {}
