export enum WorkflowStepEnumType {
  None = 0,
  Notice = 50,
  MoveOut = 100,
  Bid = 200,
  MakeReady = 250,
  Reno = 300,
  Punch = 350,
  Approval = 400,
  Marketing = 500,
  LeaseUp = 600,
  MoveIn = 700,
  Archive = 800,
}
