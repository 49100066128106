import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { selectWorkflowStep } from '@shared/observables/select-workflow-step';

@Pipe({
  name: 'workflowStepName',
})
export class WorkflowStepNamePipe implements PipeTransform {
  constructor(private store: Store<{}>) {}

  transform(step: WorkflowStepEnumType): Observable<string> {
    return selectWorkflowStep(this.store, step).pipe(map(step => step.displayText));
  }
}
