<div class="summary-report-modal">
  <form [formGroup]="form" class="h-100">
    <div class="summary-report-modal__header">
      <div class="summary-report-modal__header__title">
        <h5>Inspections Summary Report</h5>

        <app-icon
          class="summary-icon cursor-pointer"
          [svg]="eIcon.DOWNLOAD_ICON"
          [iconColor]="eColorPalette.cGray5"
          [iconWidth]="20"
          [iconHeight]="20"
          (click)="onDownloadXlsFile()"></app-icon>
      </div>

      <div class="summary-report-modal__header__group">
        <p>Group by Property</p>

        <app-slide-toggle formControlName="groupByProperty"></app-slide-toggle>
      </div>
    </div>

    <div class="summary-report-modal__filter">
      <div class="summary-report-modal__filter__period">
        <p>Period:</p>

        <ng-container [ngTemplateOutlet]="(showCustomDatePickers$ | async) ? customDatePicker : dates"></ng-container>

        <app-select-filter
          [showBrackets]="periodControl.value.id !== 6"
          [selectedFilter]="periodControl.value"
          [selectFiltersConfig]="selectPeriodFiltersConfig"
          (selectedItemEvent)="selectedPeriod($event)"></app-select-filter>
      </div>

      <div>
        <p>Show By:</p>

        <div [style.margin-top]="data.isResident ? '0' : '1px'">
          <ng-container [ngTemplateOutlet]="data.isResident ? showByType : selectShowByFilter"></ng-container>
        </div>
      </div>
    </div>

    <ng-container
      [ngTemplateOutlet]="
        (isLoading$ | async) ? loading : (isDataEmpty$ | async) ? emptyDashboard : summaryReportData
      "></ng-container>
  </form>
</div>

<ng-template #loading>
  <app-summary-skeleton></app-summary-skeleton>
</ng-template>

<ng-template #summaryTable>
  <app-summary-table
    (filterInspectionListValue)="onFilterInspectionList($event)"
    [tableData]="summaryReportTableData | async"
    [showByType]="showByTypeControl.value"
    [templateScrollPointName]="(templateScrollPointName$ | async).table"></app-summary-table>
</ng-template>

<ng-template #expandedSummaryTable>
  <app-summary-expanded-table
    (filterInspectionListValue)="onFilterInspectionList($event)"
    [summaryExpandedTableData]="summaryReportTableExpandedData | async"
    [expandedRawsIds]="expandedRawsIds$ | async"
    [templateScrollPointName]="(templateScrollPointName$ | async).expanded"></app-summary-expanded-table>
</ng-template>

<ng-template #summaryReportData>
  <div class="summary-report-modal__body">
    <div class="summary-report-modal__body__table">
      <ng-container
        [ngTemplateOutlet]="groupByPropertyControl.value ? expandedSummaryTable : summaryTable"></ng-container>
    </div>

    <div class="summary-report-modal__body__chart">
      <app-summary-chart [summaryChartData]="summaryReportChartData | async"></app-summary-chart>
    </div>
  </div>
</ng-template>

<ng-template #emptyDashboard>
  <div class="empty-dashboard">
    <img width="284" height="284" src="assets/empty-dashboard.png" alt="" />
    <div class="image-description">{{ emptyDashboardMessage }}</div>
  </div>
</ng-template>

<ng-template #showByType>
  <p class="showTypeTitle">Type</p>
</ng-template>

<ng-template #selectShowByFilter>
  <app-select-filter
    [selectedFilter]="showByTypeControl.value"
    [selectFiltersConfig]="selectShowByFiltersConfig"
    (selectedItemEvent)="selectedShowBy($event)"></app-select-filter>
</ng-template>

<ng-template #dates>
  <span *ngIf="activeStartDate && activeEndDate">
    {{ activeStartDate.format('MM/DD/YYYY') }} - {{ activeEndDate.format('MM/DD/YYYY') }}
  </span>
</ng-template>

<ng-template #customDatePicker>
  <form [formGroup]="formCustomPeriod" class="custom-datepicker">
    <div></div>

    <div>
      <app-date-dropdown
        class="date-dropdown-sm"
        [isHideArrow]="true"
        formControlName="startDate"
        [max]="endDateControl.value"></app-date-dropdown>

      <span>-</span>

      <app-date-dropdown
        class="date-dropdown-sm"
        [isHideArrow]="true"
        formControlName="endDate"
        [min]="startDateControl.value"></app-date-dropdown>
    </div>
  </form>
</ng-template>
