import { TicketAttachmentType } from '@shared/enums/attachment-type';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { UpdateTypedAttachmentToEntityModel } from '@shared/interfaces/turnover.interface';

export function getAttachmentToEntityParams(
  attachmentItem: AttachmentItem,
  ticketId: number
): UpdateTypedAttachmentToEntityModel {
  let params: UpdateTypedAttachmentToEntityModel = {
    description: '',
    externalUrl: '',
    title: '',
    unitId: 0,
    propertyId: 0,
    portfolioId: 0,
    turnoverIds: [],
    applianceId: 0,
    bidId: 0,
    ticketId: ticketId,
    attachmentType: TicketAttachmentType.Attachment,
  };

  if (attachmentItem?.link) {
    params = {
      ...params,
      externalUrl: attachmentItem.link,
    };
  } else {
    params = {
      ...params,
      fileUploadId: attachmentItem?.upload?.id,
      title: attachmentItem?.upload?.originalFileName,
      description: attachmentItem?.upload?.originalFileName,
    };
  }

  return params;
}
