import { Pipe, PipeTransform } from '@angular/core';

import { CompareOperator } from '@shared/enums/compare-operator.enum';

@Pipe({
  name: 'compareOperator',
})
export class CompareOperatorPipe implements PipeTransform {
  transform(value: CompareOperator): string {
    switch (value) {
      case CompareOperator.LESS: {
        return '<';
      }
      case CompareOperator.MORE: {
        return '>';
      }
      case CompareOperator.LESS_EQUAL: {
        return '&#x2264;';
      }
      case CompareOperator.MORE_EQUAL: {
        return '&#x2265;';
      }
      default: {
        return '';
      }
    }
  }
}
