import { Pipe, PipeTransform } from '@angular/core';

import { FormatAddressToStringFunction } from '@shared/functions/format-address-to-string.function';
import { RestAddressModel } from '@shared/interfaces/address.interface';

@Pipe({
  name: 'physicalAddress',
})
export class PhysicalAddressPipe implements PipeTransform {
  transform(address: RestAddressModel): string {
    return FormatAddressToStringFunction(address);
  }
}
