import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

import { WysiwygEditorComponent } from '@ui-components/components/wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  declarations: [WysiwygEditorComponent],
  imports: [CommonModule, QuillModule, ReactiveFormsModule],
  exports: [WysiwygEditorComponent],
})
export class WysiwygEditorModule {}
