import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ScoringModels } from '@main-application/inspections/models/rest-inspections-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ScoringModelsService {
  private scoringModelSubject = new BehaviorSubject<ScoringModels>(0);
  private scoreWeightItems = [
    { label: 'Auto', value: 100 },
    { label: '90%', value: 90 },
    { label: '80%', value: 80 },
    { label: '70%', value: 70 },
    { label: '60%', value: 60 },
    { label: '50%', value: 50 },
    { label: '40%', value: 40 },
    { label: '30%', value: 30 },
    { label: '20%', value: 20 },
    { label: '10%', value: 10 },
    { label: 'Custom', value: 0 },
  ];

  private customWeightsSubject = new BehaviorSubject<number[]>([]);

  scoringModel$ = this.scoringModelSubject.asObservable();
  combinedWeights$ = this.customWeightsSubject.asObservable();

  setScoringModel(value: ScoringModels): void {
    this.scoringModelSubject.next(value);
  }

  getCombinedWeights(): { label: string; value: number }[] {
    const customWeights = this.customWeightsSubject.getValue();
    const allWeights = [
      ...this.scoreWeightItems,
      ...customWeights
        .filter(weight => !this.scoreWeightItems.some(item => item.value === weight))
        .map(weight => ({ label: `${weight}%`, value: weight })),
    ];

    allWeights.sort((a, b) => {
      if (a.value === 100) return -1; // 'Auto' always the first
      if (b.value === 100) return 1;
      if (a.value === 0) return 1; // 'Custom' always the last
      if (b.value === 0) return -1;
      return b.value - a.value;
    });

    return allWeights;
  }

  addCustomWeight(weight: number): void {
    const customWeights = this.customWeightsSubject.getValue();
    if (!customWeights.includes(weight)) {
      this.customWeightsSubject.next([...customWeights, weight]);
    }
  }
}
