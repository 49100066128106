import { createReducer, on } from '@ngrx/store';

import { InspectionsListConstants } from '@main-application/inspections/components/inspections-list/constants';
import {
  InspectionSummaryAppliedFilters,
  InspectionSummaryStatsResponse,
} from '@main-application/inspections/models/inspection-summary.model';
import getInspectionsSummaryFiltersKey from '@main-application/inspections/utils/inspection-mappers';
import { IInspectionFilterForm } from '@template/interfaces/filter.interface';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import {
  clearAllInspections,
  clearDynamicInspections,
  clearInspectionsData,
  clearNewlyAddedInspectionId,
  clearRepeatingInspections,
  loadAllInspections,
  loadAllInspectionsFailed,
  loadAllInspectionsSuccess,
  loadDynamicInspections,
  loadDynamicInspectionsFailed,
  loadDynamicInspectionsSuccess,
  loadInReviewInspections,
  loadInReviewInspectionsSuccess,
  loadInspectionSummaryReport,
  loadInspectionSummaryReportFailed,
  loadInspectionSummaryReportSucceeded,
  loadRepeatingInspections,
  loadRepeatingInspectionsFailed,
  loadRepeatingInspectionsSuccess,
  loadTemplatesForUnitFailed,
  loadTemplatesForUnitSuccess,
  setInspectionsFilter,
  setSummaryReportAppliedFilter,
} from './inspections.actions';
import { RestDynamicInspectionModel, RestInspectionsModel } from '../models/rest-inspections-model.interface';

import TimeFrame = InspectionsListConstants.TimeFrame;

export const inspectionsFeatureKey = 'inspections';

export interface IInspectionsStateTree {
  readonly [inspectionsFeatureKey]: InspectionState;
}

export interface InspectionState {
  inspectionFilter: Partial<IInspectionFilterForm>;
  isLoaded: boolean;
  listItemsLoading?: boolean;
  inReviewListItemsLoading?: boolean;
  dynamicListItemsLoading?: boolean;
  repeatingListItemsLoading?: boolean;
  listItems?: RestInspectionsModel[];
  inReviewListItems?: RestInspectionsModel[];
  dynamicListItems?: RestDynamicInspectionModel[];
  repeatingListItems?: RestInspectionsModel[];
  templatesForUnit?: RestTemplateModel[];
  entityUpdateOngoing?: boolean;
  deleteEntityOngoing?: boolean;
  summaryReportData: { [filterKey: string]: InspectionSummaryStatsResponse };
  summaryReportAppliedFilter: InspectionSummaryAppliedFilters;
  summaryReportDataLoading?: boolean;
}

export const initialState: InspectionState = {
  inspectionFilter: {
    timeframe: TimeFrame.Week,
    status: null,
    unitOrAssignee: null,
    templateIds: null,
    assigneeId: null,
  },
  isLoaded: false,
  listItems: [],
  inReviewListItems: [],
  listItemsLoading: false,
  inReviewListItemsLoading: false,
  dynamicListItemsLoading: false,
  entityUpdateOngoing: false,
  deleteEntityOngoing: false,
  summaryReportData: {},
  summaryReportAppliedFilter: null,
  summaryReportDataLoading: true,
};

export const reducer = createReducer(
  initialState,
  /* Templates List */
  on(loadAllInspections, (state, action) => ({ ...state, listItemsLoading: true })),
  on(loadAllInspectionsSuccess, (state, action) => ({
    ...state,
    listItemsLoading: false,
    listItems: action.listItems,
    deleteEntityOngoing: false,
  })),
  on(loadAllInspectionsFailed, (state, action) => ({
    ...state,
    listItemsLoading: false,
    listItems: [],
    deleteEntityOngoing: false,
  })),
  on(clearAllInspections, state => ({
    ...state,
    listItems: [],
  })),
  on(loadDynamicInspections, (state, action) => ({ ...state, dynamicListItemsLoading: true })),
  on(loadDynamicInspectionsSuccess, (state, action) => ({
    ...state,
    dynamicListItemsLoading: false,
    dynamicListItems: action.listItems,
  })),
  on(loadDynamicInspectionsFailed, state => ({
    ...state,
    dynamicListItemsLoading: false,
  })),
  on(clearDynamicInspections, state => ({
    ...state,
    dynamicListItems: null,
  })),
  on(loadRepeatingInspections, (state, action) => ({ ...state, repeatingListItemsLoading: true })),
  on(loadRepeatingInspectionsSuccess, (state, action) => ({
    ...state,
    repeatingListItemsLoading: false,
    repeatingListItems: action.listItems,
  })),
  on(loadRepeatingInspectionsFailed, state => ({
    ...state,
    repeatingListItemsLoading: false,
  })),
  on(clearRepeatingInspections, state => ({
    ...state,
    repeatingListItems: null,
  })),
  on(clearInspectionsData, (state, action) => ({
    ...state,
    listItemsLoading: false,
    entityUpdateOngoing: false,
    deleteEntityOngoing: false,
  })),
  on(loadTemplatesForUnitSuccess, (state, action) => ({
    ...state,
    templatesForUnit: action.list,
  })),
  on(loadTemplatesForUnitFailed, (state, action) => ({
    ...state,
    templatesForUnit: [],
  })),
  on(clearNewlyAddedInspectionId, (state, action) => ({
    ...state,
    newlyAddedItemId: null,
  })),
  on(setInspectionsFilter, (state, action) => ({
    ...state,
    inspectionFilter: action,
  })),
  on(loadInspectionSummaryReport, (state, action) => ({
    ...state,
    summaryReportDataLoading: true,
  })),
  on(loadInspectionSummaryReportSucceeded, (state, action) => ({
    ...state,
    summaryReportDataLoading: false,
    summaryReportData: {
      ...state.summaryReportData,
      [getInspectionsSummaryFiltersKey(action.filter)]: action.payload,
    },
  })),
  on(setSummaryReportAppliedFilter, (state, action) => ({
    ...state,
    summaryReportAppliedFilter: action.appliedFilters,
  })),
  on(loadInspectionSummaryReportFailed, state => ({
    ...state,
    repeatingListItemsLoading: false,
    error: true,
  })),
  on(loadInReviewInspections, (state, action) => ({ ...state, listItemsLoading: true })),
  on(loadInReviewInspectionsSuccess, (state, action) => ({
    ...state,
    inReviewListItemsLoading: false,
    inReviewListItems: action.inReviewListItems,
  })),
  on(loadAllInspectionsFailed, (state, action) => ({
    ...state,
    inReviewListItemsLoading: false,
    inReviewListItems: [],
  })),
  on(clearAllInspections, state => ({
    ...state,
    inReviewListItems: [],
  }))
);
