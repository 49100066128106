import { PackageType } from '@shared/enums/package-type';
import { TenantData } from '@shared/interfaces/tenant-data';

export function checkTrialDaysLeft(tenantData: TenantData, packageType: PackageType, now: Date): number | undefined {
  if (!tenantData) {
    return undefined;
  }

  if (checkIfPackageEnabled(tenantData, packageType)) {
    return undefined;
  }

  switch (packageType) {
    case PackageType.Turns: {
      return daysLeft(tenantData.turnsPackageTrialEndOn, now);
    }
    case PackageType.Inspections: {
      return daysLeft(tenantData.inspectionPackageTrialEndOn, now);
    }
    case PackageType.InspectionsPro: {
      return daysLeft(tenantData.inspectionProPackageTrialEndsOn, now);
    }
    case PackageType.Service:
      return daysLeft(tenantData.servicePackageTrialExpiresOn, now);
    case PackageType.Residents:
      return undefined;
  }
}

export function checkIfPackageAvailable(tenantData: TenantData, packageType: PackageType, now: Date): boolean {
  if (!tenantData) {
    return false;
  }

  switch (packageType) {
    case PackageType.Turns: {
      return checkIfPackageEnabled(tenantData, packageType) || isTrialActive(tenantData.turnsPackageTrialEndOn, now);
    }
    case PackageType.Inspections: {
      return (
        checkIfPackageEnabled(tenantData, packageType) || isTrialActive(tenantData.inspectionPackageTrialEndOn, now)
      );
    }
    case PackageType.InspectionsPro: {
      return (
        checkIfPackageEnabled(tenantData, packageType) || isTrialActive(tenantData.inspectionProPackageTrialEndsOn, now)
      );
    }
    case PackageType.Service:
      return (
        checkIfPackageEnabled(tenantData, packageType) || isTrialActive(tenantData.servicePackageTrialExpiresOn, now)
      );
    case PackageType.Residents:
      return checkIfPackageEnabled(tenantData, packageType);
    case PackageType.CannedAnswers:
      return checkIfPackageEnabled(tenantData, packageType);
    case PackageType.SpareParts:
      return checkIfPackageEnabled(tenantData, packageType);
    case PackageType.ResidentPortalPackageEnabled:
      return checkIfPackageEnabled(tenantData, packageType);
    case PackageType.ResidentInspectionEnabled:
      return checkIfPackageEnabled(tenantData, packageType);
  }
}

function checkIfPackageEnabled(tenantData: TenantData, packageType: PackageType): boolean {
  if (!tenantData) {
    return false;
  }

  // TODO residentPortalPackageEnabled
  const packageMapping: Record<PackageType, boolean | undefined> = {
    [PackageType.Turns]: tenantData.turnsPackageEnabled,
    [PackageType.Inspections]: tenantData.inspectionPackageEnabled,
    [PackageType.Service]: tenantData.servicePackageEnabled,
    [PackageType.InspectionsPro]: tenantData.inspectionProPackageEnabled,
    [PackageType.Residents]: tenantData.residentPortalPackageEnabled,
    [PackageType.CannedAnswers]: tenantData.cannedAnswersEnabled,
    [PackageType.SpareParts]: tenantData.sparePartsPackageEnabled,
    [PackageType.ResidentPortalPackageEnabled]: tenantData.residentPortalPackageEnabled,
    [PackageType.ResidentInspectionEnabled]: tenantData.residentInspectionEnabled,
  };

  return !!packageMapping[packageType];
}

const isTrialActive = (date: Date, now: Date) => {
  return date && date.getTime() - now.getTime() > 0;
};

const daysLeft = (date: Date, now: Date): number | undefined => {
  if (!date || !now) {
    return undefined;
  }
  const time = date.getTime() - now.getTime();
  const days = Math.floor(time / (1000 * 60 * 60 * 24)) || 0;
  return days;
};
