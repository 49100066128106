import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

import { OidcStorageService } from '@app/services/oidc-storage.service';

export const AUTH_CONFIG_ID = 'authConfigId';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oidcSecurityService: OidcSecurityService, private oidcStorageService: OidcStorageService) {}

  public isAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated(this.getConfigId());
  }

  public getConfigId() {
    return localStorage.getItem(AUTH_CONFIG_ID);
  }

  public setConfigId(configId: string) {
    localStorage.setItem(AUTH_CONFIG_ID, configId);
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken(this.getConfigId());
  }

  public clearStorage() {
    this.oidcStorageService.clear();
  }

  public logout() {
    const currentConfigId = this.getConfigId();
    localStorage.removeItem(AUTH_CONFIG_ID);
    this.oidcSecurityService.logoff(currentConfigId);
  }
}
