<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle mr-4">
      Choose new assignee for {{ data.nextWorkflowStep | enumerationValue: workflowStepList }}
    </div>
  </div>

  <mat-dialog-content>
    <div class="display-flex flex-column grid-gap-32">
      <div class="display-flex flex-column grid-gap-16" *ngIf="form" [formGroup]="form">
        <app-read-only-field
          [containerCss]="'display-flex'"
          [labelCss]="'nowrap body-small-bold pr-6'"
          [label]="'Unit:'"
          [valueContainerCss]="'value-container fit-height'"
          [value]="propertyName + ' - ' + data.turnoverData?.unit?.name"></app-read-only-field>

        <app-user-select-dropdown
          [containerCss]="'display-flex flex-column turnover-select-next-step-assignee'"
          [containerWidthCss]="'turnover-select-next-step-assignee'"
          [allowClear]="false"
          [labelRequired]="true"
          [label]="(data.nextWorkflowStep | enumerationValue: workflowStepList) + ' assignee'"
          formControlName="assigneeId"
          [users]="userListModel"
          [attrAppendTo]="'body'"
          [attrPlaceholder]="'select assignee'"
          [listLoading]="propertyUserListLoading$ | async"></app-user-select-dropdown>

        <mat-checkbox color="primary" formControlName="rememberIt">remember my choice</mat-checkbox>
      </div>

      <div class="display-flex flex-1 justify-content-center grid-gap-16">
        <button mat-stroked-button color="warn" class="modal-button" (click)="close()">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          class="modal-button"
          (click)="save()"
          [disabled]="form.invalid || (updateInProgress$ | async)">
          <div class="display-flex align-items-center grid-gap-4">
            Done
            <mat-spinner *ngIf="updateInProgress$ | async" color="accent" diameter="20"></mat-spinner>
          </div>
        </button>
      </div>
      <div class="display-flex justify-content-center body-small">
        <span>
          Over this message?
          <a [routerLink]="['/', ManagementRoute.SETTINGS, ManagementRoute.TURNOVER_CONFIGURATION]" target="_blank">
            Choose all assignees here
          </a>
        </span>
      </div>
    </div>
  </mat-dialog-content>
</div>
