import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { isNumber } from 'lodash';
import { BehaviorSubject, take } from 'rxjs';

import { procentCut } from '@app/utils/procent-cut';
import { InspectionStatus } from '@main-application/inspections/models/rest-inspections-model.interface';
import { InspectionsFacade } from '@main-application/inspections/store/inspections.facade';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import {
  SUMMARY_TABLE_COLUMNS_RECORD,
  SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL,
  SUMMARY_TOTAL_EMPTY,
} from '@ui-components/modals/inspections-summary-report-modal/components/summary-table/summary-table.constant';
import { SELECT_FILTER_SHOW_BY_CONFIG } from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.constant';
import {
  ISelectFilterConfig,
  InspectionSummaryClickFiltering,
  InspectionSummaryShowByRecord,
  InspectionSummaryTableDTO,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryTableComponent implements OnChanges, AfterViewInit {
  @Input() tableData: InspectionSummaryTableDTO[] = [];

  @Input() showByType: ISelectFilterConfig = SELECT_FILTER_SHOW_BY_CONFIG[1];

  @Input() templateScrollPointName: string | null = null;

  @Output() filterInspectionListValue = new EventEmitter<InspectionSummaryClickFiltering>();

  @ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

  totals = { ...SUMMARY_TOTAL_EMPTY };

  readonly summaryTableColumnsRecord = { ...SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL, ...SUMMARY_TABLE_COLUMNS_RECORD };
  readonly inspectionSummaryShowByRecord = InspectionSummaryShowByRecord;

  readonly eIcon = EIcon;
  readonly eColorPalette = EColorPalette;
  readonly inspectionStatus = InspectionStatus;

  appliedFilter$ = this.inspectionsFacade.appliedFilter$.pipe(take(1));

  isAnimationDisabled$ = new BehaviorSubject(true);

  dataSource: MatTableDataSource<InspectionSummaryTableDTO> = new MatTableDataSource([]);

  columns = [SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL.template, ...Object.keys(SUMMARY_TABLE_COLUMNS_RECORD)];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private inspectionsFacade: InspectionsFacade) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData.currentValue?.length) {
      this.setTableData(changes.tableData.currentValue);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    this.rows && this.scrollTo();
  }

  private setTableData(value: InspectionSummaryTableDTO[]): void {
    if (value?.length) {
      this.dataSource = new MatTableDataSource(value);

      this.dataSource.sort = this.sort;

      this.totals = value.reduce(
        (acc, val) => {
          acc.not_started += val.not_started || 0;
          acc.in_progress += val.in_progress || 0;
          acc.late += val.late || 0;
          acc.expired += val.expired || 0;
          acc.review += val.review || 0;
          acc.completed += +val.completed || 0;
          acc.completed_late += +val.completed_late || 0;
          acc.total += val.total || 0;
          return acc;
        },
        {
          ...SUMMARY_TOTAL_EMPTY,
        }
      );
      this.totals.completed_procent = procentCut((this.totals.completed / this.totals.total) * 100);
      this.totals.completed_late_procent = procentCut((this.totals.completed_late / this.totals.total) * 100);
    }
  }

  onFilterInspectionList(value: InspectionSummaryTableDTO, status?: number): void {
    const filter = {
      templateId: value.templateId,
      assigneeId: value.assigneeId,
      status: isNumber(status) ? status : null,
      template: value.template,
    } as InspectionSummaryClickFiltering;

    this.filterInspectionListValue.emit(filter);

    this.updateAppliedFilters(value.template);
  }

  scrollTo() {
    if (this.templateScrollPointName) {
      const elem = this.rows.find(row => row.nativeElement.id === this.templateScrollPointName);

      elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  private updateAppliedFilters(template: string): void {
    this.appliedFilter$.subscribe(filters =>
      this.inspectionsFacade.setAppliedFilters({
        ...filters,
        template,
      })
    );
  }
}
