import { ChangeDetectionStrategy, Component } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: 'app-summary-skeleton',
  templateUrl: './summary-skeleton.component.html',
  styleUrls: ['./summary-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummarySkeletonComponent {
  readonly rows = range(10);
  readonly columns = range(8);
  readonly legendList = ['Completed', 'Completed Late', 'Expired', 'In Progress', 'Not Started', 'Late', 'Review'];
}
