import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPercentageValue',
})
export class GetPercentageValuePipe implements PipeTransform {
  transform(value: number, sumValue: number): string {
    if (!value || !sumValue) {
      return '0 %';
    }
    return `${((value / sumValue) * 100).toFixed(2)} %`;
  }
}
