import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateLength',
})
export class TruncateLengthPipe implements PipeTransform {
  transform(value: string, length = 32, completeWords = false, ellipsis = '...'): string {
    if (!value) return '';
    if (value.length <= length) return value;
    let truncatedValue = value.substring(0, length);
    if (completeWords) {
      const lastSpaceIndex = truncatedValue.lastIndexOf(' ');
      if (lastSpaceIndex > 0) {
        truncatedValue = truncatedValue.substring(0, lastSpaceIndex);
      }
    }
    return truncatedValue + ellipsis;
  }
}
