import { CommonModule, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MenuPositionX } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { IconComponent } from '@ui-components/components/icon/icon.component';

export interface IOption<T> {
  label?: string;
  id: T;
  disabled?: boolean;
}

export type buttonSize = 'sm' | 'm' | 'l' | 'xl';

@Component({
  selector: 'app-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgIf,
    NgForOf,
    IconComponent,
    MatIconModule,
    CommonModule,
  ],
})
export class ButtonMenuComponent implements OnChanges, OnInit {
  @Input() isEdit = false;
  @Input() isInProgress = false;
  @Input() title = '';
  @Input() titlePrefix = '';
  @Input() size: buttonSize = 'm';
  @Input() xPosition: MenuPositionX = 'after';
  @Input() options: IOption<any>[] = [];
  @Input() selectedOption: { label: string; id: number };
  @Input() isDisabled = false;
  @Input() isDisabledStroke = false;
  @Input() mainButtonDisabled = false;
  @Input() arrowDropdownDisabled = false;
  @Input() mainButtonAction: string | number | null = null;
  @Input() submitOnMainButtonClick = false;
  @Input() isRaisedButton = false;
  @Input() isLowercase = false;
  @Input() btnWidth: number;
  @Input() btnReports = false;
  @Input() btnResident = false;

  @Output() action = new EventEmitter<any | null>();
  @Output() submitAction = new EventEmitter<any | null>();

  EColorPalette = EColorPalette;
  EIcon = EIcon;

  optionsToDisplay: IOption<any>[] = [];

  ngOnInit(): void {
    this.optionsToDisplay = this.options;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedOption'] && this.options)
      this.optionsToDisplay = this.options.filter(el => el.id !== this.selectedOption.id);
  }

  onAction(optionId: string | number | null, isOption = false): void {
    this.submitOnMainButtonClick && !isOption ? this.submitAction.emit(optionId) : this.action.emit(optionId);
  }
}
