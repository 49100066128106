import { AbstractControl, ValidationErrors } from '@angular/forms';

export function propertyStateValidator(control: AbstractControl): ValidationErrors | null {
  const emojis = control.value;

  if (!emojis || emojis.length === 0) {
    return null;
  }

  const defaultEmojisCount = emojis.filter((emoji: any) => emoji.isDefault).length;

  if (defaultEmojisCount === 1) {
    return null;
  }

  return { defaultEmojiRequired: true };
}
