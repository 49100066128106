import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLinkWithHref } from '@angular/router';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { ChartModule } from '@ui-components/components/chart/chart.module';
import { ChartCardModule } from '@ui-components/components/chart-card/chart-card.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';

import { TurnoversArcChartCardComponent } from './turnovers-arc-chart-card.component';

@NgModule({
  declarations: [TurnoversArcChartCardComponent],
  imports: [
    SlideToggleComponent,
    CommonModule,
    ChartCardModule,
    ChartModule,
    ReactiveFormsModule,
    TooltipDirectiveModule,
    RouterLinkWithHref,
  ],
  exports: [TurnoversArcChartCardComponent],
})
export class TurnoversArcChartCardModule {}
