import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { suffixFunction } from '@shared/functions/suffix.function';

@Pipe({
  name: 'timeDiff',
})
export class TimeDiffPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: Date | string): string {
    const diffLabelPrefix = 'due in';
    const diffLabelSuffix = 'ago';

    const date = new Date(value);
    const today = this.timezoneService.getCurrentDate();

    const diffInYears = moment(today).diff(moment(date), 'years');
    const diffInMonths = moment(today).diff(moment(date), 'months');
    const diffInWeeks = moment(today).diff(moment(date), 'weeks');
    const diffInDays = moment(today).diff(moment(date), 'days');
    const diffInHours = moment(today).diff(moment(date), 'hours');

    if (diffInYears) {
      if (diffInYears > 0) {
        return `${this.getLabel(diffInYears, 'year')} ${diffLabelSuffix}`;
      }
      return `${diffLabelPrefix} ${this.getLabel(diffInYears, 'year')}`;
    }

    if (diffInMonths) {
      if (diffInMonths > 0) {
        return `${this.getLabel(diffInMonths, 'month')} ${diffLabelSuffix}`;
      }
      return `${diffLabelPrefix} ${this.getLabel(diffInMonths, 'month')}`;
    }

    if (diffInWeeks) {
      if (diffInWeeks > 0) {
        return `${this.getLabel(diffInWeeks, 'week')} ${diffLabelSuffix}`;
      }
      return `${diffLabelPrefix} ${this.getLabel(diffInWeeks, 'week')}`;
    }

    if (diffInDays) {
      if (diffInDays > 0) {
        if (diffInDays === 1) {
          return 'yesterday';
        }
        return `${this.getLabel(diffInDays, 'day')} ${diffLabelSuffix}`;
      }
      if (diffInDays === -1) {
        return `due tomorrow`;
      }
      return `${diffLabelPrefix} ${this.getLabel(diffInDays, 'day')}`;
    }

    if (diffInHours) {
      if (diffInHours > 0) {
        if (moment(today).isAfter(date)) {
          return 'yesterday';
        }
        return `today`;
      }
      return `due today`;
    }

    return `just now`;
  }

  getLabel(value: number, unit: string): string {
    value = Math.abs(value);

    return `${value} ${unit}${suffixFunction(value)}`;
  }
}
