import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { PictureModule } from '@ui-components/components/picture/picture.module';

import { GalleryModalComponent } from './gallery-modal.component';

@NgModule({
  declarations: [GalleryModalComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, PictureModule, IconComponent, TooltipDirectiveModule],
})
export class GalleryModalModule {}
