export function checkSupportedFormatFunction(file: any, acceptedFormatList: string[]): boolean {
  let type = file.type;
  if (type === '' && file.name.includes('.heic')) type = 'image/heic';
  if (type === '' && file.name.includes('.heif')) type = 'image/heif';

  return acceptedFormatList?.length === 0
    ? true
    : type === '' || type === null || type === undefined
    ? false
    : acceptedFormatList?.length
    ? acceptedFormatList?.includes(type)
    : true;
}
