import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const error = route.queryParamMap.get('error');
    const errorDescription = route.queryParamMap.get('error_description');
    const permissionRequired = route.queryParamMap.get('permission-required');
    const anyPermissionRequired = route.queryParamMap.get('any-permission-required');

    if ((error && errorDescription) || permissionRequired || anyPermissionRequired) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
