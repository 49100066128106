import { createAction, props } from '@ngrx/store';

import { AttachmentSection } from '@shared/enums/attachment-section';
import { UploadedFileItem } from '@shared/interfaces/uploaded-file-item.interface';

export const loadFileUploads = createAction('[FileUpload] Load FileUploads');

/**
 * upload file
 */
export const uploadFile = createAction(
  '[FileUpload] upload file',
  props<{ file: File; attachmentSection: AttachmentSection }>()
);
export const uploadFileSuccess = createAction(
  '[FileUpload] upload file success',
  props<{ uploadedFileItem: UploadedFileItem }>()
);
export const clearUploadedFile = createAction('[FileUpload] clear uploaded file');
export const uploadFileFail = createAction('[FileUpload] upload file fail');

/**
 * delete file
 */
export const deleteFile = createAction('[FileUpload] delete file', props<{ fileId: number }>());
export const deleteFileSuccess = createAction('[FileUpload] delete file success');
export const deleteFileFailed = createAction('[FileUpload] delete file failed');
