<div class="display-flex justify-content-center" [style.min-width]="attrWidth" [style.min-height]="attrHeight">
  <ng-lottie
    *ngIf="options"
    [options]="options"
    [width]="attrWidth"
    [height]="attrHeight"
    (animationCreated)="animationCreated($event)"
    (mouseover)="play()"
    (mouseleave)="stop()"></ng-lottie>
</div>
