/**
 * Copies passed string to clipboard
 * @param value string to copy
 */
export function copyToClipboard(value: string): boolean {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = value;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  const result = document.execCommand('copy');
  document.body.removeChild(selBox);
  return result;
}
