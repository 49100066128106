import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceValue',
})
export class ReplaceValuePipe implements PipeTransform {
  transform(value: string, pattern = '', replace = ''): string {
    if (!value) {
      return '';
    }
    return value.replace(pattern, replace);
  }
}
