import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { CalendarHeaderComponent } from './calendar-header.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [CalendarHeaderComponent],
  imports: [CommonModule, MatIconModule, IconComponent, MatButtonModule],
})
export class CalendarHeaderModule {}
