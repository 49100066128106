import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, filter, of, shareReplay } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';

import { UnitsService } from '@app/services/units.service';
import { loadUnitList } from '@dashboards/store/actions/dashboard.actions';
import { selectUnitList, selectUnitListLoading } from '@dashboards/store/selectors/dashboards.selectors';
import { selectSelectedPortfolioId } from '@dashboards/store/selectors/property-selector.selectors';
import { allPortfoliosValue } from '@main-application/application-menu/property-selector/constants';
import { selectProperties, selectPropertiesLoading } from '@portfolio/store/portfolio.selectors';
import { getPortfolioProperties } from '@shared/functions/get-portfolio-properties.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-property-unit-selector-modal',
  templateUrl: './property-unit-selector-modal.component.html',
  styleUrls: ['./property-unit-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioButtonDropdownModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class PropertyUnitSelectorModalComponent implements OnInit {
  form!: FormGroup;

  properties$: Observable<IRadioButtonOption<number>[]> = this.getProperties();
  units$ = new BehaviorSubject<IRadioButtonOption<number>[] | null>(null);
  selectPropertiesLoading$: Observable<boolean> = this.store.select(selectPropertiesLoading);
  unitListLoading$: Observable<boolean> = this.store.select(selectUnitListLoading);
  properties: RestPropertyModel[] = [];
  portfolioId!: number;
  submitted$ = new BehaviorSubject<boolean>(false);
  loadingUnit$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<PropertyUnitSelectorModalComponent>,
    private store: Store<{}>,
    private fb: FormBuilder,
    private unitsService: UnitsService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectSelectedPortfolioId)
      .pipe(untilDestroyed(this))
      .subscribe(portfolioId => {
        this.portfolioId = portfolioId;
      });

    this.initForm();
    this.onPropertyControlChange();
    this.getUnits();
  }

  initForm(): void {
    this.form = this.fb.group({
      propertyId: [null, [Validators.required]],
      unitId: [null, [Validators.required]],
    });
  }

  submit(): void {
    this.submitted$.next(true);
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.loadingUnit$.next(true);
    this.getUnit(Number(this.form.get('unitId')?.value));
  }

  private getUnit(unitId: number): void {
    this.unitsService
      .getUnitById(unitId)
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.snackbarService.error(SnackbarErrorMessage.LoadingUnit);
          return of(null);
        })
      )
      .subscribe(unit => {
        if (!unit) return;
        this.loadingUnit$.next(false);
        const property = this.properties.find(el => el.id === this.form.get('propertyId')?.value);
        this.dialogRef.close({ unit, property });
      });
  }

  private getProperties(): Observable<IRadioButtonOption<number>[]> {
    return this.store.select(selectProperties).pipe(
      filter((properties: RestPropertyModel[]) => !!properties),
      map(properties => {
        this.properties = properties;
        return getPortfolioProperties(
          properties.filter(p => this.portfolioId === allPortfoliosValue || p.portfolioId === this.portfolioId)
        );
      }),
      shareReplay(),
      untilDestroyed(this)
    );
  }

  private onPropertyControlChange(): void {
    this.form
      .get('propertyId')
      ?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(propertyId => {
        this.form.get('unitId').setValue(null);
        if (propertyId) this.store.dispatch(loadUnitList({ propertyId }));
      });
  }

  private getUnits(): void {
    this.store
      .select(selectUnitList)
      .pipe(
        map(unitList => {
          return unitList
            .map<IRadioButtonOption<number>>(item => {
              return {
                value: item.id,
                label: item.name,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        }),
        shareReplay(),
        untilDestroyed(this)
      )
      .subscribe(units => {
        if (units.length === 1) this.form.get('unitId').setValue(units[0].value);
        this.units$.next(units);
      });
  }
}
