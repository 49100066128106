import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { EmptyDashboardModule } from '@app/modules/ui-components/components/empty-dashboard/empty-dashboard.module';
import { AnimationModule } from '@ui-components/components/animation/animation.module';

import { DashboardContainerComponent } from './dashboard-container.component';

@NgModule({
  declarations: [DashboardContainerComponent],
  imports: [CommonModule, MatProgressSpinnerModule, EmptyDashboardModule, AnimationModule],
  exports: [DashboardContainerComponent],
})
export class DashboardContainerModule {}
