import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  InspectionSummaryAppliedFilters,
  InspectionSummaryFilters,
  InspectionSummaryStatsResponse,
} from '@main-application/inspections/models/inspection-summary.model';
import {
  loadInspectionSummaryReport,
  setSummaryReportAppliedFilter,
} from '@main-application/inspections/store/inspections.actions';
import getInspectionsSummaryFiltersKey from '@main-application/inspections/utils/inspection-mappers';
import { selectTemplatesLoading } from '@template/store/template.selectors';

import { IInspectionsStateTree } from './inspections.reducer';
import {
  selectDynamicInspectionsLoading,
  selectInspectionsLoading,
  selectInspectionsSummaryReport,
  selectInspectionsSummaryReportAppliedFilter,
  selectRepeatingInspectionsLoading,
} from './inspections.selectors';

@Injectable({ providedIn: 'root' })
export class InspectionsFacade {
  isLoading$ = combineLatest([
    this.store.select(selectInspectionsLoading),
    this.store.select(selectDynamicInspectionsLoading),
    this.store.select(selectRepeatingInspectionsLoading),
    this.store.select(selectTemplatesLoading).pipe(take(2)),
  ]).pipe(map(([a, b, c, d]) => a || b || c || d));

  inspectionSummaryReportLoading$ = this.store.select(selectInspectionsLoading);

  appliedFilter$ = this.store.select(selectInspectionsSummaryReportAppliedFilter);

  constructor(private store: Store<IInspectionsStateTree>) {}

  getInspectionSummaryReportDataByFilterKey(
    filter: InspectionSummaryFilters
  ): Observable<InspectionSummaryStatsResponse | null> {
    this.store.dispatch(loadInspectionSummaryReport({ filter }));

    return this.store
      .select(selectInspectionsSummaryReport)
      .pipe(map(value => (value ? value[getInspectionsSummaryFiltersKey(filter)] : null)));
  }

  setAppliedFilters(appliedFilters: InspectionSummaryAppliedFilters): void {
    this.store.dispatch(setSummaryReportAppliedFilter({ appliedFilters }));
  }
}
