import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { checkIfElementVisible } from '@main-application/shared/template-editor-dialog/models/share-methods.function';
import {
  AreaPageElementRatingGroupModel,
  AreaPageModel,
  TemplateEditorRatingGroupItem,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { AnimatedEmojiService } from '@main-application/shared/template-editor-dialog/services/animated-emoji.service';
import { DndExpandableService } from '@main-application/shared/template-editor-dialog/services/dnd-expandable.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-rating-group',
  templateUrl: './template-editor-rating-group.component.html',
  styleUrls: ['./template-editor-rating-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogRatingGroupComponent implements OnInit {
  EColorPalette = EColorPalette;
  EIcon = EIcon;
  emojis$ = this.animatedEmojiService.getEmojiContent();
  showEmoji = false;

  @Input() ratingGroup: AreaPageElementRatingGroupModel;
  @Input() parent: AreaPageModel;
  @Input() greatGrandParent: RestTemplateModel;

  @Output() ratingGroupChange = new EventEmitter<AreaPageElementRatingGroupModel>();

  constructor(
    private snackbarService: SnackbarService,
    private animatedEmojiService: AnimatedEmojiService,
    private cdr: ChangeDetectorRef,
    private dndExpandableService: DndExpandableService,
    private readonly viewRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.showEmoji = checkIfElementVisible(
      this.viewRef.element.nativeElement,
      this.dndExpandableService.extraBufferHeight
    );
    setTimeout(() => this.recheckShowEmoji(), 50);

    this.dndExpandableService.viewportZoneChanging$.subscribe(e => this.recheckShowEmoji());
  }

  private recheckShowEmoji() {
    const showEmoji = checkIfElementVisible(
      this.viewRef.element.nativeElement,
      this.dndExpandableService.extraBufferHeight
    );
    if (this.showEmoji != showEmoji) {
      this.showEmoji = showEmoji;
      this.cdr.markForCheck();
    }
  }

  makeDefault(item: TemplateEditorRatingGroupItem) {
    this.ratingGroupChange.emit({
      ...this.ratingGroup,
      items: this.ratingGroup.items.map(ratingItem => ({
        item: {
          ...ratingItem.item,
          isDefault: ratingItem.item.id === item.item.id && !item.item.isDefault,
        },
      })),
    });
  }

  onRatingItemChange(item: TemplateEditorRatingGroupItem) {
    const items = [...this.ratingGroup.items];
    const index = items.findIndex(e => e.item.id == item.item.id);
    if (index < 0) return;

    items.splice(index, 1, item);
    this.ratingGroupChange.emit({
      ...this.ratingGroup,
      items: items,
    });
  }

  onRatingItemDeleted(id: string) {
    const items = [...this.ratingGroup.items];
    if (items.length < 3) {
      this.snackbarService.error('Inspections require at least 2 ratings');
      return;
    }
    const index = items.findIndex(e => e.item.id == id);
    if (index < 0) return;

    items.splice(index, 1);
    this.ratingGroupChange.emit({
      ...this.ratingGroup,
      items: items,
    });
  }

  add() {
    if (this.ratingGroup.items.length >= 6) return;

    this.emojis$.pipe(untilDestroyed(this), take(1)).subscribe(emojis => {
      const emoji = emojis[Math.floor(Math.random() * emojis.length)];
      const newItem: TemplateEditorRatingGroupItem = {
        item: {
          id: `item${uuidv4()}`,
          text: null,
          emoji: emoji.emojiCode,
          backgroundColor: '#FFFFFF',
          onSelect: this.parent.compulsoryItems ? [...this.parent.compulsoryItems] : [],
          isDefault: false,
          timeMultiplier: 0,
          materialsMultiplier: 0,
          resultScore: null,
          showQtyWidget: false,
          showSpareParts: false,
          showHoursWidget: false,
          ticketRequired: false,
          sparePartsSelectionRequired: false,
        },
      };
      const items = [...this.ratingGroup.items, newItem];

      this.ratingGroupChange.emit({
        ...this.ratingGroup,
        items: items,
      });
    });
  }

  trackBy(_index: number, item: TemplateEditorRatingGroupItem) {
    return item.item.id;
  }
}
