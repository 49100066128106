import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartConfig, ChartDataItem } from '@shared/interfaces/chart-data-item';

@UntilDestroy()
@Component({
  selector: 'app-summary-chart',
  templateUrl: './summary-chart.component.html',
  styleUrls: ['./summary-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryChartComponent extends ComponentAbstract {
  @Input() set summaryChartData(value: ChartDataItem[]) {
    if (value?.length) {
      this.chartData = [...value];
    }
  }

  chartData: ChartDataItem[] = [];

  readonly chartConfig: ChartConfig = {
    showTextTooltip: false,
    showTooltip: true,
    showDataLabel: false,
    cutoutLevel: 70,
    spacing: 1,
  };

  readonly eColorPalette = EColorPalette;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
