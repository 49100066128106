import { AbstractControl, ValidatorFn } from '@angular/forms';

export const PhoneNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value) {
      return null;
    }

    const { phoneCode, phoneNumber } = parsePhoneNumber(control.value);
    const phoneCodeN = parseInt(phoneCode);
    const phoneNumberN = parseInt(phoneNumber);

    return !isNaN(phoneNumberN) &&
      phoneNumber.length === 10 &&
      !isNaN(phoneCodeN) &&
      phoneCode.length > 0 &&
      phoneCode.length < 5
      ? null
      : { phoneNumber: true };
  };
};

export const parsePhoneNumber = (value?: string): { phoneNumber: string; phoneCode: string } => {
  const number = (value || '').replace(/\D/g, '');
  let phoneNumber = null;
  let phoneCode = null;
  if (number.length >= 10) {
    phoneNumber = number.substring(number.length - 10);
    phoneCode = number.substring(0, number.length - 10);
  } else {
    phoneNumber = number;
    phoneCode = null;
  }
  return { phoneNumber, phoneCode };
};
