import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { loadAllUsers } from '@main-application/administration/store/actions/administration.actions';
import { IAdministrationStateTree } from '@main-application/administration/store/reducers/administration.reducer';
import { selectAllUsers } from '@main-application/administration/store/selectors/administration.selectors';

@Injectable({ providedIn: 'root' })
export class AdministrationFacade {
  readonly usersList$ = this.store.select(selectAllUsers);

  constructor(private store: Store<IAdministrationStateTree>) {}

  getUsers(): void {
    this.store.dispatch(loadAllUsers({}));
  }
}
