import { v4 as uuidv4 } from 'uuid';

import { AreaHelper } from './area.helper';
import { AreaModel, AreaSurveyModel } from './template-editor-model';

export function scrollIfOutOfDialog(element: HTMLElement) {
  if (element && !checkIfElementVisible(element)) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}

export function checkIfElementVisible(element: HTMLElement, extraHeightSpace: number = null) {
  if (!element) return false;

  let matDialogElement = element.parentElement;
  while (matDialogElement.tagName.toUpperCase() != 'MAT-DIALOG-CONTENT' && matDialogElement.parentElement) {
    matDialogElement = matDialogElement.parentElement;
  }
  if (!matDialogElement) {
    return true;
  }
  const rect = element.getBoundingClientRect();
  const dialogRect = matDialogElement.getBoundingClientRect();

  const bottom = rect.top + (typeof extraHeightSpace === 'number' ? -extraHeightSpace : rect.height);
  const top = rect.top + (typeof extraHeightSpace === 'number' ? rect.height + extraHeightSpace : 0);

  return bottom <= dialogRect.top + dialogRect.height && top >= dialogRect.top;
}

export function getUpdatedAreaModel(area: AreaModel) {
  // update title for parentless this.isParentless ? [{ ...this.area.areaSurvey.pages[0], title: value }]
  const isParentlesss = area.areaSurvey.pages?.length === 1 && !area.areaSurvey.pages[0].pageType;
  return {
    ...area,
    ...(isParentlesss ? { title: area.areaSurvey.pages[0].title } : {}),
    areaSurvey: { ...area.areaSurvey, pages: [...area.areaSurvey.pages] },
  };
}

export function getInitialAreaData(
  title: string,
  isParentless = false,
  pageTitle = 'Item 1',
  forNewTemplate = false,
  ratingGroup = null
): AreaSurveyModel {
  const areaSurvey: AreaSurveyModel = {
    title: title,
    logoPosition: 'right',
    pages: [
      {
        uuid: uuidv4(),
        name: `page1`,
        title: isParentless ? title : pageTitle,
        compulsoryItems: [],
        ...(!isParentless && { pageType: 'area' }),
        elements: [
          // default invisible items for mobile
          {
            type: 'file',
            name: 'Pictures:',
            title: 'Pictures:',
            description: 'Pictures:',
          },
          {
            type: 'comment',
            name: 'Enter a note:',
            title: 'Enter a note:',
          },
        ],
      },
    ],
  };
  if (forNewTemplate) {
    AreaHelper.addQuestion(areaSurvey, areaSurvey.pages[0], 'Is it incredible?');
    AreaHelper.addQuestion(areaSurvey, areaSurvey.pages[0], 'is it truly incredible?', false);
  }
  if (ratingGroup) {
    AreaHelper.addOrReplaceRatingGroup(areaSurvey, areaSurvey.pages[0], ratingGroup);
  }
  return areaSurvey;
}

export function handleMaxLimitContentEditable(event: KeyboardEvent, divElement: HTMLDivElement, limit: number) {
  if (
    divElement.textContent.length > limit - 1 &&
    event.keyCode != 8 &&
    event.keyCode != 37 &&
    event.keyCode != 38 &&
    event.keyCode != 39 &&
    event.keyCode != 40 &&
    event.keyCode != 46
  ) {
    event.preventDefault();
  }

  if (divElement.textContent.length > limit - 1) {
    divElement.textContent = divElement.textContent?.substring(0, limit);
    setTimeout(() => {
      const range = document.createRange();
      range.setStart(divElement, divElement.textContent.length);
      range.setEnd(divElement, divElement.textContent.length);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }
}
