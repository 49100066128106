import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';

export type TooltipHelpMarginLeft = 'm' | 'xs' | 's' | 'none';
export type TooltipHelpPosition = 'after' | 'before' | 'above' | 'below' | 'left' | 'right';

@Component({
  selector: 'app-tooltip-help',
  templateUrl: './tooltip-help.component.html',
  styleUrls: ['./tooltip-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TooltipDirectiveModule],
})
export class TooltipHelpComponent {
  @Input() text!: string;
  @Input() position: TooltipHelpPosition = 'above';
  @Input() marginLeftType: TooltipHelpMarginLeft = 'none';
  @Input() label?: string;
}
