<div class="prompt-modal-container display-flex flex-column">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content>
    <form [formGroup]="form" class="mb-20">
      <div class="display-flex flex-column grid-gap-16">
        <app-input
          [attrLabel]="data.fieldName"
          [contentCss]="'display-flex flex-column fill company-details-input body-small'"
          [formControl]="displayName"
          [attrPlaceholder]="data.fieldPlaceholder"
          [labelRequired]="true"
          [showValidationError]="isSubmitted"
          (keydownEnterEvent)="save()"></app-input>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-space-between">
    <button mat-stroked-button [color]="data.cancelButtonColor" (click)="cancel()">Cancel</button>
    <button mat-raised-button [color]="data.okButtonColor" (click)="save()">{{ data.okButtonText }}</button>
  </mat-dialog-actions>
</div>
