import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputTimeManualComponent } from './input-time-manual.component';

@NgModule({
  declarations: [InputTimeManualComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  exports: [InputTimeManualComponent],
})
export class InputTimeManualModule {}
