import { TreeModel, TreeNode } from '@circlon/angular-tree-component';

// built-in function had deffects (highly affecting tests)
// https://github.com/CirclonGroup/angular-tree-component/issues/928
//
export function doForAllTreeNodes(treeModel: TreeModel, callback: (node: TreeNode) => void) {
  treeModel.roots.forEach(node => doForAllNodes(node, callback));
}

function doForAllNodes(node: TreeNode, callback: (node: TreeNode) => void) {
  if (!node) return;

  callback(node);
  if (node.children) {
    node.children.forEach(child => doForAllNodes(child, callback));
  }
}

export const NODES_RE_INIT_DELAY = 500;
