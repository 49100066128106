import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundValue',
})
export class RoundValuePipe implements PipeTransform {
  transform(value: number): number {
    if (!value) {
      return 0;
    }
    return Math.round(value);
  }
}
