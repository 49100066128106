import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ISelectFilterConfig } from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@UntilDestroy()
@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilterComponent {
  @Input() showBrackets = false;

  @Input() selectFiltersConfig: ISelectFilterConfig[];

  @Input() selectedFilter: ISelectFilterConfig;

  @Output() selectedItemEvent: EventEmitter<ISelectFilterConfig> = new EventEmitter<ISelectFilterConfig>();

  selectedTab(item: ISelectFilterConfig) {
    this.selectedItemEvent.emit(item);
  }
}
