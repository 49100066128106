import { RestAddressModel } from '@shared/interfaces/address.interface';

export function FormatAddressToStringFunction(address: RestAddressModel): string {
  if (!address) {
    return '–';
  }
  return `${address?.street1 || ''} ${address?.street2 || ''} ${address?.city || ''}  ${address?.state || ''} ${
    address?.zip || ''
  }`;
}
