import { createReducer, on } from '@ngrx/store';

import { setWindowWidth } from '@main-application/store/actions/window-resize.actions';

export const windowResizeFeatureKey = 'windowResize';

export interface State {
  windowWidth: number;
}

export const initialState: State = {
  windowWidth: null,
};

export const reducer = createReducer(
  initialState,
  /**
   * set window width
   */
  on(setWindowWidth, (state, action) => ({
    ...state,
    windowWidth: action.windowWidth,
  }))
);
