import { EnumerationItemFields } from '@shared/enums/enumeration-item-fields';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export function getEnumerationRadioListFunction<T>(
  enumerationItems: EnumerationItem[],
  nullValueLabel = 'All',
  withNullValue = true,
  valueKey = EnumerationItemFields.enumerationValue
): IRadioButtonOption<T>[] {
  const list: IRadioButtonOption<T>[] = [];

  if (withNullValue) {
    list.push({
      label: nullValueLabel,
      value: null,
    });
  }

  (enumerationItems || []).forEach(item => {
    list.push({
      value: item[valueKey] as T,
      label: item.displayText,
    });
  });

  return list;
}
