import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { IconComponent } from '@ui-components/components/icon/icon.component';

import { TableStatusToggleComponent } from './table-status-toggle.component';

@NgModule({
  declarations: [TableStatusToggleComponent],
  exports: [TableStatusToggleComponent],
  imports: [CommonModule, IconComponent, MatButtonModule, MatCheckboxModule, ReactiveFormsModule],
})
export class TableStatusToggleModule {}
