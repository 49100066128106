import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { RestSurveyTemplate } from '@shared/interfaces/survey-template';
import { RestTemplateAreaModel } from '@template/models/rest-template-model.interface';

@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  constructor(private restApiService: RestapiService) {}

  getSurveyTemplates(): Observable<RestSurveyTemplate[]> {
    return this.restApiService.getData<RestSurveyTemplate[]>('AdminPanel/templatesList');
  }

  saveTemplate(id: number, template: string): Observable<RestSurveyTemplate[]> {
    return this.restApiService.update<RestSurveyTemplate[]>('AdminPanel/template', { id, template });
  }

  get(id: number): Observable<RestTemplateAreaModel> {
    return this.restApiService.getData<RestTemplateAreaModel>(`inspections/template/survey/${id}`);
  }

  add(survey: RestTemplateAreaModel): Observable<number> {
    return this.restApiService.post<number>(`inspections/template/survey/add`, survey);
  }

  update(survey: RestTemplateAreaModel): Observable<void> {
    return this.restApiService.post(`inspections/template/survey/${survey.id}`, survey);
  }

  delete(id: number): Observable<void> {
    return this.restApiService.post<void>('inspections/template/survey/delete', id);
  }
}
