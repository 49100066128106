import { cloneDeep, forEach, reduce } from 'lodash';

import { PropertyBasicInfo, PropertyBasicInfoNode } from '@shared/interfaces/propertyBasicInfo';

export function formatPropertyStructureToTree(allProperties: PropertyBasicInfo[]) {
  const root = {
    children: [],
    name: 'All',
    id: -1,
  } as unknown as PropertyBasicInfoNode;
  const properties: PropertyBasicInfoNode[] = cloneDeep(allProperties);
  const propertiesIndexMap = reduce(
    properties,
    (result, singleProperty, index) => {
      result[singleProperty.id] = index;
      return result;
    },
    {}
  );

  forEach(properties, singleProperty => {
    if (!singleProperty.parentId) {
      singleProperty.parent = root;
      root.children = [...root.children, singleProperty];
      return;
    }

    const parentIndex = propertiesIndexMap[singleProperty.parentId];
    const parent = properties[parentIndex];
    if (parent === undefined) {
      console.warn(
        "couldn't find parent for element ",
        singleProperty.id,
        ' with parentId = ',
        singleProperty.parentId
      );
      return;
    }
    singleProperty.parent = parent;
    parent.children = [...(parent.children || []), singleProperty];
  });

  return [root];
}
