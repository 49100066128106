export interface ParamsCompareItem {
  currentValue: unknown;
  expectValue: unknown;
}

export interface ParamsCompareFunc {
  compare: () => boolean;
}

export function isParamsCompareFunc(object: any): object is ParamsCompareFunc {
  return typeof object.compare === 'function';
}
