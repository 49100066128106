import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-too-long-modal',
  templateUrl: './comment-too-long-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentTooLongModalComponent {
  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CommentTooLongModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {}

  cancel() {
    this.dialogRef.close();
  }
}
