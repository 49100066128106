import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'appInputTextPlaceholder',
})
export class PlaceholderPipe implements PipeTransform {
  transform(controlValue: string, placeholder = ''): string {
    return controlValue ? '' : placeholder;
  }
}
