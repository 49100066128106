import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule, TooltipDirectiveModule],
})
export class SlideToggleComponent extends CustomControlAbstract<boolean> implements OnInit, ControlValueAccessor {
  @Input() attrRightLabel: string;
  @Input() attrLeftLabel: string;
  @Input() labelCss = 'body-small';
  @Input() containerCss = 'display-flex grid-gap-8 align-items-center';
  @Input() activeBold = false;
  @Input() tooltip: string;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlBase();

    if (this.ngControl instanceof NgModel) {
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
        if ((this.ngControl.control as unknown as { _pendingChange: boolean })._pendingChange) {
          this.ngControl.viewToModelUpdate(value);
        }
      });
    }
  }

  writeValue(value: boolean): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  valueChange(value: boolean) {
    this.onChanged(value);
  }

  setValue(value: boolean) {
    if (this.ngControl?.control?.disabled) {
      return;
    }
    this.onChanged(value);
    this.onTouched();
  }
}
