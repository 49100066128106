import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { InputModule } from '@ui-components/controls/input/input.module';

import { PromptModalComponent } from './prompt-modal.component';

@NgModule({
  declarations: [PromptModalComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, ReactiveFormsModule, InputModule],
})
export class PromptModalModule {}
