import { reduce } from 'lodash';

import { EnumerationItem } from '@shared/interfaces/enumeration-item';

export enum EnumerationProperties {
  enumerationValue = 'enumerationValue',
  fullyqualifiedUrn = 'fullyqualifiedUrn',
  simpleText = 'simpleText',
  displayText = 'displayText',
  fullDescription = 'fullDescription',
}

interface EnumerationItemMap {
  [key: string]: number | string;
}

export function enumerationListToKeyValueMap(
  enumerationList: EnumerationItem[],
  key: EnumerationProperties,
  value: EnumerationProperties
): EnumerationItemMap {
  return reduce(
    enumerationList,
    (result, enumerationItem) => {
      result[enumerationItem[key]] = enumerationItem[value];
      return result;
    },
    {} as EnumerationItemMap
  );
}
