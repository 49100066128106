<div class="number-input-container" [ngClass]="[containerCss, classList]">
  <ng-container *ngIf="!labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
  <div class="display-flex flex-column fill">
    <div class="input-wrapper fill" [ngClass]="inputCss">
      <mat-form-field
        floatLabel="never"
        [ngClass]="[
          markAsInvalid || (errors && showValidationErrorOrSubmitted) ? 'invalid' : '',
          errors ? 'error-section' : '',
          control.value || control.value === 0 ? 'filled' : '',
          control.disabled ? 'disabled' : ''
        ]"
        [attr.aria-invalid]="errors && showValidationErrorOrSubmitted">
        <ng-container *ngIf="labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
        <div class="input-container">
          <div class="prefix" [ngClass]="{ 'small-font': isSmallFont }">{{ prefix }}</div>
          <div class="input-text-resizer" [ngClass]="{ 'small-font': isSmallFont }">
            {{ inputControl.value }}
            <input
              aria-label="number-input"
              #inputControl
              [ngClass]="[
                control.value | numberValidation,
                (errors && showValidationErrorOrSubmitted) || markAsInvalid ? 'invalid' : '',
                errors ? 'error-section' : '',
                control.value || control.value === 0 ? 'filled' : '',
                control.disabled ? 'disabled' : ''
              ]"
              matInput
              type="text"
              [formControl]="innerControl"
              [placeholder]="attrPlaceholder"
              [attr.aria-invalid]="(errors && showValidationErrorOrSubmitted) || markAsInvalid"
              (blur)="onBlur($event)"
              (keydown.enter)="keydownEnter($event)"
              (keyup)="keyUp($event, inputControl)"
              [mask]="attrMask"
              [thousandSeparator]="attrThousandSeparator"
              [validation]="true"
              [allowNegativeNumbers]="true"
              [maxlength]="max" />
          </div>
          <div class="postfix" [ngClass]="{ 'small-font': isSmallFont }">{{ postfix }}</div>
        </div>
        <button
          aria-label="delete-button"
          type="button"
          *ngIf="showClearButton"
          mat-icon-button
          mat-icon-button
          (click)="clear()">
          <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
        </button>
      </mat-form-field>
    </div>

    <app-input-error
      *ngIf="errorSection && errors && displayErrorContent"
      [errors]="errors"
      [visible]="markAsInvalid || (errors && showValidationErrorOrSubmitted)"></app-input-error>
  </div>
</div>

<ng-template #labelTemplate>
  <div #labelElement class="label-container" *ngIf="label || icon">
    <label
      class="dropdown-label"
      [ngClass]="[
        labelCss,
        labelInside ? 'inside' : '',
        control.disabled ? 'disable' : '',
        labelRequired ? 'label-required' : ''
      ]"
      [matTooltip]="labelTooltip"
      *ngIf="label">
      {{ label }}
    </label>
    <app-icon [matTooltip]="iconTooltip" *ngIf="icon" [svg]="icon" [iconHeight]="24" [iconWidth]="24"></app-icon>
  </div>
</ng-template>
